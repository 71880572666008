import React from "react"
import "./ABDetail.css"
import { connect } from "react-redux"
import Navbar from "../../components/Navbar"
import { faClock } from "@fortawesome/free-regular-svg-icons"
import { faArrowCircleUp, faArrowCircleDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Chart from "chart.js"
import { compareLineData } from "../../datas/chartjs/line"
import Card from "../../components/chart/Card"
import BootstrapTable from 'react-bootstrap-table-next';

function upliftFormatter(cell){
    if(cell > 0){
        return(
        <span className="text-success">{"+"+cell+"%"}</span>
        )
    } else if (cell < 0){
        return(
            <span className="text-danger">{cell+"%"}</span>
        )
    } else {
        return(
            <span className="text-dark">{cell}</span>
        )
    }
}

class ABDetail extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            config1: compareLineData,
            AOV_columns: [
                {
                    dataField: 'variation',
                    text: 'Variation',
                    sort: true,
                },
                {
                    dataField: 'user',
                    text: 'User',
                    sort: false,
                },
                {
                    dataField: 'purchases',
                    text: 'Purchases',
                    sort: false,
                },
                {
                    dataField: 'AOV',
                    text: 'AOV',
                    sort: false,
                },
                {
                    dataField: 'uplift',
                    text: 'Uplift',
                    sort: false,
                    formatter: upliftFormatter,
                },
                {
                    dataField: 'best',
                    text: 'Probability to be Best',
                    sort: false,
                },
            ],
            AOV_products: [
                {
                    "variation": "Variation A",
                    "user": "3,881",
                    "purchases": "6,375",
                    "AOV": "$26.56",
                    "uplift": "Baseline",
                    "best": "0%",
                },
                {
                    "variation": "Variation B",
                    "user": "2,581",
                    "purchases": "3,035",
                    "AOV": "$22.56",
                    "uplift": 11.3,
                    "best": ">99%",
                }
            ],
            CTR_columns: [
                {
                    dataField: 'variation',
                    text: 'Variation',
                    sort: true,
                },
                {
                    dataField: 'user',
                    text: 'User',
                    sort: false,
                },
                {
                    dataField: 'clicks',
                    text: 'Clicks',
                    sort: false,
                },
                {
                    dataField: 'impressions',
                    text: 'Impressions',
                    sort: false,
                },
                {
                    dataField: 'uplift',
                    text: 'Uplift',
                    sort: false,
                    formatter: upliftFormatter,
                },
                {
                    dataField: 'best',
                    text: 'Probability to be Best',
                    sort: false,
                },
            ],
            CTR_products: [
                {
                    "variation": "Variation A",
                    "user": "3,881",
                    "clicks": "6,375",
                    "impressions": "$26.56",
                    "uplift": "Baseline",
                    "best": ">99%",
                },
                {
                    "variation": "Variation B",
                    "user": "2,581",
                    "clicks": "3,035",
                    "impressions": "$22.56",
                    "uplift": -7.5,
                    "best": "0%",
                }
            ],
            Purchases_columns: [
                {
                    dataField: 'variation',
                    text: 'Variation',
                    sort: true,
                },
                {
                    dataField: 'user',
                    text: 'User',
                    sort: false,
                },
                {
                    dataField: 'purchases',
                    text: 'Purchases',
                    sort: false,
                },
                {
                    dataField: 'purchasesPerUser',
                    text: 'Purchases / User',
                    sort: false,
                },
                {
                    dataField: 'uplift',
                    text: 'Uplift',
                    sort: false,
                    formatter: upliftFormatter,
                },
                {
                    dataField: 'best',
                    text: 'Probability to be Best',
                    sort: false,
                },
            ],
            Purchases_products: [
                {
                    "variation": "Variation A",
                    "user": "3,881",
                    "purchases": "6,375",
                    "purchasesPerUser": "$26.56",
                    "uplift": "Baseline",
                    "best": ">99%",
                },
                {
                    "variation": "Variation B",
                    "user": "2,581",
                    "purchases": "3,035",
                    "purchasesPerUser": "$22.56",
                    "uplift": -23.7,
                    "best": "0%",
                }
            ],
        }
    }

    componentDidMount() {
        var ctx = document.getElementById("chart-1").getContext("2d");
        var chart_1 = new Chart(ctx, this.state.config1)
        this.setState({chart_1})
    }

    render(){
        if(this.state.chart_1){
            this.state.chart_1.update()
        }
        return (
            <>
            <div className="ab--detail--progress">
                <Navbar type="2" title ="A/B Test" title2 = "Result" />
            </div>
            <div className="ab--detail--title">
                <div className="title--top">
                    <div className="col-4 pt-2 pb-1" style={{borderRight:"2px solid rgba(226, 226, 226, 0.9)"}}>
                        <span className="text-secondary">Time Frame</span>
                        <h5>Current Version(13 days)</h5>
                    </div>
                    <div className="col-4 pt-2 pb-1" style={{borderRight:"2px solid rgba(226, 226, 226, 0.9)"}}>
                        <span className="text-secondary">Primary Metric</span>
                        <h5>Puchases Revenue / User</h5>
                    </div>
                    <div className="col-4 pt-2 pb-1">
                        <span className="text-secondary">Last Modified</span>
                        <h5>2020-07-24, <span className="text-secondary">By Admin</span></h5>
                    </div>
                </div>
                <div className="title--body">
                    <FontAwesomeIcon icon={faClock} size="2x" style={{color:"gray", marginRight:"0.7rem"}}/>
                    <span>
                        <span className="h6">
                            Rec Widget, Variation A(Control) is leading
                        </span>
                        : however we recommend waiting 3 more days until the minimum test period (2 weeks) is met
                    </span>
                 </div>
            </div>
            <div className="ab--detail--content">
                <div className="mt-3">
                    <Card title="Puchases Revenue / User" canvasId="chart-1" height="250px"/>
                </div>
                <h4 className="text-secondary">Additional Metric</h4>
                <div className="content--table">
                    <div className="content--table--top" style={{borderBottom:"2px solid green"}}>
                        <FontAwesomeIcon icon={faArrowCircleUp} size="lg" style={{color:"green"}}/>
                        <span className="h5 mb-0">&nbsp;AOV</span>
                    </div>
                    <BootstrapTable 
                        bootstrap4
                        keyField='variation' 
                        noDataIndication={ 'No Tests, Please Create' }
                        data={ this.state.AOV_products } 
                        columns={ this.state.AOV_columns }
                    />
                </div>
                <div className="content--table">
                    <div className="content--table--top" style={{borderBottom:"2px solid red"}}>
                        <FontAwesomeIcon icon={faArrowCircleDown} size="lg" style={{color:"red"}}/>
                        <span className="h5 mb-0">&nbsp;CTR</span>
                    </div>
                    <BootstrapTable 
                        bootstrap4
                        keyField='variation' 
                        noDataIndication={ 'No Tests, Please Create' }
                        data={ this.state.CTR_products } 
                        columns={ this.state.CTR_columns }
                    />
                </div>
                <div className="content--table">
                    <div className="content--table--top" style={{borderBottom:"2px solid red"}}>
                        <FontAwesomeIcon icon={faArrowCircleDown} size="lg" style={{color:"red"}}/>
                        <span className="h5 mb-0">&nbsp;Purchases</span>
                    </div>
                    <BootstrapTable 
                        bootstrap4
                        keyField='variation' 
                        noDataIndication={ 'No Tests, Please Create' }
                        data={ this.state.Purchases_products } 
                        columns={ this.state.Purchases_columns }
                    />
                </div>
            </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ABDetail);