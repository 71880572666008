import React from "react"
import "./ABResult.css"
import { connect } from "react-redux"
import { changePage } from "../../store/actions/authentication"
import Navbar from "../../components/Navbar"
import { faPlus, faUserCircle, faPen, faPlay, faPause, faChartBar, faTrashAlt, faEllipsisV, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getABTest, deleteABTest, updateABTest, addSubTest } from "../../store/actions/condition"
import SelectionBoxs from "../../components/SelectionBoxs"
import $ from 'jquery'

class ABResult extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            setCategories: [], //편집 카테고리 드랍다운 이름 (2차원배열, 재설정 or ~)
            newCategories: [], //편집 카테고리 값 (2차원배열, 배열값)
            addCategories: [], //테스트 추가 카테고리 드랍다운 이름
            addNewCategories: [], //테스트 추가 카테고리 값
            addAlgorithm: [], //테스트 추가 알고리즘 값
            abTestName: "",
            experienceName: "experienceA",
            categories : ["신발", "패션소품", "상의", "하의", "쥬얼리", "헤어악세사리", "메이크업", "가발", "신발", "패션소품", "상의", "하의", "쥬얼리", "헤어악세사리", "메이크업", "가발"],
            abTests: this.props.abTests,
            modal_backup : {
                abDivisionNumber: "",
                algorithm: "",
                categories: []
            },
            resetSelection: false,
            addSubTest: [],
            selectedHost: this.props.selectedHost,
        }
        this.props.getABTest(this.props.email, this.props.selectedHost)

    }

    componentDidUpdate() {
        //새로고침 되돌리기
        if(this.state.selectedHost !== this.props.selectedHost){
            this.setState({selectedHost: this.props.selectedHost});
            this.props.getABTest(this.props.email, this.props.selectedHost)
        }

        if(this.props.abTests.length > 0) {
            //맨 처음에만 값 설정해주기
            if(this.state[`isEdit_0`] == null){
                var obj = {}
                for(var j = 0; j < this.props.abTests.length; j++){
                    obj[`isEdit_${j}`] = false;
                    var addNewCate = JSON.parse(JSON.stringify(this.state.categories))
                    this.state.addCategories.push("A와 동일")
                    this.state.addNewCategories.push(addNewCate)
                    this.state.addAlgorithm.push("알고리즘 1")
                    this.state.addSubTest.push({
                        algorithm: "알고리즘 1",
                        categories: [],
                        abDivision: [],
                        abDivisionName: "",
                        abDivisionNumber: 0,
                        lastModify: ""
                    })
                    for(var w = 0; w < this.props.abTests[j].tests.length; w++){
                        var newCate = JSON.parse(JSON.stringify(this.state.categories))
                        if(w === 0){
                            this.state.setCategories.push(["기존 선택"])
                            this.state.newCategories.push([newCate])
                        } else {
                            this.state.setCategories[j].push("기존 선택")
                            this.state.newCategories[j].push(newCate)
                        }
                    }

                }
                this.setState(obj)
            }

            // 업데이트 할 때마다 값 설정해주기
            if(this.state.abTests !== this.props.abTests){
                var lines = document.getElementsByClassName("dynamic--line");
                for(var q = 0; q < this.props.abTests.length; q++){
                    if(q < this.props.abTests.length -1){
                        lines[q].style.height = this.props.abTests[q].tests.length * 80  + 146 + "px"
                    }
                }    
                this.setState({abTests: this.props.abTests})
            }
        }
    }
    
    playCheckABTest = (name, state, isSub) => {
        if(isSub == null){
            if(state){
                if(window.confirm("A/B테스트 상품을 사용하시겠습니까?")){
                    this.props.updateABTest(this.props.email, this.props.selectedHost, name, {"isPlay": state})
                }
            } else {
                if(window.confirm("A/B테스트 상품을 중지하시겠습니까?")){
                    this.props.updateABTest(this.props.email, this.props.selectedHost, name, {"isPlay": state})
                }
            }
        } else {
            var obj = {}
            if(state){
                obj[`tests.${isSub}.isPlay`] = state;
                if(window.confirm(`Test - ${String.fromCharCode(isSub+66)} 사용하시겠습니까?`)){
                    this.props.updateABTest(this.props.email, this.props.selectedHost, name, obj)
                }
            } else {
                obj[`tests.${isSub}.isPlay`] = state;
                if(window.confirm(`Test - ${String.fromCharCode(isSub+66)} 중지하시겠습니까?`)){
                    this.props.updateABTest(this.props.email, this.props.selectedHost, name, obj)
                }
            }
        }
    }

    addSubTest = (sub, mainIndex, addAbDivision) => {
        var addSubTest = this.state.addSubTest;
        if(this.state.addCategories[mainIndex] === "A와 동일"){
            addSubTest[mainIndex].categories = this.state.categories
        } else {
            addSubTest[mainIndex].categories = this.state.addNewCategories[mainIndex]
        }
        addSubTest[mainIndex].algorithm = this.state.addAlgorithm[mainIndex]

        if(!addSubTest[mainIndex].abDivision[0]){
            addSubTest[mainIndex].abDivision = addAbDivision.menus
        }
        addSubTest[mainIndex].abDivisionName = addSubTest[mainIndex].abDivision[0].name;
        addSubTest[mainIndex].abDivisionNumber = parseInt(addSubTest[mainIndex].abDivision[0].subMenus[0].name);
        addSubTest[mainIndex].isPlay = true;
        addSubTest[mainIndex].lastModify = new Date(new Date().getTime() + (3600000*9));

        this.props.addSubTest(this.props.email, this.props.selectedHost, this.props.abTests[mainIndex].abTestName, addSubTest[mainIndex])
        .then(()=> {
            alert("추가되었습니다.")
            $(`#newModal_${mainIndex}`).modal('hide')
            this.setState(state =>{
                var addAlgorithm = state.addAlgorithm;
                var addCategories = state.addCategories;
                var addNewCategories = state.addNewCategories;
                // var addSubTest = state.addSubTest;

                addAlgorithm[mainIndex] = "알고리즘 1";
                addCategories[mainIndex] = "A와 동일";
                addNewCategories[mainIndex] = state.categories;
                addSubTest[mainIndex] = {
                    algorithm: "알고리즘 1",
                    categories: [],
                    abDivision: [],
                    abDivisionName: "",
                    abDivisionNumber: 0,
                    lastModify: ""
                }
                var newCate = JSON.parse(JSON.stringify(state.categories))
                var setCategories = state.setCategories;
                var newCategories = state.newCategories;
                setCategories[mainIndex].push("기존 선택");
                newCategories[mainIndex].push(newCate)

                return {
                    addAlgorithm,
                    addCategories,
                    addNewCategories,
                    addSubTest,
                    resetSelection: true,
                    setCategories,
                    newCategories
                }
            })
        })
    }

    updateABTest = (sub, mainIndex, subIndex) => {
        var updateSet = {}
        if(this.state.setCategories[mainIndex][subIndex] === "기존 선택"){
            // console.log("update original sub",sub)
            updateSet[`tests.${subIndex}.categories`] = sub.categories;
        } else {
            // console.log("update newsetting sub", this.state.newCategories[mainIndex][subIndex])
            updateSet[`tests.${subIndex}.categories`] = this.state.newCategories[mainIndex][subIndex];
        }
        for(var i = 0; i < this.props.abTests[mainIndex].tests.length; i++){
            // console.log(this.props.abTests[mainIndex].tests[i].abDivisionNumber)
            updateSet[`tests.${i}.abDivisionNumber`] = this.props.abTests[mainIndex].tests[i].abDivisionNumber
            updateSet[`tests.${i}.abDivision.0.subMenus.0.name`] = sub.abDivisionName === "할당량 지정" ? this.props.abTests[mainIndex].tests[i].abDivisionNumber + "%" : this.props.abTests[mainIndex].tests[i].abDivisionNumber + "회 초과"
        }
        updateSet[`tests.${subIndex}.algorithm`] = sub.algorithm;
        updateSet[`tests.${subIndex}.lastModify`] = new Date(new Date().getTime() + (3600000*9))
        this.props.updateABTest(this.props.email, this.props.selectedHost, this.props.abTests[mainIndex].abTestName, updateSet)
        .then(() => {
            alert("저장되었습니다.")
            $(`#exampleModal_${mainIndex}_${subIndex}`).modal('hide')
            this.setState({
                modal_backup:{
                    abDivisionNumber: "",
                    categories: [],
                    algorithm: ""
                },
                resetSelection: true,
            })
        })
    }

    deleteABTest = (name) =>{
        const { email, selectedHost } = this.props
        if(window.confirm("선택한 테스트 상품을 삭제하시겠습니까?")){
            this.props.deleteABTest(email, selectedHost, name)
        }
    }

    cancelModalChange = (sub, mainIdx, subIdx) => {
        const { modal_backup } = this.state
        if(modal_backup["abDivisionNumber"] !== ""){
            sub.abDivisionNumber = modal_backup["abDivisionNumber"];
        }
        if(modal_backup["categories"][0]){
            sub.categories = modal_backup["categories"];
        }
        if(modal_backup["algorithm"] !== ""){
            sub.algorithm = modal_backup["algorithm"];
        }

        this.setState(state=>{
            var setCategories = state.setCategories;
            var newCategories = state.newCategories;
            setCategories[mainIdx][subIdx] = "기존 선택";
            newCategories[mainIdx][subIdx] = JSON.parse(JSON.stringify(this.state.categories));
            return{
                modal_backup:{
                    abDivisionNumber: "",
                    categories: [],
                    algorithm: ""
                },
                resetSelection: true,
                setCategories,
                newCategories
            }
        })
    }
 
    handleChange = event => {
        this.setState({
          [event.target.name]: event.target.value
        });
    }
    
    changeABAlgorithm = (e, main, sub) => {
        var algoName = ""
        if(e.target.nodeName === "SPAN"){
            algoName = e.target.parentNode.childNodes[1].data
        } else {
            algoName = e.target.childNodes[1].data
        }
        this.setState(state=>{
            var modal_backup = state.modal_backup;
            if(state.modal_backup["algorithm"] === ""){
                modal_backup["algorithm"] = this.props.abTests[main].tests[sub].algorithm;
            }
            this.props.abTests[main].tests[sub].algorithm = algoName;
            return{
                algoName,
                modal_backup
            }
        })

    }
    
    changeAddAlgorithm = (e, main) => {
        var algoName = ""
        if(e.target.nodeName === "SPAN"){
            algoName = e.target.parentNode.childNodes[1].data
        } else {
            algoName = e.target.childNodes[1].data
        }
        this.setState(state=>{
            var addAlgorithm = state.addAlgorithm
            addAlgorithm[main] = algoName;
            return{
                addAlgorithm
            }
        })

    }
    
    returnAlgoColor = (algoName) => {
        if(algoName === "알고리즘 1") {
            return "btn--box btn btn-primary";
            
        } else if(algoName === "알고리즘 2") {
            return "btn--box btn btn-success"
            
        } else if(algoName === "알고리즘 3"){
            return "btn--box btn btn-danger"
            
        } else if(algoName === "알고리즘 4"){
            return "btn--box btn btn-warning"
        }
    }
    
    changeSetCategories = (e, main, sub) => {
        var textName = e.target.innerText;
        this.setState(state=>{
            var setCategories = state.setCategories;
            setCategories[main][sub] = textName;
            return{
                setCategories
            }
        })

    }

    changeAddCategories = (e, main) => {
        var textName = e.target.innerText;
        this.setState(state=>{
            var addCategories = state.addCategories;
            addCategories[main] = textName;
            return{
                addCategories
            }
        })
    }

    deleteCategories = (idx, categories, mainIndex, subIndex) => {
        if(mainIndex != null){ // 카테고리 모두 불러와서 재설정
            this.state.newCategories[mainIndex][subIndex].splice(idx, 1)
            this.setState({newCategories: this.state.newCategories})
        } else { // 기존에 저장되어있는 카테고리 수정
            this.setState(state=>{
                var modal_backup = state.modal_backup;
                if(!modal_backup["categories"][0]){
                    modal_backup["categories"] = JSON.parse(JSON.stringify(categories));
                }
                categories.splice(idx, 1)
                return{modal_backup}
            })
        }
    }

    deleteAddCategories = (idx, categories, mainIndex) => {
        categories[mainIndex].splice(idx, 1)
        this.setState({addNewCategories: categories})
    }
    
    setABDivision = (menus, main, sub) => {
        this.setState(state=>{
            var modal_backup = state.modal_backup
            if(modal_backup["abDivisionNumber"] === ""){
                modal_backup["abDivisionNumber"] = this.props.abTests[main].tests[sub].abDivisionNumber;
            }
            this.props.abTests[main].tests[sub].abDivisionNumber = parseInt(menus.menus[0].subMenus[0].name)
            return{
                abTest: this.props.abTest,
                modal_backup
            }
        })
    }
    setAddABDivision = (menus, main) => {
        this.setState(state=>{
            var addSubTest = state.addSubTest;
            addSubTest[main].abDivision = menus.menus;
            return{
                addSubTest
            }
        })
    }

    sucessResetSelection = () => {
        this.setState({resetSelection: false})
    }
    
    showEdit = (name, state) => {
        var obj = {}
        obj[name] = state
        this.setState(obj)
    }

    addHideEvent = (sub, mainIdx, subIdx) => {
        //모달 켜질 때 취소이벤트를 바인딩해준다!
        $(`#exampleModal_${mainIdx}_${subIdx}`).on("hide.bs.modal", ()=>{
            this.cancelModalChange(sub, mainIdx, subIdx)
        })
    }

    renderLine = (index) => {
        if(index !== 0){
            return (
                <div style={{display:"contents"}} key={index}>
                    <div className="collapse" id={"collapseExample" + index}>
                        <div className="dynamic--line">
                        </div>
                    </div>
                    <div className="ab--line"></div>
                    <div className="ab--circle"><FontAwesomeIcon className="ab--default--font" icon={faPlus} size="sm"/></div>
                    <div className="ab--line2"></div>
                    <div className="ab--circle2">
                        <FontAwesomeIcon className={this.state[`isEdit_${index}`] === true ? "ab--select--font" : "ab--default--font"} icon={faUserCircle} size="2x"/>
                        <div className="ab--line3"></div>
                    </div>
                </div>
            )
        }
    }

    renderSubBody = (key, subIndex, recommendationName, mainIndex) => {
        const { setCategories, newCategories, addCategories, addNewCategories } = this.state

        //기존추천상품(A)의 값이 할당량 지정일때 (100% - 모든 key값의 %합)
        if(key.abDivisionName === "할당량 지정" && subIndex === 0){
            var aCount = 100;
            for(var i = 0; i < this.props.abTests[mainIndex].tests.length; i++){
                aCount -= this.props.abTests[mainIndex].tests[i].abDivisionNumber;
            }
        }
        
        //addAbDivision 개별 설정 값 넣기
        var addAbDivision = JSON.parse(JSON.stringify(this.props.abDivision))
        addAbDivision.menus = JSON.parse(JSON.stringify(key.abDivision));
        addAbDivision.menus[0].subMenus[0].name = key.abDivisionName === "할당량 지정" ? "0%" : "3회 초과"
        addAbDivision.mainCategories = [key.abDivisionName]

        //서브 카테고리 할당량의 최대값이 테스트A의 값을 못넘도록!
        addAbDivision.subCategories["할당량 지정"] = addAbDivision.subCategories["할당량 지정"].filter((subCate) => {if(parseInt(subCate) <= aCount){return subCate}else{return ""}})

        //abDivision 개별로 설정했던 값 넣기
        var abDivision = JSON.parse(JSON.stringify(this.props.abDivision))
        abDivision.menus = key.abDivision;
        abDivision.mainCategories = [key.abDivisionName]
        
        //총합 100%안넘도록 제한하기
        var limitCount = 100;
        for(var j = 0; j < this.props.abTests[mainIndex].tests.length; j++){
            if(subIndex !== j && key.abDivisionName ==="할당량 지정"){
                limitCount -= this.props.abTests[mainIndex].tests[j].abDivisionNumber
            }
        }
        abDivision.subCategories["할당량 지정"] = abDivision.subCategories["할당량 지정"].filter((subCate) => {if(parseInt(subCate) <= limitCount){return subCate}else{return ""}})
        return(
            <div key={subIndex}>
                {
                    subIndex === 0 ? 
                    <div className="ab--content">
                        <div className="w-50 d-flex align-items-center">
                            <div className="bg-light d-flex align-items-center justify-content-center h2 mb-0" style={{margin:"0 7.5px", width:"60px", height:"55px", boxShadow:"0 0 5px rgba(0,0,0,.125)", borderRadius:"5px"}}>{String.fromCharCode(subIndex+65)}</div>
                            <div>기존 추천상품 - {recommendationName}</div>
                        </div>
                        <div className="w-25 d-flex align-items-center"></div>
                        <div className="w-25 d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center justify-content-center bg-light" style={{width:"60px", height:"55px", boxShadow:"0 0 5px rgba(0,0,0,.125)", borderRadius:"5px"}}>{key.abDivisionName === "할당량 지정" ? `${aCount}%` : `≤${key.abDivisionNumber}`}</div>
                            <div className="d-flex align-items-center text-right pr-3">
                                <button type="button" className="btn btn-secondary font-weight-bold" data-toggle="modal" data-target={"#newModal_"+mainIndex}>테스트 추가</button>
                            </div>
                            <div className="modal fade" id={"newModal_"+mainIndex} tabIndex="-1" role="dialog" aria-labelledby={"newModalLabel_"+mainIndex} aria-hidden="true">
                            <div className="modal-dialog modal-lg" role="document">
                                <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id={"newModalLabel_"+mainIndex}>ADD NEW TEST</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="mb-3">
                                        <h6 className="text-dark">* A/B 구분 기준</h6>
                                        <SelectionBoxs reset={this.state.resetSelection} successReset={this.sucessResetSelection} notAndOr={true} menus={addAbDivision} onSubmit={(menus)=>this.setAddABDivision(menus, mainIndex)} />
                                    </div>
                                    <div className="mb-3">
                                        <h6 className="font-weight-bold">* 알고리즘</h6>
                                        <div className={this.returnAlgoColor(this.state.addAlgorithm[mainIndex])}>
                                            <div style={{textAlign:"left", color:"white"}}>All widget slots</div>
                                            <button type="button" className="btn--algo d-flex btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <div>
                                                    {this.state.addAlgorithm[mainIndex]}
                                                </div>
                                            </button>
                                            <div className="dropdown-menu">
                                                <a onClick={(e) => this.changeAddAlgorithm(e, mainIndex)} className="dropdown-item" href="#/dashboard/abShowing"><span className="h5 text-primary">■&nbsp;</span>알고리즘 1</a>
                                                <a onClick={(e) => this.changeAddAlgorithm(e, mainIndex)} className="dropdown-item" href="#/dashboard/abShowing"><span className="h5 text-success">■&nbsp;</span>알고리즘 2</a>
                                                <a onClick={(e) => this.changeAddAlgorithm(e, mainIndex)} className="dropdown-item" href="#/dashboard/abShowing"><span className="h5 text-danger">■&nbsp;</span>알고리즘 3</a>
                                                <a onClick={(e) => this.changeAddAlgorithm(e, mainIndex)} className="dropdown-item" href="#/dashboard/abShowing"><span className="h5 text-warning">■&nbsp;</span>알고리즘 4</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <h6 className="font-weight-bold">* 상품군</h6>
                                        <ul className="nav side--navbar--nav d-flex">
                                            <li className="d-flex flex-column">
                                                <div className="container--div d-flex">
                                                    <div className="main--div h-100 d-flex flex-column">
                                                        <div className="nav-item dropdown drop--div">
                                                            <input onChange={this.handleChange} value={addCategories[0] ? addCategories[mainIndex] : ""} autoComplete="off" placeholder="기존 선택"  className="drop--input"  data-toggle="dropdown" aria-haspopup="true"/>
                                                            <button className="drop--toggle bg-white dropdown-toggle dropdown-toggle-split" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"/>
                                                            <div className="dropdown-menu" aria-labelledby="dropmenu1" draggable="false">
                                                                <a href="#/dashboard/abShowing" onClick={(e)=> addCategories[0] ? this.changeAddCategories(e, mainIndex) : ""} className="dropdown-item" >A와 동일</a>
                                                                <a href="#/dashboard/abShowing" onClick={(e)=> addCategories[0] ? this.changeAddCategories(e, mainIndex) : ""} className="dropdown-item" >재설정</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                        {
                                            addCategories[0] ? addCategories[mainIndex] === "재설정" ? 
                                            <div className="categoris--menu mt-1">
                                                {   
                                                    addNewCategories[0] ? 
                                                    addNewCategories[mainIndex].map(
                                                        (categorie, idx) => 
                                                        <div key={idx} className="btn btn-secondary categoris--item">
                                                            {categorie} <FontAwesomeIcon onClick={() => this.deleteAddCategories(idx, addNewCategories, mainIndex)} icon={faTimes}/>
                                                        </div>
                                                    ) : ""
                                                }
                                            </div>
                                            :""
                                            :""
                                        }
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                    <button onClick={()=>this.addSubTest(key, mainIndex, addAbDivision)} type="button" className="btn btn-primary">Save Changes</button>
                                </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div> 
                    : ""
                }
                <div className="ab--content">
                    <div className="w-50 d-flex align-items-center">
                        <div className="bg-light d-flex align-items-center justify-content-center h2 mb-0" style={{margin:"0 7.5px", width:"60px", height:"55px", boxShadow:"0 0 5px rgba(0,0,0,.125)", borderRadius:"5px"}}>{String.fromCharCode(subIndex+66)}</div>
                        <div>Test {String.fromCharCode(subIndex+66)} - {key.algorithm}</div>
                    </div>
                    <div className="w-25 d-flex align-items-center">{key.lastModify.substring(0,10)}</div>
                    <div className="w-25 d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center justify-content-center bg-light" style={{width:"60px", height:"55px", boxShadow:"0 0 5px rgba(0,0,0,.125)", borderRadius:"5px"}}>{key.abDivisionName === "할당량 지정" ? `${key.abDivisionNumber}%` : `＞${key.abDivisionNumber}`}</div>
                        <div className="d-flex align-items-center text-right pr-3">
                            <FontAwesomeIcon onClick={()=>this.addHideEvent(key, mainIndex, subIndex)} className="pointer" icon={faPen} size="2x" style={{marginRight:"10px", width:"18px", color:"grey"}} data-toggle="modal" data-target={"#exampleModal_"+mainIndex+"_"+subIndex}/>
                            {
                                key.isPlay ?
                                <FontAwesomeIcon className="pointer" onClick={()=>this.playCheckABTest(this.props.abTests[mainIndex].abTestName, false, subIndex)} icon={faPause} size="lg" style={{marginRight:"10px", width:"20px", height:"20px", color:"grey"}}/>
                                :
                                <FontAwesomeIcon className="pointer" onClick={()=>this.playCheckABTest(this.props.abTests[mainIndex].abTestName, true, subIndex)} icon={faPlay} size="lg" style={{marginRight:"10px", width:"20px", height:"20px", color:"grey"}}/>
                            }
                            <FontAwesomeIcon className="pointer" icon={faEllipsisV} size="lg" style={{marginRight:"10px", width:"20px", height:"20px", color:"grey"}}/>
                        </div>
                        <div className="modal fade" id={"exampleModal_"+mainIndex+"_"+subIndex} tabIndex="-1" role="dialog" aria-labelledby={"exampleModalLabel_"+mainIndex+"_"+subIndex} aria-hidden="true">
                            <div className="modal-dialog modal-lg" role="document">
                                <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id={"exampleModalLabel_"+mainIndex+"_"+subIndex}>Test B - Edit</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="mb-3">
                                        <h6 className="text-dark">* A/B 구분 기준</h6>
                                        <SelectionBoxs reset={this.state.resetSelection} successReset={this.sucessResetSelection} notAndOr={true} menus={abDivision} onSubmit={(menus)=>this.setABDivision(menus, mainIndex, subIndex)} />
                                    </div>
                                    <div className="mb-3">
                                        <h6 className="font-weight-bold">* 알고리즘</h6>
                                        <div className={this.returnAlgoColor(key.algorithm)}>
                                            <div style={{textAlign:"left", color:"white"}}>All widget slots</div>
                                            <button type="button" className="btn--algo d-flex btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <div>
                                                    {key.algorithm}
                                                </div>
                                            </button>
                                            <div className="dropdown-menu">
                                                <a onClick={(e) => this.changeABAlgorithm(e, mainIndex, subIndex)} className="dropdown-item" href="#/dashboard/abShowing"><span className="h5 text-primary">■&nbsp;</span>알고리즘 1</a>
                                                <a onClick={(e) => this.changeABAlgorithm(e, mainIndex, subIndex)} className="dropdown-item" href="#/dashboard/abShowing"><span className="h5 text-success">■&nbsp;</span>알고리즘 2</a>
                                                <a onClick={(e) => this.changeABAlgorithm(e, mainIndex, subIndex)} className="dropdown-item" href="#/dashboard/abShowing"><span className="h5 text-danger">■&nbsp;</span>알고리즘 3</a>
                                                <a onClick={(e) => this.changeABAlgorithm(e, mainIndex, subIndex)} className="dropdown-item" href="#/dashboard/abShowing"><span className="h5 text-warning">■&nbsp;</span>알고리즘 4</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <h6 className="font-weight-bold">* 상품군</h6>
                                        <ul className="nav side--navbar--nav d-flex">
                                            <li className="d-flex flex-column">
                                                <div className="container--div d-flex">
                                                    <div className="main--div h-100 d-flex flex-column">
                                                        <div className="nav-item dropdown drop--div">
                                                            <input onChange={this.handleChange} value={setCategories[0] ? setCategories[mainIndex][subIndex] ? setCategories[mainIndex][subIndex] : "" : ""} autoComplete="off" placeholder="기존 선택"  className="drop--input"  data-toggle="dropdown" aria-haspopup="true"/>
                                                            <button className="drop--toggle bg-white dropdown-toggle dropdown-toggle-split" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"/>
                                                            <div className="dropdown-menu" aria-labelledby="dropmenu1" draggable="false">
                                                                <a href="#/dashboard/abShowing" onClick={(e)=> setCategories[0] ? this.changeSetCategories(e, mainIndex, subIndex) : ""} className="dropdown-item" >기존 선택</a>
                                                                <a href="#/dashboard/abShowing" onClick={(e)=> setCategories[0] ? this.changeSetCategories(e, mainIndex, subIndex) : ""} className="dropdown-item" >재설정</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                        {
                                            setCategories[0] ? setCategories[mainIndex][subIndex] === "재설정" ? 
                                            <div className="categoris--menu mt-1">
                                                {   
                                                    newCategories[0] ? 
                                                    newCategories[mainIndex][subIndex].map(
                                                        (categorie, idx) => 
                                                        <div key={idx} className="btn btn-secondary categoris--item">
                                                            {categorie} <FontAwesomeIcon onClick={() => this.deleteCategories(idx, key.categories, mainIndex, subIndex)} icon={faTimes}/>
                                                        </div>
                                                    ) : ""
                                                }
                                            </div>
                                            : 
                                            <div className="categoris--menu mt-1">
                                                {
                                                    key.categories.map(
                                                        (categorie, idx) => 
                                                        <div key={idx} className="btn btn-secondary categoris--item">
                                                            {categorie} <FontAwesomeIcon onClick={() => this.deleteCategories(idx, key.categories)} icon={faTimes}/>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            :""
                                        }
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                    <button onClick={()=>this.updateABTest(key, mainIndex, subIndex)} type="button" className="btn btn-primary">Save Changes</button>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderBody = (test,index) => {
        return(
            <div key={index} className="result--container bg-white">
                <div className="result--header">
                    <div className="result--title">
                        <h5 className="m-0 font-weight-bold">{test.abTestName}</h5>
                        {
                            this.state[`isEdit_${index}`] === true ? "" : <small>추천상품 "{test.recommendationName}"을 기반으로 실행</small>
                        }
                    </div>
                    {
                        this.state[`isEdit_${index}`] === true ? 
                        <div onClick={()=>this.showEdit(`isEdit_${index}`,false)} className="d-flex justify-content-start align-items-start pr-3" data-toggle="collapse" data-target={"#collapseExample" + (index+1)} aria-expanded="false" aria-controls={"collapseExample"+ (index+1)}>
                            <FontAwesomeIcon className="pointer" icon={faTimes} size="lg" style={{color:"grey"}}/>
                        </div>
                        :
                        <div className="result--widget">
                            <FontAwesomeIcon className="pointer" onClick={()=>this.showEdit(`isEdit_${index}`,true)} icon={faPen} size="2x" style={{marginRight:"10px", width:"18px", color:"grey"}}  data-toggle="collapse" data-target={"#collapseExample" + (index+1)} aria-expanded="false" aria-controls={"collapseExample"+ (index+1)}/>
                            <FontAwesomeIcon className="pointer" onClick={()=> this.props.props.history.push('/dashboard/abDetail')} icon={faChartBar} size="2x" style={{marginRight:"10px", width:"23px", color:"grey"}}/>
                            {
                                test.isPlay ?
                                <FontAwesomeIcon className="pointer" onClick={()=>this.playCheckABTest(test.abTestName, false)} icon={faPause} size="lg" style={{marginRight:"10px", width:"20px", height:"20px", color:"grey"}}/>
                                :
                                <FontAwesomeIcon className="pointer" onClick={()=>this.playCheckABTest(test.abTestName, true)} icon={faPlay} size="lg" style={{marginRight:"10px", width:"20px", height:"20px", color:"grey"}}/>
                            }
                            <FontAwesomeIcon className="pointer" onClick={()=>this.deleteABTest(test.abTestName)} icon={faTrashAlt} size="lg" style={{marginRight:"10px", height:"25px", width:"17px", color:"grey"}}/>
                        </div>
                    }
                </div>
                <div className="collapse" id={"collapseExample" + (index+1)} >
                    <div className="card card-body m-0">
                        <div className="d-flex">
                            <span className="w-50 font-weight-bold">테스트 구분</span>
                            <span className="w-25 font-weight-bold">최근 수정 날짜</span>
                            <span className="w-25 font-weight-bold pl-1">할당량{test.tests[0].abDivisionName ==="페이지 뷰 횟수" ? "(페이지뷰)" : ""}</span>
                        </div>
                        {
                            test.tests.map((key, idx) => this.renderSubBody(key, idx, test.recommendationName, index))
                        }
                    </div>
                </div>
            </div>
        )
    }

    render(){
        const {abTests} = this.props
        return (
            <>
            <div className="ab--result--progress">
                <Navbar type="2" title ="A/B Test" title2 = "Edit" />
            </div>
            <div className="ab--result--title">
                <h4 style={{}}>A/B Test Widget</h4>
                <h5 style={{}}>____________________________</h5>
                <h6 style={{}}>Please Check A/B Test Widget</h6>
            </div>
            {
                abTests.length > 0 ?
                <div className="ab--result--con">
                    <div className="ab--con--line">
                        <div className="ab--line"></div>
                        <div className="ab--circle"><FontAwesomeIcon className="ab--default--font" icon={faPlus} size="sm"/></div>
                        <div className="ab--line2"></div>
                        <div className="ab--circle2">
                            <FontAwesomeIcon className={this.state[`isEdit_0`] === true ? "ab--select--font" : "ab--default--font"} icon={faUserCircle} size="2x"/>
                            <div className="ab--line3"></div>
                        </div>
                        {
                            abTests.map((test, index)=> this.renderLine(index))
                        }
                    </div>
                    <div className="ab--container" >
                        {
                            abTests.map((test, index) => this.renderBody(test,index))
                        }
                    </div>
                </div>
                :
                <div className="w-100 d-flex justify-content-center mt-5 h3">A/B테스트 상품이 존재하지 않습니다</div>
            }
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        email: state.authentication.status.userEmail,
        selectedHost: state.authentication.status.selectedHost,
        abTests: state.condition.abTests,
        abDivision: state.condition.abDivision,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changePage: (pageName) => {
            return dispatch(changePage(pageName))
        },
        getABTest: (email, host) => {
            return dispatch(getABTest(email, host))
        },
        deleteABTest: (email, host, deleteABName) => {
            return dispatch(deleteABTest(email, host, deleteABName))
        },
        updateABTest: (email, host, updateRecName, updateSet) => {
            return dispatch(updateABTest(email, host, updateRecName, updateSet))
        },
        addSubTest: (email, host, updateRecName, updateSet) => {
            return dispatch(addSubTest(email, host, updateRecName, updateSet))
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ABResult);