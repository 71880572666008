import React from "react"
import { connect } from 'react-redux';
import "./SideNavbar.css"

import {  faHome, faUser, faUserCircle, faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import { faAutoprefixer } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { changePage } from "../store/actions/authentication"
import UserEditModal from "./UserEditModal"

class SideNavbar extends React.Component {
    constructor(props){
        super(props);
        this.state ={
            currentNav: "",
            currentDropItem: "",
            isFirst: true,
            pageName: this.props.pageName,
            status: props.status,
            platformType: props.platformType,
            customPage: ""
        }
        if(localStorage.getItem("dashboard_page") === null){
            localStorage.setItem("dashboard_page", "")
        }
        window.onhashchange = () => {
            if(window.location.hash.indexOf("#/dashboard") !== -1){
                var hash = window.location.hash
                var localPageName = localStorage.getItem("dashboard_page")
                if (hash.indexOf(localPageName) < 1){
                    //기준이 1보다 작은 이유는 home에서 뒤로가기한 후 체크하면 결과값이 -1이 아니라 0 이 나오기 때문
                    if(hash.indexOf(localPageName) === 0){
                        if(hash === "#/dashboard"){
                            // ("test 3 - 그냥 들어온거")
                        } else {
                            // ("test 3 - 홈에서 뒤로가기")
                            this.props.changePage(hash.replace("#/dashboard/", ""))
                        }
                    } else {
                        if(hash === "#/dashboard"){
                            // ("test 3 - 홈아닌데서 홈으로 뒤로가기")
                            this.props.changePage("home")
                        } else {
                            // ("test 3 - 홈아닌데서 홈아닌데로 뒤로가기")
                            this.props.changePage(hash.replace("#/dashboard/", ""))
                        }
                    }
                }
            }
        }
    }
    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.pageName !== nextState.pageName){
            return true
        } else if(nextProps.currentUser !== nextState.currentUser) {
            return true
        } else {return false}
    }
    
    componentDidMount(){
        var initLink = document.getElementsByClassName("nav--item--active")
        // this.state.currentNav = initLink[0]
        this.setState({
            currentNav: initLink[0],
        })
    }

    componentDidUpdate(){
        var localPageName = localStorage.getItem("dashboard_page") === "" ? "home" : localStorage.getItem("dashboard_page")
        if(this.state.pageName !== this.props.pageName){
            console.log("test 1", this.state.pageName, " // ", this.props.pageName)
            this.checkPageName(this.props.pageName)
        } else if (this.props.pageName !== localPageName){
            this.props.changePage(localPageName)
            this.checkPageName(localPageName)
            console.log("test 2")
        } 

        if(this.state.customPage !== this.props.customPage){
            console.log("custompage", this.props.customPage)
            this.setState({customPage: this.props.customPage})
            this.checkPageName(this.props.pageName)
        }

        if(this.state.platformType !== this.props.platformType){
            console.log("platformtype", this.props.platformType)
            this.setState({platformType: this.props.platformType})
            this.checkPageName(localPageName)
        }

    }

    
    addParentNavClass = (target, className) => {
        this.state.currentNav.classList.remove(className)
        target.classList.add(className)
        this.setState({currentNav: target})
        // this.state.currentNav = target
    }

    addNavClass = (target, className, isDropdown, pageName) => {
        if(target){
            if(target.classList.value.indexOf(className) === -1) {
                // console.log("side 1 ", target.classList.value)
                // this.props.changePage(pageName)// reducer에 페이지 이름 바꾸는 함수
    
                if(isDropdown === false){
                    this.state.currentNav.classList.remove(className)
                    if(this.state.currentDropItem){
                        this.state.currentDropItem.classList.remove("nav--drop--active")
                    }
                    target.classList.add(className)
                    this.setState({currentNav:target})
                    // this.state.currentNav = target
                } else {
                    if (this.state.isFirst){
                        target.classList.add(className);
                        this.setState({
                            currentDropItem: target,
                            isFirst: false
                        })
                        // this.state.currentDropItem = target;
                        // this.state.isFirst = false;
                        this.addParentNavClass(target.parentNode.parentNode.firstChild, "nav--item--active")
                        target.parentNode.parentNode.classList.add("show")
                        target.parentNode.classList.add("show")
                    } else {
                        this.state.currentDropItem.classList.remove(className)
                        target.classList.add(className);
                        this.addParentNavClass(target.parentNode.parentNode.firstChild, "nav--item--active")
                        target.parentNode.parentNode.classList.add("show")
                        target.parentNode.classList.add("show")
                        this.setState({currentDropItem: target})
                        // this.state.currentDropItem = target;
                    }
                }
            } 
        }
    }

    checkPageName = (newPageName, e) => {
        //페이지 이동
        if(newPageName === "home"){
            localStorage.setItem("dashboard_page", "")
        }else{
            localStorage.setItem("dashboard_page", newPageName)
        }
        var target = ""
        if(newPageName === "home"){
            target = document.getElementById("sidebar-home")
            this.addNavClass(target, "nav--item--active", false)
        } else if (newPageName === "abSetting"){
            target = document.getElementById("sidebar-abSetting")
            this.addNavClass(target, "nav--drop--active", true)
        } else if (newPageName === "abShowing"){
            target = document.getElementById("sidebar-abShowing")
            this.addNavClass(target, "nav--drop--active", true)
        } else if (newPageName === "recommendation"){
            target = document.getElementById("sidebar-recommendation")
            this.addNavClass(target, "nav--drop--active", true)
        } else if (newPageName === "createRecommendation") {
            target = document.getElementById("sidebar-createRecommendation")
            this.addNavClass(target, "nav--drop--active", true)
        } else if (newPageName === "summary2") {
            target = document.getElementById("sidebar-summary2")
            this.addNavClass(target, "nav--drop--active", true)
        } else if (newPageName === "summary") {
            target = document.getElementById("sidebar-summary")
            this.addNavClass(target, "nav--drop--active", true)
        } else if (newPageName === "purchaseChangeRate") {
            target = document.getElementById("sidebar-purchaseChangeRate")
            this.addNavClass(target, "nav--drop--active", true)
        } else if (newPageName === "totalPrice") {
            target = document.getElementById("sidebar-totalPrice")
            this.addNavClass(target, "nav--drop--active", true)
        } else if (newPageName === "clickCount") {
            target = document.getElementById("sidebar-clickCount")
            this.addNavClass(target, "nav--drop--active", true)
        } else if (newPageName === "clickRate") {
            target = document.getElementById("sidebar-clickRate")
            this.addNavClass(target, "nav--drop--active", true)
        } else if (newPageName === "apiCall") {
            target = document.getElementById("sidebar-apiCall")
            this.addNavClass(target, "nav--drop--active", true)
        } else if (newPageName === "custom") {
            target = document.getElementById("sidebar-custom")
            this.addNavClass(target, "nav--item--active", false)
        } else if (newPageName === "pageName") {
            target = document.getElementById("sidebar-pageName")
            this.addNavClass(target, "nav--item--active", false)
        } 

        console.log("newPage change", newPageName, " // ", target)

        
        this.setState({
            pageName: this.props.pageName
        })

        //midia 작은 상태에서 nav클릭시 다시 숨기기
        var mainSidebar = document.getElementsByClassName('main-sidebar')[0]
        if(!mainSidebar.classList.contains("hide-sidebar")){
            mainSidebar.classList.add("hide-sidebar")
            mainSidebar.classList.remove("show-sidebar")

            var dropmenus = document.getElementsByClassName("side-drop")
            for(var i = 0; i < dropmenus.length; i++){
                if(dropmenus[i].classList.contains("show")){
                    dropmenus[i].classList.remove("show")
                }
            }
        }
    }

    render(){
        return(
            <aside className="hide-sidebar main-sidebar sidebar-dark-primary bg-dark elevation-4">
                    <div className="brand--div">
                        {/* <img/> */}
                        {
                            this.props.email === "lugstay@allbigdat.com" ? 
                            <img style={{width:"200px", textAlign:"center"}} src="lugstay.png"/>
                            :
                            <span className="pt-5 brand--text text-light">
                                <FontAwesomeIcon className="mr-2" icon={faAutoprefixer} size={"2x"}/>
                                <span className="h4">ALLBIGDAT</span>
                            </span>
                        }
                    </div>
                    <div className="brand--div dropdown pointer">
                        {/* <img/> */}
                        <span className="pt-5 brand--text text-light" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <FontAwesomeIcon className="mr-2" icon={faUserCircle} size={"2x"} />
                            <span className="h3">{this.props.currentUser ? this.props.currentUser : "비회원"}</span>
                        </span>
                        <div className="dropdown-menu" style={{top:"50px"}} aria-labelledby="dropdownMenuButton">
                            <div className="dropdown-item" data-toggle="modal" data-target="#userInfoModal">내정보수정</div>
                            <a className="dropdown-item" href="#/login" onClick={()=>{localStorage.removeItem("token")}}>로그아웃</a>
                        </div>
                        <UserEditModal status={this.props.status} />
                    </div>
                    <nav className="navbar navbar-dark bg-dark side--navbar">
                        <ul className="nav navbar-nav side--navbar--nav">
                            <li className="nav-item">
                                <a id="sidebar-home" onClick={() => this.props.changePage('home')} className="nav-link nav--item--active" href="#/dashboard">
                                    <FontAwesomeIcon className="mr-2" icon={faHome}/>
                                    Home
                                </a>
                            </li>
                            {/* <li className="nav-item">
                                <a id="sidebar-pageName" onClick={() => this.props.changePage('pageName')} className="nav-link" href="#/dashboard/pageName">
                                    <FontAwesomeIcon className="mr-2" icon={faHome}/>
                                    PageName
                                </a>
                            </li> */}
                            {
                                this.props.customPage ? 
                                <li className="nav-item">
                                    <a id="sidebar-custom" onClick={() => this.props.changePage('custom')} className="nav-link" href="#/dashboard/custom">
                                        <FontAwesomeIcon className="mr-2" icon={faHome}/>
                                        Custom
                                    </a>
                                </li>
                                :""    
                            }
                            {/* <li className="nav-item">
                                <a id="sidebar-recommendation" onClick={() => this.props.changePage('recommendation')} className="nav-link" href="#/dashboard">
                                    <FontAwesomeIcon className="mr-2" icon={faThumbsUp}/>
                                    Recommendation
                                </a>
                            </li> */}
                            {/* <li className="nav-item">
                                <a onClick={(e)=> this.addNavClass(e.currentTarget, "nav--item--active", false)} className="nav-link" href="#/dashboard">
                                    <FontAwesomeIcon className="mr-2" icon={faClock} spin/>
                                    Real Time
                                </a>
                            </li> */}
                            {
                                this.props.platformType === "godomall" ?
                                <li className="nav-item dropdown">
                                    <a className="nav-link d-flex justify-content-between" href="#/dashboard" id="resourceDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <p>
                                            <FontAwesomeIcon className="mr-2" icon={faUser}/>
                                            Recommendation
                                        </p>
                                        <FontAwesomeIcon className="mt-1 text-right dropdown--icon" icon={faChevronLeft}/>
                                    </a>
                                    <div className="dropdown-menu side-drop" aria-labelledby="resourceDropdownMenuLink" >
                                        <a id="sidebar-summary2" onClick={()=> this.props.changePage("summary2")} className="dropdown-item" href="#/dashboard/summary2">대시보드</a>
                                        <a id="sidebar-summary" onClick={()=> this.props.changePage("summary")} className="dropdown-item" href="#/dashboard/summary">추천상품 대시보드</a>
                                        <a id="sidebar-purchaseChangeRate" onClick={()=> this.props.changePage("purchaseChangeRate")} className="dropdown-item" href="#/dashboard/purchaseChangeRate">구매전환율 Top100</a>
                                        <a id="sidebar-totalPrice" onClick={()=> this.props.changePage("totalPrice")} className="dropdown-item" href="#/dashboard/totalPrice">매출액 Top100</a>
                                        <a id="sidebar-clickRate" onClick={()=> this.props.changePage("clickRate")} className="dropdown-item" href="#/dashboard/clickRate">클릭률 Top100</a>
                                        <a id="sidebar-clickCount" onClick={()=> this.props.changePage("clickCount")} className="dropdown-item" href="#/dashboard/clickCount">클릭수 Top100</a>
                                        <a id="sidebar-apiCall" onClick={()=> this.props.changePage("apiCall")} className="dropdown-item" href="#/dashboard/apiCall">API 콜 수 Top100</a>
                                        {/* <a id="sidebar-createRecommendation" onClick={() => this.props.changePage("createRecommendation")} className="dropdown-item" href="#/dashboard/createRecommendation">추천상품 제작</a>
                                        <a id="sidebar-recommendation" onClick={()=> this.props.changePage("recommendation")} className="dropdown-item" href="#/dashboard/recommendation">추천상품 결과</a>
                                        <a onClick={(e)=> this.addNavClass(e.currentTarget, "nav--drop--active", true)} className="dropdown-item" href="#/dashboard">Something else here</a> */}
                                    </div>
                                </li>
                                : ""
                            }
                            {/* <li className="nav-item dropdown">
                                <a className="nav-link d-flex justify-content-between" href="#/dashboard" id="resourceDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <p>
                                        <FontAwesomeIcon className="mr-2" icon={faUser}/>
                                        A/B Test
                                    </p>
                                    <FontAwesomeIcon className="mt-1 text-right dropdown--icon" icon={faChevronLeft}/>
                                </a>
                                <div className="dropdown-menu side-drop" aria-labelledby="resourceDropdownMenuLink" >
                                    <a id="sidebar-abSetting" onClick={() => this.props.changePage("abSetting")} className="dropdown-item" href="#/dashboard/abSetting">A/B condition</a>
                                    <a id="sidebar-abShowing" onClick={()=> this.props.changePage("abShowing")} className="dropdown-item" href="#/dashboard/abShowing">A/B result</a>
                                    <a onClick={(e)=> this.addNavClass(e.currentTarget, "nav--drop--active", true)} className="dropdown-item" href="#/dashboard">Something else here</a>
                                </div>
                            </li> */}
                            {/* <li className="nav-item dropdown">
                                <a className="nav-link d-flex justify-content-between" href="#/dashboard" id="resourceDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <p>
                                        <FontAwesomeIcon className="mr-2" icon={faUser}/>
                                        그냥유저
                                    </p>
                                    <FontAwesomeIcon className="mt-1 text-right dropdown--icon" icon={faChevronLeft}/>
                                </a>
                                <div className="dropdown-menu side-drop" aria-labelledby="resourceDropdownMenuLink" >
                                    <a onClick={(e)=> this.addNavClass(e.currentTarget, "nav--drop--active", true)} className="dropdown-item" href="#/dashboard">유저</a>
                                    <a onClick={(e)=> this.addNavClass(e.currentTarget, "nav--drop--active", true)} className="dropdown-item" href="#/dashboard">유저 action</a>
                                    <a onClick={(e)=> this.addNavClass(e.currentTarget, "nav--drop--active", true)} className="dropdown-item" href="#/dashboard">유저 else here</a>
                                </div>
                            </li>
                            <li className="nav-item">
                                <a onClick={(e)=> this.addNavClass(e.currentTarget, "nav--item--active", false)} className="nav-link" href="#/dashboard">Contact</a>
                            </li> */}
                        </ul>
                    </nav>
                </aside>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser : state.authentication.status.currentUser,
        customPage: state.authentication.status.customPage,
        pageName: state.authentication.page.pageName,
        email: state.authentication.status.userEmail,
        platformType: state.authentication.status.platformType,
        status: state.authentication.status
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changePage: (pageName) => {
            return dispatch(changePage(pageName))
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideNavbar);