import React from "react"
import { connect } from "react-redux"
// import { getUserOfTime } from '../../store/actions/chart';
import "./Sankey.css"
import * as d3 from "d3";
import * as d3Color from 'd3-color'
import { sankey, sankeyLinkHorizontal } from "d3-sankey"

class Sankey extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nodes: this.props.nodes,
            links: this.props.links,
            sankey: this.props.sankey,
        }
    }
    shouldComponentUpdate(nextProps, nextState){
        if (nextProps.nodes !== nextState.nodes){
            // console.log(nextProps, " /date/ ", nextState )
            this.setState({
                nodes: nextProps.nodes,
                links: nextProps.links,
                sankey: nextProps.sankey,
            });
            return true
        } else {return false}
    }

    componentDidUpdate(){
        var svg = document.getElementById("sankey_svg")
            if(svg){
                svg.remove()
            }
        this.drawChart()
    }
    
    componentDidMount(){

        this.drawChart()
        
    }

    drawChart = () => {
      console.log("draw@@@@@ ")
        const d3 = window.d3
        // set the dimensions and margins of the graph
        var margin = {
            top: 1,
            right: 1,
            bottom: 6,
            left: 1
          },
          width = 1400 - margin.left - margin.right,
          height = 450 - margin.top - margin.bottom,
          animDuration = 500;

        var formatNumber = d3.format(",.0f"),
          format = function(d) {
            return formatNumber(d) + " TWh";
          },
          color = d3.scaleOrdinal(d3.schemeCategory20);

          var svg = d3.select("#my_datavi").append("svg")
          .attr("width", width)
          .attr("height", height + margin.top + margin.bottom)
          .attr("id", "sankey_svg")
          .append("g")
          .attr("transform", "translate(" + margin.left-50 + "," + margin.top + ")");
    
        var links = svg.append("g"),
            nodes = svg.append("g");
    
        var sankey = d3.sankey()
          .nodeWidth(15)
          .nodePadding(10)
          .size([width, height])
          .align('left');
    
        var path = sankey.link();
    
    
          sankey
            .nodes(this.props.nodes)
            .links(this.props.links)
            .layout(0);
    
        
    
          var link = links.selectAll(".link")
            .data(sankey.links());
    
          var newLink = link.enter().append("path")
              .attr("class", "link")
              .style("stroke-width", function (d) {
                return Math.max(1, d.dy) + 'px';
              });
    
          newLink.append("title")
            .text(function (d) {
              return d.source.name + " → " + d.target.name + "\n" + format(d.value);
            });
    
          link = newLink.merge(link);
    
          link.transition().duration(animDuration)
            .attr("d", path)
            .style("stroke-width", function (d) {
              return Math.max(1, d.dy) + 'px';
            });
    
          var node = nodes.selectAll(".node")
            .data(sankey.nodes());
    
          var newNode = node.enter().append("g")
            .attr("class", "node");
    
          newNode.attr("transform", function (d) {
            return "translate(" + d.x + "," + d.y + ")";
          });
    
          node.transition().duration(animDuration)
            .attr("transform", function (d) {
              return "translate(" + d.x + "," + d.y + ")";
            });
    
          node = newNode.merge(node);
    
          newNode.append('rect');
          newNode.append('text');
    
          newNode.select("rect")
            .attr("width", sankey.nodeWidth())
            .attr("height", function (d) {
              return d.dy;
            })
            .append("title")
              .text(function (d) {
                return d.name + "\n" + format(d.value);
              });
    
          node.select("rect")
            .style("fill", function (d) {
              return d.color = color(d.name.replace(/ .*/, ""));
            })
            .style("stroke", function (d) {
              return d3.rgb(d.color).darker(2);
            })
            .transition().duration(animDuration)
              .attr("height", function (d) {
                return d.dy;
              });
    
          newNode.select("text")
            .attr("dy", ".35em")
            .attr("transform", null)
            .attr("y", function (d) {
              return d.dy / 2;
            });
    
          node.select("text")
            .text(function (d) {
              return d.name;
            })
            .attr("x", -6)
            .attr("text-anchor", "end")
            .filter(function (d) {
              return d.x < width / 2;
            })
              .attr("x", 6 + sankey.nodeWidth())
              .attr("text-anchor", "start");
    
          node.select('text').transition().duration(animDuration)
            .attr("y", function (d) {
              return d.dy / 2;
            });
        

    }

    drawChart2 = () => {
        const d3 = window.d3
        var colors = {
            'environment':         '#edbd00',
            'social':              '#367d85',
            'animals':             '#97ba4c',
            'health':              '#f5662b',
            'research_ingredient': '#3f3e47',
            'fallback':            '#9f9fa3'
          };
      d3.json("//cdn.rawgit.com/q-m/d3.chart.sankey/master/example/data/product.json", (error, json) => {
        var chart = d3.select("#chart").append("svg").chart("Sankey.Path");
        // console.log("Check", chart)
        chart
        .name(label)
        .colorNodes(function(name, node) {
            return color(node, 1) || colors.fallback;
        })
        .colorLinks(function(link) {
            return color(link.source, 4) || color(link.target, 1) || colors.fallback;
        })
        .nodeWidth(15)
        .nodePadding(10)
        .spread(false)
        .iterations(0)
        .draw(this.props.sankey);
        
        console.log("chart@@", chart)
        function label(node) {
            return node.name.replace(/\s*\(.*?\)$/, '' + node.value);
        }
        // console.log("cheart", chart)
        function color(node, depth) {
            // console.log("node@@", node)
          var id = String(node.id).replace(/(_score)?(_\d+)?$/, '');
          if (colors[id]) {
            return colors[id];
          } else if (depth > 0 && node.targetLinks && node.targetLinks.length === 1) {
            return color(node.targetLinks[0].source, depth-1);
          } else {
            return null;
          }
        }
        
      });
    }

    drawChart3 = () => {
        var newSan = sankey()
        console.log("newSan", newSan.nodes(this.state.sankey).nodes(this.state.nodes))
        console.log("sankey", sankey())
        const {nodes, links} = sankey()(this.state.sankey);
        console.log("nodes", nodes)
        
        var margin = {top: 10, right: 10, bottom: 10, left: 10},
        width = 450 - margin.left - margin.right,
        height = 480 - margin.top - margin.bottom;

        var svg = d3.select("#my_datavi").append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .attr("id", "sankey_svg")
        .attr("preserveAspectRatio", "xMinYMin meet")
        .attr("viewBox", "0 0 960 500")

        svg.append("g")
        .selectAll("rect")
        .data(nodes)
        .join("rect")
        .attr("x", d => d.x0 + 1)
        .attr("y", d => d.y0)
        .attr("height", d => d.y1 - d.y0)
        .attr("width", d => d.x1 - d.x0 - 2)
        .attr("fill", d => {
            let c;
            for (const link of d.sourceLinks) {
                link.color = "grey"
            if (c === undefined) c = link.color;
            else if (c !== link.color) c = null;
            }
            if (c === undefined) for (const link of d.targetLinks) {
                link.color = "darkgray"
            if (c === undefined) c = link.color;
            else if (c !== link.color) c = null;
            }
            return (d3Color.color(c) || d3Color.color("grey")).darker(0.5);
        })
        .append("title")
        .text(d => `${d.name}\n${d.value.toLocaleString()}`);

        const link = svg.append("g")
        .attr("fill", "none")
        .selectAll("g")
        .data(links)
        .join("g")
        .attr("stroke", d => d3Color.color("grey") || "darkgrey")
        .style("mix-blend-mode", "multiply");

        link.append("path")
            .attr("d", sankeyLinkHorizontal())
            .attr("stroke-width", d => Math.max(1, d.width));

        link.append("title")
            .text(d => `${d.source.name} → ${d.target.name}\n${d.value.toLocaleString()}`);

        svg.append("g")
        .style("font", "10px sans-serif")
        .selectAll("text")
        .data(nodes)
        .join("text")
        .attr("x", d => d.x0 < width / 2 ? d.x1 + 6 : d.x0 - 6)
        .attr("y", d => (d.y1 + d.y0) / 2)
        .attr("dy", "0.35em")
        .attr("text-anchor", d => d.x0 < width / 2 ? "start" : "end")
        .text(d => d.name)
        .append("tspan")
        .attr("fill-opacity", 0.7)
        .text(d => ` ${d.value.toLocaleString()}`);

        console.log("ssssankeyt111 @@", sankey().update)

        sankey()
        .nodeId(d => d.name)
        // .nodeAlign(sankeyLeft)
        //.nodeSort(inputOrder ? null : undefined)
        .nodeSort( (l1, l2) => {
            return l2.value - l1.value
        })
        .nodeWidth(15)
        .nodePadding(7)
        .extent([[0, 5], [width, height - 5]])

    }

    render() {
        return (
            <div id="my_datavi" className="d-flex justify-content-center"/> 
            // <div id="chart"></div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        nodes: state.chart.sankey.nodes,
        links: state.chart.sankey.links,
        sankey: state.chart.sankey,
        // continuousUser: state.chart.d3.continuousUser,
        // myGroups: state.chart.d3.myGroups,
        // myVars: state.chart.d3.myVars,
        // conditionMainArr: state.chart.userData.conditionMainArr,
        // conditionSubArr: state.chart.userData.conditionSubArr,
        // email: state.authentication.status.email,
        // selectedHost: state.authentication.status.selectedHost,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // getUserOfTime: (host, type, conditionMainArr, conditionSubArr) => {
        //     return dispatch(getUserOfTime(host,type, conditionMainArr, conditionSubArr));
        // },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sankey);