import React, {Component} from "react"
import { connect } from "react-redux"


class LugstayModal extends Component{
    constructor(props){
        super(props)

        this.state ={
            segment: props.segment,
            id: props.segment.id,
            title: props.segment.title,
            purchaseCount1: props.segment.purchaseCount[0], 
            purchaseCount2: props.segment.purchaseCount[1], 
            purchaseRate1: props.segment.purchaseRate[0], 
            purchaseRate2: props.segment.purchaseRate[1], 
            visitCount1: props.segment.visitCount[0], 
            visitCount2: props.segment.visitCount[1], 
            visitRate1: props.segment.visitRate[0],
            visitRate2: props.segment.visitRate[1],
            visitRadio: props.segment.visitRadio,
            purchaseRadio: props.segment.purchaseRadio,
            obj: {
                purchaseCount1: "purchaseRate1",
                purchaseCount2: "purchaseRate2",
                purchaseRate1: "purchaseCount1",
                purchaseRate2: "purchaseCount2",
                visitCount1: "visitRate1",
                visitCount2: "visitRate2",
                visitRate1: "visitCount1",
                visitRate2: "visitCount2"
            }
        }
    }

    componentDidMount(){
    }

    componentDidUpdate(){
        if(this.state.segment !== this.props.segment){
            const { visitRadio, purchaseRadio, purchaseCount, purchaseRate, visitCount, visitRate } = this.props.segment
            const { maxPurchaseCount, maxVisitCount } = this.props
            

            this.setState({
                segment: this.props.segment,
                id: this.props.segment.id,
                title: this.props.segment.title,
                purchaseCount1: this.props.segment.purchaseCount[0], 
                purchaseCount2: this.props.segment.purchaseCount[1], 
                purchaseRate1: this.props.segment.purchaseRate[0], 
                purchaseRate2: this.props.segment.purchaseRate[1], 
                visitCount1: this.props.segment.visitCount[0], 
                visitCount2: this.props.segment.visitCount[1], 
                visitRate1: this.props.segment.visitRate[0],
                visitRate2: this.props.segment.visitRate[1],
                visitRadio: this.props.segment.visitRadio,
                purchaseRadio: this.props.segment.purchaseRadio
            })
        }
    }

    inputChangeTitle = (e) => {
        var name = e.target.name;
        var value = e.target.value;
        this.setState({
            [name]: value,
        })
    }

    inputChange = (e) => {
        const { maxPurchaseCount, maxVisitCount } = this.props;
        const { obj } = this.state;
        var name = e.target.name;
        var value = e.target.value;
        var friend = obj[name]
        var num = 100;
        if(name.indexOf("purchase") !== -1){
            if(maxPurchaseCount !== 0){
                if(name.indexOf("Count") !== -1){
                    // if(value < maxPurchaseCount){
                        num = Math.round((value / maxPurchaseCount) * 10000)/100
                    // }
                } else {
                    num = Math.floor(value / 100 * maxPurchaseCount)
                }
            } else {
                num = 0
                value = 0
            }
        } else {
            if(maxVisitCount !== 0){
                if(name.indexOf("Count") !== -1){
                    // if(value < maxVisitCount){
                        num = Math.round((value / maxVisitCount) * 10000)/100
                    // }
                } else {
                    num = Math.floor(value / 100 * maxVisitCount)
                }
            } else {
                num = 0
                value = 0
            }
        }
        this.setState({
            [name]: parseFloat(value),
            [friend]: parseFloat(num)
        })
    }

    onSubmit = () => {
        if(!this.state.title){
            alert("이름을 정해주세요.")
        } else {
            var obj = {
                title: this.state.title,
                purchaseCount: [this.state.purchaseCount1 ? this.state.purchaseCount1 : 0, this.state.purchaseCount2 ? this.state.purchaseCount2 : 0],
                purchaseRate: [this.state.purchaseRate1 ? this.state.purchaseRate1 : 0, this.state.purchaseRate2 ? this.state.purchaseRate2 : 0],
                visitCount: [this.state.visitCount1 ? this.state.visitCount1 : 0, this.state.visitCount2 ? this.state.visitCount2 : 0],
                visitRate: [this.state.visitRate1 ? this.state.visitRate1 : 0, this.state.visitRate2 ? this.state.visitRate2 : 0],
                visitRadio: this.state.visitRadio,
                purchaseRadio: this.state.purchaseRadio
            }
            this.props.onSubmit(obj)        
        }
    }

    changeVisitRadio = (value) => {
        this.setState({
            visitRadio: value
        })
    }
    changePurchaseRadio = (value) => {
        this.setState({
            purchaseRadio: value
        })
    }

    render(){
        var { 
            id, title, purchaseCount1, purchaseCount2, 
            purchaseRate1, purchaseRate2, visitCount1, visitCount2, visitRate1, visitRate2,
        } = this.state
        return(
            <div className="modal fade" id={id} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <input autoComplete="off" onChange={this.inputChangeTitle} className="form-control" type="text" value={title} name="title" />
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label>방문빈도</label>
                            <div className="d-flex">
                                <div className="form-check d-flex align-items-center">
                                    <input onChange={()=>this.changeVisitRadio("count")} style={{marginBottom:"7px"}} className="form-check-input" type="radio"  value="count" checked={this.state.visitRadio === "count"} />
                                </div>
                                <input min={0} onChange={this.inputChange} style={{width:"100px"}} type="number" className="form-control" placeholder="number" name="visitCount1" value={visitCount1}/>
                                <span className="m-2"> ~ </span>
                                <input min={0} onChange={this.inputChange} style={{width:"100px"}} type="number" className="form-control" placeholder="number" name="visitCount2" value={visitCount2}/> 
                                <span className="m-2"> (횟수) (max {this.props.maxVisitCount})</span>
                            </div>
                            <div className="d-flex">
                                <div className="form-check d-flex align-items-center">
                                    <input onChange={()=>this.changeVisitRadio("rate")} style={{marginBottom:"7px"}} className="form-check-input" type="radio"  value="rate" checked={this.state.visitRadio === "rate"}/>
                                </div>
                                <input min={0} onChange={this.inputChange} style={{width:"100px"}} type="number" step="0.01" className="form-control" placeholder="%" name="visitRate1" value={visitRate1}/>
                                <span className="m-2"> ~ </span>
                                <input min={0} onChange={this.inputChange} style={{width:"100px"}} type="number" step="0.01" className="form-control" placeholder="%" name="visitRate2" value={visitRate2}/>
                                <span className="m-2"> (%) </span>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>구매율</label>
                            <div className="d-flex">
                                <div className="form-check d-flex align-items-center">
                                    <input onChange={()=>this.changePurchaseRadio("count")} style={{marginBottom:"7px"}} className="form-check-input" type="radio"  value="rate" checked={this.state.purchaseRadio === "count"}/>
                                </div>
                                <input min={0} onChange={this.inputChange} style={{width:"100px"}} type="number" className="form-control" placeholder="number" name="purchaseCount1" value={purchaseCount1}/>
                                <span className="m-2"> ~ </span>
                                <input min={0} onChange={this.inputChange} style={{width:"100px"}} type="number" className="form-control" placeholder="number" name="purchaseCount2" value={purchaseCount2}/>
                                <span className="m-2"> (횟수) (max {this.props.maxPurchaseCount})</span>
                            </div>
                            <div className="d-flex">
                                <div className="form-check d-flex align-items-center">
                                    <input onChange={()=>this.changePurchaseRadio("rate")} style={{marginBottom:"7px"}} className="form-check-input" type="radio"  value="rate" checked={this.state.purchaseRadio === "rate"}/>
                                </div>
                                <input min={0} onChange={this.inputChange} style={{width:"100px"}} type="number" step="0.01" className="form-control" placeholder="%" name="purchaseRate1" value={purchaseRate1}/>
                                <span className="m-2"> ~ </span>
                                <input min={0} onChange={this.inputChange} style={{width:"100px"}} type="number" step="0.01" className="form-control" placeholder="%" name="purchaseRate2" value={purchaseRate2}/>
                                <span className="m-2"> (%) </span>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button onClick={this.onSubmit} type="button" className="btn btn-primary" data-dismiss="modal">Save changes</button>
                    </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // getLugstayCustom: (dates, status) => {
        //     return dispatch(getLugstayCustom(dates, status));
        // },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LugstayModal);