import React from 'react'
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import {FormControl} from 'react-bootstrap'
import moment from "moment"
import "../DateTimer.css"
import { faChevronLeft, faChevronRight, faCalendarAlt } from "@fortawesome/free-solid-svg-icons"
import { faClock } from "@fortawesome/free-regular-svg-icons"
import { library, icon } from "@fortawesome/fontawesome-svg-core"
import { connect } from "react-redux"

class DateTimer_lug extends React.Component {
    constructor(props){
        super(props);
        library.add(faChevronLeft, faChevronRight, faCalendarAlt, faClock)
        let now = new Date();
        let today = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0,0,0,0));
        let start = moment(today).subtract(7, "days");
        var minDate = new Date("2020-11-17T00:00:00").getTime()
        if(start._d.getTime() < minDate){
            start = moment(new Date("2020-11-17T00:00:00"))
        }
        let end = moment(today).add(1, "days").subtract(1, "seconds");
        let ranges = {
            "어제": [moment(today).subtract(1, "days"), moment(end)],
            "지난 주": [moment(today).subtract(1, "weeks").startOf('week'), moment(today).subtract(1, "weeks").endOf('week')],
            "지난 달": [moment(today).subtract(1, "months").startOf('month'), moment(today).subtract(1, "months").endOf('month')],
            "최근 7일": [moment(today).subtract(7, "days"), moment(end)],
            "지난 30일": [moment(today).subtract(30, "days"), moment(end)],
        }
        let local = {
            "format":"YYYY-MM-DD HH:mm",
            "sundayFirst" : false,
            "days" : ['월', '화', '수', '목', '금', '토', '일'],
            "months": ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"],
        }
        let maxDate = moment(end)
        this.state = {
            start : start,
            end : end,
            value: `${start.format("YYYY-MM-DD HH:mm")} - ${end.format("YYYY-MM-DD HH:mm")}`,
            ranges: ranges,
            local: local,
            maxDate: maxDate,
        }

        this.applyCallback = this.applyCallback.bind(this);
    }

    componentDidUpdate(){
        //새로고침 되돌리기
    }

    componentDidMount(){
        const leftArrowContainer = document.createElement('span');
        const leftArrowContainer2 = document.createElement('span');
        const rightArrowContainer = document.createElement('span');
        const rightArrowContainer2 = document.createElement('span');
        const clockContainer = document.createElement('span')
        const clockContainer2 = document.createElement('span')
        const calendarContainer = document.createElement('span')
        const calendarContainer2 = document.createElement('span')

        calendarContainer.setAttribute("class", "glyphicon font-calender")
        calendarContainer2.setAttribute("class", "glyphicon font-calender")
        clockContainer.setAttribute("class", "font-clock")
        clockContainer2.setAttribute("class", "font-clock")

        leftArrowContainer.innerHTML = icon({ prefix: 'fas', iconName: 'chevron-left' }).html;
        leftArrowContainer2.innerHTML = icon({ prefix: 'fas', iconName: 'chevron-left' }).html;
        rightArrowContainer.innerHTML = icon({ prefix: 'fas', iconName: 'chevron-right' }).html;
        rightArrowContainer2.innerHTML = icon({ prefix: 'fas', iconName: 'chevron-right' }).html;
        clockContainer.innerHTML = icon({ prefix: 'far', iconName: 'clock' }).html;
        clockContainer2.innerHTML = icon({ prefix: 'far', iconName: 'clock' }).html;
        calendarContainer.innerHTML = icon({ prefix: 'fas', iconName: 'calendar-alt'}).html;
        calendarContainer2.innerHTML = icon({ prefix: 'fas', iconName: 'calendar-alt'}).html;

        var leftArrow = document.getElementsByClassName("glyphicon-chevron-left")[0]
        var leftArrow2 = document.getElementsByClassName("glyphicon-chevron-left")[1]
        var rightArrow = document.getElementsByClassName("glyphicon-chevron-right")[0]
        var rightArrow2 = document.getElementsByClassName("glyphicon-chevron-right")[1]
        var calendar = document.getElementsByClassName('calendarAddon')[0]
        var calendar2 = document.getElementsByClassName('calendarAddon')[1]
        var clock = document.getElementsByClassName('timeContainer')[0]
        var clock2 = document.getElementsByClassName('timeContainer')[1]

        leftArrow.appendChild(leftArrowContainer)
        leftArrow2.appendChild(leftArrowContainer2)
        rightArrow.appendChild(rightArrowContainer)
        rightArrow2.appendChild(rightArrowContainer2)
        calendar.insertBefore(calendarContainer, calendar.firstChild)
        calendar2.insertBefore(calendarContainer2, calendar2.firstChild)
        clock.appendChild(clockContainer)
        clock2.appendChild(clockContainer2)

        // this.applyCallback(this.state.start, this.state.end)
    }

    applyCallback = (startDate, endDate) => {
        console.log("startDate", startDate)
        var minDate = new Date("2020-11-17T00:00:00").getTime()
        var start = new Date(startDate._d.getTime()).toISOString()
        var end = new Date(endDate._d.getTime()).toISOString()
        var startMoment = startDate
        var endMoment = endDate
        if(startDate._d.getTime() < minDate){
            start = new Date(new Date("2020-11-17T00:00:00")).toISOString()
            startMoment = moment(new Date("2020-11-17T00:00:00"))
        }
        if(endDate._d.getTime() < minDate) {
            end = new Date(new Date("2020-11-17T23:59:00")).toISOString()
            endMoment = moment(new Date("2020-11-17T23:59:00"))
        }
        var dates = [start, end]
        this.props.setDates(dates)
        this.setState({
            start: startMoment,
            end: endMoment,
            value:`${startMoment.format("YYYY-MM-DD HH:mm")} - ${endMoment.format("YYYY-MM-DD HH:mm")}`,
        })
    }


    render(){
            return(
                <div>
                    <DateTimeRangeContainer 
                        ranges={this.state.ranges}
                        start={this.state.start}
                        end={this.state.end}
                        local={this.state.local}
                        maxDate={this.state.maxDate}
                        applyCallback={this.applyCallback}
                        // smartMode
                        leftMode
                        noMobileMode
                        // forceMobileMode
                    >    
                        <FormControl
                        id="formControlsTextB"
                        type="text"
                        label="Text"
                        placeholder="Enter text"
                        value={this.state.value}
                        onChange={this.applyCallback}
                        disabled={false}
                        style={{width:"300px", cursor: "pointer"}}
                        /> 
                    </DateTimeRangeContainer>
                </div>
            );
        }
}

const mapStateToProps = (state) => {
    return {

    };
  };
  
  const mapDispatchToProps = (dispatch) => {
      return {
      };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(DateTimer_lug);