import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Heatmap1 from '../../datas/d3/heatmap'
import Heatmap2 from "../../datas/d3/heatmap2"
import Sankey from "../d3/Sankey"

const renderRealTimeTable = (user, index) => {
    return(
        <tr key={index}>
            <td style={{maxWidth:"200px"}}>{index + 1}</td>
            <td style={{maxWidth:"200px"}}>{user.name}</td>
            <td style={{maxWidth:"200px"}}>
            </td>
            <td className="text-right"><span className="badge bg-danger">{user.count} 명</span></td>
        </tr>
    )
}

const renderPageViewTable = (page, index) => {
    return (
        <tr key={index}>
            <td>{index + 1}</td>
            <td>{page.name}</td>
            <td>
            </td>
            <td className="text-right"><span className="badge bg-success">{page.count}</span></td>
        </tr>
    )
}

const Card = ({title, useDetail, useCompare, useCompare2, canvasId, useTable, useFooter, icon1, icon2, height, customBtn, changeBtn }) => {
    return (
        <div className="card">
            <div className="card-header">
                <div className="d-flex justify-content-between">
                    <h5 className="card-title">{title}</h5>
                    {useDetail ? <a href={`#/dashboard/${useDetail}`}>View Report</a> : ""}
                    {
                        customBtn ?
                        <div className="btn-group" role="group" aria-label="Basic example">
                            <button onClick={()=>changeBtn("all")} type="button" className={("btn ") + (customBtn === "all" ? "btn-info": "btn-muted")} style={{borderColor:"rgba(0,0,0,0.125)"}}>전체</button>
                            <button onClick={()=>changeBtn("rec")} type="button" className={("btn ") + (customBtn === "rec" ? "btn-info": "btn-muted")} style={{borderColor:"rgba(0,0,0,0.125)"}}>추천</button>
                            <button onClick={()=>changeBtn("noRec")} type="button" className={("btn ") + (customBtn === "noRec" ? "btn-info": "btn-muted")} style={{borderColor:"rgba(0,0,0,0.125)"}}>미추천</button>
                        </div> : ""
                    }
                </div>
            </div>
            <div className="card-body">
                {useCompare ? 
                    <div className="d-flex">
                        <p className="d-flex flex-column">
                            <span className="font-weight-bold h5">
                                {useCompare}
                            </span>
                            <span>Visitors Over Time</span>
                        </p>
                        {/* <p className=" ml-auto d-flex flex-column text-right">
                            <span className="text-success">
                                <FontAwesomeIcon icon={icon1}/>
                                12.5%
                            </span>
                            <span className="text-muted">
                                Since last week
                            </span>
                        </p> */}
                    </div>
                    : ""
                }
                <div className="position-relative mb-4">
                    {
                        canvasId === "heatmap1" ? <Heatmap1 /> 
                        : canvasId === "heatmap2" ? <Heatmap2 />
                        : canvasId === "sankey" ? <Sankey />
                        : canvasId ? <canvas style={height ? {left:"10%", height:height} : {left:"10%"}} id={canvasId}></canvas>
                        : ""
                    }
                    {
                        useTable ?
                        <table className="table table-sm">
                            <thead>
                                <tr>
                                    <th style={{ width: "10%"}}>#</th>
                                    <th>페이지</th>
                                    <th ></th>
                                    <th className="text-right" style={{ width: "20%"}}>페이지 뷰</th>
                                </tr>
                            </thead>
                            <tbody>
                                {useTable.map(renderPageViewTable)}
                            </tbody>
                        </table>
                        : ""
                    }
                </div>
                {
                    useCompare2 ?
                    <div className="d-flex flex-row justify-content-end">
                        <span className="mr-2">
                            <FontAwesomeIcon className="text-primary mr-1" icon={icon2}/>
                            This Week
                        </span>
                        <span>
                            <FontAwesomeIcon className="text-muted mr-1" icon={icon2}/>
                            Last Week
                        </span>
                    </div>
                    : ""
                }
            </div>
            {
                useFooter ? 
                <div className="card-footer bg-white" style={{maxHeight:"255px", overflow:"auto"}}>
                    <table className="table table-sm">
                        <thead>
                            <tr>
                                <th style={{ width: "10%"}}>#</th>
                                <th>인기페이지</th>
                                <th ></th>
                                <th className="text-right" style={{ width: "15%"}}>사용자</th>
                            </tr>
                        </thead>
                        <tbody>
                            {useFooter.map(renderRealTimeTable)}
                        </tbody>
                    </table>
                </div>
                :
                ""
            }
        </div>
    )
}

export default Card