import React from "react"
import Chart from "chart.js"
import {expandDoughnut} from "../../datas/chartjs/doughnut"
class ExpandDoughnut extends React.Component {
    constructor(props){
        super(props)
        var doughnut = JSON.parse(JSON.stringify(expandDoughnut))
        this.state = {
            config1: doughnut,
            chartNum: Math.floor(Math.random()*100)
        }
    }
    componentDidMount() {
        var totalNum = this.props.one + this.props.two + this.props.three
        this.setState(state => {
            var config1 = state.config1;
            config1.data.datasets[0].data = [this.props.one, this.props.two, this.props.three]
            config1.options.elements.center.text = `   Total   \n${totalNum.toLocaleString()}`
            var ctx = document.getElementById(`chart-${state.chartNum}`).getContext("2d");
            var chart_1 = new Chart(ctx, config1)
            return {
                config1,
                chart_1
            }
        })
        // this.setState({chart_1})
    }
    render(){
        return (
            <div className="d-flex" style={{width:"30%", marginRight:"10%"}}>
                <canvas style={{left:"10%"}} id={`chart-${this.state.chartNum}`}></canvas>
            </div>
        )
    }
}

export default ExpandDoughnut