import React from "react";
import { HashRouter, Route } from "react-router-dom";
// import Home from "./pages/Home"
// import ReactBs from "./pages/ReactBs"
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Register from "./pages/Register";
import { connect } from "react-redux";
import {
    getProfileFetch,
    refreshCafe24Token,
    getCafe24Category,
    getCafe24Board,
    getGodomallCategory,
    getGodomallBoard,
} from "./store/actions/authentication";
import RowChart from "./datas/d3/rowChart";
import Heatmap2 from "./datas/d3/heatmap2";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            platformType: this.props.status.platformType,
            client_id: this.props.client_id,
        };
        this.props.getProfileFetch();
    }

    componentDidUpdate() {
        if (this.props.status.platformType !== this.state.platformType) {
            this.setState({
                platformType: this.props.status.platformType,
            });
            const { platformType } = this.props.status;
            if (platformType === "cafe24") {
                this.getCafe24Token();
                //카테고리목록은 토큰없이 client_id만 있으면 가져올 수 있음
                this.props.getCafe24Category(this.props.cafe24);
            } else if (platformType === "godomall") {
                this.props.getGodomallCategory(this.props.godomall);
                this.props.getGodomallBoard(this.props.godomall);
                // this.props.getGodomallProductAll(this.props.godomall)
            } else {
                console.log("this is local account!!");
            }
        }
    }

    getCafe24Token = () => {
        if (this.props.cafe24.client_id) {
            var userEmail = this.props.status.userEmail;
            var client_id = this.props.cafe24.client_id;
            var mall_id = this.props.cafe24.mall_id;
            var redirectURL = "https://saas.allbigdat.com/getCafe24Code";
            var scope =
                "mall.read_application,mall.write_application,mall.read_product,mall.read_category,mall.read_community,mall.read_order";
            if (this.props.cafe24.access_token) {
                var currentDate = new Date();
                var access_date = new Date(this.props.cafe24.expires_at);
                if (currentDate < access_date) {
                    //access 토큰 사용가능하니 board 목록을 가져올 수 있다.
                    console.log("access토큰 사용 가능");
                    this.props.getCafe24Board(this.props.cafe24);
                } else {
                    var refresh_date = new Date(
                        this.props.cafe24.refresh_token_expires_at
                    );
                    if (currentDate < refresh_date) {
                        //리프레시 가능
                        console.log("refresh토큰 사용 가능");
                        this.props.refreshCafe24Token(
                            this.props.cafe24,
                            userEmail
                        );
                    } else {
                        //새로 받아오기(code, access, refresh)
                        window.open(
                            `https://${mall_id}.cafe24.com/api/v2/oauth/authorize?response_type=code&client_id=${client_id}&state=${userEmail}&redirect_uri=${redirectURL}&scope=${scope}`,
                            "",
                            ""
                        );
                    }
                }
            } else {
                //새로 받아오기(code, access, refresh)
                window.open(
                    `https://${mall_id}.cafe24.com/api/v2/oauth/authorize?response_type=code&client_id=${client_id}&state=${userEmail}&redirect_uri=${redirectURL}&scope=${scope}`,
                    "",
                    ""
                );
            }
        }
    };

    render() {
        return (
            <HashRouter>
                <Route path="/" component={Login} exact={true} />
                {/* <Route path='/' component={Dashboard} exact={true} /> */}
                <Route path="/dashboard" component={Dashboard} />
                <Route path="/login" component={Login} exact={true} />
                <Route path="/register" component={Register} exact={true} />
                <Route path="/rowChart" component={RowChart} exact={true} />
                <Route path="/heatmap" component={Heatmap2} exact={true} />
            </HashRouter>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        status: state.authentication.status,
        cafe24: state.authentication.cafe24,
        godomall: state.authentication.godomall,
        local: state.authentication.local,
    };
};

const mapDispatchToProps = (dispatch) => ({
    getProfileFetch: () => dispatch(getProfileFetch()),
    refreshCafe24Token: (cafe24, userEmail) =>
        dispatch(refreshCafe24Token(cafe24, userEmail)),
    getCafe24Category: (cafe24) => dispatch(getCafe24Category(cafe24)),
    getCafe24Board: (cafe24) => dispatch(getCafe24Board(cafe24)),
    getGodomallCategory: (godomall) => dispatch(getGodomallCategory(godomall)),
    getGodomallBoard: (godomall) => dispatch(getGodomallBoard(godomall)),
    // getGodomallProductAll: (godomall) => dispatch(getGodomallProductAll(godomall)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
