const initialState = {
    lugstay: {
        segmentGroup: {
            "기본 세그먼트": [
                {
                    title:"잠재 충성 고객",
                    purchaseCount: [0, 5],
                    purchaseRate: [0, 10],
                    visitCount: [10, 15],
                    visitRate: [30, 50],
                    bg: "rgba(70, 130, 204, .7)",
                    id: "modal_1",
                    visitRadio: "count",
                    purchaseRadio: "count"
                },
                {
                    title:"Frequent User",
                    purchaseCount: [5, 10],
                    purchaseRate: [10, 30],
                    visitCount: [10, 15],
                    visitRate: [30, 50],
                    bg: "#4682CC",
                    id: "modal_2",
                    visitRadio: "count",
                    purchaseRadio: "count"
                },
                {
                    title:"충성 고객",
                    purchaseCount: [10, 15],
                    purchaseRate: [30, 50],
                    visitCount: [10, 15],
                    visitRate: [30, 50],
                    bg: "rgba(0, 40, 139, .8)",
                    id: "modal_3",
                    visitRadio: "count",
                    purchaseRadio: "count"
                },
                {
                    title:"이벤트 필요 고객",
                    purchaseCount: [0, 5],
                    purchaseRate: [0, 10],
                    visitCount: [5, 10],
                    visitRate: [10, 30],
                    bg: "rgba(105, 176, 250, .5)",
                    id: "modal_4",
                    visitRadio: "count",
                    purchaseRadio: "count"
                },
                {
                    title:"마케팅 필요 고객",
                    purchaseCount: [5, 10],
                    purchaseRate: [10, 30],
                    visitCount: [5, 10],
                    visitRate: [10, 30],
                    bg: "rgba(70, 130, 204, .7)",
                    id: "modal_5",
                    visitRadio: "count",
                    purchaseRadio: "count"
                },
                {
                    title:"Frequent Buyer",
                    purchaseCount: [10, 15],
                    purchaseRate: [30, 50],
                    visitCount: [5, 10],
                    visitRate: [10, 30],
                    bg: "#4682CC",
                    id: "modal_6",
                    visitRadio: "count",
                    purchaseRadio: "count"
                },
                {
                    title:"이탈 직전 고객",
                    purchaseCount: [0, 5],
                    purchaseRate: [0, 10],
                    visitCount: [0, 5],
                    visitRate: [0, 10],
                    bg: "rgba(115, 186, 250, .3)",
                    id: "modal_7",
                    visitRadio: "count",
                    purchaseRadio: "count"
                },
                {
                    title:"정기 구매 고객",
                    purchaseCount: [5, 10],
                    purchaseRate: [10, 30],
                    visitCount: [0, 5],
                    visitRate: [0, 10],
                    bg: "rgba(105, 176, 250, .5)",
                    id: "modal_8",
                    visitRadio: "count",
                    purchaseRadio: "count"
                },
                {
                    title:"신규 구매 고객",
                    purchaseCount: [10, 15],
                    purchaseRate: [30, 50],
                    visitCount: [0, 5],
                    visitRate: [0, 10],
                    bg: "rgba(70, 130, 204, .7)",
                    id: "modal_9",
                    visitRadio: "count",
                    purchaseRadio: "count"
                }
            ]
        },
        segmentGroupNames: ["기본 세그먼트"],
        selectedGroupName: "기본 세그먼트",
        segment: [
            {
                title:"잠재 충성 고객",
                purchaseCount: [0, 5],
                purchaseRate: [0, 10],
                visitCount: [10, 15],
                visitRate: [30, 50],
                bg: "rgba(70, 130, 204, .7)",
                id: "modal_1",
                visitRadio: "count",
                purchaseRadio: "count"
            },
            {
                title:"Frequent User",
                purchaseCount: [5, 10],
                purchaseRate: [10, 30],
                visitCount: [10, 15],
                visitRate: [30, 50],
                bg: "#4682CC",
                id: "modal_2",
                visitRadio: "count",
                purchaseRadio: "count"
            },
            {
                title:"충성 고객",
                purchaseCount: [10, 15],
                purchaseRate: [30, 50],
                visitCount: [10, 15],
                visitRate: [30, 50],
                bg: "rgba(0, 40, 139, .8)",
                id: "modal_3",
                visitRadio: "count",
                purchaseRadio: "count"
            },
            {
                title:"이벤트 필요 고객",
                purchaseCount: [0, 5],
                purchaseRate: [0, 10],
                visitCount: [5, 10],
                visitRate: [10, 30],
                bg: "rgba(105, 176, 250, .5)",
                id: "modal_4",
                visitRadio: "count",
                purchaseRadio: "count"
            },
            {
                title:"마케팅 필요 고객",
                purchaseCount: [5, 10],
                purchaseRate: [10, 30],
                visitCount: [5, 10],
                visitRate: [10, 30],
                bg: "rgba(70, 130, 204, .7)",
                id: "modal_5",
                visitRadio: "count",
                purchaseRadio: "count"
            },
            {
                title:"Frequent Buyer",
                purchaseCount: [10, 15],
                purchaseRate: [30, 50],
                visitCount: [5, 10],
                visitRate: [10, 30],
                bg: "#4682CC",
                id: "modal_6",
                visitRadio: "count",
                purchaseRadio: "count"
            },
            {
                title:"이탈 직전 고객",
                purchaseCount: [0, 5],
                purchaseRate: [0, 10],
                visitCount: [0, 5],
                visitRate: [0, 10],
                bg: "rgba(115, 186, 250, .3)",
                id: "modal_7",
                visitRadio: "count",
                purchaseRadio: "count"
            },
            {
                title:"정기 구매 고객",
                purchaseCount: [5, 10],
                purchaseRate: [10, 30],
                visitCount: [0, 5],
                visitRate: [0, 10],
                bg: "rgba(105, 176, 250, .5)",
                id: "modal_8",
                visitRadio: "count",
                purchaseRadio: "count"
            },
            {
                title:"신규 구매 고객",
                purchaseCount: [10, 15],
                purchaseRate: [30, 50],
                visitCount: [0, 5],
                visitRate: [0, 10],
                bg: "rgba(70, 130, 204, .7)",
                id: "modal_9",
                visitRadio: "count",
                purchaseRadio: "count"
            }
        ],
        avgData: {},
        totalAvgData: {}
    }
};
 
export default function custom(state = initialState, action) {
  switch(action.type) {
    case "LUGSTAY_SUCCESS":
        if(action.results){
            console.log("lugstay custom ",action.results)
            var { avgPerBrowser, avgPerUserId } = action.results

            // main 데이터 잘못 들어온 녀석들 예외처리..
            avgPerBrowser = avgPerBrowser.filter(doc => doc.funnel ? doc : null)
            avgPerUserId = avgPerUserId.filter(doc => doc.funnel ? doc : null)

            var totalDoc = [...avgPerBrowser, ...avgPerUserId]
            
            var total_stay = 0
            var total_scroll = 0
            var total_view = 0
            var total_visit = 0
            var total_purchase = 0
            var avg_stay = 0
            var avg_scroll = 0
            var avg_view = 0
            var avg_visit = 0
            var avg_purchase = 0
            var arr_device = []
            var arr_funnel = []
            var maxPurchaseCount = 0;
            var maxVisitCount = 0;

            if(totalDoc[0]){
                for(var i = 0; i < totalDoc.length; i++){
                    total_stay += totalDoc[i].avgStayTime;
                    total_scroll += totalDoc[i].avgScrollDepth;
                    total_view += totalDoc[i].avgViewCount;
                    total_visit += totalDoc[i].visitCount;
                    total_purchase += totalDoc[i].purchaseCount;
                }
    
                totalDoc.sort((a,b) => b.purchaseCount - a.purchaseCount)
                maxPurchaseCount = totalDoc[0].purchaseCount
                totalDoc.sort((a,b) => b.visitCount - a.visitCount)
                maxVisitCount = totalDoc[0].visitCount
    
                avg_stay = Math.round(total_stay / totalDoc.length)
                avg_scroll = Math.round(total_scroll / totalDoc.length)
                avg_view = Math.round(total_view / totalDoc.length)
                avg_visit = Math.round(total_visit / totalDoc.length)
                avg_purchase = Math.round((total_purchase / totalDoc.length)* 1000) / 10
    
                var hour = (avg_stay / 3600000) > 0 ? Math.floor((avg_stay / 3600000)) : ""
                var minute = (avg_stay / 60000) > 0 ? Math.floor((avg_stay % 3600000) / 60000) : ""
                var second = (avg_stay / 1000) > 0 ? Math.floor((avg_stay % 60000) / 1000) : 0
                avg_stay = `${hour ? hour + "시간" : ""}${minute ? minute + "분": ""}${second + "초"}`
    
                var total_funnel = {}
                var total_day = {}
                var total_device = {}
                for(i = 0; i < avgPerBrowser.length; i++){
                    if(total_funnel[avgPerBrowser[i].funnel]){
                        total_funnel[avgPerBrowser[i].funnel] += 1
                    } else {
                        total_funnel[avgPerBrowser[i].funnel] = 1
                    }
                    if(total_day[avgPerBrowser[i].day]){
                        total_day[avgPerBrowser[i].day] += 1
                    } else {
                        total_day[avgPerBrowser[i].day] = 1
                    }
                    if(total_device[avgPerBrowser[i].device]){
                        total_device[avgPerBrowser[i].device] += 1
                    } else {
                        total_device[avgPerBrowser[i].device] = 1
                    }
                }
    
                var filterUserId = []
                var filterUserIdObj = {}
                // 메인데이터를 구할 때는 동일 브라우저는 중복되므로 한개만 남겨둔다.
                for(i = 0; i < avgPerUserId.length; i++){
                    if(!filterUserIdObj[avgPerUserId[i].browserKey]){
                        filterUserIdObj[avgPerUserId[i].browserKey] = true
                        filterUserId.push(avgPerUserId[i])
                    }
                }
                for(i = 0; i < filterUserId.length; i++){
                    if(total_funnel[filterUserId[i].funnel]){
                        total_funnel[filterUserId[i].funnel] += 1
                    } else {
                        total_funnel[filterUserId[i].funnel] = 1
                    }
                    if(total_day[filterUserId[i].day]){
                        total_day[filterUserId[i].day] += 1
                    } else {
                        total_day[filterUserId[i].day] = 1
                    }
                    if(total_device[filterUserId[i].device]){
                        total_device[filterUserId[i].device] += 1
                    } else {
                        total_device[filterUserId[i].device] = 1
                    }
                }
    
                //메인데이터를 나누는 분모로 사용
                var filterTotalDocLen = avgPerBrowser.length + filterUserId.length;
    
                for(var a in total_funnel){
                    var newName = ""
                    if(a.indexOf("인하우스 마케팅, ") !== -1){
                        newName = a.replace("인하우스 마케팅, ", "")
                    } else if (a.indexOf("자연유입_Direct") !== -1){
                        newName = a.replace("자연유입_Direct", "직접 유입")
                    } else if (a.indexOf("자연유입_검색, ") !== -1) {
                        newName = a.replace("자연유입_검색, ", "")
                    } else if (a.indexOf("유료마케팅_검색, ") !== -1) {
                        newName = a.replace("유료마케팅_검색, ", "")
                    }
                    arr_funnel.push({name: newName ? newName : a, avg: Math.round((total_funnel[a] / filterTotalDocLen)* 100)})
                }
                arr_funnel.sort((a,b) => b.avg - a.avg)
    
                for(a in total_device){
                    arr_device.push({name: a, avg: Math.round((total_device[a] / filterTotalDocLen)* 100)})
                }
                arr_device.sort((a,b) => b.avg - a.avg)
            }


            var avgData ={
                arr_device: arr_device,
                arr_funnel: arr_funnel,
                avg_stay: avg_stay,
                avg_scroll: avg_scroll,
                avg_view: avg_view,
                avg_visit: avg_visit,
                avg_purchase: avg_purchase,
                totalLength: totalDoc.length,
                avgPerBrowser: avgPerBrowser,
                avgPerUserId: avgPerUserId,
                maxPurchaseCount: maxPurchaseCount,
                maxVisitCount: maxVisitCount,
            }
        }
        return {
            ...state,
            lugstay: {
                ...state.lugstay,
                avgData: avgData,
                totalAvgData: avgData
            }
        }
    case "GET_SEGMENT_SUCCESS" :
        if(action.results){
            var segmentGroup = action.results;
            var segmentGroupNames = []
            for(var a in segmentGroup){
                segmentGroupNames.push(a)
            }
            var segment = segmentGroup[segmentGroupNames[0]]
            var selectedGroupName = segmentGroupNames[0]
            if(action.name){
                segment = segmentGroup[action.name]
                selectedGroupName = action.name
            }

        }
        return {
            ...state,
            lugstay: {
                ...state.lugstay,
                segmentGroup: segmentGroup,
                segmentGroupNames: segmentGroupNames,
                segment: segment,
                selectedGroupName: selectedGroupName
            }
        }
    case "SET_SEGMENT_FILTER" :
        if(action.filter){
            var {login, device, funnel} = action.filter
            var funnelFilter = [];
            for(var a in funnel){
                if(!funnel[a]){
                    funnelFilter.push(a)
                }
            }
            var deviceFilter = [];
            for(a in device){
                if(!device[a]){
                    deviceFilter.push(a)
                }
            }
            
            var { avgPerBrowser, avgPerUserId } = state.lugstay.totalAvgData
            console.log("filter prev totalDoc", [...avgPerBrowser, ...avgPerUserId])
            console.log("filter ", action.filter)
            function filter(arr, filter, filterName){
                for(var i = 0; i < filter.length; i++){
                    arr = arr.filter(ar => {
                        if(ar[filterName] !== filter[i]){
                            return ar
                        }
                    })
                }
                return arr
            }
            avgPerBrowser = filter(avgPerBrowser, funnelFilter, "funnel")
            avgPerBrowser = filter(avgPerBrowser, deviceFilter, "device")
            avgPerUserId = filter(avgPerUserId, funnelFilter, "funnel")
            avgPerUserId = filter(avgPerUserId, deviceFilter, "device")

            if(!login.login){
                if(!login.noLogin){
                    avgPerBrowser = [];
                    avgPerUserId = []
                } else {
                    avgPerBrowser = avgPerBrowser.filter(ar => ar.userId === null)
                    avgPerUserId = avgPerUserId.filter(ar => ar.userId === null)
                }
            } else {
                if(!login.noLogin){
                    avgPerBrowser = avgPerBrowser.filter(ar => ar.userId !== null)
                    avgPerUserId = avgPerUserId.filter(ar => ar.userId !== null)
                }
            }
            var totalDoc = [...avgPerBrowser, ...avgPerUserId]
            console.log("filtered totalDoc", totalDoc)

            var total_stay = 0
            var total_scroll = 0
            var total_view = 0
            var total_visit = 0
            var total_purchase = 0
            var avg_stay = 0
            var avg_scroll = 0
            var avg_view = 0
            var avg_visit = 0
            var avg_purchase = 0
            var arr_device = []
            var arr_funnel = []
            var maxPurchaseCount = 0;
            var maxVisitCount = 0;

            if(totalDoc[0]){
                for(var i = 0; i < totalDoc.length; i++){
                    total_stay += totalDoc[i].avgStayTime;
                    total_scroll += totalDoc[i].avgScrollDepth;
                    total_view += totalDoc[i].avgViewCount;
                    total_visit += totalDoc[i].visitCount;
                    total_purchase += totalDoc[i].purchaseCount;
                }
    
                totalDoc.sort((a,b) => b.purchaseCount - a.purchaseCount)
                maxPurchaseCount = totalDoc[0].purchaseCount
                totalDoc.sort((a,b) => b.visitCount - a.visitCount)
                maxVisitCount = totalDoc[0].visitCount
    
                avg_stay = Math.round(total_stay / totalDoc.length)
                avg_scroll = Math.round(total_scroll / totalDoc.length)
                avg_view = Math.round(total_view / totalDoc.length)
                avg_visit = Math.round(total_visit / totalDoc.length)
                avg_purchase = Math.round((total_purchase / totalDoc.length)* 1000) / 10
    
                var hour = (avg_stay / 3600000) > 0 ? Math.floor((avg_stay / 3600000)) : ""
                var minute = (avg_stay / 60000) > 0 ? Math.floor((avg_stay % 3600000) / 60000) : ""
                var second = (avg_stay / 1000) > 0 ? Math.floor((avg_stay % 60000) / 1000) : 0
                avg_stay = `${hour ? hour + "시간" : ""}${minute ? minute + "분": ""}${second + "초"}`
    
                var total_funnel = {}
                var total_day = {}
                var total_device = {}
                for(i = 0; i < avgPerBrowser.length; i++){
                    if(total_funnel[avgPerBrowser[i].funnel]){
                        total_funnel[avgPerBrowser[i].funnel] += 1
                    } else {
                        total_funnel[avgPerBrowser[i].funnel] = 1
                    }
                    if(total_day[avgPerBrowser[i].day]){
                        total_day[avgPerBrowser[i].day] += 1
                    } else {
                        total_day[avgPerBrowser[i].day] = 1
                    }
                    if(total_device[avgPerBrowser[i].device]){
                        total_device[avgPerBrowser[i].device] += 1
                    } else {
                        total_device[avgPerBrowser[i].device] = 1
                    }
                }
    
                var filterUserId = []
                var filterUserIdObj = {}
                // 메인데이터를 구할 때는 동일 브라우저는 중복되므로 한개만 남겨둔다.
                for(i = 0; i < avgPerUserId.length; i++){
                    if(!filterUserIdObj[avgPerUserId[i].browserKey]){
                        filterUserIdObj[avgPerUserId[i].browserKey] = true
                        filterUserId.push(avgPerUserId[i])
                    }
                }
                for(i = 0; i < filterUserId.length; i++){
                    if(total_funnel[filterUserId[i].funnel]){
                        total_funnel[filterUserId[i].funnel] += 1
                    } else {
                        total_funnel[filterUserId[i].funnel] = 1
                    }
                    if(total_day[filterUserId[i].day]){
                        total_day[filterUserId[i].day] += 1
                    } else {
                        total_day[filterUserId[i].day] = 1
                    }
                    if(total_device[filterUserId[i].device]){
                        total_device[filterUserId[i].device] += 1
                    } else {
                        total_device[filterUserId[i].device] = 1
                    }
                }
    
                //메인데이터를 나누는 분모로 사용
                var filterTotalDocLen = avgPerBrowser.length + filterUserId.length;
    
                for(var a in total_funnel){
                    var newName = ""
                    if(a.indexOf("인하우스 마케팅, ") !== -1){
                        newName = a.replace("인하우스 마케팅, ", "")
                    } else if (a.indexOf("자연유입_Direct") !== -1){
                        newName = a.replace("자연유입_Direct", "직접 유입")
                    } else if (a.indexOf("자연유입_검색, ") !== -1) {
                        newName = a.replace("자연유입_검색, ", "")
                    } else if (a.indexOf("유료마케팅_검색, ") !== -1) {
                        newName = a.replace("유료마케팅_검색, ", "")
                    }
                    arr_funnel.push({name: newName ? newName : a, avg: Math.round((total_funnel[a] / filterTotalDocLen)* 100)})
                }
                arr_funnel.sort((a,b) => b.avg - a.avg)
    
                for(a in total_device){
                    arr_device.push({name: a, avg: Math.round((total_device[a] / filterTotalDocLen)* 100)})
                }
                arr_device.sort((a,b) => b.avg - a.avg)
            }


            var avgData ={
                arr_device: arr_device,
                arr_funnel: arr_funnel,
                avg_stay: avg_stay,
                avg_scroll: avg_scroll,
                avg_view: avg_view,
                avg_visit: avg_visit,
                avg_purchase: avg_purchase,
                totalLength: totalDoc.length,
                avgPerBrowser: avgPerBrowser,
                avgPerUserId: avgPerUserId,
                maxPurchaseCount: maxPurchaseCount,
                maxVisitCount: maxVisitCount,
            }
        }
        return {
            ...state,
            lugstay: {
                ...state.lugstay,
                avgData: avgData
            }
        }
    default:
      return state;
  }
}