import React from "react"
import "./Nav.css"
import { connect } from "react-redux"
import SelectionBoxs from "./SelectionBoxs"
import Navbar from "./Navbar"
import { conditionInitialization } from "../store/actions/chart"

class Nav extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            homeMenu: JSON.parse(JSON.stringify(this.props.homeMenu)),
        }
        this.props.conditionInitialization()
    }


    render(){

        // const {menus} = this.state
        return(
            <>
            <div className="nav--container navbar navbar-expand-lg navbar-light bg-white d-flex flex-column align-items-baseline">
                {
                    this.props.isDetail ? 
                    <div className="mb-3 w-100">
                        <Navbar type="1" title={this.props.isDetail[0]}/>
                        <span className="text-secondary">{this.props.isDetail[1]}</span>
                    </div>
                    :
                    <>
                    <div className="mb-3 w-100">
                        <Navbar type="1" title="Audience Explorer"/>
                        <span className="text-secondary">Gain valuable insights by applying filtering conditions to your users</span>
                    </div>
                    <SelectionBoxs requestFunc={true} menus={this.state.homeMenu}/>
                    </>
                }
                {
                    this.props.noUseBtn ? ""
                    :
                    <div className="mt-3">
                        <button type="button" className="btn btn-secondary" disabled>CREATE AUDIENCE</button>
                    </div>
                }
            </div>
            {/* <div className="progress-div">
                <div className="progress">
                    <div className="progress-bar active"  role="progressbar" aria-valuenow="45" aria-valuemin="0" aria-valuemax="100">
                    </div>
                </div>
            </div> */}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        pageName: state.authentication.page.pageName,
        homeMenu: state.condition.homeMenu,
        abMenu: state.condition.abMenu,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        conditionInitialization: () => {
            return dispatch(conditionInitialization());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Nav);