import axios from "../../api"

export function getFunnel(host, type, conditionMainArr, conditionSubArr, conditionDates) {
    return (dispatch) => {
        // Inform Register API is starting
        // dispatch(register());
        return axios.post('/getApi', { host, type, conditionMainArr, conditionSubArr, conditionDates })
        .then((response) => {
            if(response.data.realtime){
                // console.log("check realtime data", response.data)
                dispatch(realtimeSuccess(response.data))
                return {success: true}
            } else if(response.data.success){
                // console.log("data@@@", response.data)
                dispatch(funnelSuccess(response.data));
                return {success: true}
            }
        }).catch((error) => {
            dispatch(funnelFailure(error));
        });
    };
}

export function funnelSuccess(response) {
    return {
        type: "API_FUNNEL_SUCCESS",
        results: response.result
    };
}

export function funnelFailure(error) {
    return {
        type: "API_FUNNEL_FAILURE",
        error
    };
}

export function realtimeSuccess(response){
    return {
        type: "REAL_TIME_SUCCESS",
        real_time_user: response.result.real_time_user,
        real_time_page: response.result.real_time_page,
        real_time_userPerPage: response.result.real_time_userPerPage,
    }
}

export function getUserOfTime(host, type, conditionMainArr, conditionSubArr, conditionDates) {
    return (dispatch) => {
        // Inform Register API is starting
        // dispatch(register());
 
        return axios.post('/getApi', { host, type, conditionMainArr, conditionSubArr, conditionDates })
        .then((response) => {
            if(response.data.success){
                // console.log(response.data)
                dispatch(userOfTimeSuccess(response.data));
            }
        }).catch((error) => {
            dispatch(userOfTimeFailure(error));
        });
    };
}

export function userOfTimeSuccess(response) {
    return {
        type: "USER_OF_TIME_SUCCESS",
        user_of_time: response.result.user_of_time ? response.result.user_of_time : null,
        continuous_user: response.result.continuous_user ? response.result.continuous_user : null,
        continuous_user_date: response.result.continuous_user_date ? response.result.continuous_user_date : null,
    };
}

export function userOfTimeFailure(error) {
    return {
        type: "USER_OF_TIME_FAILURE",
        error
    };
}

export function requestCondition(host, type, condition, conditionDates) {

    function replaceName(target){
        if(target.indexOf("%") > -1){
            target = target.replace("%", "");
        }
        if(target.indexOf("Times") > -1){
            target = target.replace("Times", "");
        }
        if(target.indexOf("Years Old") > -1){
            target = target.replace("Years Old", "");
        }
        if(target.indexOf("Hours") > -1){
            target = target.replace("Hours", "");

            // 1시간에 3600000 밀리sec
            if(target.indexOf("-") > -1){
                target = target.split("-")
                target = [parseInt(target[0]) * 3600000, parseInt(target[1]) * 3600000]
                return target
            } else {
                target = parseInt(target) * 3600000
                return target
            }
        }
        if(target.indexOf("-") > -1){
            target = target.split("-")
            target = [parseInt(target[0]), parseInt(target[1])]
            return target
        } else {
            return parseInt(target)
        }
    }

    function combinationName(compareName, keyName){
        var result = ""
        var key = replaceName(keyName)
        if(compareName === "is"){
            result = { "$gte" : key[0], "$lte": key[1] }
        } else if (compareName === "at least") {
            result = { "$gte" : key }
            
            //revisit 예외처리
            if(keyName === "0 Hours" || keyName === "0Hours"){
                result = {"$gte": 3600000}
            }
        } else if (compareName === "at most"){
            result = { "$lte" : key }

            //revisit 예외처리
            if(keyName.indexOf("Hours") > -1 ) {
                result = { "$gte": 3600000, "$lte": key }
            }
        }
        return result
    }
    var conditionSubTransform = {
        "Scroll depth": "scrollDepth",
        "Time of revisit": "stayTime",
        // "Number of revisit": "revisit", - 추후 special case로 구분...
    }
    var conditionMainTransform = {
        "Device category": "device",
    }
    var conditionMainArr = []
    var conditionSubArr = []
    for(var i = 0; i < condition.length; i++){
        var target = condition[i].subMenus[0]
        //(main) main과 sub db 둘다 접근해야 하는 필드가 있을 수 있으므로(date) main-sub 분리시켜놓음

        var mainFieldName, subFieldName

        if(target !== undefined){
            var mainLastQuery = []
            var subLastQuery = []
            if(conditionMainTransform[target.parentName]){ 
                //1차뎁스에서 메인db의 필드이름이 바로 추출되는 경우
                mainFieldName = conditionMainTransform[target.parentName]
                if(target.compareName){//ex)  ScreenSize -> is -> (1000 x 600) - (2000 x 1200) px
                    if(condition[i].subMenus.length > 1){ // +or
                        for(var j = 0; j < condition[i].subMenus.length; j ++){
                            mainLastQuery.push(combinationName(condition[i].subMenus[j].compareName, condition[i].subMenus[j].name))
                        }
                    } else {
                        mainLastQuery = combinationName(target.compareName, target.name)
                    }
                } else if (!target.lastMenu){//ex)  Device -> PC
                    if(condition[i].subMenus.length > 1){// +or
                        for(j = 0; j < condition[i].subMenus.length; j++){
                            mainLastQuery.push(condition[i].subMenus[j].name)
                        }
                    } else {
                        mainLastQuery = target.name
                    }
                } 
                conditionMainArr.push({"$match": {}})
    
                if(condition[i].subMenus.length > 1){// +or
                    conditionMainArr[conditionMainArr.length-1]["$match"] = { "$or" : [] }
                    for(j = 0; j < condition[i].subMenus.length; j++){
                        conditionMainArr[conditionMainArr.length-1]["$match"]["$or"][j] = {};
                        conditionMainArr[conditionMainArr.length-1]["$match"]["$or"][j][mainFieldName] = mainLastQuery[j]
                    }
                } else {
                    conditionMainArr[conditionMainArr.length-1]["$match"][mainFieldName] = mainLastQuery
                }
    
            } else if (conditionMainTransform[target.name]){ 
                //2차뎁스에서 메인db의 필드이름이 추출되는 경우
                mainFieldName = conditionMainTransform[target.name]
                if(target.lastMenu.compareName){//ex)  ??생각이 안난다..
                    if(condition[i].subMenus.length > 1){
                        for(j = 0; j < condition[i].subMenus.length; j++){
                            mainLastQuery.push(combinationName(condition[i].subMenus[j].lastMenu.compareName, condition[i].subMenus[j].lastMenu.name))
                        }
                    } else {
                        mainLastQuery = combinationName(target.lastMenu.compareName, target.lastMenu.name)
                    }
                } else {//ex)  Funnel -> Social -> YOUTUBE
                    if(condition[i].subMenus.length > 1){
                        for(j = 0; j < condition[i].subMenus.length; j++){
                            mainLastQuery.push(condition[i].subMenus[j].lastMenu.name)
                        }
                    } else {
                        mainLastQuery = target.lastMenu.name
                    }
                }
                conditionMainArr.push({"$match": {} })
                if(condition[i].subMenus.length > 1){
                    conditionMainArr[conditionMainArr.length-1]["$match"] = { "$or": []}
                    for(j = 0; j <condition[i].subMenus.length; j++){
                        conditionMainArr[conditionMainArr.length-1]["$match"]["$or"][j] = {};
                        conditionMainArr[conditionMainArr.length-1]["$match"]["$or"][j][mainFieldName] = mainLastQuery[j]
                    }
                } else {
                    conditionMainArr[conditionMainArr.length-1]["$match"][mainFieldName] = mainLastQuery
                }
            } 
            
            //(sub)
            if (conditionSubTransform[target.parentName]){
                //1차뎁스에서 서브db의 필드이름이 바로 추출되는 경우
                subFieldName = conditionSubTransform[target.parentName]
                if(target.compareName){//ex) scroll depth -> is -> 10-30 %
                    if(condition[i].subMenus.length > 1){
                        for(j = 0; j < condition[i].subMenus.length; j++){
                            subLastQuery.push(combinationName(condition[i].subMenus[j].compareName, condition[i].subMenus[j].name))
                        }
                    } else {
                        subLastQuery = combinationName(target.compareName, target.name)
                    }
                } else if (!target.lastMenu){//ex) login or not -> true
                    if(condition[i].subMenus.length > 1){
                        for(j = 0; j < condition[i].subMenus.length; j++){
                            subLastQuery.push(condition[i].subMenus[j].name)
                        }
                    }else {
                        subLastQuery = target.name
                    }
                }
                conditionSubArr.push({"$match": {} })
                if(condition[i].subMenus.length > 1){
                    conditionSubArr[conditionSubArr.length-1]["$match"] = { "$or" : []}
                    for(j = 0; j < condition[i].subMenus.length; j++){
                        conditionSubArr[conditionSubArr.length-1]["$match"]["$or"][j] = {};
                        conditionSubArr[conditionSubArr.length-1]["$match"]["$or"][j][subFieldName] = subLastQuery[j]
                    }
                } else {
                    conditionSubArr[conditionSubArr.length-1]["$match"][subFieldName] = subLastQuery
                }
    
            } else if (conditionSubTransform[target.name]){
                //2차뎁스에서 서브db의 필드이름이 추출되는 경우
                subFieldName = conditionSubTransform[target.name]
                if(target.lastMenu.compareName){//ex) Revisit or not -> Time of revisit -> is -> 2-3 Hours
                    if(condition[i].subMenus.length > 1){
                        for(j =0; j < condition[i].subMenus.length; j++){
                            subLastQuery.push(combinationName(condition[i].subMenus[j].lastMenu.compareName, condition[i].subMenus[j].lastMenu.name))
                        }
                    } else {
                        subLastQuery = combinationName(target.lastMenu.compareName, target.lastMenu.name)
                    }
                } else {
                    if(condition[i].subMenus.length > 1){
                        for(j = 0; j < condition[i].subMenus.length; j++){
                            subLastQuery.push(condition[i].subMenus[j].lastMenu.name)
                        }
                    } else {
                        subLastQuery = target.lastMenu.name
                    }
                }
                conditionSubArr.push({"$match": {} })
                if(condition[i].subMenus.length > 1){
                    conditionSubArr[conditionSubArr.length-1]["$match"] = { "$or": []}
                    for(j =0; j < condition[i].subMenus.length; j++){
                        conditionSubArr[conditionSubArr.length-1]["$match"]["$or"][j] = {};
                        conditionSubArr[conditionSubArr.length-1]["$match"]["$or"][j][subFieldName] = subLastQuery[j]
                    }
                } else {
                    conditionSubArr[conditionSubArr.length-1]["$match"][subFieldName] = subLastQuery
                }
            }
        }
    }

    if(conditionMainArr.length > 0){
        conditionMainArr.push({ "$group": { "_id": "$key" }})
    }
    if(conditionSubArr.length > 0){
        conditionSubArr.push({ "$group": { "_id": "$key" }})
    }

    return (dispatch) => {
        dispatch(setCondition({conditionMainArr, conditionSubArr}))

        return axios.post('/getApi', { host, type, conditionMainArr, conditionSubArr, conditionDates })
        .then((response) => {
            if(response.data.realtime){
                dispatch(realtimeSuccess(response.data))
            } else if(response.data.success){
                dispatch(funnelSuccess(response.data));
            }
        }).catch((error) => {
            dispatch(funnelFailure(error));
        });
    };
}

export function conditionInitialization() {
    var emptyArr1 = [];
    var emptyArr2 = [];
    return (dispatch) => {
        dispatch(setCondition({emptyArr1, emptyArr2}))
    }
}

export function setCondition({conditionMainArr, conditionSubArr}) {
    return {
        type: "PASS_TO_STORE",
        condition_main_arr: conditionMainArr,
        condition_sub_arr: conditionSubArr,
    };
}

export function setDates(dates) {
    return (dispatch) => {
        dispatch(setConditionDates(dates))
    };
}

export function setConditionDates(dates) {
    return {
        type: "SET_CONDITION_DATES",
        condition_dates: dates,
    };
}

export function setLoading(bool){
    return (dispatch) => {
        dispatch(setLoadingData(bool))
    }
}

export function setLoadingData(bool){
    return {
        type: "SET_LOADING",
        bool: bool,
    }
}

export function storeInterval(intervalId){
    return (dispatch) => {
        dispatch(storeIntervalId(intervalId))
    }
}

export function storeIntervalId(intervalId){
    return {
        type: "SET_INTERVALID",
        intervalId: intervalId
    }
}