import React, {Component} from "react"
import MoonLoader from "react-spinners/MoonLoader"
import "./Loading.css"
// import { css } from "@emotion/core";


// const override = css`
//   display: block;
//   margin: 0 auto;
//   border-color: #4A90E2;
// `;

class Loading extends Component{
    // constructor(props){
    //     super(props)
    // }

    componentDidMount(){
        window.addEventListener('scroll', (e) => {
            if(document.getElementsByClassName("sweet-loading")[0]){
                var loadingDiv = document.getElementsByClassName("sweet-loading")[0]
                loadingDiv.style.top = `${window.scrollY}px`
            }
        })
    }

    componentDidUpdate(){
        var loadingDiv
        if(this.props.loading){
            loadingDiv = document.getElementsByClassName("sweet-loading")[0]
            // console.log("settime - 1")
            loadingDiv.style.top = `${window.scrollY}px`
            loadingDiv.style.left = `0`
            loadingDiv.style.width = `${document.body.clientWidth - 250}px`
            loadingDiv.style.height = `${window.innerHeight}px`;
            loadingDiv.style["z-index"] = 100;
        } else {
            loadingDiv = document.getElementsByClassName("sweet-loading")[0]
            loadingDiv.style["z-index"] = 0;
            loadingDiv.style.top = `0`
            loadingDiv.style.width = `0`
            loadingDiv.style.height = `0`;
        }
    }

    render(){
        return (
            <div className="sweet-loading">
                <MoonLoader
                    // css={override}
                    size={150}
                    height={8}
                    width={150}
                    color={"rgb(0,0,112)"}
                    loading={this.props.loading}
                />
            </div>
        )
    }
}

export default Loading;