var userOfTime = [
    {group: "일", variable: "11오후", value:"0"},
    {group: "일", variable: "10오후", value:"5"},
    {group: "일", variable: "9오후", value:"2"},
    {group: "일", variable: "8오후", value:"5"},
    {group: "일", variable: "7오후", value:"3"},
    {group: "일", variable: "6오후", value:"5"},
    {group: "일", variable: "5오후", value:"5"},
    {group: "일", variable: "4오후", value:"5"},
    {group: "일", variable: "3오후", value:"5"},
    {group: "일", variable: "2오후", value:"5"},
    {group: "일", variable: "1오후", value:"5"},
    {group: "일", variable: "12오후", value:"5"},
    {group: "일", variable: "11오전", value:"5"},
    {group: "일", variable: "10오전", value:"5"},
    {group: "일", variable: "9오전", value:"5"},
    {group: "일", variable: "8오전", value:"5"},
    {group: "일", variable: "7오전", value:"5"},
    {group: "일", variable: "6오전", value:"5"},
    {group: "일", variable: "5오전", value:"5"},
    {group: "일", variable: "4오전", value:"5"},
    {group: "일", variable: "3오전", value:"5"},
    {group: "일", variable: "2오전", value:"5"},
    {group: "일", variable: "1오전", value:"5"},
    {group: "일", variable: "12오전", value:"5"},
    {group: "월", variable: "11오후", value:"5"},
    {group: "월", variable: "10오후", value:"5"},
    {group: "월", variable: "9오후", value:"5"},
    {group: "월", variable: "8오후", value:"5"},
    {group: "월", variable: "7오후", value:"5"},
    {group: "월", variable: "6오후", value:"5"},
    {group: "월", variable: "5오후", value:"5"},
    {group: "월", variable: "4오후", value:"5"},
    {group: "월", variable: "3오후", value:"5"},
    {group: "월", variable: "2오후", value:"5"},
    {group: "월", variable: "1오후", value:"5"},
    {group: "월", variable: "12오후", value:"5"},
    {group: "월", variable: "11오전", value:"5"},
    {group: "월", variable: "10오전", value:"5"},
    {group: "월", variable: "9오전", value:"5"},
    {group: "월", variable: "8오전", value:"5"},
    {group: "월", variable: "7오전", value:"5"},
    {group: "월", variable: "6오전", value:"5"},
    {group: "월", variable: "5오전", value:"5"},
    {group: "월", variable: "4오전", value:"5"},
    {group: "월", variable: "3오전", value:"5"},
    {group: "월", variable: "2오전", value:"5"},
    {group: "월", variable: "1오전", value:"5"},
    {group: "월", variable: "12오전", value:"5"},
    {group: "화", variable: "11오후", value:"5"},
    {group: "화", variable: "10오후", value:"5"},
    {group: "화", variable: "9오후", value:"5"},
    {group: "화", variable: "8오후", value:"5"},
    {group: "화", variable: "7오후", value:"5"},
    {group: "화", variable: "6오후", value:"5"},
    {group: "화", variable: "5오후", value:"5"},
    {group: "화", variable: "4오후", value:"5"},
    {group: "화", variable: "3오후", value:"5"},
    {group: "화", variable: "2오후", value:"5"},
    {group: "화", variable: "1오후", value:"5"},
    {group: "화", variable: "12오후", value:"5"},
    {group: "화", variable: "11오전", value:"5"},
    {group: "화", variable: "10오전", value:"5"},
    {group: "화", variable: "9오전", value:"5"},
    {group: "화", variable: "8오전", value:"5"},
    {group: "화", variable: "7오전", value:"5"},
    {group: "화", variable: "6오전", value:"5"},
    {group: "화", variable: "5오전", value:"5"},
    {group: "화", variable: "4오전", value:"5"},
    {group: "화", variable: "3오전", value:"5"},
    {group: "화", variable: "2오전", value:"5"},
    {group: "화", variable: "1오전", value:"5"},
    {group: "화", variable: "12오전", value:"5"},
    {group: "수", variable: "11오후", value:"5"},
    {group: "수", variable: "10오후", value:"5"},
    {group: "수", variable: "9오후", value:"5"},
    {group: "수", variable: "8오후", value:"5"},
    {group: "수", variable: "7오후", value:"5"},
    {group: "수", variable: "6오후", value:"5"},
    {group: "수", variable: "5오후", value:"5"},
    {group: "수", variable: "4오후", value:"5"},
    {group: "수", variable: "3오후", value:"5"},
    {group: "수", variable: "2오후", value:"5"},
    {group: "수", variable: "1오후", value:"5"},
    {group: "수", variable: "12오후", value:"5"},
    {group: "수", variable: "11오전", value:"5"},
    {group: "수", variable: "10오전", value:"5"},
    {group: "수", variable: "9오전", value:"5"},
    {group: "수", variable: "8오전", value:"5"},
    {group: "수", variable: "7오전", value:"5"},
    {group: "수", variable: "6오전", value:"5"},
    {group: "수", variable: "5오전", value:"5"},
    {group: "수", variable: "4오전", value:"5"},
    {group: "수", variable: "3오전", value:"5"},
    {group: "수", variable: "2오전", value:"5"},
    {group: "수", variable: "1오전", value:"5"},
    {group: "수", variable: "12오전", value:"5"},
    {group: "목", variable: "11오후", value:"5"},
    {group: "목", variable: "10오후", value:"5"},
    {group: "목", variable: "9오후", value:"5"},
    {group: "목", variable: "8오후", value:"5"},
    {group: "목", variable: "7오후", value:"5"},
    {group: "목", variable: "6오후", value:"5"},
    {group: "목", variable: "5오후", value:"5"},
    {group: "목", variable: "4오후", value:"5"},
    {group: "목", variable: "3오후", value:"5"},
    {group: "목", variable: "2오후", value:"5"},
    {group: "목", variable: "1오후", value:"5"},
    {group: "목", variable: "12오후", value:"5"},
    {group: "목", variable: "11오전", value:"5"},
    {group: "목", variable: "10오전", value:"5"},
    {group: "목", variable: "9오전", value:"5"},
    {group: "목", variable: "8오전", value:"5"},
    {group: "목", variable: "7오전", value:"5"},
    {group: "목", variable: "6오전", value:"5"},
    {group: "목", variable: "5오전", value:"5"},
    {group: "목", variable: "4오전", value:"5"},
    {group: "목", variable: "3오전", value:"5"},
    {group: "목", variable: "2오전", value:"5"},
    {group: "목", variable: "1오전", value:"5"},
    {group: "목", variable: "12오전", value:"5"},
    {group: "금", variable: "11오후", value:"5"},
    {group: "금", variable: "10오후", value:"5"},
    {group: "금", variable: "9오후", value:"5"},
    {group: "금", variable: "8오후", value:"5"},
    {group: "금", variable: "7오후", value:"5"},
    {group: "금", variable: "6오후", value:"5"},
    {group: "금", variable: "5오후", value:"5"},
    {group: "금", variable: "4오후", value:"5"},
    {group: "금", variable: "3오후", value:"5"},
    {group: "금", variable: "2오후", value:"5"},
    {group: "금", variable: "1오후", value:"5"},
    {group: "금", variable: "12오후", value:"5"},
    {group: "금", variable: "11오전", value:"5"},
    {group: "금", variable: "10오전", value:"5"},
    {group: "금", variable: "9오전", value:"5"},
    {group: "금", variable: "8오전", value:"5"},
    {group: "금", variable: "7오전", value:"5"},
    {group: "금", variable: "6오전", value:"5"},
    {group: "금", variable: "5오전", value:"5"},
    {group: "금", variable: "4오전", value:"5"},
    {group: "금", variable: "3오전", value:"5"},
    {group: "금", variable: "2오전", value:"5"},
    {group: "금", variable: "1오전", value:"5"},
    {group: "금", variable: "12오전", value:"5"},
    {group: "토", variable: "11오후", value:"5"},
    {group: "토", variable: "10오후", value:"5"},
    {group: "토", variable: "9오후", value:"5"},
    {group: "토", variable: "8오후", value:"5"},
    {group: "토", variable: "7오후", value:"5"},
    {group: "토", variable: "6오후", value:"5"},
    {group: "토", variable: "5오후", value:"5"},
    {group: "토", variable: "4오후", value:"5"},
    {group: "토", variable: "3오후", value:"5"},
    {group: "토", variable: "2오후", value:"5"},
    {group: "토", variable: "1오후", value:"5"},
    {group: "토", variable: "12오후", value:"5"},
    {group: "토", variable: "11오전", value:"5"},
    {group: "토", variable: "10오전", value:"5"},
    {group: "토", variable: "9오전", value:"5"},
    {group: "토", variable: "8오전", value:"5"},
    {group: "토", variable: "7오전", value:"5"},
    {group: "토", variable: "6오전", value:"5"},
    {group: "토", variable: "5오전", value:"5"},
    {group: "토", variable: "4오전", value:"5"},
    {group: "토", variable: "3오전", value:"5"},
    {group: "토", variable: "2오전", value:"5"},
    {group: "토", variable: "1오전", value:"5"},
    {group: "토", variable: "12오전", value:"5"},
]

export {userOfTime}