import axios from "../../api"

export function getLugstayCustom(dates, status){
    return (dispatch) => {
        return axios.post('/customPage/getLugstayCustom', { dates, status })
        .then((response) => {
            if(response.data.success){
                dispatch(successRecResults(response.data.results));
                return response.data.results
            }
        })
    };
}

export function successRecResults(results){
    return {
        type: "LUGSTAY_SUCCESS",
        results
    }
}

export function getLugstaySegment(email){
    return (dispatch) => {
        return axios.post('/customPage/getLugstaySegment', {email})
        .then((response) => {
            if(response.data.success){
                dispatch(segmentGetSuccess(response.data.results.segment, ""));
                return response.data.results.filter
            }
        })
    };
}

export function setLugstaySegment(email, segment, name, filter){
    return (dispatch) => {
        console.log("come")
        return axios.post('/customPage/setLugstaySegment', {email, segment, name, filter})
        .then((response) => {
            if(response.data.success){
                console.log("success set segment")
                dispatch(segmentGetSuccess(response.data.results.segment, response.data.results.name));
                return response.data.success
            }
        })
    };
}

export function segmentGetSuccess(results, name){
    return {
        type: "GET_SEGMENT_SUCCESS",
        results,
        name
    }
}

export function getLugstayTotalFunnel(){
    return (dispatch) => {
        return axios.get('/customPage/getLugstayTotalFunnel')
        .then((response) => {
            if(response.data.success){
                return response.data.results
            }
        })
    };
}

export function setLugstayFilter(email, filter){
    return (dispatch) => {
        return axios.post('/customPage/setLugstayFilter', {email, filter})
        .then((response) => {
            if(response.data.success){
                return true
            } else {
                return false
            }
        })
    };
}

export function filterSegment(filter){
    return (dispatch) => {
        dispatch(settingFilter(filter));
    };
}

export function settingFilter(filter){
    return {
        type: "SET_SEGMENT_FILTER",
        filter
    }
}