import { lineChartData, realTimeLineData, flowOfUserData } from "../../datas/chartjs/line"
import { stackedBar } from "../../datas/chartjs/bar"
import { deviceDoughnut } from "../../datas/chartjs/doughnut"
import { userOfTime } from "../../datas/d3/userOfTime"
import { continuousUser } from "../../datas/d3/continuousUser"

const initialState = {
    line: lineChartData,
    bar: stackedBar,
    realTimeLine: realTimeLineData,
    deviceDoughnut: deviceDoughnut,
    flowOfUser: flowOfUserData,
    userData: {
        userCount: 0,
        sessionCount: 0,
        numberOfRun: 0,
        rateOfRun: 0,
        sessionTime: "",
        realTimeUser: 0,
        realTimeUserPerPage: [{
            "name": "/",
            "count": 0
        }],
        pageView: [], // page limit 
        page_view: [{name: "first", count: 1}], // page all
        conditionMainArr: [],
        conditionSubArr: [],
        conditionDates: []
    },
    d3: {
        userOfTime: userOfTime,
        continuousUser: continuousUser,
        myGroups: ["0번째 일", "1번째 일", "2번째 일", "3번째 일", "4번째 일", "5번째 일", "6번째 일"],
        myVars: ["5월26일", "5월25일", "5월24일", "5월23일", "5월22일", "5월21일", "5월20일", "모든 사용자"]
    },
    loading: false,
    intervalId: [],
    sankey: {
        nodes:
        [
            {"name":"node0"},
            {"name":"node1"},
            {"name":"node2"},
            {"name":"node3"},
            {"name":"node4"},
            {"name":"node1-5"},
            {"name":"node1-6"},
            {"name":"node1-7"},
            {"name":"node1-8"},
            {"name":"node1-9"},
            {"name":"node3-1"},
            // {"id":4,"name":"node4-1"},
        ],
        links:
        [
            {"source":0,"target":2,"value":300},
            {"source":1,"target":2,"value":500},
            {"source":2,"target":3,"value":400},
            {"source":3,"target":4,"value":200},
            {"source":5,"target":2,"value":200},
            {"source":6,"target":2,"value":200},
            {"source":7,"target":2,"value":200},
            {"source":8,"target":2,"value":200},
            {"source":9,"target":2,"value":200},
            {"source":2,"target":10,"value":400},
            // {"source":10,"target":11,"value":3},
        ],
    }
}

export default function datachange(state = initialState, action) {
    switch(action.type){
        case "API_FUNNEL_SUCCESS":
            const {
                funnel,
                user_count,
                session_count,
                number_of_run,
                session_time,
                // real_time_user,
                // real_time_page,
                // real_time_userPerPage,
                page_view, device_count, 
                flow_of_user, 
                flow_of_user_date,
                page_moving_nodes,
                page_moving_links,
            } = action.results
            if(funnel){
                var newBar = Object.assign({}, state.bar)
                newBar.data.datasets = funnel.newDatasets
                newBar.data.labels = funnel.newDates
                newBar.options.scales.xAxes[0].scaleLabel.labelString = funnel.newTotalDate
            }
            // if(real_time_page){
            //     var newRealTimeLine1 = Object.assign({}, state.realTimeLine)
            //     newRealTimeLine1.data.datasets[0].data = real_time_page;
            // }
            // if(real_time_userPerPage){
            //     console.log("here is r t u p p @@@")
            //     real_time_userPerPage.forEach(page => page.count = page.count.toLocaleString())
            // }
            if(device_count){
                var newDeviceDoughnut = Object.assign({}, state.deviceDoughnut)
                newDeviceDoughnut.data.datasets[0].data = device_count;
            }
            // var rate_of_run = 0;
            // if(number_of_run !== undefined){
            //         rate_of_run = (number_of_run / parseInt(state.userData.sessionCount.replace(/,/g,""))) * 100;
            //         if(rate_of_run - Math.floor(rate_of_run) !== 0){
            //             rate_of_run = rate_of_run.toFixed(2)
            //         }
            // }
            // if(session_count !== undefined){
            //     rate_of_run = (state.userData.numberOfRun / session_count) * 100;
            //     if(rate_of_run - Math.floor(rate_of_run) !== 0){
            //         rate_of_run = rate_of_run.toFixed(2)
            //     }
            // }
            if(page_view){
                var pageView = page_view.slice(0,20)
                pageView.forEach(page => {
                    page.name = decodeURI(page.name);
                    // page.count = page.count.toLocaleString();
                })
                console.log("page_view", page_view)
                console.log("view detail test", action.results.page_view_sub_detail)
            }

            if(flow_of_user){
                var newFlowOfUser = Object.assign({}, state.flowOfUser)
                var dateOfFunnel = flow_of_user_date;
                // console.log("flow@@@ ",flow_of_user)
                for(var i = 0; i < flow_of_user.length; i++){
                    newFlowOfUser.data.datasets[0].data[flow_of_user.length - i - 1] = flow_of_user[i].day;
                    newFlowOfUser.data.datasets[1].data[flow_of_user.length - i - 1] = flow_of_user[i].week;
                    newFlowOfUser.data.datasets[2].data[flow_of_user.length - i - 1] = flow_of_user[i].month;
                    dateOfFunnel[i] = {
                        month: new Date(dateOfFunnel[i]).getMonth() + 1,
                        day: new Date(dateOfFunnel[i]).getDate()
                    }
                    newFlowOfUser.data.labels[flow_of_user.length - i - 1] = dateOfFunnel[i].day;
                }
                newFlowOfUser.options.scales.xAxes[0].scaleLabel.labelString = 
                `${dateOfFunnel[flow_of_user.length-1].month}월${dateOfFunnel[flow_of_user.length-1].day}일 ~ ${dateOfFunnel[0].month}월${dateOfFunnel[0].day}일`
            }

            if(page_moving_links){
                console.log("nodes@@", action.results.page_moving_nodes)
                console.log("ilnks@@@", action.results.page_moving_links)
            }

            return {
                ...state,
                bar: newBar ? newBar : state.bar,
                userData : {
                    ...state.userData,
                    userCount: user_count !== undefined ? user_count.toLocaleString() : state.userData.userCount,
                    sessionCount: session_count !== undefined ? session_count : state.userData.sessionCount,
                    numberOfRun: number_of_run !== undefined ? number_of_run : state.userData.numberOfRun,
                    // rateOfRun: rate_of_run ? isNaN(rate_of_run) ? 0 : rate_of_run : state.userData.rateOfRun,
                    sessionTime: session_time ? session_time : state.userData.sessionTime,
                    // realTimeUser: real_time_user ? real_time_user.toLocaleString() : state.userData.realTimeUser,
                    // realTimeUserPerPage: real_time_userPerPage ? real_time_userPerPage : state.userData.realTimeUserPerPage,
                    pageView: page_view ? pageView : state.userData.pageView,
                    page_view: page_view ? page_view : ""
                },
                sankey: {
                    nodes: page_moving_nodes ? page_moving_nodes : state.sankey.nodes,
                    links: page_moving_links ? page_moving_links : state.sankey.links,
                }
            }
        case "API_FUNNEL_FAILURE":
            return {
                ...state
            }
        case "REAL_TIME_SUCCESS":
            var newRealTimeLine2 = Object.assign({}, state.realTimeLine)
            newRealTimeLine2.data.datasets[0].data = action.real_time_page;
            if(action.real_time_userPerPage){
                action.real_time_userPerPage.forEach(page => page.count = page.count.toLocaleString())
            }
            return {
                ...state,
                userData: {
                    ...state.userData,
                    realTimeUser: action.real_time_user.toLocaleString(),
                    realTimeUserPerPage: action.real_time_userPerPage,
                }
            }
        case "USER_OF_TIME_SUCCESS":
            if(action.user_of_time !== null){
                var newUserOfTime = state.d3.userOfTime.slice();
                for(var q = 0; q < action.user_of_time.length; q++){
                    var e = 23;
                    for(var w = 0 + (q*24); w < (q*24 + 24); w++){
                        newUserOfTime[w].value = action.user_of_time[q][e][0] ? action.user_of_time[q][e].length : 0;
                        e--;
                    }
                }
            }

            if(action.continuous_user !== null){
                var allCount = [0, 0, 0, 0, 0, 0, 0] // allcount: 모든 사용자 구하는 용도
                var newContinuousUser = state.d3.continuousUser.slice();
                for(var z = 0; z < 7; z++){
                    for(var x = 0; x < action.continuous_user[z].length; x++){
                        allCount[x] += action.continuous_user[z][x];
                        if(x===0){// 0번째 일에는 전부 100%이므로 value에 100을 넣어준다.
                            newContinuousUser[z + x*8].value = 100;
                            newContinuousUser[z + x*8].count = action.continuous_user[z][x];
                        } else {
                            if(action.continuous_user[z][0] === 0){
                                newContinuousUser[z + x*8].value = 0;
                                newContinuousUser[z + x*8].count = action.continuous_user[z][x];
                            } else {
                                newContinuousUser[z + x*8].value = Math.round((action.continuous_user[z][x] / action.continuous_user[z][0])*1000)/10;
                                newContinuousUser[z + x*8].count = action.continuous_user[z][x];
                            }
                        }
                    }
                }

                //모든 사용자 비교 값 대입
                var count = allCount[0]
                for(var idx = 0; idx < 6; idx++){
                    count = count - newContinuousUser[idx].count
                    if(allCount[idx+1] === 0){
                        newContinuousUser[(idx+2)*7 + (idx+1)].value = 0;
                    } else {
                        newContinuousUser[(idx+2)*7 + (idx+1)].value = Math.round((allCount[idx+1] / count)*1000)/10;
                    }
                }

                //날짜 입력
                var date = action.continuous_user_date;
                for(var index = 0; index < date.length; index++){
                    date[index] = {
                        month: new Date(date[index]).getMonth() + 1,
                        day: new Date(date[index]).getDate()
                    }
                }
                var newMyVars = state.d3.myVars;
                for(var index2 = 0; index2 < 7; index2++){
                    newMyVars[index2] = `${date[index2].month}월${date[index2].day}일`;
                    for(var index3 = 0; index3 < 7; index3++){
                        newContinuousUser[index2 + (index3*8)].variable = `${date[index2].month}월${date[index2].day}일`
                    }
                }

            }

            return{
                ...state,
                d3:{
                    ...state.d3,
                    userOfTime: newUserOfTime ? newUserOfTime : state.d3.userOfTime,
                    continuousUser: newContinuousUser ? newContinuousUser : state.d3.continuousUser,
                    myVars: newMyVars ? newMyVars : state.d3.myVars
                }
            }
        case "USER_OF_TIME_FAILURE":
            return{
                ...state
            }

        case "PASS_TO_STORE":
            return {
                ...state,
                userData: {
                    ...state.userData,
                    conditionMainArr: action.condition_main_arr,
                    conditionSubArr: action.condition_sub_arr
                }
            }

        case "SET_CONDITION_DATES":
            return{
                ...state,
                userData: {
                    ...state.userData,
                    conditionDates: action.condition_dates
                }
            }
        case "SET_LOADING":
            return {
                ...state,
                loading: action.bool
            }
        case "SET_INTERVALID":
            var intervalId = state.intervalId
            if(intervalId.length > 0){
                for(var inteiIdx = 0; inteiIdx < intervalId.length; inteiIdx++){
                    clearInterval(intervalId[inteiIdx])
                }
                intervalId = [action.intervalId]
            } else {
                intervalId.push(action.intervalId)
            }
            return {
                ...state,
                intervalId: intervalId
            }
        default:
            return state;
    }
}