const initialState = {
    login: {
        status: 'INIT',
        error: -1
    },
    register: {
        status: 'INIT',
        error: -1
    },
    status: {
        valid: false,
        isLoggedIn: false,
        currentUser: '',
        userEmail: '',
        platformType: '',
        userHosts: [],
        selectedHost : "",
        userAuth: null,
        customPage: ""
    },
    page: {
      pageName: 'home'
    },
    cafe24: {
      mall_id: "",
      client_id: "",
      client_secret: "",
      access_token: "",
      expires_at: "",
      refresh_token: "",
      refresh_token_expires_at: "",
      urlName: "",
      pathInclude: "",
      categories: "",
      boards: "",
    },
    godomall: {
      urlName: "",
      partner_key: "",
      key: "",
      categories: "",
      boards: "",
    },
    local: {
      urlName: "",
      pathInclude: "",
      searchParam: ""
    }
};
 
export default function authentication(state = initialState, action) {
  switch(action.type) {
    case "AUTH_REGISTER_SUCCESS":
      return {
        ...state,
        register: {
          ...state.register,
          status: 'SUCCESS'
        }
      }
    case "AUTH_REGISTER_FAILURE":
      return {
        ...state,
        register:{
          status: 'FAILURE',
          error: action.error
        }
      }
    case "AUTH_LOGIN_SUCCESS":
      return {
        ...state,
        login: {
            status: 'SUCCESS'
        },
        status: {
          ...state.status,
          isLoggedIn: true,
          currentUser: action.username,
          userEmail: action.useremail,
          platformType: action.platform_type,
          userAuth: action.userauth,
          userHosts: action.url,
          selectedHost: action.url[0],
          customPage: action.customPage
        },
        cafe24: {
          ...state.cafe24,
          mall_id: action.mall_id,
          client_id: action.client_id,
          client_secret: action.client_secret,
          access_token: action.access_token,
          expires_at: action.expires_at,
          refresh_token: action.refresh_token,
          refresh_token_expires_at: action.refresh_token_expires_at,
          urlName: action.urlName,
          pathInclude: action.pathInclude
        },
        godomall: {
          ...state.godomall,
          urlName: action.urlName,
          partner_key: action.partner_key,
          key: action.key
        },
        local: {
          ...state.local,
          urlName: action.urlName,
          pathInclude: action.pathInclude,
          searchParam: action.searchParam
        }
      }
    case "AUTH_LOGIN_FAILURE":
        return {
          ...state,
          login:{
            status: 'FAILURE',
            error: action.error
          },
          status: {
            valid: false,
            isLoggedIn: false,
            currentUser: '',
            userEmail: '',
            platformType: '',
            userHosts: [],
            selectedHost : "",
            userAuth: null,
            customPage: ""
         },
          cafe24: {
            ...state.cafe24,
            mall_id: "",
            client_id: "",
            client_secret: "",
            access_token: "",
            expires_at: "",
            refresh_token: "",
            refresh_token_expires_at: "",
            urlName: "",
            pathInclude: "",
            categories: "",
            boards: "",
          },
          godomall: {
            urlName: "",
            partner_key: "",
            key: "",
            categories: "",
            boards: "",
          },
          local: {
            urlName: "",
            pathInclude: "",
            searchParam: ""
          }
        }
    case 'LOGOUT_USER':
      return {
        ...state,
        login: {
          status: 'FAILURE'
        },
        status: {
           // valid: false,
          isLoggedIn: false,
          currentUser: '',
          userEmail: '',
          platformType: '',
          userAuth: 'member',
          customPage: ""
        },
        cafe24: {
          ...state.cafe24,
          mall_id: "",
          client_id: "",
          client_secret: "",
          access_token: "",
          expires_at: "",
          refresh_token: "",
          refresh_token_expires_at: "",
          urlName: "",
          pathInclude: "",
          categories: "",
          boards: "",
        },
        godomall: {
          urlName: "",
          partner_key: "",
          key: "",
          categories: "",
          boards: "",
        },
        local: {
          urlName: "",
          pathInclude: "",
          searchParam: ""
        }
      }
    case "PAGE_CHANGE_SUCCESS":
      // console.log("@@reducer pagename : ", action.pageName)
      return {
        ...state,
        page: {
          ...state.page,
          pageName: action.pageName
        }
      }
    case "CAFE24_REFRESH_SUCCESS":
      return {
        ...state,
        cafe24: {
          ...state.cafe24,
          access_token: action.response.access_token,
          expires_at: action.response.expires_at,
          refresh_token: action.response.refresh_token,
          refresh_token_expires_at: action.response.refresh_token_expires_at
        }
      }
    case "GET_CAFE24_PRODUCTS":
      if(action.results){
        console.log("GET_CAFE24_PRODUCTS", action.results)
      }
      return {
          ...state
      }
    case "GET_CAFE24_CATEGORY":
      return {
        ...state,
        cafe24 : {
          ...state.cafe24,
          categories: action.results
        }
      }
    case "GET_CAFE24_BOARD":
      return{
        ...state,
        cafe24: {
          ...state.cafe24,
          boards: action.results.boards
        }
      }
    case "GET_GODOMALL_PRODUCTS":
      return{
        ...state,
      }
    case "GET_GODOMALL_CATEGORIES":
      return {
        ...state,
        godomall: {
          ...state.godomall,
          categories: action.results
        }
      }
    case "GET_GODOMALL_BOARDS":
      return {
        ...state,
        godomall: {
          ...state.godomall,
          boards: action.results
        }
      }
    case "GET_GODOMALL_PRODUCTALL":
      return{
        ...state
      }
    case "EDIT_USER_INFO":
      return {
        ...state,
        status: {
          ...state.status,
          currentUser: action.results,
      }
    }
    default:
      return state;
  }
}