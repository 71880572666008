import axios from "../../api"

/* REGISTER */
export function registerRequest(platformType, userName, password, userEmail, url) {
    return (dispatch) => {
        // Inform Register API is starting
        // dispatch(register());
 
        return axios.post('/auth/signup', { platformType, userName, password, userEmail, url })
        .then((response) => {
            if(response.data.success){
                dispatch(registerSuccess());
            }
        }).catch((error) => {
            dispatch(registerFailure(error.response.data.code));
        });
    };
}
 
export function registerSuccess() {
    return {
        type: "AUTH_REGISTER_SUCCESS",
    };
}
 
export function registerFailure(error) {
    return {
        type: "AUTH_REGISTER_FAILURE",
        error
    };
}

export function loginRequest(userEmail, password) {
    return (dispatch) => {
        // API REQUEST
        return axios.post('/auth/signin', { userEmail, password })
        .then((response) => {
            // SUCCEED
            localStorage.setItem("token", response.data.token)
            dispatch(loginSuccess(response.data));
        }).catch((error) => {
            // FAILED
            dispatch(loginFailure(error.response.data.code));
        });
    };
  }

  
export function loginSuccess(response) {
    return {
        type: "AUTH_LOGIN_SUCCESS",
        username: response.userName,
        useremail: response.userEmail,
        platform_type: response.platformType,
        userauth: response.userAuth,
        url: response.url,
        mall_id: response.mall_id,
        client_id: response.client_id,
        client_secret: response.client_secret,
        access_token: response.access_token,
        expires_at: response.expires_at,
        refresh_token: response.refresh_token,
        refresh_token_expires_at: response.refresh_token_expires_at,
        urlName: response.urlName,
        pathInclude: response.pathInclude,
        partner_key: response.partner_key,
        key: response.key,
        searchParam: response.searchParam,
        customPage: response.customPage,
    };
}

export function loginFailure(error) {
    return {
        type: "AUTH_LOGIN_FAILURE",
        error
    };
}

export function getProfileFetch() {
    return (dispatch) => {
        const token = localStorage.token;
        if (token) {
            return axios.post('/auth/verifyJwt', {token})
            .then((response) => {
                localStorage.setItem("token", response.data.token)
                dispatch(loginSuccess(response.data))
                return response.data
            }).catch ((error) => {
                alert('세션이 만료되었습니다.')
                localStorage.removeItem("token")
                dispatch(loginFailure())
                document.location.href = "/"
            })
        } else {
            console.log('Token Expired')
            dispatch(loginFailure())
        }
    }
}

export const logoutUser = () => ({
    type: 'LOGOUT_USER'
})

export const socialSignUp = (userData) => {
    return (dispatch) => {
        axios.post('/auth/socialSignUp', {userData})
        .then((response) => {
            localStorage.setItem("token", response.data.token)
            dispatch(loginSuccess(response.data))
        }).catch ((error) => {
            alert('social login err', error)
            dispatch(loginFailure())
        })
    }
}

// USER NAV PAGE
export function changePage(pageName) {
    return (dispatch) => {
        dispatch(changeSuccess(pageName))
    }
}
export function changeSuccess(pageName) {
    return{
        type: "PAGE_CHANGE_SUCCESS",
        pageName
    }
}

export function refreshCafe24Token(cafe24, userEmail) {
    return (dispatch) => {
        axios.post('/auth/getCafe24Code', {cafe24, userEmail})
        .then((response) => {
            dispatch(getCafe24RefreshSuccess(response.data))
        })
    }
}
export function getCafe24RefreshSuccess(response) {
    return{
        type: "CAFE24_REFRESH_SUCCESS",
        response
    }
}

export function getCafe24Products(cafe24, productsNumber){
    return (dispatch) => {
        return axios.post('/auth/getCafe24Products', { cafe24, productsNumber })
        .then((response) => {
            if(response.data.success){
                dispatch(successCafe24Products(response.data.results));
                return response.data.results
            }
        })
    };
}

export function successCafe24Products(results){
    return {
        type: "GET_CAFE24_PRODUCTS",
        results
    }
}

export function getCafe24Category(cafe24){
    return (dispatch) => {
        return axios.post('/auth/getCafe24Category', { cafe24 })
        .then((response) => {
            if(response.data.success){
                dispatch(successCafe24Category(response.data.results));
            }
        })
    };
}

export function successCafe24Category(results){
    return {
        type: "GET_CAFE24_CATEGORY",
        results
    }
}

export function getCafe24Board(cafe24){
    return (dispatch) => {
        return axios.post('/auth/getCafe24Board', { cafe24 })
        .then((response) => {
            if(response.data.success){
                dispatch(successCafe24Board(response.data.results));
            }
        })
    };
}

export function successCafe24Board(results){
    return {
        type: "GET_CAFE24_BOARD",
        results
    }
}

export function getGodomallProducts(godomall, productsNumber){
    return (dispatch) => {
        return axios.post('/auth/getGodomallProducts', { godomall, productsNumber })
        .then((response) => {
            if(response.data.success){
                dispatch(successGodomallProducts(response.data.results));
                return response.data.results
            }
        })
    };
}

export function successGodomallProducts(results){
    return {
        type: "GET_GODOMALL_PRODUCTS",
        results
    }
}

export function getGodomallCategory(godomall){
    return (dispatch) => {
        return axios.post('/auth/getGodomallCategory', { godomall })
        .then((response) => {
            if(response.data.success){
                dispatch(successGodomallCategories(response.data.results));
            }
        })
    };
}

export function successGodomallCategories(results){
    return {
        type: "GET_GODOMALL_CATEGORIES",
        results
    }
}

export function getGodomallBoard(godomall){
    return (dispatch) => {
        return axios.post('/auth/getGodomallBoard', { godomall })
        .then((response) => {
            if(response.data.success){
                dispatch(successGodomallBoards(response.data.results));
            }
        })
    };
}

export function successGodomallBoards(results){
    return {
        type: "GET_GODOMALL_BOARDS",
        results
    }
}

export function getGodomallProductsAll(status){
    return (dispatch) => {
        return axios.post('/auth/getGodomallProductsAll', { status })
        .then((response) => {
            if(response.data.success){
                dispatch(successGodomallProductAll(response.data.results));
                return response.data.results
            }
        })
    };
}

export function successGodomallProductAll(results){
    return {
        type: "GET_GODOMALL_PRODUCTALL",
        results
    }
}

export function editUserInfo(email, newName, currentPass, newPass){
    return (dispatch) => {
        return axios.post('/auth/editUserInfo', { email, newName, currentPass, newPass })
        .then((response) => {
            if(response.data.success){
                dispatch(successEditUserInfo(response.data.results));
                return response.data.results
            } else {
                return response.data.error
            }
        })
    };
}

export function successEditUserInfo(results){
    return {
        type: "EDIT_USER_INFO",
        results
    }
}

