import React, {Component} from "react"
// import "./Summary.css"
import { connect } from "react-redux"
import DateTimer_lug from "../../components/custom/DateTimer_lug"
import Loading from "../../components/Loading"
import moment from "moment"
import { getLugstayCustom, getLugstaySegment, setLugstaySegment, getLugstayTotalFunnel, setLugstayFilter, filterSegment } from "../../store/actions/custom"
import {  faUndo, faLongArrowAltRight, faCogs } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./Custom.css"
import LugstayModal from "../../components/custom/LugstayModal"
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, CSVExport  } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import { ExportToCsv } from 'export-to-csv';
const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;

class Custom extends Component{
    constructor(props){
        super(props)
        let now = new Date();
        let today = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0,0,0,0));
        let start = moment(today).subtract(7, "days");
        var minDate = new Date("2020-11-17T00:00:00").getTime()
        if(start._d.getTime() < minDate){
            start = moment(new Date("2020-11-17T00:00:00"))
        }
        let end = moment(today).add(1, "days").subtract(1, "seconds");
        this.state ={
            loading: true,
            dates: [start, end],
            status: props.status,
            segmentGroup: props.segmentGroup,
            segmentGroupNames: props.segmentGroupNames,
            selectedGroupName: props.selectedGroupName,
            newSegmentName: "",
            copySegmentModel: props.segmentGroupNames[0],
            segment: props.segment, // 9개 세그먼트 박스
            copySeg: props.segment, // 업데이트 될 때 변경위한 복사본
            avgData: props.avgData,
            selectedSegment: { // 선택된 세그먼트 요약(summary)정보
                title: "Total Data",
                avg_view: 0,
                avg_visit: 0,
                avg_stay: 0,
                avg_scroll: 0,
                avg_purchase: 0,
                arr_device: [{name:"init", avg: 0}],
                arr_funnel: [{name:"init", avg: 0}],
                percentage: 100
            },
            selectionBoxIndex: "", // 빨간테두리 구분용도
            exporter: "",
            filterGroup: {},
            filterCheckbox: {
                login: {
                    "login": true,
                    "noLogin": true
                },
                device: {
                    "PC": true,
                    "Mobile": true,
                    "Tablet": true
                },
                funnel: {}
            },
            funnelAllCheckbox: true,
            totalCheckbox: {
                login: {
                    "login": true,
                    "noLogin": true
                },
                device: {
                    "PC": true,
                    "Mobile": true,
                    "Tablet": true
                },
                funnel: {}
            },
            funnel: []
        }
    }

    componentDidMount(){
        this.props.getLugstayTotalFunnel()
        .then((response)=> {
            var funnel = []
            var funnelCheckBox = {}
            for(var i = 0; i < response.length; i++){
                funnel.push(response[i]._id)
                funnelCheckBox[response[i]._id] = true
            }
            this.setState({
                funnel,
                filterCheckbox : {
                    ...this.state.filterCheckbox,
                    funnel: funnelCheckBox
                },
                totalCheckbox: {
                    ...this.state.totalCheckbox,
                    funnel: funnelCheckBox
                }
            })
        })

        if(this.props.status.userEmail){
            this.props.getLugstaySegment(this.props.status.userEmail)
            .then((response)=> {
                var selectedFilter = response[this.props.selectedGroupName]
                var totalCheckbox = JSON.parse(JSON.stringify(this.state.totalCheckbox));
                var funnel = totalCheckbox.funnel;
                var funnelAllCheckbox = true;
                for(var a in selectedFilter.funnel){
                    funnel[a] = selectedFilter.funnel[a];
                    if(!selectedFilter.funnel[a]){
                        funnelAllCheckbox = false;
                    }
                }
                var filterCheckbox = {
                    login: selectedFilter.login,
                    device: selectedFilter.device,
                    funnel: funnel,
                }
                this.setState({
                    filterGroup: response,
                    filterCheckbox: filterCheckbox,
                    funnelAllCheckbox: funnelAllCheckbox
                })
            })
        }

        const options = { 
            filename: "고객정보",
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true, 
            showTitle: false,  
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
        };
        var csvExporter = new ExportToCsv(options)
        this.setState({exporter: csvExporter})
    }

    componentDidUpdate(){
        if(this.state.status.userEmail !== this.props.status.userEmail){
            this.setState({status: this.props.status})
            if(this.props.status.userEmail){
                this.props.getLugstaySegment(this.props.status.userEmail)
                .then((response)=> {
                    var selectedFilter = response[this.props.selectedGroupName]
                    var totalCheckbox = JSON.parse(JSON.stringify(this.state.totalCheckbox));
                    var funnel = totalCheckbox.funnel;
                    var funnelAllCheckbox = true;
                    for(var a in selectedFilter.funnel){
                        funnel[a] = selectedFilter.funnel[a];
                        if(!selectedFilter.funnel[a]){
                            funnelAllCheckbox = false;
                        }
                    }
                    var filterCheckbox = {
                        login: selectedFilter.login,
                        device: selectedFilter.device,
                        funnel: funnel,
                    }
                    this.setState({
                        filterGroup: response,
                        filterCheckbox: filterCheckbox,
                        funnelAllCheckbox: funnelAllCheckbox
                    })
                })
            }
        }

        // pagenation a tag event prevent
        if(document.getElementsByClassName("react-bs-table-sizePerPage-dropdown")[0]){
            var drops = document.getElementsByClassName("react-bs-table-sizePerPage-dropdown")[0]
            var aTag = drops.childNodes[1].childNodes
            for(var i = 0; i < aTag.length; i++){
                aTag[i].setAttribute("href", "/#/dashboard/custom")
            }

            if(document.getElementsByClassName("react-bootstrap-table")[0]){
                var rTable = document.getElementsByClassName("react-bootstrap-table")[0]
                rTable.childNodes[0].setAttribute("id", "exportCSV")
            }
        }

        if(this.state.status !== this.props.status){
            this.setState({status: this.props.status})
            if(this.props.status.customPage === "lugstay"){
                this.setDates(this.state.dates)
            }
        }
        // if(this.state.apiCall !== this.props.apiCall) {
        //     this.setState({apiCall: this.props.apiCall})
        // }
        if(this.state.avgData !== this.props.avgData){
            if(this.props.segment[0]){
                this.setInitCountAndPercentage()
            }
            this.setState({
                loading: false,
                avgData: this.props.avgData,
                selectedSegment: {
                    ...this.state.selectedSegment,
                    avg_view: this.props.avgData.avg_view,
                    avg_visit: this.props.avgData.avg_visit,
                    avg_scroll: this.props.avgData.avg_scroll,
                    avg_stay: this.props.avgData.avg_stay,
                    avg_purchase: this.props.avgData.avg_purchase,
                    arr_device: this.props.avgData.arr_device,
                    arr_funnel: this.props.avgData.arr_funnel,
                    totalDoc: [...this.props.avgData.avgPerBrowser, ...this.props.avgData.avgPerUserId]
                }
            })
        }

        if(this.state.copySeg !== this.props.segment){
            if(this.props.avgData.maxPurchaseCount){
                this.setInitCountAndPercentage()
            }
            this.setState({
                copySeg: this.props.segment
            })
        }

        if(this.state.segmentGroupNames !== this.props.segmentGroupNames){
            this.setState({
                segmentGroup: this.props.segmentGroup,
                segmentGroupNames: this.props.segmentGroupNames,
                selectedGroupName: this.props.selectedGroupName,
                segment: this.props.segment,
                newSegmentName: "",
                copySegmentModel: this.props.segmentGroupNames[0]
            })
        }
    }

    setInitCountAndPercentage = () => {
        const { maxPurchaseCount, maxVisitCount } = this.props.avgData
        var customSegment = JSON.parse(JSON.stringify(this.state.segment))
        for(var i = 0; i < customSegment.length; i++){
            var { visitRadio, purchaseRadio, purchaseCount, purchaseRate, visitCount, visitRate } = customSegment[i]
            var pcNum1 = "";
            var pcNum2 = "";
            var prNum1 = "";
            var prNum2 = "";
            if(maxPurchaseCount !== 0){
                if(purchaseRadio === "count"){
                    prNum1 = Math.round((purchaseCount[0] / maxPurchaseCount) * 10000)/100
                    prNum2 = Math.round((purchaseCount[1] / maxPurchaseCount) * 10000)/100
                } else {
                    pcNum1 = Math.floor(purchaseRate[0] / 100 * maxPurchaseCount)
                    pcNum2 = Math.floor(purchaseRate[1] / 100 * maxPurchaseCount)
                }
            }

            var vcNum1 = "";
            var vcNum2 = "";
            var vrNum1 = "";
            var vrNum2 = "";
            if(maxVisitCount !== 0){
                if(visitRadio === "count"){
                    vrNum1 = Math.round((visitCount[0] / maxVisitCount) * 10000)/100
                    vrNum2 = Math.round((visitCount[1] / maxVisitCount) * 10000)/100
                } else {
                    vcNum1 = Math.floor(visitRate[0] / 100 * maxVisitCount)
                    vcNum2 = Math.floor(visitRate[1] / 100 * maxVisitCount)
                }
            }
            customSegment[i].purchaseCount = [typeof(pcNum1) === "number" ? pcNum1 : purchaseCount[0], typeof(pcNum2) === "number" ? pcNum2 : purchaseCount[1]]
            customSegment[i].purchaseRate = [typeof(prNum1) === "number" ? prNum1 : purchaseRate[0], typeof(prNum2) === "number" ? prNum2 : purchaseRate[1]]
            customSegment[i].visitCount = [typeof(vcNum1) === "number" ? vcNum1 : visitCount[0], typeof(vcNum2) === "number" ? vcNum2 : visitCount[1]]
            customSegment[i].visitRate = [typeof(vrNum1) === "number" ? vrNum1 : visitRate[0], typeof(vrNum2) === "number" ? vrNum2 : visitRate[1]]
        }
        this.setState({
            segment: customSegment,
        })
    }

    setDates = (dates) => {
        this.setState({
            dates: dates,
            loading: true,
        })
        if(this.state.status.isLoggedIn){
            this.props.getLugstayCustom(dates, this.props.status)
            .then(()=> {
                this.props.filterSegment(this.state.filterCheckbox)
            })
        }
    }

    changeSegment = (obj, index) => {
        var { segment, segmentGroup, selectedGroupName, filterGroup } = this.state;
        segment[index] = {
            ...segment[index],
            ...obj
        }
        segmentGroup[selectedGroupName] = segment;
        this.setState({
            ...this.state,
            segment: segment,
            segmentGroup: segmentGroup
        })
        this.props.setLugstaySegment(this.state.status.userEmail, segmentGroup, selectedGroupName, filterGroup)
    }

    changeSelection = (index) => {
        this.setState({loading: true})
        var segment = this.state.segment[index]
        var {avgPerBrowser, avgPerUserId} = this.state.avgData
        var [pCount1, pCount2] = segment.purchaseCount
        var [vCount1, vCount2] = segment.visitCount
        var filterBrowser = avgPerBrowser.filter((data, index)=> {
            if(data.visitCount >= vCount1 && data.visitCount <= vCount2){
                if(data.purchaseCount >= pCount1 && data.purchaseCount <= pCount2){
                    return data
                }
            }
        })
        var filterUserId = avgPerUserId.filter((data, index)=> {
            if(data.visitCount >= vCount1 && data.visitCount <= vCount2){
                if(data.purchaseCount >= pCount1 && data.purchaseCount <= pCount2){
                    return data
                }
            }
        })

        var totalDoc = [...filterBrowser, ...filterUserId]
        var total_stay = 0
        var total_scroll = 0
        var total_view = 0
        var total_visit = 0
        var total_purchase = 0
        for(var i = 0; i < totalDoc.length; i++){
            total_stay += totalDoc[i].avgStayTime;
            total_scroll += totalDoc[i].avgScrollDepth;
            total_view += totalDoc[i].avgViewCount;
            total_visit += totalDoc[i].visitCount;
            total_purchase += totalDoc[i].purchaseCount;
        }

        var avg_stay = Math.round(total_stay / totalDoc.length)
        var avg_scroll = Math.round(total_scroll / totalDoc.length)
        var avg_view = Math.round(total_view / totalDoc.length)
        var avg_visit = Math.round(total_visit / totalDoc.length)
        var avg_purchase = Math.round((total_purchase / totalDoc.length)* 1000) / 10

        var hour = (avg_stay / 3600000) > 0 ? Math.floor((avg_stay / 3600000)) : ""
        var minute = (avg_stay / 60000) > 0 ? Math.floor((avg_stay % 3600000) / 60000) : ""
        var second = (avg_stay / 1000) > 0 ? Math.floor((avg_stay % 60000) / 1000) : 0
        avg_stay = `${hour ? hour + "시간" : ""}${minute ? minute + "분": ""}${second + "초"}`

        var total_funnel = {}
        var total_day = {}
        var total_device = {}
        for(i = 0; i < filterBrowser.length; i++){
            if(total_funnel[filterBrowser[i].funnel]){
                total_funnel[filterBrowser[i].funnel] += 1
            } else {
                total_funnel[filterBrowser[i].funnel] = 1
            }
            if(total_day[filterBrowser[i].day]){
                total_day[filterBrowser[i].day] += 1
            } else {
                total_day[filterBrowser[i].day] = 1
            }
            if(total_device[filterBrowser[i].device]){
                total_device[filterBrowser[i].device] += 1
            } else {
                total_device[filterBrowser[i].device] = 1
            }
        }

        var filterUserId_2 = []
        var filterUserIdObj = {}
        // 메인데이터를 구할 때는 동일 브라우저는 중복되므로 한개만 남겨둔다.
        for(i = 0; i < filterUserId.length; i++){
            if(!filterUserIdObj[filterUserId[i].browserKey]){
                filterUserIdObj[filterUserId[i].browserKey] = true
                filterUserId_2.push(filterUserId[i])
            }
        }
        for(i = 0; i < filterUserId_2.length; i++){
            if(total_funnel[filterUserId_2[i].funnel]){
                total_funnel[filterUserId_2[i].funnel] += 1
            } else {
                total_funnel[filterUserId_2[i].funnel] = 1
            }
            if(total_day[filterUserId_2[i].day]){
                total_day[filterUserId_2[i].day] += 1
            } else {
                total_day[filterUserId_2[i].day] = 1
            }
            if(total_device[filterUserId_2[i].device]){
                total_device[filterUserId_2[i].device] += 1
            } else {
                total_device[filterUserId_2[i].device] = 1
            }
        }

        //메인데이터를 나누는 분모로 사용
        var filterTotalDocLen = filterBrowser.length + filterUserId_2.length;

        var arr_funnel = []
        for(var a in total_funnel){
            var newName = ""
            if(a.indexOf("인하우스 마케팅, ") !== -1){
                newName = a.replace("인하우스 마케팅, ", "")
            } else if (a.indexOf("자연유입_Direct") !== -1){
                newName = a.replace("자연유입_Direct", "직접 유입")
            } else if (a.indexOf("자연유입_검색, ") !== -1) {
                newName = a.replace("자연유입_검색, ", "")
            } else if (a.indexOf("유료마케팅_검색, ") !== -1) {
                newName = a.replace("유료마케팅_검색, ", "")
            }
            arr_funnel.push({name: newName ? newName : a, avg: Math.round((total_funnel[a] / filterTotalDocLen)* 100)})
        }
        arr_funnel.sort((a,b) => b.avg - a.avg)

        var arr_device = []
        for(a in total_device){
            arr_device.push({name: a, avg: Math.round((total_device[a] / filterTotalDocLen)* 100)})
        }
        arr_device.sort((a,b) => b.avg - a.avg)

        var obj ={
            arr_device: arr_device,
            arr_funnel: arr_funnel,
            avg_stay: avg_stay,
            avg_scroll: avg_scroll,
            avg_view: avg_view,
            avg_visit: avg_visit,
            avg_purchase: avg_purchase,
            totalLength: totalDoc.length
        }
        var percentage = Math.round((totalDoc.length / this.state.avgData.totalLength) * 100 )
        this.setState({
            loading: false,
            selectedSegment: {
                ...this.state.selectedSegment,
                avg_view: obj.avg_view,
                avg_visit: obj.avg_visit,
                avg_scroll: obj.avg_scroll,
                avg_stay: obj.avg_stay,
                avg_purchase: obj.avg_purchase,
                arr_device: obj.arr_device,
                arr_funnel: obj.arr_funnel,
                percentage: percentage,
                title: segment.title,
                totalDoc: totalDoc
            },
            selectionBoxIndex: index
        })
    }

    initSegment = () => {
        this.setState({
            selectedSegment: {
                ...this.state.selectedSegment,
                avg_view: this.props.avgData.avg_view,
                avg_visit: this.props.avgData.avg_visit,
                avg_scroll: this.props.avgData.avg_scroll,
                avg_stay: this.props.avgData.avg_stay,
                avg_purchase: this.props.avgData.avg_purchase,
                arr_device: this.props.avgData.arr_device,
                arr_funnel: this.props.avgData.arr_funnel,
                percentage: 100,
                title: "Total Data",
                totalDoc: [...this.props.avgData.avgPerBrowser, ...this.props.avgData.avgPerUserId]
            },
            selectionBoxIndex: ""
        })
    }

    exportCSV = () => {
        const { exporter } = this.state
        let filterData = this.state.selectedSegment.totalDoc
        var newFilterData = []
        for(var i = 0; i < filterData.length; i++){
            var cell = filterData[i].avgStayTime
            var newCell = ""
            if(cell) {
                var hour = (cell / 3600000) > 0 ? Math.floor((cell / 3600000)) : ""
                var minute = (cell / 60000) > 0 ? Math.floor((cell % 3600000) / 60000) : ""
                var second = (cell / 1000) > 0 ? Math.floor((cell % 60000) / 1000) : 0
                newCell = `${hour ? hour + "시간" : ""}${minute ? minute + "분": ""}${second + "초"}`
            } else {
                newCell = "0초"
            }

            var cell2 = filterData[i].funnel
            var newName = ""
            if(cell2){
                if(cell2.indexOf("인하우스 마케팅, ") !== -1){
                    newName = cell2.replace("인하우스 마케팅, ", "")
                } else if (cell2.indexOf("자연유입_Direct") !== -1){
                    newName = cell2.replace("자연유입_Direct", "직접 유입")
                } else if (cell2.indexOf("자연유입_검색, ") !== -1) {
                    newName = cell2.replace("자연유입_검색, ", "")
                } else if (cell2.indexOf("유료마케팅_검색, ") !== -1) {
                    newName = cell2.replace("유료마케팅_검색, ", "")
                } else {
                    newName = cell2
                }
            } else {
                newName = "No data"
            }
                    
            newFilterData.push({
                "사용자계정": filterData[i].userId ? filterData[i].userId : "비로그인",
                "평균페이지뷰": filterData[i].avgViewCount.toLocaleString(),
                '세션수': filterData[i].visitCount.toLocaleString(),
                "평균체류시간": newCell,
                "평균스크롤깊이": filterData[i].avgScrollDepth + "%",
                "유입경로": newName,
                "구매수": filterData[i].purchaseCount,
                "기기": filterData[i].device,
                "키값": filterData[i].browserKey
            })
        }
        exporter.generateCsv(newFilterData)
    }

    changeSegmentGroup = (e) => {
        if(e.target.name === "selectedGroupName"){
            var selectedFilter = this.state.filterGroup[e.target.innerText]
            var totalCheckbox = JSON.parse(JSON.stringify(this.state.totalCheckbox));
            var funnel = totalCheckbox.funnel;
            var funnelAllCheckbox = true;
            for(var a in selectedFilter.funnel){
                funnel[a] = selectedFilter.funnel[a];
                if(!selectedFilter.funnel[a]){
                    funnelAllCheckbox = false;
                }
            }
            var filterCheckbox = {
                login: selectedFilter.login,
                device: selectedFilter.device,
                funnel: funnel,
            }
            console.log("change test", this.state.segmentGroup[e.target.innerText])
            this.setState({
                [e.target.name]: e.target.innerText,
                segment: this.state.segmentGroup[e.target.innerText],
                filterCheckbox: filterCheckbox,
                funnelAllCheckbox: funnelAllCheckbox,
            })
            this.props.filterSegment(filterCheckbox)
            this.initSegment()
        } else {
            this.setState({
                [e.target.name]: e.target.innerText
            })
        }
        // console.log(e.target)
    }

    changeNewSegment = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    createNewSegmentGroup = () => {
        var { newSegmentName, copySegmentModel, segmentGroup, segmentGroupNames, selectedGroupName, filterGroup } = this.state;
        if(segmentGroupNames.includes(newSegmentName)){
            alert("이미 등록되어있는 이름입니다.")
        } else {
            var selectedFilter = filterGroup[copySegmentModel]
            var totalCheckbox = JSON.parse(JSON.stringify(this.state.totalCheckbox));
            var funnel = totalCheckbox.funnel;
            var funnelAllCheckbox = true;
            for(var a in selectedFilter.funnel){
                funnel[a] = selectedFilter.funnel[a];
                if(!selectedFilter.funnel[a]){
                    funnelAllCheckbox = false;
                }
            }
            var filterCheckbox = {
                login: selectedFilter.login,
                device: selectedFilter.device,
                funnel: funnel,
            }
            segmentGroup[newSegmentName] = segmentGroup[copySegmentModel];
            filterGroup[newSegmentName] = filterCheckbox;

            this.props.setLugstaySegment(this.state.status.userEmail, segmentGroup, newSegmentName, filterGroup)
            .then((response)=> {
                if(response){
                    this.setState({
                        filterCheckbox: filterGroup[newSegmentName],
                        funnelAllCheckbox: funnelAllCheckbox,
                    })
                    this.props.filterSegment(filterGroup[newSegmentName])
                    alert("생성되었습니다")
                }
            })
        }
    }

    deleteSegment = () => {
        var { selectedGroupName, segmentGroup, segmentGroupNames, filterGroup } = this.state
        if(segmentGroupNames.length === 1){
            alert("하나 이상의 세그먼트가 필요합니다")
        } else {
            if(window.confirm(`${selectedGroupName}을 삭제하시겠습니까?`)){
                delete segmentGroup[selectedGroupName]
                delete filterGroup[selectedGroupName]

                this.props.setLugstaySegment(this.state.status.userEmail, segmentGroup, "", filterGroup)
                .then((response) => {
                    if(response){
                        var selectedFilter = filterGroup[segmentGroupNames[0]]
                        var totalCheckbox = JSON.parse(JSON.stringify(this.state.totalCheckbox));
                        var funnel = totalCheckbox.funnel;
                        var funnelAllCheckbox = true;
                        for(var a in selectedFilter.funnel){
                            funnel[a] = selectedFilter.funnel[a];
                            if(!selectedFilter.funnel[a]){
                                funnelAllCheckbox = false;
                            }
                        }
                        var filterCheckbox = {
                            login: selectedFilter.login,
                            device: selectedFilter.device,
                            funnel: funnel,
                        }
                        
                        this.setState({
                            filterCheckbox: filterCheckbox,
                            funnelAllCheckbox: funnelAllCheckbox
                        })
                        this.props.filterSegment(filterGroup[segmentGroupNames[0]])
                        alert("삭제되었습니다.")
                    }
                })
            } 
        }
    }

    changeFilterCheckbox = (e, target) => {
        if(target === "all"){
            var bool = !this.state.funnelAllCheckbox;
            var funnelCheck = this.state.filterCheckbox.funnel;
            for(var a in funnelCheck){
                funnelCheck[a] = bool;
            }
            this.setState({
                funnelAllCheckbox: bool,
                filterCheckbox: {
                    ...this.state.filterCheckbox,
                    funnel: funnelCheck
                }
            })
        } else if (target === "funnel"){
            var funnelAllCheckbox = true;
            var funnelCheckbox = this.state.filterCheckbox.funnel;
            funnelCheckbox[e.target.name] = !funnelCheckbox[e.target.name];
            for(var a in funnelCheckbox){
                if(!funnelCheckbox[a]){
                    funnelAllCheckbox = false;
                    break;
                }
            }
            this.setState({
                filterCheckbox: {
                    ...this.state.filterCheckbox,
                    funnel: funnelCheckbox,
                },
                funnelAllCheckbox: funnelAllCheckbox
            })
        } else {
            this.setState({
                filterCheckbox: {
                    ...this.state.filterCheckbox,
                    [target]:{
                        ...this.state.filterCheckbox[target],
                        [e.target.name]: !this.state.filterCheckbox[target][e.target.name]
                    }
                }
            })
        }
    }

    setLugstayFilter = () => {
        console.log("tselected group", this.state.selectedGroupName)
        var filterGroup = this.state.filterGroup
        filterGroup[this.state.selectedGroupName]= this.state.filterCheckbox;
        this.setState({
            filterGroup: filterGroup
        })
        console.log("setLugstayFilter test", this.state.status.userEmail, filterGroup)
        this.props.setLugstayFilter(this.state.status.userEmail, filterGroup)
        .then((response)=> {
            if(response){
                this.props.filterSegment(this.state.filterCheckbox)
            }
        })
    }

    render(){
        console.log("render test ", this.state.segment)
        const { selectedSegment, selectionBoxIndex, segmentGroupNames, selectedGroupName, newSegmentName, copySegmentModel } = this.state
        const columns = [{
            dataField: 'userId',
            text: '사용자계정',
            sort: true,
            headerAlign: 'center',
            align: 'center',
            headerClasses: 'w-16',
            formatter: (cell, row) => {
                if(cell){
                    return (
                        <p title={cell} className="m-0 over--split">{cell}</p>
                    )
                } else {
                    return (
                        <span>비로그인</span>
                    )
                }
            }
          }, {
            dataField: 'avgViewCount',
            text: '평균페이지뷰',
            sort: true,
            headerAlign: 'center',
            align: 'center',
            formatter: (cell, row) => {
                return (
                    <span>{cell.toLocaleString()}</span>
                )
            }
          }, {
            dataField: 'visitCount',
            text: '세션수',
            sort: true,
            headerAlign: 'center',
            align: 'center',
            headerClasses: 'w-8',
            formatter: (cell, row) => {
                return (
                    <span>{cell.toLocaleString()}</span>
                )
            }
          }, {
            dataField: 'avgStayTime',
            text: '평균체류시간',
            sort: true,
            headerAlign: 'center',
            align: 'center',
            formatter: (cell, row) => {
                if(cell){
                    var hour = (cell / 3600000) > 0 ? Math.floor((cell / 3600000)) : ""
                    var minute = (cell / 60000) > 0 ? Math.floor((cell % 3600000) / 60000) : ""
                    var second = (cell / 1000) > 0 ? Math.floor((cell % 60000) / 1000) : 0
                    var newCell = `${hour ? hour + "시간" : ""}${minute ? minute + "분": ""}${second + "초"}`
                    return (
                        <span>{newCell}</span>
                    )
                } else {
                    return (
                        <span>0초</span>
                    )
                }
            }
          },{
            dataField: 'avgScrollDepth',
            text: '평균스크롤깊이',
            sort: true,
            headerAlign: 'center',
            align: 'center',
            formatter: (cell, row) => {
                return (
                    <span>{cell}%</span>
                )
            }
          },{
            dataField: 'funnel',
            text: '유입경로',
            sort: true,
            headerAlign: 'center',
            align: 'center',
            formatter: (cell, row) => {
                var newName = ""
                if(cell){
                    if(cell.indexOf("인하우스 마케팅, ") !== -1){
                        newName = cell.replace("인하우스 마케팅, ", "")
                    } else if (cell.indexOf("자연유입_Direct") !== -1){
                        newName = cell.replace("자연유입_Direct", "직접 유입")
                    } else if (cell.indexOf("자연유입_검색, ") !== -1) {
                        newName = cell.replace("자연유입_검색, ", "")
                    } else if (cell.indexOf("유료마케팅_검색, ") !== -1) {
                        newName = cell.replace("유료마케팅_검색, ", "")
                    }
                    return (
                        <p title={newName ? newName : cell} className="m-0 over--split">{newName ? newName : cell}</p>
                    )
                } else {
                    return (
                        <span>No data</span>
                    )
                }
            }
          }, {
            dataField: 'purchaseCount',
            text: '구매수',
            sort: true,
            headerAlign: 'center',
            align: 'center',
            headerClasses: 'w-8'
          }, {
            dataField: 'device',
            text: '기기',
            sort: true,
            headerAlign: 'center',
            align: 'center',
            headerClasses: 'w-8',
            formatter: (cell, row) => {
                if(cell){
                    return (
                        <span>{cell}</span>
                    )
                } else {
                    return (
                        <span>No data</span>
                    )
                }
            }
          },{
            dataField: 'browserKey',
            text: '키값',
            headerAlign: 'center',
            align: 'center',
            formatter: (cell, row) => {
                if(cell){
                    return (
                        <p title={cell} className="m-0 over--split">{cell}</p>
                    )
                } else {
                    return (
                        <span>No data</span>
                    )
                }
            }
          }
        ];
        return(
            <div className="content pl-2 pr-2">
                <Loading loading={this.state.loading}/>
                <div className="d-flex row w-100 m-0 mb-2 mt-2 pl-2 pr-2">
                    <div  className="content--header col bg-light pt-2 pb-2 pl-0">
                        {
                            this.props.status.customPage === "lugstay" ? 
                            <div className="d-flex">
                                <div className="form-control dropdown mr-1 pr-1" style={{width:"fit-content"}}>
                                    <input data-toggle="dropdown" autoComplete="off" readOnly placeholder="Select Condition" value={selectedGroupName} className="drop--input" aria-haspopup="true"/>
                                    <button data-toggle="dropdown" className="drop--toggle bg-white dropdown-toggle dropdown-toggle-split" type="button" aria-haspopup="true" aria-expanded="false"/>
                                    <div className="dropdown-menu mt-0 segment-dropdown-menu" draggable="false"> 
                                        {
                                            segmentGroupNames.map((name, index) => 
                                            <a key={index} onClick={this.changeSegmentGroup} href={`#/dashboard/${localStorage.getItem("dashboard_page")}`} className="dropdown-item" name="selectedGroupName">{name}</a>)
                                        }
                                    </div>
                                </div>
                                <div className="btn btn-primary mr-1" data-toggle="modal" data-target="#newSegment">Create segment</div>
                                <div onClick={this.deleteSegment} className="btn btn-danger">Delete segment</div>
                                <div className="modal fade" id="newSegment" tabIndex="-1" role="dialog" aria-labelledby="newSegmentLabel" aria-hidden="true">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="newSegmentLabel">Create segment</h5>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="form-group">
                                                <label>Segment name</label>
                                                <input autoComplete="off" onChange={this.changeNewSegment} className="form-control" name="newSegmentName" value={newSegmentName} placeholder="Enter name"/>
                                            </div>
                                            <div className="form-group">
                                                <label>Copy model</label>
                                                <div className="form-control dropdown mr-1 pr-1" style={{width:"fit-content"}}>
                                                    <input data-toggle="dropdown" autoComplete="off" readOnly placeholder="Select Condition" value={copySegmentModel} className="drop--input" aria-haspopup="true"/>
                                                    <button data-toggle="dropdown" className="drop--toggle bg-white dropdown-toggle dropdown-toggle-split" type="button" aria-haspopup="true" aria-expanded="false"/>
                                                    <div className="dropdown-menu mt-0 segment-dropdown-menu" draggable="false"> 
                                                        {
                                                            segmentGroupNames.map((name, index) => 
                                                            <a key={index} onClick={this.changeSegmentGroup} href={`#/dashboard/${localStorage.getItem("dashboard_page")}`} className="dropdown-item" name="copySegmentModel">{name}</a>)
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                            <button onClick={this.createNewSegmentGroup} type="button" className="btn btn-primary" data-dismiss="modal">Create</button>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>        
                            : ""
                        }
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="mr-2 pointer" data-toggle="modal" data-target="#segmentFilter">
                            <FontAwesomeIcon icon={faCogs} size="lg"/>
                        </div>
                        <div className="modal fade" id="segmentFilter" tabIndex="-1" role="dialog" aria-labelledby="segmentFilterLabel" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="segmentFilterLabel">Filter segment</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="h5">사용자 계정</div>
                                    <div className="form-group d-flex">
                                        <div className="form-check form-check-inline">
                                            <input onChange={(e)=> this.changeFilterCheckbox(e, "login")} className="form-check-input" type="checkbox" name="login" checked={this.state.filterCheckbox.login["login"]} />
                                            <label className="form-check-label" >로그인</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input onChange={(e)=> this.changeFilterCheckbox(e, "login")} className="form-check-input" type="checkbox" name="noLogin" checked={this.state.filterCheckbox.login["noLogin"]} />
                                            <label className="form-check-label">비로그인</label>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className="h5">기기</div>
                                    <div className="form-group d-flex">
                                        <div className="form-check form-check-inline">
                                            <input onChange={(e)=> this.changeFilterCheckbox(e, "device")} className="form-check-input" type="checkbox" name="PC" checked={this.state.filterCheckbox.device["PC"]} />
                                            <label className="form-check-label" >PC</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input onChange={(e)=> this.changeFilterCheckbox(e, "device")} className="form-check-input" type="checkbox" name="Mobile" checked={this.state.filterCheckbox.device["Mobile"]} />
                                            <label className="form-check-label">Mobile</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input onChange={(e)=> this.changeFilterCheckbox(e, "device")} className="form-check-input" type="checkbox" name="Tablet" checked={this.state.filterCheckbox.device["Tablet"]} />
                                            <label className="form-check-label">Tablet</label>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className="d-flex">
                                        <div className="h5">유입경로</div>
                                        <div className="form-check form-check-inline ml-3 mb-1">
                                            <input onChange={(e)=> this.changeFilterCheckbox(e, "all")} className="form-check-input" type="checkbox" name={"funnelAllCheckbox"} checked={this.state.funnelAllCheckbox} />
                                            <label className="form-check-label" >전체 선택</label>
                                        </div>
                                    </div>
                                    <div className="form-group d-flex flex-wrap mb-0" style={{whiteSpace:"nowrap"}}>
                                        {
                                            this.state.funnel.map((fun, index) => 
                                            <div key={index} className="form-check form-check-inline">
                                                <input onChange={(e)=> this.changeFilterCheckbox(e, "funnel")} className="form-check-input" type="checkbox" name={fun} checked={this.state.filterCheckbox.funnel[fun]} />
                                                <label className="form-check-label" >{fun}</label>
                                            </div>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                    <button onClick={this.setLugstayFilter} type="button" className="btn btn-primary" data-dismiss="modal">Save</button>
                                </div>
                                </div>
                            </div>
                        </div>
                        <DateTimer_lug setDates={(dates) => this.setDates(dates)}/>
                    </div>
                </div>
                <div className="row w-100 m-0">
                    <div className="w-100 pl-2 pr-2">
                        <div className={"card m-0 mb-3"}>
                            <div className={"card-body  table-responsive p-0"}>
                                <div className="pt-3 pl-3 pr-3">
                                    {
                                        this.props.status.customPage === "lugstay" ? 
                                        <div className="d-flex lugstay--custom">
                                            <div className="d-flex align-items-center" style={{width:"2%", height:"fit-content", whiteSpace:"nowrap", marginTop:"7%", transform:"rotate(270deg)"}}>
                                                방문빈도
                                                <FontAwesomeIcon className="ml-2" icon={faLongArrowAltRight} size="3x" color="gray" />
                                            </div>
                                            <div style={{width:"98%"}}>
                                                <div className="custom--header w-100">
                                                    <div className="w-100 d-flex">
                                                        <div className="custom--segment col-7 d-flex flex-wrap">
                                                            {
                                                                this.state.segment ?
                                                                this.state.segment.map((seg, index) => {
                                                                    return (
                                                                        <div key={index} className={("col-4 pl-0") + (index > 5 ? "" : " pb-2")}>
                                                                            <div className={("w-100 d-flex justify-content-center align-items-center h5 box-one") + (index === 3 || index === 6 || index === 7 ? "" : " text-white") + (index === selectionBoxIndex ? " selection--box" : "") } style={{backgroundColor: seg.bg}}>
                                                                                {seg.title}
                                                                            </div>
                                                                            <div className="pr-3 w-100 box-two">
                                                                                <div className="box-three w-100 d-flex justify-content-center align-items-center text-white h5">
                                                                                    <div onClick={()=>this.changeSelection(index)} className="pointer mr-3 rounded-circle bg-primary d-flex justify-content-center align-items-center" style={{width:"50px", height:"50px"}}>AVG</div>
                                                                                    <div data-toggle="modal" data-target={`#${seg.id}`} className="pointer rounded-circle bg-primary d-flex justify-content-center align-items-center" style={{width:"50px", height:"50px"}}>Set</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }) : ""
                                                            }
                                                        </div>
                                                        <div className="custom--summary col-5">
                                                            {
                                                                this.state.avgData ? 
                                                                <div className={"card inner--card m-0"}>
                                                                    <div className={"card-body  table-responsive p-3"}>
                                                                        <div className="d-flex justify-content-between">
                                                                            <h4>{selectedSegment.title}</h4>
                                                                            <FontAwesomeIcon onClick={()=>this.initSegment()} className="pointer" icon={faUndo} size="lg" />
                                                                        </div>
                                                                        <p className="mb-2">Funnel: <small>{
                                                                            selectedSegment.arr_funnel.map((funnel, index) => {
                                                                                if(index === 0){
                                                                                    return (
                                                                                        funnel.name + "(" + funnel.avg + "%)"
                                                                                    )
                                                                                } else if (index < 3){
                                                                                        return (
                                                                                            ", " + funnel.name + "(" + funnel.avg + "%)"
                                                                                        )
                                                                                }
                                                                            })
                                                                        }</small></p>
                                                                        <p className="mb-2">Device: {
                                                                            selectedSegment.arr_device.map((device, index) => {
                                                                                if(index === 0){
                                                                                    return (
                                                                                        `${device.name}(${device.avg}%)`
                                                                                    )
                                                                                } else if (index < 2){
                                                                                    return (
                                                                                        `, ${device.name}(${device.avg}%)`
                                                                                    )
                                                                                }
                                                                            })
                                                                        }</p>
                                                                        <p className="mb-2">Avg. View: {selectedSegment.avg_view}page / visit</p>
                                                                        <p className="mb-2">Avg. Visit: {selectedSegment.avg_visit}visit</p>
                                                                        <p className="mb-2">Avg. Stay: {selectedSegment.avg_stay}</p>
                                                                        <p className="mb-2">Avg. Depth: {selectedSegment.avg_scroll}%</p>
                                                                        <p className="mb-2">Conversion: {selectedSegment.avg_purchase}%</p>
                                                                        <div className="progress w-100">
                                                                            <div className="progress-bar h5 m-0 font-weight-normal" role="progressbar" style={{width: selectedSegment.percentage + "%"}} aria-valuemin="0" aria-valuemax="100">{selectedSegment.percentage}% in Total</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :""
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className=" col-7 d-flex align-items-center justify-content-end" style={{paddingRight:"3%"}}>
                                                        {/* <div className="w-50 d-flex">
                                                            <input className="form-control" placeholder="세그먼트 이름" />
                                                            <div className="btn btn-info">새로만들기</div>
                                                        </div> */}
                                                        <div className="d-flex align-items-center justify-content-end">
                                                            구매율
                                                            <FontAwesomeIcon className="ml-2" icon={faLongArrowAltRight} size="3x" color="gray" />
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    this.state.segment ? 
                                                    this.state.segment.map((seg, index) => 
                                                    <div key={index}>
                                                        <LugstayModal
                                                        segment={seg}
                                                        maxPurchaseCount={this.state.avgData.maxPurchaseCount ? this.state.avgData.maxPurchaseCount : 0}
                                                        maxVisitCount={this.state.avgData.maxVisitCount ? this.state.avgData.maxVisitCount : 0}
                                                        onSubmit={(obj)=> this.changeSegment(obj, index)}
                                                        />
                                                    </div>
                                                    )
                                                    : ""
                                                }
                                                
                                                <div className="custom--body">
                                                    <div className="custom--table">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :""
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={"card m-0"}>
                            <div className={"card-body  table-responsive p-0"}>
                                <div className={" p-3"}>
                                    {
                                        this.state.selectedSegment.totalDoc ?
                                        <ToolkitProvider
                                        keyField="_id"
                                        data={ this.state.selectedSegment.totalDoc }
                                        columns={ columns }
                                        bootstrap4
                                        search
                                        exportCSV={ {
                                            fileName: 'custom.csv',
                                            // ignoreHeader: false,
                                            // blobType: 'text/plain;charset=cp949',
                                            // noAutoBOM: false,
                                          } }
                                        >
                                        {
                                            props => (
                                            <div>
                                                <div className="d-flex justify-content-between">
                                                    <SearchBar { ...props.searchProps } />
                                                    <div>
                                                        <button onClick={()=> this.exportCSV()} className="btn btn-success">Export to CSV</button>
                                                    </div>
                                                </div>
                                                <BootstrapTable
                                                bordered={ false }
                                                hover
                                                noDataIndication="Table is Empty"
                                                pagination={ paginationFactory() }
                                                { ...props.baseProps }
                                                />
                                            </div>
                                            )
                                        }
                                        </ToolkitProvider>
                                        :""
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        status: state.authentication.status,
        segmentGroup: state.custom.lugstay.segmentGroup,
        segmentGroupNames: state.custom.lugstay.segmentGroupNames,
        selectedGroupName: state.custom.lugstay.selectedGroupName,
        segment: state.custom.lugstay.segment,
        avgData: state.custom.lugstay.avgData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getLugstayCustom: (dates, status) => {
            return dispatch(getLugstayCustom(dates, status));
        },
        getLugstaySegment: (email) => {
            return dispatch(getLugstaySegment(email));
        },
        setLugstaySegment: (email, segment, name, filter) => {
            return dispatch(setLugstaySegment(email, segment, name, filter))
        },
        getLugstayTotalFunnel: () => {
            return dispatch(getLugstayTotalFunnel())
        },
        setLugstayFilter: (email, filter) => {
            return dispatch(setLugstayFilter(email, filter))
        },
        filterSegment: (filter) => {
            return dispatch(filterSegment(filter))
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Custom);