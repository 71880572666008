import React from "react"
import "./RecResult.css"
import { connect } from "react-redux"
import { getRecommendation, deleteRecommendation, updateRecommendation } from "../../store/actions/condition"
import { changePage } from "../../store/actions/authentication"
import Navbar from "../../components/Navbar"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faUserCircle, faPen, faPlay, faPause, faChartBar, faTrashAlt,  faTimes } from "@fortawesome/free-solid-svg-icons"
import {deviceDoughnut} from "../../datas/chartjs/doughnut"
import ExpandDoughnut from "../../components/chart/ExpandDoughnut"

class RecResult extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            algorithm: {},
            where: {},
            categories: {},
            config1: deviceDoughnut,
            recommendations: this.props.recommendations,
            selectedHost: this.props.selectedHost,
        }
        this.props.getRecommendation(this.props.email, this.props.selectedHost)
    }

    componentDidUpdate() {
        //새로고침 되돌리기
        if(this.props.selectedHost !== this.state.selectedHost){
            this.setState({selectedHost: this.props.selectedHost})
            this.props.getRecommendation(this.props.email, this.props.selectedHost)
        }
        
        if(this.props.recommendations.length > 0) {
            //맨 처음에만 값 설정해주기
            if(this.state[`isEdit_0`] == null){
                var lines = document.getElementsByClassName("dynamic--line");
                var obj = {}
                for(var j = 0; j < this.props.recommendations.length; j++){
                    if(j < this.props.recommendations.length -1){
                        lines[j].style.height = "282px";
                    }
                    obj[`isEdit_${j}`] = false;
                }
                this.setState(obj)
            }
        }
    }

    deleteRec = (name) =>{
        const { email, selectedHost } = this.props
        if(window.confirm("선택한 추천 상품을 삭제하시겠습니까?")){
            this.props.deleteRecommendation(email, selectedHost, [name])
        }
    }


    showEdit = (name, state) => {
        var obj = {}
        obj[name] = state
        console.log(obj)
        this.setState(obj)
    }

    updateRec = (name, state) => {
        if(state === "On"){
            if(window.confirm("추천상품을 사용하시겠습니까?")){
                this.props.updateRecommendation(this.props.email, this.props.selectedHost, name, {"done": state})
            }
        } else {
            if(window.confirm("추천상품을 중지하시겠습니까?")){
                this.props.updateRecommendation(this.props.email, this.props.selectedHost, name, {"done": state})
            }
        }
    }

    renderLine = (index) => {
        if(index !== 0){
            return (
                <div style={{display:"contents"}} key={index}>
                    <div className="collapse" id={"collapseExample" + index}>
                        <div className="dynamic--line">
                        </div>
                    </div>
                    <div className="ab--line"></div>
                    <div className="ab--circle"><FontAwesomeIcon className="ab--default--font" icon={faPlus} size="sm"/></div>
                    <div className="ab--line2"></div>
                    <div className="ab--circle2">
                        <FontAwesomeIcon className={this.state[`isEdit_${index}`] === true ? "ab--select--font" : "ab--default--font"} icon={faUserCircle} size="2x"/>
                        <div className="ab--line3"></div>
                    </div>
                </div>
            )
        }
    }

    renderBody = (test,index) => {
        var who = JSON.parse(test.who)
        var age = []
        var gender = []
        var result = ""
        for(var i = 0; i < who.length-1 ; i ++) {
            if(who[i].$match.$or){
                for(var j = 0; j < who[i].$match.$or.length; j++){
                    if(who[i].$match.$or[j].age){
                        if(j === 0){
                            age[0] = who[i].$match.$or[j].age.$gte;
                            age[1] = who[i].$match.$or[j].age.$lte;
                        }
                        age[1] =  who[i].$match.$or[j].age.$lte;
                    }      
                    if(who[i].$match.$or[j].gender){
                        gender[j] = who[i].$match.$or[j].gender;
                    }
                }
            } else {
                if(who[i].$match.age){
                        age[0] = who[i].$match.age.$gte;
                        age[1] =  who[i].$match.age.$lte;
                }      
                if(who[i].$match.gender){
                    gender[0] = who[i].$match.gender;
                }
            }
        }
        result = `${age[0] ? "(연령? " + age[0] + "-" + age[1] +") " : ""} ${gender[0] ? gender[1] ? "(성별? " + gender[0] + ", " + gender[1] +")" : "(성별? " + gender[0] +")" : ""}`
 
        return(
            <div key={index} className="result--container bg-white">
                <div className="result--header">
                    <div className="result--title">
                        <h5 className="m-0">{test.recommendationName}</h5>
                        {
                            this.state[`isEdit_${index}`] === true ? <small>&nbsp;</small> : <small>추천상품이 메인으로 잡힘</small>
                        }
                    </div>
                    {
                        this.state[`isEdit_${index}`] === true ? 
                        <div onClick={()=>this.showEdit(`isEdit_${index}`,false)} className="d-flex justify-content-start align-items-start pr-3" data-toggle="collapse" data-target={"#collapseExample" + (index+1)} aria-expanded="false" aria-controls={"collapseExample"+ (index+1)}>
                            <FontAwesomeIcon icon={faTimes} size="lg" style={{color:"grey"}}/>
                        </div>
                        :
                        <div className="result--widget">
                            <FontAwesomeIcon onClick={()=>this.showEdit(`isEdit_${index}`,true)} icon={faPen} size="2x" style={{marginRight:"10px", width:"18px", color:"grey"}}  data-toggle="collapse" data-target={"#collapseExample" + (index+1)} aria-expanded="false" aria-controls={"collapseExample"+ (index+1)}/>
                            <FontAwesomeIcon icon={faChartBar} size="2x" style={{marginRight:"10px", width:"23px", color:"grey"}}/>
                            {
                                test.done === "Off" ?
                                <FontAwesomeIcon onClick={()=>this.updateRec(test.recommendationName, "On")} icon={faPlay} size="lg" style={{marginRight:"10px", width:"20px", height:"20px", color:"grey"}}/>
                                :
                                <FontAwesomeIcon onClick={()=>this.updateRec(test.recommendationName, "Off")} icon={faPause} size="lg" style={{marginRight:"10px", width:"20px", height:"20px", color:"grey"}}/>
                            }
                            <FontAwesomeIcon onClick={()=>this.deleteRec(test.recommendationName)} icon={faTrashAlt} size="lg" style={{marginRight:"10px", height:"25px", width:"17px", color:"grey"}}/>
                        </div>
                    }
                </div>
                <div className="collapse" id={"collapseExample" + (index+1)} >
                    <div className="card card-body m-0">
                        {/* <div className="d-flex mb-2" style={{}}>
                            <span className="w-50 font-weight-bold">&nbsp;</span>
                            <span className="w-25 font-weight-bold">데이터</span>
                            <span className="w-25 font-weight-bold pl-1">설정사항</span>
                        </div> */}
                        <div className="d-flex mb-2">
                            <ExpandDoughnut one={30} two={40} three={20} />
                            <div className="d-flex flex-column">
                                <div className="d-flex">
                                    <div className="d-flex flex-column" style={{width:"40%"}}>
                                        <div className="font-weight-bold">데이터</div>
                                        <div className="d-flex">
                                            <p className="font-weight-bold" style={{width:"100px"}}>추천뷰수:</p>
                                            <span>2233123</span>
                                        </div>
                                        <div className="d-flex">
                                            <p className="font-weight-bold" style={{width:"100px"}}>클릭횟수:</p>
                                            <span>1111002</span>
                                        </div>
                                        <div className="d-flex">
                                            <p className="font-weight-bold" style={{width:"100px"}}>구매횟수:</p>
                                            <span>48.8%</span>
                                        </div>
                                        <div className="d-flex">
                                            <p className="font-weight-bold" style={{width:"100px"}}>매출액:</p>
                                            <span>$12,354</span >
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column" style={{width:"60%"}}>
                                        <div className="font-weight-bold">설정사항</div>
                                        <div className="d-flex">
                                            <p className="font-weight-bold" style={{width:"100px"}}>알고리즘:</p>
                                            <span>{test.algorithm}</span>
                                        </div>
                                        <div className="d-flex">
                                            <p className="font-weight-bold" style={{width:"100px"}}>설정대상:</p>
                                            <span>{result}</span>
                                        </div>
                                        <div className="d-flex">
                                            <p className="font-weight-bold" style={{width:"100px"}}>추천위치:</p>
                                            <span>{test.where["pageType"].map((where)=> {return where + " "})}</span>
                                        </div>
                                        <div className="d-flex">
                                            <p className="font-weight-bold" style={{width:"100px"}}>상품군:</p>
                                            <span>{test.categories.map((cate, index) => {if(index < 4){return cate + " "}else{return "."}})}</span >
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <small className="font-italic">
                                        위 상품은 클릭률 대비 구매전환율이 다른 상품들보다 높게 측정되므로 적절한 추천 상품이라고 판단됩니다. {<br/>}
                                        계속해서 추천상품을 사용하실 것을 권장합니다.
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    
    render(){
        const { recommendations } = this.props

        return (
            <>
            <div className="ab--result--progress">
                <Navbar type="2" title ="Recommendations" title2 = "Result" />
            </div>
            <div className="ab--result--title">
                <h4 style={{}}>Recommendations Widget</h4>
                <h5 style={{}}>____________________________</h5>
                <h6 style={{}}>please check recommendations</h6>
            </div>
            {
                recommendations.length === 0 ?
                <div className="w-100 d-flex justify-content-center mt-5 h3">추천 상품이 존재하지 않습니다</div>
                :
                <div className="ab--result--con">
                    <div className="ab--con--line">
                        <div className="ab--line"></div>
                        <div className="ab--circle"><FontAwesomeIcon className="ab--default--font" icon={faPlus} size="sm"/></div>
                        <div className="ab--line2"></div>
                        <div className="ab--circle2">
                            <FontAwesomeIcon className={this.state[`isEdit_0`] === true ? "ab--select--font" : "ab--default--font"} icon={faUserCircle} size="2x"/>
                            <div className="ab--line3"></div>
                        </div>
                        {
                            recommendations.map((test, index)=> this.renderLine(index))
                        }
                    </div>
                    <div className="ab--container" >
                        {
                            recommendations.map((test, index) => this.renderBody(test,index))
                        }
                    </div>
                </div>
            }
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        email: state.authentication.status.userEmail,
        host: state.authentication.status.userHost,
        recommendations: state.condition.recommendations,
        selectedHost: state.authentication.status.selectedHost,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getRecommendation: (email, host) => {
            return dispatch(getRecommendation(email, host))
        },
        deleteRecommendation: (email, host, deleteRecName) => {
            return dispatch(deleteRecommendation(email, host, deleteRecName))
        },
        updateRecommendation: (email, host, updateRecName, updateSet) => {
            return dispatch(updateRecommendation(email, host, updateRecName, updateSet))
        },
        changePage: (pageName) => {
            return dispatch(changePage(pageName))
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecResult);