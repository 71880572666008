const initialState = {
    sum: {
        purchaseChangeRate : 0,
        totalPrice: 0,
        purchaseCount: 0,
        clickRate: 0,
        clickCount: 0
    },
    dataPerDate: {
        purchaseChangeRate : [0,0,0,0,0,0,0],
        totalPrice: [0,0,0,0,0,0,0],
        purchaseCount: [0,0,0,0,0,0,0],
        clickRate: [0,0,0,0,0,0,0],
        clickCount: [0,0,0,0,0,0,0],
    },
    chartDate: ["11-01", "11-02", "11-03", "11-04", "11-05", "11-06", "11-06"],
    table: {
        purchaseChangeRate: [],
        totalPrice: [],
        clickRate: [],
        clickCount: [],
        apiCall: [],
    }
};
 
export default function authentication(state = initialState, action) {
  switch(action.type) {
    case "GET_REC_RESULTS":
        if(action.results){
            var { date, clickCount, purchaseCount, totalPrice, purchaseChangeRate, clickRate, sum } = action.results
        }
        return {
            ...state,
            sum: {
                ...state.sum,
                purchaseChangeRate : sum.purchaseChangeRate,
                totalPrice: sum.totalPrice,
                purchaseCount: sum.purchaseCount,
                clickRate: sum.clickRate,
                clickCount: sum.clickCount
            },
            dataPerDate: {
                ...state.dataPerDate,
                purchaseChangeRate : purchaseChangeRate,
                totalPrice: totalPrice,
                purchaseCount: purchaseCount,
                clickRate: clickRate,
                clickCount: clickCount
            },
            chartDate: date
        }
    case "GET_PURCHASE_CHANGE_RATE" :
        return {
            ...state,
            table: {
                ...state.table,
                purchaseChangeRate: action.results
            }
        }
    case "GET_TOTAL_PRICE" :
        if(action.results){
            console.log("total result", action.results)
        }
        return{
            ...state,
            table: {
                ...state.table,
                totalPrice: action.results
            }
        }
    case "GET_CLICK_RATE":
        if(action.results){
            console.log("click rate", action.results)
        }
        return{
            ...state,
            table: {
                ...state.table,
                clickRate: action.results
            }
        }
    case "GET_CLICK_COUNT" :
        if(action.results){
            console.log("click count", action.results)
        }
        return {
            ...state,
            table: {
                ...state.table,
                clickCount: action.results
            }
        }
    case "GET_API_CALL":
        if(action.results){
            console.log("api call", action.results)
        }
        return {
            ...state,
            table: {
                ...state.table,
                apiCall: action.results
            }
        }
    case "GET_REC2_RESULTS":
        return {
            ...state
        }
    default:
      return state;
  }
}