import React, {Component} from "react"
// import "./Summary.css"
import { faSyncAlt, faSearchDollar, faCartArrowDown, faMousePointer } from "@fortawesome/free-solid-svg-icons"
import Chart from "chart.js"
import { connect } from "react-redux"
import DateTimer_rec from "../../../components/DateTimer_rec"
import Minibox2 from "../../../components/chart/MiniBox2"
import Card from "../../../components/chart/Card"
import Loading from "../../../components/Loading"
import { getRecResults } from '../../../store/actions/recommendation'
import { recSummaryLine } from '../../../datas/chartjs/line'
import moment from "moment"

class Summary extends Component{
    constructor(props){
        super(props)
        let now = new Date();
        let today = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0,0,0,0));
        let start = moment(today).subtract(7, "days");
        let end = moment(today).add(1, "days").subtract(1, "seconds");
        this.state ={
            loading: false,
            dates: [start, end],
            chartDate: this.props.chartDate,
            config1: recSummaryLine,
            status: this.props.status,
            datasets: {},
            selectedDatasets: ["purchaseChangeRate"],
        }
    }

    componentDidMount(){
        // var showZeroPlugin = {
        //     beforeRender  : function (chartInstance) {
        //         var datasets = chartInstance.config.data.datasets;
        
        //         for (var i = 0; i < datasets.length; i++) {
        //             var meta = datasets[i]._meta;
        //             // It counts up every time you change something on the chart so
        //             // this is a way to get the info on whichever index it's at
        //             var metaData = meta[Object.keys(meta)[0]];
        //             var bars = metaData.data;
        
        //             for (var j = 0; j < bars.length; j++) {
        //                 var model = bars[j]._model;

        //                 if (metaData.type === "horizontalBar" && model.base === model.x) {
        //                     model.x = model.base + 2;
        //                 } 
        //                 if (model.base === model.y) {
        //                     model.y = model.base - 2;
        //                 }
        //                 if ((model.base - model.y)<3) {
        //                     model.y = model.base - 3;
        //                 }
        //             }
        //         }
        //     }
        // };
        // Chart.pluginService.register(showZeroPlugin);
        var ctx1 = document.getElementById("chart-1");
        ctx1.style.height = `${window.innerHeight-400}px`
        var chart_1 = new Chart(ctx1, this.state.config1)
        this.setState({chart_1})
    }

    componentDidUpdate(){
        if(this.state.status !== this.props.status){
            this.setState({status: this.props.status})
            this.setDates(this.state.dates)
        }
    }

    setDates = (dates) => {
        this.setState({dates})
        if(this.state.status.isLoggedIn){
            this.props.getRecResults(dates, this.props.status)
            .then((results)=> {
                console.log("rec summary results", results)
                var config = this.state.config1
                if(results){

                }
                config.data.labels = results.date
                var datasets ={
                    purchaseChangeRate: {
                        backgroundColor: "#17a2b8",
                        borderColor: "#17a2b8",
                        data: results.purchaseChangeRate,
                        label: '구매전환율',
                        type: "line",
                        yAxisID: 'rate',
                        fill: false,
                        order: 1
                    },
                    clickRate: {
                        backgroundColor: "rgba(255, 0, 230, 0.9)",
                        borderColor: "rgba(255, 0, 230, 0.9)",
                        data: results.clickRate,
                        label: '클릭률',
                        type: "line",
                        yAxisID: 'rate',
                        fill: false,
                        order: 2
                    },
                    clickCount: {
                        backgroundColor: "rgba(20, 20, 255, 0.7)",
                        borderColor: "rgba(20, 20, 255, 0.5)",
                        data: results.clickCount,
                        label: '클릭수',
                        type: "bar",
                        yAxisID: 'count',
                        order: 3,
                    },
                    purchaseCount: {
                        backgroundColor: "rgba(20, 255, 20, .9)",
                        borderColor: "rgba(20, 255, 20, 0.5)",
                        data: results.purchaseCount,
                        label: '구매수',
                        type: "bar",
                        yAxisID: 'count',
                        order: 3,
                        // minBarLength: 50,
                    },
                    totalPrice: {
                        backgroundColor: "rgba(255, 20, 20, 0.7)",
                        borderColor: "rgba(255, 20, 20, 0.5)",
                        data: results.totalPrice,
                        label: '매출액',
                        type: "bar",
                        yAxisID: "total",
                        stack: "123",
                        order: 3
                    }
                }
                this.setState({
                    config1: config,
                    datasets: datasets,
                })
                this.changeChart()
            })
        }
    }

    changeChart = (name) => {
        var { config1, datasets, selectedDatasets } = this.state
        var copyDatasets = JSON.parse(JSON.stringify(datasets))
        if(name){
            var eName = ""
            if(name === "구매전환율"){
                eName = "purchaseChangeRate"
            } else if (name === "매출액"){
                eName = "totalPrice"
            } else if (name === "구매수"){
                eName = "purchaseCount"
            } else if (name === "클릭률"){
                eName = "clickRate"
            } else if (name === "클릭수"){
                eName = "clickCount"
            }
            if(selectedDatasets.indexOf(eName) !== -1){
                selectedDatasets.splice(selectedDatasets.indexOf(eName), 1)
            } else {
                selectedDatasets.push(eName)
            }
            selectedDatasets.sort((a,b)=> a > b ? -1 : 1)
            if(selectedDatasets.indexOf("totalPrice") !== -1){
                var index = selectedDatasets.indexOf("totalPrice")
                var temp = selectedDatasets[index]
                selectedDatasets.splice(index, 1)
                selectedDatasets.push(temp)
            }
        }
        var yAxes = []
        if(selectedDatasets.indexOf("purchaseChangeRate") !== -1 || selectedDatasets.indexOf("clickRate") !== -1){
            yAxes.push({
                id: "rate",
                ticks: {
                    min: 0,
                    max: 100
                },
                stacked: false,
                display:false
            })
        }
        if(selectedDatasets.indexOf("clickCount") !== -1 && selectedDatasets.indexOf("purchaseCount") !== -1){
            var clickCountData = copyDatasets["clickCount"].data
            var maxClickCount = Math.max(...clickCountData)
            var minClickCount = Math.min(...clickCountData)
            var purchaseCountData = copyDatasets["purchaseCount"].data
            var maxPurchase = Math.max(...purchaseCountData)
            if(maxPurchase > 0){
                console.log("here@@", purchaseCountData)
                var len = String(maxClickCount).length;
                var max = 0
                var stepSize = 0
                
                if(len === 2){
                    max = Math.ceil(maxClickCount / 10) * 10
                    stepSize = (max / 10)
                }
                if(len > 2){
                    max = Math.ceil(maxClickCount/(Math.pow(10, len-2))) * Math.pow(10,len-2)
                    stepSize = (max / 10)
                }
                
                var limitPurchase = stepSize * 4  
                var ratio = limitPurchase / maxPurchase
                
                var newPurchaseCountData = []
                for(var q = 0; q < purchaseCountData.length; q++){
                    newPurchaseCountData.push(purchaseCountData[q] * ratio)
                }
    
                var newClickCountData = []
                for(q = 0; q < clickCountData.length; q++){
                    if(clickCountData[q] < (max/2) && clickCountData[q] > maxPurchase){
                        newClickCountData.push(limitPurchase + (clickCountData[q] / 5))
                    } else if (clickCountData[q] <= maxPurchase){
                        newClickCountData.push(clickCountData[q] * ratio)
                    } else {
                        newClickCountData.push(clickCountData[q] - newPurchaseCountData[q])
                    }
                }  
    
                copyDatasets["clickCount"].data = newClickCountData
                copyDatasets["purchaseCount"].data = newPurchaseCountData
    
                yAxes.push({
                    id: "count",
                    title: "건 수",
                    stacked: true,
                    type: "linear",
                    ticks: {
                        max: max,
                        maxTicksLimit: 11,
                        stepSize: stepSize,
                        fixedStepSize: stepSize,
                        callback: function(label, index, labels) {
                            console.log(label, index, labels)
                            // if (label < ) {
                            //     console.log(label, index)
                            // }
                            if(index === 5){
                                return "······"
                            }
                            if(index === 6){
                                return maxPurchase + "건";
                            }
                            if(index === 8){
                                if((Math.floor(maxPurchase / 2) === (maxPurchase/2))){
                                    return maxPurchase / 2 + "건"; 
                                } else {
                                    return ;
                                }
                            }
                            if(index % 2 === 0){
                                return label + "건";
                            }
       
                        },
                    }
                })
            } else {
                yAxes.push({
                    id: "count",
                    title: "건 수",
                    stacked: true,
                    type: "linear",
                    ticks: {
                        callback: function(label, index, labels) {
                            if(parseInt(label) === label){
                                return label + "건";
                            }
                        },
                    }
                })
            }
        } else if (selectedDatasets.indexOf("clickCount") !== -1) {
            yAxes.push({
                id: "count",
                title: "건 수",
                stacked: true,
                type: "linear",
                ticks: {
                    callback: function(label, index, labels) {
                        if(parseInt(label) === label){
                            return label + "건";
                        }
                    },
                }
            })
        } else if (selectedDatasets.indexOf("purchaseCount") !== -1){
            yAxes.push({
                id: "count",
                title: "건 수",
                stacked: true,
                type: "linear",
                ticks: {
                    callback: function(label, index, labels) {
                        if(parseInt(label) === label){
                            return label + "건";
                        }
                    },
                }
            })
        }


        if(selectedDatasets.indexOf("totalPrice") !== -1){
            yAxes.push({
                id: "total",
                stacked: false,
                position: "right",
                ticks: {
                    callback: function(label, index, labels){
                        return label.toLocaleString() + "원"
                    }
                }
            })
        }

        config1.options.scales.yAxes = yAxes

        var checkIsRateChart = false
        if(selectedDatasets.length === 2){
            if(selectedDatasets.indexOf("purchaseChangeRate") !== -1){
                if(selectedDatasets.indexOf("clickRate") !== -1){
                    checkIsRateChart = true
                }
            }
        }
        if(selectedDatasets.length === 1){
            if(selectedDatasets.indexOf("purchaseChangeRate") !== -1){
                checkIsRateChart = true
            } else if (selectedDatasets.indexOf("clickRate") !== -1){
                checkIsRateChart = true
            }
        }

        var obj = []
        for(var i = 0; i < selectedDatasets.length; i++){
            obj.push(copyDatasets[selectedDatasets[i]])
        }
        config1.data.datasets = obj
        this.setState({
            config1: config1,
            selectedDatasets: selectedDatasets,
        })
    }

    render(){
        const { sum } = this.props
        const { selectedDatasets } = this.state
        if(this.state.chart_1){
            this.state.chart_1.update()
        }
        return(
            <div className="content pl-2 pr-2">
                <Loading loading={this.state.loading}/>
                <div className="d-flex row w-100 m-0 mb-2 mt-2 pl-2 pr-2">
                    <h5 className="content--header col bg-light p-2 ml-2"></h5>
                    <DateTimer_rec setDates={(dates) => this.setDates(dates)}/>
                </div>
                <div className="row w-100 m-0">
                    <Minibox2 onSubmit={(name)=>this.changeChart(name)} color={selectedDatasets.indexOf("purchaseChangeRate") !== -1 ? "bg-info" : "bg-light"} icon={faSyncAlt} name={"구매전환율"} subname={" %"} data={sum.purchaseChangeRate} />
                    <Minibox2 onSubmit={(name)=>this.changeChart(name)} color={selectedDatasets.indexOf("totalPrice") !== -1 ? "bg-info" : "bg-light"} icon={faSearchDollar} name={"매출액"} subname={" 원"} data={sum.totalPrice.toLocaleString()} />
                    <Minibox2 onSubmit={(name)=>this.changeChart(name)} color={selectedDatasets.indexOf("purchaseCount") !== -1 ? "bg-info" : "bg-light"} icon={faCartArrowDown} name={"구매수"} subname={" 건"} data={sum.purchaseCount.toLocaleString()} />
                    <Minibox2 onSubmit={(name)=>this.changeChart(name)} color={selectedDatasets.indexOf("clickRate") !== -1 ? "bg-info" : "bg-light"} icon={faMousePointer} name={"클릭률"} subname={" %"} data={sum.clickRate} />
                    <Minibox2 onSubmit={(name)=>this.changeChart(name)} color={selectedDatasets.indexOf("clickCount") !== -1 ? "bg-info" : "bg-light"} icon={faMousePointer} name={"클릭수"} subname={" 건"} data={sum.clickCount.toLocaleString()} />
                </div>
                <div className="row w-100 m-0">
                    <div className="w-100 pl-2 pr-2">
                        <Card title="Graph chart" canvasId="chart-1" />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        status: state.authentication.status,
        sum: state.recommendation.sum,
        dataPerDate: state.recommendation.dataPerDate,
        chartDate: state.recommendation.chartDate,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getRecResults: (dates, status) => {
            return dispatch(getRecResults(dates, status));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Summary);