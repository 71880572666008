var continuousUser = [
    {group: "0번째 일", variable: "5월26일", value:"5"},
    {group: "0번째 일", variable: "5월25일", value:"5"},
    {group: "0번째 일", variable: "5월24일", value:"5"},
    {group: "0번째 일", variable: "5월23일", value:"5"},
    {group: "0번째 일", variable: "5월22일", value:"5"},
    {group: "0번째 일", variable: "5월21일", value:"5"},
    {group: "0번째 일", variable: "5월20일", value:"5"},
    {group: "0번째 일", variable: "모든 사용자", value:"100"},
    {group: "1번째 일", variable: "5월26일", value:"5"},
    {group: "1번째 일", variable: "5월25일", value:"5"},
    {group: "1번째 일", variable: "5월24일", value:"5"},
    {group: "1번째 일", variable: "5월23일", value:"5"},
    {group: "1번째 일", variable: "5월22일", value:"5"},
    {group: "1번째 일", variable: "5월21일", value:"5"},
    {group: "1번째 일", variable: "5월20일", value:"5"},
    {group: "1번째 일", variable: "모든 사용자", value:"0"},
    {group: "2번째 일", variable: "5월26일", value:"5"},
    {group: "2번째 일", variable: "5월25일", value:"5"},
    {group: "2번째 일", variable: "5월24일", value:"5"},
    {group: "2번째 일", variable: "5월23일", value:"5"},
    {group: "2번째 일", variable: "5월22일", value:"5"},
    {group: "2번째 일", variable: "5월21일", value:"5"},
    {group: "2번째 일", variable: "5월20일", value:"5"},
    {group: "2번째 일", variable: "모든 사용자", value:"0"},
    {group: "3번째 일", variable: "5월26일", value:"5"},
    {group: "3번째 일", variable: "5월25일", value:"5"},
    {group: "3번째 일", variable: "5월24일", value:"5"},
    {group: "3번째 일", variable: "5월23일", value:"5"},
    {group: "3번째 일", variable: "5월22일", value:"5"},
    {group: "3번째 일", variable: "5월21일", value:"5"},
    {group: "3번째 일", variable: "5월20일", value:"5"},
    {group: "3번째 일", variable: "모든 사용자", value:"0"},
    {group: "4번째 일", variable: "5월26일", value:"5"},
    {group: "4번째 일", variable: "5월25일", value:"5"},
    {group: "4번째 일", variable: "5월24일", value:"5"},
    {group: "4번째 일", variable: "5월23일", value:"5"},
    {group: "4번째 일", variable: "5월22일", value:"5"},
    {group: "4번째 일", variable: "5월21일", value:"5"},
    {group: "4번째 일", variable: "5월20일", value:"5"},
    {group: "4번째 일", variable: "모든 사용자", value:"10"},
    {group: "5번째 일", variable: "5월26일", value:"5"},
    {group: "5번째 일", variable: "5월25일", value:"5"},
    {group: "5번째 일", variable: "5월24일", value:"5"},
    {group: "5번째 일", variable: "5월23일", value:"5"},
    {group: "5번째 일", variable: "5월22일", value:"5"},
    {group: "5번째 일", variable: "5월21일", value:"5"},
    {group: "5번째 일", variable: "5월20일", value:"5"},
    {group: "5번째 일", variable: "모든 사용자", value:"0"},
    {group: "6번째 일", variable: "5월26일", value:"5"},
    {group: "6번째 일", variable: "5월25일", value:"5"},
    {group: "6번째 일", variable: "5월24일", value:"5"},
    {group: "6번째 일", variable: "5월23일", value:"5"},
    {group: "6번째 일", variable: "5월22일", value:"5"},
    {group: "6번째 일", variable: "5월21일", value:"5"},
    {group: "6번째 일", variable: "5월20일", value:"5"},
    {group: "6번째 일", variable: "모든 사용자", value:"0"}
]

export { continuousUser }