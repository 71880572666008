import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const MiniBox = ({color, icon, name, subname, data}) => {
    return(
        <div className="col-12 col-sm-6 col-md-3 pl-2 pr-2">
            <div className="info-box">
                <span className={"info-box-icon elevation-1 " + color}>
                <FontAwesomeIcon icon={icon}/>
                </span>
                <div className="info-box-content">
                    <span className="info-box-text">
                        {name}
                    </span>
                    <span className="info-box-number">
                        {data}
                        <small>{subname}</small>
                    </span>
                </div>
            </div>
        </div> 
    )
}

export default MiniBox