import React, {Component} from "react"
import "./PageFlow.css"
import Sankey from "../../components/d3/Sankey"
import Card from "../../components/chart/Card"
import { faSquare } from "@fortawesome/free-solid-svg-icons"
import SubTimer from "../../components/SubTimer"
import axios2 from 'axios'
import { connect } from "react-redux"


class PageFlow extends Component{
    constructor(props){
        super(props)
        // console.log("home constructor")
        this.state ={
        }
    }

    render(){
        console.log("pageflow@@: ", this.props.userEmail)
        return (
            <div className="w-100 h-100">
                <div className="d-flex justify-content-between align-items-center">
                    <h3 className="m-2">유저 행동</h3>
                    <SubTimer />
                </div>
                <div className="page--flow--container">
                    <div className="flow--header">
                        <div className="selection--seg">
                            <h5>모든 사용자</h5>
                        </div>
                        <div className="add--seg">
                            <h5>세그먼트 선택</h5>
                        </div>
                        <div>
                        <button onClick={this.getCafe24Api} type="button" class="btn btn-primary">API</button>
                        </div>
                    </div>
                    <div className="flow--body">
                        <Card title="유저 행동 흐름" canvasId="sankey" />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userEmail: state.authentication.status.userEmail
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
      return {
      };
  };
  

export default connect(mapStateToProps, mapDispatchToProps)(PageFlow);