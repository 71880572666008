import React, {Component} from "react"
import "./Dashboard.css"
import SideNavbar from "../components/SideNavbar"
import Nav from "../components/Nav"
import { connect } from "react-redux"
import Home from "./dashboard/Home"
// import ABTest from "./dashboard/ABTest"
import Recommendation from "./dashboard/Recommendation"
import RecResult from "./dashboard/RecResult"
import ABCreate from "./dashboard/ABCreate"
import ABResult from "./dashboard/ABResult"
import ABDetail from "./dashboard/ABDetail"
import PageFlow from "./dashboard/PageFlow"
import PageView from "./dashboard/PageView"
import Summary from "./dashboard/recommendation/Summary"
import PurchaseChangeRate from "./dashboard/recommendation/PurchaseChangeRate"
import TotalPrice from "./dashboard/recommendation/TotalPrice"
import ClickRate from "./dashboard/recommendation/ClickRate"
import ClickCount from "./dashboard/recommendation/ClickCount"
import ApiCall from "./dashboard/recommendation/ApiCall"
import Custom from "./dashboard/Custom"
import { Route } from "react-router-dom"
import { getProfileFetch } from '../store/actions/authentication'
import Summary2 from "./dashboard/recommendation/Summary2"
import PageName from "./dashboard/PageName"


class Dashboard extends Component{
    constructor(props){
        super(props)
        this.state ={
            dashboardPage: "",
            intervalId:[]
        }
        this.checkLogin()
    }

    checkLogin = () => {
        if(!localStorage.getItem("token")){
            setTimeout(()=> {
                this.props.history.push('/login')
            }, 700)
        }
    }

    componentDidUpdate(){
        //대시보드 홈에서 벗어나면 실시간 종료해주기
        if(localStorage.getItem("dashboard_page")!==""){
            if(this.props.intervalId[0]){
                console.log("delete interval")
                clearInterval(this.props.intervalId[0])
            }
        }

        //대시보드 안에서 페이지 바뀔 때마다 토큰 살아있는지 확인
        if(this.state.dashboardPage !== localStorage.getItem("dashboard_page")){
            this.setState({dashboardPage: localStorage.getItem("dashboard_page")})
            this.props.getProfileFetch()
            this.checkLogin()
        }
    }

    render(){
        const { match } = this.props
        // console.log("dashboard render", this.props.status.currentUser)
        return(
            <div className="dashboard--container col-12 col-sm-12 bg-light" style={{minHeight:window.innerHeight}}>
                <Route path={`${match.path}`} render={props => <SideNavbar props={props}/>}/>
                <div className="col p-0 content--wrapper">
                    <Route path={`${match.url}`} render={props => <><Nav/><Home props={props} setInterval={(intervalId)=>this.setInterval(intervalId)} /></>}exact={true} /> 
                    <Route path={`${match.url}/recommendation`} component={RecResult} exact={true}/>
                    <Route path={`${match.url}/createRecommendation`} render={props => <Recommendation props={props}/>} exact={true}/>
                    <Route path={`${match.url}/abSetting`} render={props => <ABCreate props={props} />} exact={true}/>
                    <Route path={`${match.url}/abShowing`} render={props => <ABResult props={props} />} exact={true}/>
                    <Route path={`${match.url}/abDetail`} component={ABDetail} exact={true}/>
                    <Route path={`${match.url}/pageFlow`} render={props => <><Nav noUseBtn={true}/><PageFlow props={props} setInterval={(intervalId)=>this.setInterval(intervalId)} /></>} exact={true}/>
                    <Route path={`${match.url}/pageView`} render={props => <><Nav isDetail={["사용자 방문 페이지 파악", "pageview detail"]} noUseBtn={true}/><PageView props={props} /></>} exact={true}/>
                    <Route path={`${match.url}/summary`} render={props => <><Nav isDetail={["추천상품 요약", "Recommendation Summary"]} noUseBtn={true}/><Summary props={props} /></>} exact={true}/>
                    <Route path={`${match.url}/purchaseChangeRate`} render={props => <><Nav isDetail={["구매전환율 TOP 100", ""]} noUseBtn={true}/><PurchaseChangeRate props={props} /></>} exact={true}/>
                    <Route path={`${match.url}/totalPrice`} render={props => <><Nav isDetail={["매출액 TOP 100", ""]} noUseBtn={true}/><TotalPrice props={props} /></>} exact={true}/>
                    <Route path={`${match.url}/clickRate`} render={props => <><Nav isDetail={["클릭률 TOP 100", ""]} noUseBtn={true}/><ClickRate props={props} /></>} exact={true}/>
                    <Route path={`${match.url}/clickCount`} render={props => <><Nav isDetail={["클릭수 TOP 100", ""]} noUseBtn={true}/><ClickCount props={props} /></>} exact={true}/>
                    <Route path={`${match.url}/apiCall`} render={props => <><Nav isDetail={["API 콜 수 TOP 100", ""]} noUseBtn={true}/><ApiCall props={props} /></>} exact={true}/>
                    <Route path={`${match.url}/custom`} render={props => <><Nav isDetail={["Custom Page", ""]} noUseBtn={true}/><Custom props={props} /></>} exact={true}/>
                    <Route path={`${match.url}/summary2`} render={props => <><Nav isDetail={["추천상품 요약", "Recommendation Summary"]} noUseBtn={true}/><Summary2 props={props} /></>} exact={true}/>
                    <Route path={`${match.url}/pageName`} render={props => <><Nav isDetail={["페이지 이름 설정", "Page naming"]} noUseBtn={true}/><PageName props={props} /></>} exact={true}/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        pageName: state.authentication.page.pageName,
        login: state.authentication.login,
        email: state.authentication.status.email,
        intervalId: state.chart.intervalId
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getProfileFetch: () => dispatch(getProfileFetch())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);