import React, {Component} from "react"
import { connect } from "react-redux"
import "./PageView.css"
import DateTimer from "../../components/DateTimer"
import Card from "../../components/chart/Card"
import { getCafe24Products, getGodomallProducts, getGodomallProductsAll } from '../../store/actions/authentication';
import Loading from "../../components/Loading"
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
const { SearchBar } = Search;
class PageFlow extends Component{
    constructor(props){
        super(props)
        // console.log("home constructor")
        this.state ={
            pageView: this.props.userData.page_view,
            fakePageView: this.props.userData.page_view,
            loading: true,
            status: this.props.status,
            test: [
                {name: "test1", count: 1},
                {name: "test2", count: 2}
            ]
        }
    }

    componentDidUpdate(){
        // pagenation a tag event prevent
        if(document.getElementsByClassName("react-bs-table-sizePerPage-dropdown")[0]){
            var drops = document.getElementsByClassName("react-bs-table-sizePerPage-dropdown")[0]
            var aTag = drops.childNodes[1].childNodes
            for(var i = 0; i < aTag.length; i++){
                aTag[i].setAttribute("href", "/#/dashboard/pageView")
            }
        }

        const { platformType } = this.props

        // pageView URL이름과 상품 번호, 카테고리 번호, 게시판 번호를 이름으로 바꾸기
        if(this.props.userData.page_view !== this.state.fakePageView){
            console.log("here", this.props.userData.page_view)
            this.setState({fakePageView: this.props.userData.page_view})
            if(platformType){
                // console.log("check is godomall all", this.props.godomall.products.length)
                if(platformType === "cafe24"){
                    this.changeCafe24ProductsNumberToName(this.props.userData.page_view, "pageView")
                } else if (platformType === "godomall"){
                    this.changeGodomallGoodsNumberToName(this.props.userData.page_view, "pageView")
                } else {
                    if(this.props.local.pathInclude[0]){
                        this.changeLocalGoodsNumberToName(this.props.userData.page_view, "pageView")
                    } else {
                        this.setState({
                            loading: false
                        })
                    }
                }
            }
        }
    }

    changeCafe24ProductsNumberToName = (UPP, type) => {
        var productsNumber = "product_no="
        var userPerPage = JSON.parse(JSON.stringify(UPP.slice(0,100)));
        var newUPP = []
        for(var i = 0; i < userPerPage.length; i++){
            newUPP[i] = userPerPage[i].name.split("?")
            if(newUPP[i][1]){
                var param = new URLSearchParams(newUPP[i][1])
                if(param.get("product_no")){
                    productsNumber += (param.get("product_no") + ",")
                    newUPP[i][2] = parseInt(param.get("product_no"))
                }
                if(param.get("cate_no")){
                    newUPP[i][3] = parseInt(param.get("cate_no"))
                }
                if(param.get("keyword")){ //검색 키워드
                    newUPP[i][4] = ("/" + param.get("keyword"))
                }
                if(param.get("board_no")){
                    newUPP[i][5] = parseInt(param.get("board_no"))
                }
            }
        }
        console.log("newUPP", newUPP)
        // location.search 에서 product_no가 있을 경우!
        var products = []
        this.props.getCafe24Products(this.props.cafe24, productsNumber)
        .then((response)=> {
            products = response.products
            if(products){
                for(var j = 0; j < newUPP.length; j++){
                    //pathname 바꾸기
                    if(this.props.cafe24.urlName[newUPP[j][0]] != null){
                        newUPP[j][0] = this.props.cafe24.urlName[newUPP[j][0]]
                    } else {
                        const {pathInclude} = this.props.cafe24
                        for(var k = 0; k < pathInclude.length; k++){
                            if(newUPP[j][0].indexOf(pathInclude[k].url) > -1){
                                newUPP[j][0] = newUPP[j][0].replace(pathInclude[k].url, pathInclude[k].name)
                                break;
                            }
                        }
                    }
                    //search 바꾸기
                    if(newUPP[j][2]){ //search에서 product_no를 이름으로바꾸기
                        for(var z = 0; z < products.length; z++){
                            if(newUPP[j][2] === products[z].product_no){
                                newUPP[j][0] += ("/"+products[z].product_name);
                                break;
                            }
                        }
                    } else if (newUPP[j][4]){// keyword 붙이기
                        newUPP[j][0] += newUPP[j][4]
                    }

                    if(newUPP[j][3]){ //search에서 cate_no를 이름으로 바꾸기
                        for(z = 0; z < this.props.cafe24.categories.length; z++){
                            if(newUPP[j][3] === this.props.cafe24.categories[z].category_no){
                                newUPP[j][0] += ("/"+this.props.cafe24.categories[z].category_name);
                                break;
                            }
                        }
                    } else if (newUPP[j][5]){ //search에서 board_no를 이름으로 바꾸기
                        for(z = 0; z < this.props.cafe24.boards.length; z++){
                            if(newUPP[j][5] === this.props.cafe24.boards[z].board_no){
                                newUPP[j][0] += ("/"+this.props.cafe24.boards[z].board_name);
                                break;
                            }
                        }
                    }
                    userPerPage[j].name = newUPP[j][0]
                }

                // no -> name 변경 후 재정렬
                userPerPage.sort((a,b) => a.name > b.name ? -1 : 1)
                for(i = userPerPage.length-2; i > -1; i--){
                    if(userPerPage[i].name === userPerPage[i+1].name){
                        userPerPage[i].count = parseInt(userPerPage[i].count) + parseInt(userPerPage[i+1].count)
                        userPerPage.splice(i+1, 1)
                    }
                }
                userPerPage.sort((a,b) => parseInt(b.count) - parseInt(a.count)) 

                // 체류시간, 이탈률 구하기
                for(i = 0; i < userPerPage.length; i++){
                    if(userPerPage[i].stayTime && userPerPage[i].pageCount){
                        userPerPage[i].complete_run = Math.round((userPerPage[i].run / userPerPage[i].distinctCount) * 10000) / 100
                        userPerPage[i].complete_stayTime = new Date(userPerPage[i].stayTime / userPerPage[i].pageCount).toISOString().slice(11, 19)

                    }
                }

                userPerPage = userPerPage.filter((page, index) => {
                    if(page.complete_stayTime){
                        return page
                    }
                })
            }
            if(type === "real"){
                this.setState({
                    realTimeUserPerPage: userPerPage,
                })
            } else if (type ==="pageView"){
                userPerPage.forEach(page => {
                    page.name = decodeURI(page.name)
                    // page.count = page.count.toLocaleString();
                })
                this.setState({
                    pageView: userPerPage,
                    loading: false
                })
            }
        })
        
    }

    changeGodomallGoodsNumberToName = (UPP, type) => {
        var userPerPage = JSON.parse(JSON.stringify(UPP));
        var newUPP = []
        var productsNumber = []
        console.log("start 1", new Date().getMinutes(), " // ", new Date().getSeconds())
        for(var i = 0; i < userPerPage.length; i++){
            newUPP[i] = userPerPage[i].name.split("?")
            if(newUPP[i][1]){
                var param = new URLSearchParams(newUPP[i][1])
                if(param.get("goodsNo")){
                    productsNumber.push(param.get("goodsNo"))
                    newUPP[i][2] = param.get("goodsNo")
                }
                if(param.get("cateCd")){
                    newUPP[i][3] = param.get("cateCd")
                }
                if(param.get("keyword")){ //검색 키워드
                    newUPP[i][4] = ("/" + param.get("keyword"))
                }
                if(param.get("bdId")){
                    newUPP[i][5] = param.get("bdId")
                }
            }
        }
        console.log("test11", productsNumber)
        console.log("start 2", new Date().getMinutes(), " // ", new Date().getSeconds())
        // this.props.getGodomallProducts(this.props.godomall, productsNumber)
        // console.log("this.status", this.props.status)
        this.props.getGodomallProductsAll(this.props.status)
        .then((response)=> {
            console.log("response", response)
            // console.log("start 3", new Date().getMinutes(), " // ", new Date().getSeconds())
            for(var j = 0; j < newUPP.length; j++){
                //pathname 바꾸기
                if(this.props.godomall.urlName[newUPP[j][0]] != null){
                    newUPP[j][0] = this.props.godomall.urlName[newUPP[j][0]]
                }
                //search 바꾸기
                if(newUPP[j][2]){ //search에서 product_no를 이름으로바꾸기
                    for(var z = 0; z < response.length; z++){
                        if(newUPP[j][2] === response[z].product_id){
                            newUPP[j][0] += ("/"+response[z].name);
                            break;
                        }
                    }
                } else if (newUPP[j][4]){// keyword 붙이기
                    newUPP[j][0] += newUPP[j][4]
                }

                if(newUPP[j][3]){ //search에서 cate_no를 이름으로 바꾸기
                    for(z = 0; z < this.props.godomall.categories.length; z++){
                        if(newUPP[j][3] === this.props.godomall.categories[z].cateCd[0]){
                            newUPP[j][0] += ("/"+this.props.godomall.categories[z].cateNm[0]);
                            break;
                        }
                    }
                } else if (newUPP[j][5]){ //search에서 board_no를 이름으로 바꾸기
                    for(z = 0; z < this.props.godomall.boards.length; z++){
                        if(newUPP[j][5] === this.props.godomall.boards[z].bdId[0]){
                            newUPP[j][0] += ("/"+this.props.godomall.boards[z].bdNm[0]);
                            break;
                        }
                    }
                }
                userPerPage[j].name = newUPP[j][0]
            }
            console.log("test4", userPerPage)
            // number -> name 변경 후 재정렬
            userPerPage.sort((a,b) => a.name > b.name ? -1 : 1)
            for(i = userPerPage.length-2; i > -1; i--){
                if(userPerPage[i].name === userPerPage[i+1].name){
                    userPerPage[i].count = parseInt(userPerPage[i].count) + parseInt(userPerPage[i+1].count)
                    userPerPage[i].pageCount = (userPerPage[i].pageCount ? userPerPage[i].pageCount : 0) + (userPerPage[i+1].pageCount ? userPerPage[i+1].pageCount : 0)
                    userPerPage[i].firstVisit = (userPerPage[i].firstVisit ? userPerPage[i].firstVisit : 0) + (userPerPage[i+1].firstVisit ? userPerPage[i+1].firstVisit : 0)
                    userPerPage[i].run = (userPerPage[i].run ? userPerPage[i].run : 0) + (userPerPage[i+1].run ? userPerPage[i+1].run : 0)
                    userPerPage[i].stayTime = (userPerPage[i].stayTime ? userPerPage[i].stayTime : 0) + (userPerPage[i+1].stayTime ? userPerPage[i+1].stayTime : 0)
                    userPerPage[i].distinctCount = (userPerPage[i].distinctCount ? userPerPage[i].distinctCount : 0) + (userPerPage[i+1].distinctCount ? userPerPage[i+1].distinctCount : 0)
                    userPerPage.splice(i+1, 1)
                }
            }
            userPerPage.sort((a,b) => parseInt(b.count) - parseInt(a.count))

            // 체류시간, 이탈률 구하기
            for(i = 0; i < userPerPage.length; i++){
                if(userPerPage[i].stayTime && userPerPage[i].pageCount){
                    userPerPage[i].complete_run = Math.round((userPerPage[i].run / userPerPage[i].distinctCount) * 10000) / 100
                    userPerPage[i].complete_stayTime = new Date(userPerPage[i].stayTime / userPerPage[i].pageCount).toISOString().slice(11, 19)

                }
            }

            userPerPage = userPerPage.filter((page, index) => {
                if(page.complete_stayTime){
                    return page
                }
            })

            if(type === "real"){
                this.setState({
                    realTimeUserPerPage: userPerPage,
                })
            } else if (type ==="pageView"){
                console.log("test5", userPerPage)
                // userPerPage.forEach(page => {
                //     page.count = page.count.toLocaleString();
                // })
                this.setState({
                    pageView: userPerPage,
                    loading: false
                })
            }
        })

    }

    changeLocalGoodsNumberToName = (UPP, type) => {
        // var userPerPage = JSON.parse(JSON.stringify(UPP.slice(0,100)));
        var userPerPage = JSON.parse(JSON.stringify(UPP));
        var newUPP = []
        const { pathInclude, searchParam, urlName } = this.props.local
        for(var i = 0; i < userPerPage.length; i++){
            newUPP[i] = userPerPage[i].name.split("?")

            //pathName 변경
            if(urlName[newUPP[i][0]] != null){
                newUPP[i][0] = urlName[newUPP[i][0]];
            } else {
                for(var z = 0; z < pathInclude.length; z++){
                    if(newUPP[i][0].indexOf(pathInclude[z].url) > -1){
                        newUPP[i][0] = pathInclude[z].name
                    }
                }
            }

            //search 변경
            if(newUPP[i][1]){
                var param = new URLSearchParams(newUPP[i][1])
                for(var j = 0; j < searchParam.length; j++){
                    if(param.get(searchParam[j].param)){
                        newUPP[i][0] += "/" + searchParam[j].name + "-" + param.get(searchParam[j].param)
                    }
                }
            }

            userPerPage[i].name = newUPP[i][0]
        }

        // no -> name 변경 후 재정렬
        userPerPage.sort((a,b) => a.name > b.name ? -1 : 1)
        for(i = userPerPage.length-2; i > -1; i--){
            if(userPerPage[i].name === userPerPage[i+1].name){
                userPerPage[i].count = parseInt(userPerPage[i].count) + parseInt(userPerPage[i+1].count)
                userPerPage.splice(i+1, 1)
            }
        }
        userPerPage.sort((a,b) => parseInt(b.count) - parseInt(a.count)) 

        // 체류시간, 이탈률 구하기
        for(i = 0; i < userPerPage.length; i++){
            if(userPerPage[i].stayTime && userPerPage[i].pageCount){
                userPerPage[i].complete_run = Math.round((userPerPage[i].run / userPerPage[i].distinctCount) * 10000) / 100
                userPerPage[i].complete_stayTime = new Date(userPerPage[i].stayTime / userPerPage[i].pageCount).toISOString().slice(11, 19)

            }
        }
        userPerPage = userPerPage.filter((page, index) => {
            if(page.complete_stayTime){
                return page
            }
        })

        if(type === "real"){
            this.setState({
                realTimeUserPerPage: userPerPage,
            })
        } else if (type ==="pageView"){
            userPerPage.forEach(page => {
                page.count = page.count.toLocaleString();
            })
            this.setState({
                pageView: userPerPage,
                loading: false
            })
        }
    }

    render(){
        console.log("this.state.pageView", this.state.pageView)
        const columns = [{
            dataField: 'name',
            text: '페이지',
            sort: true,
            headerAlign: 'center',
            align: 'left',
            headerClasses: 'w-25'
          }, {
            dataField: 'count',
            text: '페이지뷰',
            sort: true,
            headerAlign: 'center',
            align: 'center',
            formatter: (cell, row) => {
                if(cell != null){
                    return (
                        <span>{cell.toLocaleString()}</span>
                    )
                }
            }
          }, {
            dataField: 'distinctCount',
            text: '순페이지뷰',
            sort: true,
            headerAlign: 'center',
            align: 'center',
            formatter: (cell, row) => {
                if(cell != null){
                    return (
                        <span>{cell.toLocaleString()}</span>
                    )
                }
            }
          }, {
            dataField: 'complete_stayTime',
            text: '평균 체류시간',
            sort: true,
            headerAlign: 'center',
            align: 'center',
          }, {
            dataField: 'firstVisit',
            text: '방문수',
            sort: true,
            headerAlign: 'center',
            align: 'center',
            formatter: (cell, row) => {
                if(cell != null){
                    return (
                        <span>{cell.toLocaleString()}</span>
                    )
                }
            }
          }, {
            dataField: 'complete_run',
            text: '이탈률',
            sort: true,
            headerAlign: 'center',
            align: 'center',
            formatter: (cell, row) => {
                if(cell != null){
                    return (
                        <span>{cell}%</span>
                    )
                }
            }
          }
        ];

        return(
            <div className="content pl-2 pr-2">
                <Loading loading={this.state.loading}/>
                <div className="d-flex row w-100 m-0 mb-2 mt-2 pl-2 pr-2">
                    <h5 className="content--header col bg-light p-2 ml-2"></h5>
                    <DateTimer selectFunnel="PAGE_VIEW_DETAIL"/>
                </div>
                <div className={"row w-100 m-0 mb-3 "}>
                    <div className=" col-lg-12 pl-2 pr-2 h-100">
                        <div className={"card m-0"}>
                            <div className={"card-body  table-responsive p-0"}>
                                <div className={" p-3"}>
                                    {
                                        this.state.pageView.length > 1 ?
                                        <ToolkitProvider
                                        keyField="name"
                                        data={ this.state.pageView }
                                        columns={ columns }
                                        bootstrap4
                                        search
                                        >
                                        {
                                            props => (
                                            <div>
                                                <SearchBar { ...props.searchProps } />
                                                <hr />
                                                <BootstrapTable
                                                bordered={ false }
                                                striped
                                                hover
                                                noDataIndication="Table is Empty"
                                                pagination={ paginationFactory() }
                                                { ...props.baseProps }
                                                />
                                            </div>
                                            )
                                        }
                                        </ToolkitProvider>
                                        :""
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="row w-100 m-0">
                    <div className="col-lg-12 pl-2 pr-2">
                        <Card title="사용자 방문 페이지 파악" useTable={this.state.pageView} />
                    </div>
                </div> */}
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        status: state.authentication.status,
        userData: state.chart.userData,
        platformType: state.authentication.status.platformType,
        cafe24: state.authentication.cafe24,
        godomall: state.authentication.godomall,
        local: state.authentication.local
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
      return {
        getCafe24Products: (cafe24, productsNumber) => {
            return dispatch(getCafe24Products(cafe24, productsNumber));
        },
        getGodomallProducts: (godomall, productsNumber) => {
            return dispatch(getGodomallProducts(godomall, productsNumber));
        },
        getGodomallProductsAll: (status) => {
            return dispatch(getGodomallProductsAll(status));
        },
      };
  };
  

export default connect(mapStateToProps, mapDispatchToProps)(PageFlow);