import Chart from "chart.js"

var lineChartData = {
    type: 'line',
    data: {
        labels: ["","","","","","",""],
        datasets: [{
            backgroundColor: "pink",
            borderColor: "red",
            data: [5, 10, 15, 20, 25, 30, 35],
            label: 'Dataset',
            // fill: boundary
        }]
    },
    options: Chart.helpers.merge(
        {
            maintainAspectRatio: false,
            spanGaps: false,
            elements: {
                line: {
                    tension: 0.000001
                }
            },
            plugins: {
                filler: {
                    propagate: false
                }
            },
            scales: {
                xAxes: [{
                    ticks: {
                        autoSkip: false,
                        maxRotation: 0
                    }
                }]
            }
        }, { title: {
                text: 'fill: ',
                display: false
            }
        }
    )
}


var realTimeLineData = {
    type: 'line',
    data: {
        labels: ["-29분","-28분","-27분","-26분","-25분","-24분","-23분","-22분","-21분","-20분","-19분","-18분","-17분","-16분","-15분","-14분","-13분","-12분","-11분","-10분","-9분","-8분","-7분","-6분","-5분","-4분","-3분","-2분", "-1분","현재"],
        datasets: [{
            backgroundColor: "rgba(255, 230, 0, 0.3)",
            borderColor: "rgba(125, 120, 200, 0.5)",
            data: [0,4,7,2,1,1,1,5,6,6,8,10,10,10,7,7,3,3,3,3,4,4,4,4,4,5,5,5,5,5],
            label: '분당 페이지 조회 수',
            // fill: boundary
        }]
    },
    options: Chart.helpers.merge(
        {
            maintainAspectRatio: false,
            spanGaps: false,
            elements: {
                line: {
                    tension: 0.000001
                }
            },
            plugins: {
                filler: {
                    propagate: false
                }
            },
            tooltips: {
                mode: 'index',
                intersect: false,
                callbacks: {
                    title: function(){
                        return "분당 페이지 조회 수"
                    },
                    label: function(tooltipItem, data){
                        var label = `${tooltipItem.label}: ${data.datasets[0].data[tooltipItem.index].toLocaleString()}회`
                        return label
                    },
                },
                footerFontStyle: 'normal'
            },
            scales: {
                xAxes: [{
                    ticks: {
                        autoSkip: false,
                        maxRotation: 0,
                        callback: function(dataLabel, index){
                            return index % 5 === 4 ? dataLabel : "";
                        }
                    }
                }]
            }
        }, { title: {
                text: 'fill: ',
                display: false
            }
        }
    )
}

var flowOfUserData = {
    type: 'line',
    data: {
        labels: ['5월22', '23', '24', '26', '27', '28', '29'],
        datasets: [{
            label: '일별',
            backgroundColor: "pink",
            borderColor: "pink",
            data: [
                5,
                4,
                3,
                8,
                10,
                3,
                5
            ],
            fill: true,
        }, {
            label: '주별',
            fill: false,
            backgroundColor: "blue",
            borderColor: "blue",
            data: [
                5,
                8,
                10,
                12,
                28,
                36,
                44
            ],
        }, {
            label: '월별',
            fill: false,
            backgroundColor: "green",
            borderColor: "green",
            borderDash: [5, 5],
            data: [
                35,
                42,
                55,
                64,
                29,
                36,
                44
            ],
        } ]
    },
    options: {
        responsive: true,
        title: {
            display: false,
            text: 'Flow of user'
        },
        tooltips: {
            mode: 'index',
            intersect: false,
            callbacks: {
                label: function(tooltipItem, data){
                    var label = `${data.datasets[tooltipItem.datasetIndex].label}: ${data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].toLocaleString()}명`
                    return label
                },
            },
            footerFontStyle: 'normal'
        },
        hover: {
            mode: 'nearest',
            intersect: true
        },
        scales: {
            xAxes: [{
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: 'Day'
                }
            }],
            yAxes: [{
                display: false,
                scaleLabel: {
                    display: true,
                    labelString: 'Value'
                }
            }]
        }
    }
}


var compareLineData = {
    type: 'line',
    data: {
        labels: ['7월18일', '7월19일', '7월20일', '7월21일', '7월22일', '7월23일', '7월24일'],
        datasets: [{
            label: 'Test A',
            borderColor: "rgb(255, 99, 132)",
            backgroundColor: "rgb(255, 99, 132)",
            data: [
                55, 65, 75, 85 ,75, 66, 77 
            ],
            fill: false,
        }, {
            label: 'Test B',
            borderColor: "rgb(54, 162, 235)",
            backgroundColor: "rgb(54, 162, 235)",
            data: [ 35, 45, 49, 66, 60, 71, 66 ],
            fill: false,
        }]
    },
    options: {
        maintainAspectRatio: false,
        responsive: true,
        title: {
            display: false,
            text: 'Chart.js Line Chart - Custom In  formation in Tooltip'
        },
        tooltips: {
            mode: 'index',
            callbacks: {
                // Use the footer callback to display the sum of the items showing in the tooltip
                footer: function(tooltipItems, data) {
                    var sum = 0;

                    tooltipItems.forEach(function(tooltipItem) {
                        sum += data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                    });
                    return 'Sum: ' + sum;
                },
            },
            footerFontStyle: 'normal',
            intersect: false
        },
        hover: {
            mode: 'index',
            intersect: false
        },
        scales: {
            xAxes: [{
                display: true,
                scaleLabel: {
                    show: true,
                    labelString: 'Month'
                }
            }],
            yAxes: [{
                display: true,
                scaleLabel: {
                    show: true,
                    labelString: 'Value'
                }
            }]
        }
    }
};

var recSummaryLine = {
    type: "bar",
    data: {
        labels: ["11-01", "11-02", "11-03", "11-04", "11-05", "11-06", "11-06"],
        datasets: [{
            backgroundColor: "rgba(255, 230, 0, 0.3)",
            borderColor: "rgba(125, 120, 200, 0.5)",
            data: [0,0,0,0,0,0,0],
            label: '구매전환율',
            yAxisID: 'rate',
            fill: false
        }]
    },
    options: Chart.helpers.merge(
        {
            maintainAspectRatio: false,
            spanGaps: false,
            elements: {
                line: {
                    tension: 0.000001
                }
            },
            plugins: {
                filler: {
                    propagate: true
                }
            },
            tooltips: {
                mode: 'index',
                intersect: false,
                callbacks: {
                    // title: function(){
                    //     return "구매전환율"
                    // },
                    label: function(tooltipItem, data){
                        var count = 0
                        var tick = ""
                        var purchaseCount = []
                        for(var i = 0; i < data.datasets.length; i++){
                            if(data.datasets[i]){
                                if(data.datasets[i].label === "클릭수"){
                                    count += 1
                                }
                                if(data.datasets[i].label === "구매수"){
                                    console.log("errtest ", data.datasets[i])
                                    if(data.datasets[i]._meta["0"]){
                                        tick = data.datasets[i]._meta["0"].data[0]._yScale.ticks[6]
                                        purchaseCount = data.datasets[i].data
                                        count += 1
                                    }
                                }
                            }
                        }

                        var label = ""

                        if(count === 2){
                            var limitPurchaseNumber = parseInt(tick) // 2
                            var maxPurchase = Math.max(...purchaseCount) // 20
                            console.log("hereree", purchaseCount)
                            if(maxPurchase > 0){
                                var half = maxPurchase + (maxPurchase / 4)
                                var ratio = maxPurchase / limitPurchaseNumber
                                var number = 0
                                if(data.datasets[tooltipItem.datasetIndex].label === "구매수"){
                                    number = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] / ratio
                                    label = `${data.datasets[tooltipItem.datasetIndex].label}: ${number.toLocaleString()}건`
                                    return label
                                } else if (data.datasets[tooltipItem.datasetIndex].label === "클릭수"){
                                    number = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] + purchaseCount[tooltipItem.index]
    
                                    if(number < half){
                                        if(number > maxPurchase){
                                            number = number - maxPurchase
                                            number = number * 5
                                        } else {
                                            number = number / ratio
                                        }
                                    }
    
                                    label = `${data.datasets[tooltipItem.datasetIndex].label}: ${number.toLocaleString()}건`
                                    return label
                                }
                            }
                        } 

                        var subLabel = ""
                        if(data.datasets[tooltipItem.datasetIndex].label === "구매전환율"){
                            subLabel = " %"
                        } else if (data.datasets[tooltipItem.datasetIndex].label === "매출액") {
                            subLabel = "원"
                        } else if (data.datasets[tooltipItem.datasetIndex].label === "구매수") {
                            subLabel = "건"
                        } else if (data.datasets[tooltipItem.datasetIndex].label === "클릭률") {
                            subLabel = " %"
                        } else if (data.datasets[tooltipItem.datasetIndex].label === "클릭수") {
                            subLabel = "건"
                        }
                        label = `${data.datasets[tooltipItem.datasetIndex].label}: ${data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].toLocaleString()}${subLabel}`
                        return label
                    },
                },
                footerFontStyle: 'normal'
            },
            scales: {
                xAxes: [{
                    // ticks: {
                        // autoSkip: false,
                        // maxRotation: 0,
                        // callback: function(dataLabel, index){
                        //     return index % 5 === 4 ? dataLabel : "";
                        // }
                    // },
                    stacked: true
                }],
                yAxes: [{
                    id: "rate",
                    min: 0,
                    max: 100,
                    stacked: true,
                    display: false,
                    ticks: {
                        display: false
                    }
                }]
            }
        }, { title: {
                text: 'fill: ',
                display: false
            }
        }
    )
}

var recSummaryLine2 = {
    type: "bar",
    data: {
        labels: ["11-01", "11-02", "11-03", "11-04", "11-05", "11-06", "11-06"],
        datasets: [{
            backgroundColor: "rgba(255, 230, 0, 0.3)",
            borderColor: "rgba(125, 120, 200, 0.5)",
            data: [0,10,20,30,40,50,60],
            label: '구매전환율',
            type: "line",
            yAxisID: 'rate',
            fill: false,
            order: 1
        },{
            backgroundColor: "rgba(20, 20, 255, 0.7)",
            borderColor: "rgba(20, 20, 255, 0.5)",
            data: [10, 20, 10, 20, 10, 30, 10],
            label: '클릭수',
            type: "bar",
            yAxisID: 'count',
            order: 3,
        }]
    },
    options: Chart.helpers.merge(
        {
            maintainAspectRatio: false,
            spanGaps: false,
            elements: {
                line: {
                    tension: 0.000001
                }
            },
            plugins: {
                filler: {
                    propagate: true
                }
            },
            tooltips: {
                mode: 'index',
                intersect: false,
                footerFontStyle: 'normal',
                callbacks: {
                    // title: function(){
                    //     return "구매전환율"
                    // },
                    label: function(tooltipItem, data){
                        var subLabel = ""
                        if(data.datasets[tooltipItem.datasetIndex].label === "구매전환율"){
                            subLabel = " %"
                        } else if (data.datasets[tooltipItem.datasetIndex].label === "매출액") {
                            subLabel = "원"
                        } else if (data.datasets[tooltipItem.datasetIndex].label === "구매수") {
                            subLabel = "건"
                        } else if (data.datasets[tooltipItem.datasetIndex].label === "클릭률") {
                            subLabel = " %"
                        } else if (data.datasets[tooltipItem.datasetIndex].label === "클릭수") {
                            subLabel = "건"
                        }
                        var label = `${data.datasets[tooltipItem.datasetIndex].label}: ${data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].toLocaleString()}${subLabel}`
                        return label
                    },
                }
            },
            scales: {
                xAxes: [{
                    ticks: {
                        autoSkip: true,
                        maxRotation: 0,
                        callback: function(dataLabel, index){
                            var newLabel = dataLabel.slice(5, 10)
                            return newLabel;
                        }
                    },
                    stacked: true
                }],
                yAxes: [{
                    id: "rate",
                    min: 0,
                    max: 100,
                    stacked: true,
                    position: "right",
                    ticks: {
                        callback: function(label, index, labels) {
                            if(parseInt(label) === label){
                                return label + "%";
                            }
                        },
                    }
                },{
                    id: "count",
                    title: "건 수",
                    stacked: true,
                    type: "linear",
                    ticks: {
                        callback: function(label, index, labels) {
                            if(parseInt(label) === label){
                                return label + "건";
                            }
                        },
                    }
                }]
            }
        }, { title: {
                text: 'fill: ',
                display: false
            }
        }
    )
}

export { lineChartData, realTimeLineData, flowOfUserData, compareLineData, recSummaryLine, recSummaryLine2 };

// eslint-disable-next-line no-unused-vars
// function toggleSmooth(btn) {
//     var value = btn.classList.toggle('btn-on');
//     Chart.helpers.each(Chart.instances, function(chart) {
//         chart.options.elements.line.tension = value ? 0.4 : 0.000001;
//         chart.update();
//     });
// }

// eslint-disable-next-line no-unused-vars
// function randomize() {
//     var seed = utils.rand();
//     Chart.helpers.each(Chart.instances, function(chart) {
//         utils.srand(seed);

//         chart.data.datasets.forEach(function(dataset) {
//             dataset.data = generateData();
//         });

//         chart.update();
//     });
// }
