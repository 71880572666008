var stackedBar = {
    type: 'bar',
    data: {
        labels: ['설정기간', '설정기간', '설정기간', '설정기간', '설정기간', '설정기간', '설정기간'],
        datasets: [{
            label: 'natural_direct',
            backgroundColor: "red",
            stack: 'Stack 0',
            data: [180, 20, 30, 40, 50, 60, 70,]
        }, {
            label: 'natural_search',
            backgroundColor: "blue",
            stack: 'Stack 1',
            data: [110, 60, 50, 40, 30, 20, 10,]
        }, {
            label: 'natural_search22',
            backgroundColor: "red",
            stack: 'Stack 1',
            data: [0, 60, 0, 40, 30, 20, 10,]
        }, {
            label: 'natural_etc',
            backgroundColor: "green",
            stack: 'Stack 2',
            data: [100, 5, 5, 5, 5, 5, 5,]
        }]
    },
    options: {
        title: {
            display: false,
            text: 'test string'
        },
        tooltips: {
            mode: 'x',
            intersect: false,
            callbacks: {
                label: function(tooltipItem, data){
                    if(data.datasets[tooltipItem.datasetIndex] != null){
                        if(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] != null){
                            var label = `${data.datasets[tooltipItem.datasetIndex].label}: ${data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].toLocaleString()}명`
                            return label
                        }
                    }
                },
            },
            footerFontStyle: 'normal'
        },
        responsive: true,
        scales: {
            xAxes: [{
                stacked: true,
                scaleLabel: {
                    display: true,
                    labelString: 'Day'
                }
            }],
            yAxes: [{
                stacked: true
            }]
        },
        legend:{
            display:false
        }
    }
}

export { stackedBar };

// randomScalingFactor = function(){
//     return Math.round(this.rand(-100, 100));
// }

// rand = function(min, max) {
//     var seed = this._seed;
//     min = min === undefined ? 0 : min;
//     max = max === undefined ? 1 : max;
//     this._seed = (seed * 9301 + 49297) % 233280;
//     return min + (this._seed / 233280) * (max - min);
// }