import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const MiniBox2 = ({color, icon, name, subname, data, onSubmit}) => {
    return(
        <div onClick={()=>onSubmit(name)} style={{width:"20%", padding:"0 .5rem", cursor:"pointer"}}>
            <div className="info-box">
                <span className={"info-box-icon elevation-1 " + color}>
                <FontAwesomeIcon icon={icon}/>
                </span>
                <div className="info-box-content">
                    <span className="info-box-text">
                        {name}
                    </span>
                    <span className="info-box-number">
                        {data}
                        <small>{subname}</small>
                    </span>
                </div>
            </div>
        </div> 
    )
}

export default MiniBox2