import React from "react"
import "./Recommendation.css"
import { connect } from "react-redux"
import SelectionBoxs from "../../components/SelectionBoxs"
import { setRecommendation } from "../../store/actions/condition"
import { changePage } from "../../store/actions/authentication"
import Navbar from "../../components/Navbar"
import { faPlus, faUserCircle, faTimes, faChevronRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"


class Recommendation extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            recommendationName: "",
            who: JSON.parse(JSON.stringify(props.who)),
            testA: [],
            testB: [],
            where: JSON.parse(JSON.stringify(props.where)),
            when: JSON.parse(JSON.stringify(props.when)),
            experienceName: "target",
            algoName: "알고리즘 1",
            algoBoxColor: "primary",
            categories : ["신발", "패션소품", "상의", "하의", "쥬얼리", "헤어악세사리", "메이크업", "가발", "신발", "패션소품", "상의", "하의", "쥬얼리", "헤어악세사리", "메이크업", "가발"]
        }
        this.target_header = React.createRef()
        this.algorithm_header = React.createRef()
        this.target_body = React.createRef()
        this.algorithm_body = React.createRef()
        this.algoBox = React.createRef()
    }

    setRecommendation = () => {
        const { recommendationName, who, where, when, algoName, categories } = this.state
        // const { email, host } = this.props
        this.props.setRecommendation(recommendationName, who, where, when, algoName, categories, this.props.email, this.props.selectedHost)
        .then(()=> {
            if(this.props.newRecommendationName === recommendationName){
                alert("Success! Please check new RECOMMENDATION")
                this.setState({who: this.props.who})
                this.props.changePage("recommendation")
                this.props.props.history.push('/dashboard/recommendation')
            } else {
                alert("추천설정에서 오류가 발생했습니다.")
            }
        })
    }

    getAllCondition = (menus, stateName) => {
        var obj = {}
        if(menus.menus[0].name === ""){
            obj[stateName] = []
        } else {
            obj[stateName] = menus.menus
        }
        this.setState(obj)
    }

    handleChange = event => {
        this.setState({
          [event.target.name]: event.target.value
        });
    }

    changeRecExperience = () => {
        const { experienceName } = this.state
        if(experienceName === "target"){
            this.target_header.current.classList.remove('now--experience')
            this.target_body.current.classList.add('now--hide')
            this.algorithm_header.current.classList.add('now--experience')
            this.algorithm_body.current.classList.remove('now--hide')
            this.setState({experienceName: "algorithm"})
        } else {
            this.target_header.current.classList.add('now--experience')
            this.target_body.current.classList.remove('now--hide')
            this.algorithm_header.current.classList.remove('now--experience')
            this.algorithm_body.current.classList.add('now--hide')
            this.setState({experienceName: "target"})
        }
    }

    changeRecAlgorithm = (e) => {
        var algoName = ""
        if(e.target.nodeName === "SPAN"){
            algoName = e.target.parentNode.childNodes[1].data
        } else {
            algoName = e.target.childNodes[1].data
        }

        this.algoBox.current.classList.remove(`btn-${this.state.algoBoxColor}`)
        if(algoName === " 알고리즘 1") {
            this.algoBox.current.classList.add("btn-primary")
            this.setState({
                algoBoxColor: "primary",
                algoName: algoName
            })
        } else if(algoName === " 알고리즘 2") {
            this.algoBox.current.classList.add("btn-success")
            this.setState({
                algoBoxColor: "success",
                algoName: algoName
            })
        } else if(algoName === " 알고리즘 3"){
            this.algoBox.current.classList.add("btn-danger")
            this.setState({
                algoBoxColor: "danger",
                algoName: algoName
            })
        } else if(algoName === " 알고리즘 4"){
            this.algoBox.current.classList.add("btn-warning")
            this.setState({
                algoBoxColor: "warning",
                algoName: algoName
            })
        }
    }

    deleteCategories = (idx) => {
        const { categories } = this.state;
        categories.splice(idx, 1)
        this.setState(categories)
    }

    checkNext = () => {
        if(this.state.recommendationName === ""){
            alert("추천상품 이름을 입력해주세요")
        } else if (this.state.who.length === undefined){
            alert("상품 대상을 설정해주세요")
        } else if (this.state.where.length === undefined){
            alert("상품 위치를 설정해주세요")
        } else if (this.state.when.length === undefined){
            alert("상품 시간을 설정해주세요")
        } else {
            this.changeRecExperience()
        }
    }
    
    render(){
        return (
            <>
            <div className="rec--progress">
                <Navbar type="2" title ="Recommendations" title2 = "Create" />
            </div>
            <div className="rec--create--title">
                <h4 style={{margin:"0"}}>New Recommendation Widget</h4>
            </div>
            <div className="rec--con--box">
                <div className="rec--con--line">
                    <div className="rec--line"></div>
                    <div className="rec--circle"><FontAwesomeIcon style={{color:"darkgray"}} icon={faPlus} size="sm"/></div>
                    <div className="rec--line2"></div>
                    <div className="rec--circle2">
                        <FontAwesomeIcon className="rec--font" icon={faUserCircle} size="2x"/>
                        <div className="rec--line3"></div>
                    </div>
                </div>
                <div className="recommendation--container" >
                    <div className="rec--header">
                        <h5>New Recommendation Experience</h5>
                        <div className="d-flex pt-4">
                            <div ref={this.target_header} className="rec--header--div now--experience">1</div>
                            <div className="font-weight-bold"> 고객 설정</div>
                            <div>―――――― </div>
                            <div ref={this.algorithm_header} className="rec--header--div ml-2">2</div>
                            <div className="font-weight-bold"> 알고리즘 설정</div>
                        </div>
                    </div>
                    <div className="rec--body">
                        {/* experience-target/ */}
                        <div ref={this.target_body}>
                            <div className="mb-3">
                                <h6 className="text-dark">* Recommendation Name</h6>
                                <input
                                    style={{border:0, borderBottom:"1px solid gray", width:"30%"}}
                                    name='recommendationName'
                                    placeholder='Enter Test Name'
                                    value={this.state.recommendationName}
                                    onChange={this.handleChange}  
                                    autoComplete="off"  
                                />
                            </div>
                            <div className="mb-3">
                                <h6 className="text-dark">* Who ?</h6>
                                <SelectionBoxs menus={this.state.who} onSubmit={(menus)=>this.getAllCondition(menus, "who")} />
                            </div>
                            <div className="mb-3">
                                <h6 className="text-dark">* Where ?</h6>
                                <SelectionBoxs menus={this.state.where} onSubmit={(menus)=>this.getAllCondition(menus, "where")}/>
                            </div>
                            <div className="mb-3">
                                <h6 className="text-dark">* When ?</h6>
                                <SelectionBoxs menus={this.state.when} onSubmit={(menus)=>this.getAllCondition(menus, "when")}/>
                            </div>
                        </div>
                        
                        {/* experience-algorithm */}
                        <div ref={this.algorithm_body} className="now--hide">
                            <div className="mb-3">
                                <h6 className="font-weight-bold">* 알고리즘</h6>
                                <div ref={this.algoBox} className="btn--box btn btn-primary">
                                    <div style={{textAlign:"left", color:"white"}}>All widget slots</div>
                                    <button type="button" className="btn--algo d-flex btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <div>
                                            {this.state.algoName}
                                        </div>
                                    </button>
                                    <div className="dropdown-menu">
                                        <a onClick={this.changeRecAlgorithm} className="dropdown-item" href="#/dashboard/createRecommendation"><span className="h5 text-primary">■ </span> 알고리즘 1</a>
                                        <a onClick={this.changeRecAlgorithm} className="dropdown-item" href="#/dashboard/createRecommendation"><span className="h5 text-success">■ </span> 알고리즘 2</a>
                                        <a onClick={this.changeRecAlgorithm} className="dropdown-item" href="#/dashboard/createRecommendation"><span className="h5 text-danger">■ </span> 알고리즘 3</a>
                                        <a onClick={this.changeRecAlgorithm} className="dropdown-item" href="#/dashboard/createRecommendation"><span className="h5 text-warning">■ </span> 알고리즘 4</a>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <h6 className="font-weight-bold">* 상품군</h6>
                                <div className="categoris--menu">
                                    {
                                        this.state.categories.map(
                                            (categorie, idx) => 
                                            <div key={idx} className="btn btn-secondary categoris--item">
                                                {categorie} <FontAwesomeIcon onClick={() => this.deleteCategories(idx)} icon={faTimes}/>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="rec--footer">
                        {
                            this.state.experienceName === "target" ? 
                            <>
                                <button onClick={this.checkNext} type="button" className="btn btn-primary float-right mr-2">
                                    다음 <FontAwesomeIcon style={{marginBottom:"2px"}} size="xs" icon={faChevronRight}/>
                                </button>
                                <button onClick={()=> {this.props.changePage("recommendation");this.props.props.history.push('/dashboard/recommendation')}} type="button" className="btn float-right mr-2 ml-2">
                                    취소
                                </button>
                            </>
                            :
                            <>
                                <button onClick={this.changeRecExperience} type="button" className="btn btn-light float-left ml-2">
                                    <FontAwesomeIcon style={{marginBottom:"2px"}} size="xs" icon={faChevronLeft}/> 이전
                                </button>
                                <button onClick={this.setRecommendation} type="button" className="btn btn-primary float-right mr-2">
                                    저장 및 실행
                                </button>
                                <button onClick={()=> {this.props.changePage("recommendation");this.props.props.history.push('/dashboard/recommendation')}} type="button" className="btn float-right mr-2 ml-2">
                                    취소
                                </button>
                            </>
                        }
                    </div>
                </div>
            </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        who: state.condition.who,
        where: state.condition.where,
        when: state.condition.when,
        email: state.authentication.status.userEmail,
        selectedHost: state.authentication.status.selectedHost,
        newRecommendationName: state.condition.newRecommendationName,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setRecommendation: (recommendationName, who, where, when, algoName, categories, email, host) => {
            return dispatch(setRecommendation(recommendationName, who, where, when, algoName, categories, email, host))
        },
        changePage: (pageName) => {
            return dispatch(changePage(pageName))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Recommendation);