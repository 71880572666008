import React, {Component} from "react"
// import "./Summary.css"
import Chart from "chart.js"
import { connect } from "react-redux"
import DateTimer_rec from "../../../components/DateTimer_rec"
import Card from "../../../components/chart/Card"
import Minibox_rec from "../../../components/chart/Minibox_rec"
import { faSyncAlt, faSearchDollar, faCartArrowDown, faMousePointer } from "@fortawesome/free-solid-svg-icons"
import Loading from "../../../components/Loading"
import { getRecResults2, getGapClickToOrder } from '../../../store/actions/recommendation'
import { recSummaryLine2 } from '../../../datas/chartjs/line'
import moment from "moment"
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, CSVExport  } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
const { SearchBar } = Search;

class Summary2 extends Component{
    constructor(props){
        super(props)
        let now = new Date();
        let today = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0,0,0,0));
        let start = moment(today).subtract(7, "days");
        var minDate = new Date("2020-11-22T00:00:00").getTime()
        if(start._d.getTime() < minDate){
            start = moment(new Date("2020-11-22T00:00:00"))
        }
        let end = moment(today).subtract(1, "seconds");
        this.state ={
            loading: true,
            dates: [start, end],
            chartDate: this.props.chartDate,
            config1: recSummaryLine2,
            totalConfigData: {},
            recConfigData: {},
            status: this.props.status,
            datasets: {},
            selectedDatasets: ["purchaseChangeRate"],
            totalTable: [],
            selectedBtn: "all",
            prevBtn: "all",
            selectedSum: {
                purchaseChangeRate: 0,
                totalPrice: 0,
                purchaseCount: 0,
                purchaseTotalCount: 0,
                clickCount: 0
            },
            sum: {
                purchaseChangeRate: 0,
                totalPrice: 0,
                purchaseCount: 0,
                purchaseTotalCount: 0,
                clickCount: 0
            },
            sum_rec: {
                purchaseChangeRate: 0,
                totalPrice: 0,
                purchaseCount: 0,
                purchaseTotalCount: 0,
                clickCount: 0
            },
        }
    }

    componentDidMount(){
        var ctx1 = document.getElementById("chart-1");
        ctx1.style.height = `${window.innerHeight-400}px`
        var chart_1 = new Chart(ctx1, this.state.config1)
        this.setState({chart_1})
    }

    componentDidUpdate(){
        if(this.state.status !== this.props.status){
            this.setState({status: this.props.status})
            this.setDates(this.state.dates)
        }
        if(this.state.selectedBtn !== this.state.prevBtn){
            var config = this.state.config1
            var lineData = [];
            var barData = []
            if(this.state.selectedBtn === "all"){
                lineData = this.state.totalConfigData.lineData
                barData = this.state.totalConfigData.barData
            } else if(this.state.selectedBtn === "rec"){
                lineData = this.state.recConfigData.lineData
                barData = this.state.recConfigData.barData
            } else if (this.state.selectedBtn === "noRec"){
                lineData = this.state.noRecConfigData.lineData
                barData = this.state.noRecConfigData.barData
            }
            config.data.datasets[0].data = lineData;
            config.data.datasets[1].data = barData;
            this.setState({
                prevBtn: this.state.selectedBtn,
                config1: config
            })
        }
    }

    setDates = (dates) => {
        this.setState({
            dates: dates,
            loading: true,
        })
        if(this.state.status.isLoggedIn){
            this.props.getRecResults2(dates, this.props.status)
            .then((results)=> {
                console.log("summary2 resuts", results)
                var {totalClick, recClick, fullDate, joinOrder1} = results

                // var filterNoMatchingUser = joinOrder1.filter(order => {
                //     if(order.browserKey){
                //         for(i = 0; i < totalClick.length; i++){
                //             if(totalClick[i]._id.code === order.goodsNo){
                //                 if(totalClick[i].browserKey.includes(order.browserKey)){
                //                     break;
                //                 }
                //             }
                //             if(i === totalClick.length - 1){
                //                 return order;
                //             }
                //         }
                //     } else {
                //         return order;
                //     }
                // })

                // var filter = joinOrder1.filter(order => !order.browserKey)
                // console.log("filtered no browserKey order", filter)
                // console.log("filtered filterNoMatchingUser", filterNoMatchingUser)

                // 모든 상품 데이터 합치기(차트용)
                var chartObjPerDate = {}
                for(var i = 0; i < totalClick.length; i++){
                    totalClick[i].key = i+1
                    // totalClick[i].date = new Date(totalClick[i]._id.year, totalClick[i]._id.month-1, totalClick[i]._id.day, 12, 0, 0).toISOString().slice(0,10)
                }
                for(i = 0; i < totalClick.length; i++){
                    var purchaseCount = totalClick[i].purchaseCount ? totalClick[i].purchaseCount : 0
                    var purchaseTotalCount = totalClick[i].purchaseTotalCount ? totalClick[i].purchaseTotalCount : 0
                    var totalPrice = totalClick[i].totalPrice ? totalClick[i].totalPrice : 0
                    if(chartObjPerDate[totalClick[i]._id.date]){
                        chartObjPerDate[totalClick[i]._id.date].clickCount += totalClick[i].clickCount;
                        chartObjPerDate[totalClick[i]._id.date].purchaseCount += purchaseCount;
                        chartObjPerDate[totalClick[i]._id.date].purchaseTotalCount += purchaseTotalCount;
                        chartObjPerDate[totalClick[i]._id.date].totalPrice += totalPrice;
                    } else {
                        chartObjPerDate[totalClick[i]._id.date] = {}
                        chartObjPerDate[totalClick[i]._id.date].clickCount = totalClick[i].clickCount;
                        chartObjPerDate[totalClick[i]._id.date].purchaseCount = purchaseCount;
                        chartObjPerDate[totalClick[i]._id.date].purchaseTotalCount = purchaseTotalCount;
                        chartObjPerDate[totalClick[i]._id.date].totalPrice = totalPrice;
                    }
                }

                var sum = {
                    purchaseChangeRate: 0,
                    totalPrice: 0,
                    purchaseCount: 0,
                    purchaseTotalCount: 0,
                    clickCount: 0
                }

                for(var a in chartObjPerDate){
                    sum.totalPrice += chartObjPerDate[a].totalPrice ? chartObjPerDate[a].totalPrice : 0;
                    sum.purchaseCount += chartObjPerDate[a].purchaseCount ? chartObjPerDate[a].purchaseCount : 0;
                    sum.purchaseTotalCount += chartObjPerDate[a].purchaseTotalCount ? chartObjPerDate[a].purchaseTotalCount : 0;
                    sum.clickCount += chartObjPerDate[a].clickCount ? chartObjPerDate[a].clickCount : 0;
                }
                sum.purchaseChangeRate = Math.round(sum.purchaseCount / sum.clickCount * 10000) / 100

                var totalLabels = []
                var totalLineData = []
                var totalBarData = []
                for(i = 0; i < fullDate.length; i++){
                    if(chartObjPerDate[fullDate[i]]){
                        totalLabels.push(fullDate[i])
                        totalLineData.push(Math.round(chartObjPerDate[fullDate[i]].purchaseCount / chartObjPerDate[fullDate[i]].clickCount * 10000) / 100)
                        totalBarData.push(chartObjPerDate[fullDate[i]].clickCount)
                    } else {
                        totalLabels.push(fullDate[i])
                        totalLineData.push(0)
                        totalBarData.push(0)
                    }
                }

                var config = this.state.config1
                config.data.labels = totalLabels;
                config.data.datasets[0].data = totalLineData
                config.data.datasets[1].data = totalBarData


                // 추천 상품 데이터 합치기(차트용)
                var recChartObjPerDate = {}
                for(var i = 0; i < recClick.length; i++){
                    recClick[i].key = i+1
                    // recClick[i].date = new Date(recClick[i]._id.year, recClick[i]._id.month-1, recClick[i]._id.day, 12, 0, 0).toISOString().slice(0,10)
                }
                for(i = 0; i < recClick.length; i++){
                    var recPurchaseCount = recClick[i].purchaseCount ? recClick[i].purchaseCount : 0
                    var recPurchaseTotalCount = recClick[i].purchaseTotalCount ? recClick[i].purchaseTotalCount : 0
                    var recTotalPrice = recClick[i].totalPrice ? recClick[i].totalPrice : 0
                    if(recChartObjPerDate[recClick[i]._id.date]){
                        recChartObjPerDate[recClick[i]._id.date].clickCount += recClick[i].clickCount;
                        recChartObjPerDate[recClick[i]._id.date].purchaseCount += recPurchaseCount;
                        recChartObjPerDate[recClick[i]._id.date].purchaseTotalCount += recPurchaseTotalCount;
                        recChartObjPerDate[recClick[i]._id.date].totalPrice += recTotalPrice;
                    } else {
                        recChartObjPerDate[recClick[i]._id.date] = {}
                        recChartObjPerDate[recClick[i]._id.date].clickCount = recClick[i].clickCount;
                        recChartObjPerDate[recClick[i]._id.date].purchaseCount = recPurchaseCount;
                        recChartObjPerDate[recClick[i]._id.date].purchaseTotalCount = recPurchaseTotalCount;
                        recChartObjPerDate[recClick[i]._id.date].totalPrice = recTotalPrice;
                    }
                }

                var sum_rec = {
                    purchaseChangeRate: 0,
                    totalPrice: 0,
                    purchaseCount: 0,
                    purchaseTotalCount: 0,
                    clickCount: 0
                }

                for(var a in recChartObjPerDate){
                    sum_rec.totalPrice += recChartObjPerDate[a].totalPrice ? recChartObjPerDate[a].totalPrice : 0;
                    sum_rec.purchaseCount += recChartObjPerDate[a].purchaseCount ? recChartObjPerDate[a].purchaseCount : 0;
                    sum_rec.purchaseTotalCount += recChartObjPerDate[a].purchaseTotalCount ? recChartObjPerDate[a].purchaseTotalCount : 0;
                    sum_rec.clickCount += recChartObjPerDate[a].clickCount ? recChartObjPerDate[a].clickCount : 0;
                }
                sum_rec.purchaseChangeRate = Math.round(sum_rec.purchaseCount / sum_rec.clickCount * 10000) / 100

                var recLineData = []
                var recBarData = []
                for(i = 0; i < fullDate.length; i++){
                    if(recChartObjPerDate[fullDate[i]]){
                        recLineData.push(Math.round(recChartObjPerDate[fullDate[i]].purchaseCount / recChartObjPerDate[fullDate[i]].clickCount * 10000) / 100)
                        recBarData.push(recChartObjPerDate[fullDate[i]].clickCount)
                    } else {
                        recLineData.push(0)
                        recBarData.push(0)
                    }
                }
                
                // // 미추천상품 데이터 만들기 (차트용, 전체 - 추천)
                // var noRecClick = JSON.parse(JSON.stringify(totalClick))
                // for(i = noRecClick.length - 1; i > -1; i--){
                //     for(var j = 0; j < recClick.length; j++){
                //         if(noRecClick[i]._id.code === recClick[j]._id.code){
                //             if(noRecClick[i].date === recClick[j].date){
                //                 noRecClick[i].clickCount -= recClick[j].clickCount;
                //                 if(noRecClick[i].clickCount === 0){
                //                     noRecClick.splice(i, 1)
                //                 } else {
                //                     if(recClick[j].purchaseCount){
                //                         noRecClick[i].purchaseCount -= recClick[j].purchaseCount;
                //                         noRecClick[i].purchaseNumberCount -= recClick[j].purchaseNumberCount;
                //                         noRecClick[i].totalPrice -= recClick[j].totalPrice;
                //                     }
                //                     if(recClick[j].likeCount){
                //                         noRecClick[i].likeCount -= recClick[j].likeCount;
                //                     }
                //                     if(recClick[j].cartCount){
                //                         noRecClick[i].cartCount -= recClick[j].cartCount;
                //                     }
                //                     break;
                //                 }
                //             }
                //         }
                //     }
                // }
                // // 미추천 상품 데이터 합치기(차트용)
                // var noRecChartObjPerDate = {}
                // for(var i = 0; i < noRecClick.length; i++){
                //     noRecClick[i].key = i+1
                //     noRecClick[i].korDate = `${noRecClick[i]._id.month}월${noRecClick[i]._id.day}일`
                //     noRecClick[i].date = new Date(noRecClick[i]._id.year, noRecClick[i]._id.month-1, noRecClick[i]._id.day, 12, 0, 0).toISOString().slice(0,10)
                // }
                // for(i = 0; i < noRecClick.length; i++){
                //     var noRecChangeCount = noRecClick[i].purchaseCount ? noRecClick[i].purchaseCount : 0
                //     if(noRecChartObjPerDate[noRecClick[i].date]){
                //         noRecChartObjPerDate[noRecClick[i].date].clickCount += noRecClick[i].clickCount;
                //         noRecChartObjPerDate[noRecClick[i].date].purchaseChangeRate += noRecChangeCount;
                //     } else {
                //         noRecChartObjPerDate[noRecClick[i].date] = {}
                //         noRecChartObjPerDate[noRecClick[i].date].clickCount = noRecClick[i].clickCount;
                //         noRecChartObjPerDate[noRecClick[i].date].purchaseChangeRate = noRecChangeCount;
                //     }
                // }

                // var noRecLineData = []
                // var noRecBarData = []
                // for(i = 0; i < fullDate.length; i++){
                //     if(noRecChartObjPerDate[fullDate[i]]){
                //         noRecLineData.push(Math.round(noRecChartObjPerDate[fullDate[i]].purchaseChangeRate / noRecChartObjPerDate[fullDate[i]].clickCount * 10000) / 100)
                //         noRecBarData.push(noRecChartObjPerDate[fullDate[i]].clickCount)
                //     } else {
                //         noRecLineData.push(0)
                //         noRecBarData.push(0)
                //     }
                // }
                var noRecLineData = []
                var noRecBarData = []
                for(i = 0; i < fullDate.length; i++){
                    if(recChartObjPerDate[fullDate[i]]){
                        var noRecPurchaseCount = chartObjPerDate[fullDate[i]].purchaseCount - recChartObjPerDate[fullDate[i]].purchaseCount
                        var noRecCount = chartObjPerDate[fullDate[i]].clickCount - recChartObjPerDate[fullDate[i]].clickCount
                        noRecLineData.push(Math.round(noRecPurchaseCount / noRecCount * 10000) / 100)
                        noRecBarData.push(noRecCount)
                    } else {
                        noRecLineData.push(0)
                        noRecBarData.push(0)
                    }
                }

                var sum_noRec = {
                    totalPrice: sum.totalPrice - sum_rec.totalPrice,
                    purchaseCount: sum.purchaseCount - sum_rec.purchaseCount,
                    purchaseTotalCount: sum.purchaseTotalCount - sum_rec.purchaseTotalCount,
                    clickCount: sum.clickCount - sum_rec.clickCount
                }
                sum_noRec.purchaseChangeRate = Math.round(sum_noRec.purchaseCount / sum_noRec.clickCount * 10000) / 100

                console.log("all click data", totalClick, " // ", recClick, " // ")
                console.log("all click data", chartObjPerDate, " // ", recChartObjPerDate, " // ")

                this.setState({
                    totalTable: totalClick,
                    config1: config,
                    totalConfigData: {
                        lineData: totalLineData,
                        barData: totalBarData
                    },
                    recConfigData: {
                        lineData: recLineData,
                        barData: recBarData
                    },
                    noRecConfigData: {
                        lineData: noRecLineData,
                        barData: noRecBarData
                    },
                    loading: false,
                    selectedSum: sum,
                    sum: sum,
                    sum_rec: sum_rec,
                    sum_noRec: sum_noRec,
                })
            })
            
        }
    }

    changeBtn = (btn) => {
        var selectedSum = "sum"
        if(btn === "rec"){
            selectedSum = "sum_rec"
        } else if (btn === "noRec"){
            selectedSum = "sum_noRec"
        }
        this.setState({
            selectedBtn: btn,
            selectedSum: this.state[selectedSum]
        })
    }

    getGapClickToOrder = () => {
        this.props.getGapClickToOrder(this.state.dates, this.props.status)
        .then((response) => {
            console.log("getGapClickToOrder response", response)
        })
    }

    render(){
        if(this.state.chart_1){
            this.state.chart_1.update()
        }

        const columns = [{
            dataField: '_id.date',
            text: '날짜',
            sort: true,
            headerAlign: 'center',
            align: 'center',
            formatter: (cell, row) => {
                if(cell){
                    // var newCell = cell.slice(5, 10)
                    // newCell = newCell.replace("-", "월").concat("일")
                    return (
                        <p title={cell} className="m-0 over--split" >{cell}</p>
                    )
                }
            }
        }, {
            dataField: 'goodsName',
            text: '상품명',
            sort: true,
            headerAlign: 'center',
            align: 'center',
            headerClasses: 'w-16',
            formatter: (cell, row) => {
                if(cell){
                    return (
                        <p title={cell} className="m-0 over--split" >{cell}</p>
                    )
                }
            }
        }, {
            dataField: 'price',
            text: '가격',
            sort: true,
            headerAlign: 'center',
            align: 'center',
            formatter: (cell, row) => {
                if(cell){
                    return (
                        <p>{cell.toLocaleString()}원</p>
                    )
                }
            }
        }, {
            dataField: 'totalPrice',
            text: '매출액',
            sort: true,
            headerAlign: 'center',
            align: 'center',
            formatter: (cell, row) => {
                if(cell){
                    return (
                        <p>{cell.toLocaleString()}원</p>
                    )
                } else {
                    return (
                        <p>0원</p>
                    )
                }
            }
        }, {
            dataField: 'clickCount',
            text: '클릭 수',
            sort: true,
            headerAlign: 'center',
            align: 'center',
            formatter: (cell, row) => {
                if(cell){
                    return (
                        <p>{cell.toLocaleString()}</p>
                    )
                }
            }
        }, {
            dataField: 'cartCount',
            text: '장바구니',
            sort: true,
            headerAlign: 'center',
            align: 'center',
            formatter: (cell, row) => {
                if(cell){
                    return (
                        <p>{cell.toLocaleString()}</p>
                    )
                } else {
                    return (
                        <p>0</p>
                    )
                }
            }
        }, {
            dataField: 'likeCount',
            text: '찜하기',
            sort: true,
            headerAlign: 'center',
            align: 'center',
            formatter: (cell, row) => {
                if(cell){
                    return (
                        <p>{cell.toLocaleString()}</p>
                    )
                } else {
                    return (
                        <p>0</p>
                    )
                }
            }
        }, {
            dataField: 'purchaseCount',
            text: '구매 확정',
            sort: true,
            headerAlign: 'center',
            align: 'center',
            formatter: (cell, row) => {
                if(cell){
                    return (
                        <p>{cell.toLocaleString()}</p>
                    )
                } else {
                    return (
                        <p>0</p>
                    )
                }
            }
        }, {
            dataField: 'purchaseTotalCount',
            text: '구매 개수',
            sort: true,
            headerAlign: 'center',
            align: 'center',
            formatter: (cell, row) => {
                if(cell){
                    return (
                        <p>{cell.toLocaleString()}</p>
                    )
                } else {
                    return (
                        <p>0</p>
                    )
                }
            }
        }, {
            dataField: 'sold_out',
            text: '상태',
            sort: true,
            headerAlign: 'center',
            align: 'center',
            formatter: (cell, row) => {
                if(cell){
                    return (
                        <p>품절</p>
                    )
                } else {
                    return (
                        <p>판매중</p>
                    )
                }
            }
        }]

        return(
            <div className="content pl-2 pr-2">
                <Loading loading={this.state.loading}/>
                <div className="d-flex row w-100 m-0 mb-2 mt-2 pl-2 pr-2">
                    <div onClick={this.getGapClickToOrder} className="btn btn-success">
                        BTN
                    </div>
                    <h5 className="content--header col bg-light p-2 ml-2"></h5>
                    <DateTimer_rec setDates={(dates) => this.setDates(dates)}/>
                </div>
                <div className="row w-100 m-0">
                    <Minibox_rec color={"bg-info"} icon={faSyncAlt} name={"구매전환율"} subname={" %"} data={this.state.selectedSum.purchaseChangeRate} />
                    <Minibox_rec color={"bg-info"} icon={faSearchDollar} name={"매출액"} subname={" 원"} data={this.state.selectedSum.totalPrice.toLocaleString()} />
                    <Minibox_rec color={"bg-info"} icon={faCartArrowDown} name={"구매확정수"} subname={" 건"} data={this.state.selectedSum.purchaseCount.toLocaleString()} />
                    <Minibox_rec color={"bg-info"} icon={faCartArrowDown} name={"구매개수"} subname={" 건"} data={this.state.selectedSum.purchaseTotalCount.toLocaleString()} />
                    <Minibox_rec color={"bg-info"} icon={faMousePointer} name={"클릭수"} subname={" 건"} data={this.state.selectedSum.clickCount.toLocaleString()} />
                </div>
                <div className="row w-100 m-0">
                    <div className="w-100 pl-2 pr-2">
                        <Card changeBtn={this.changeBtn} customBtn={this.state.selectedBtn} title="Graph chart" canvasId="chart-1" />
                    </div>
                </div>
                <div className="row w-100 m-0">
                    <div className="w-100 pl-2 pr-2">
                    <div className={"card m-0"}>
                            <div className={"card-body  table-responsive p-0"}>
                                <div className={" p-3"}>
                                    {
                                        this.state.totalTable[0] ?
                                        <ToolkitProvider
                                        keyField="key"
                                        data={ this.state.totalTable }
                                        columns={ columns }
                                        bootstrap4
                                        search
                                        >
                                        {
                                            props => (
                                            <div>
                                                <SearchBar { ...props.searchProps } />
                                                <BootstrapTable
                                                bordered={ false }
                                                hover
                                                noDataIndication="Table is Empty"
                                                pagination={ paginationFactory() }
                                                { ...props.baseProps }
                                                />
                                            </div>
                                            )
                                        }
                                        </ToolkitProvider>
                                        :""
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        status: state.authentication.status,
        chartDate: state.recommendation.chartDate,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getRecResults2: (dates, status) => {
            return dispatch(getRecResults2(dates, status));
        },
        getGapClickToOrder: (dates, status) => {
            return dispatch(getGapClickToOrder(dates, status));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Summary2);