import React, {Component} from "react"
import { connect } from "react-redux"
import { editUserInfo } from "../store/actions/authentication"
import $ from 'jquery'

class UserEditModal extends Component{
    constructor(props){
        super(props)

        this.state ={
            status: props.status,
            newName: props.status.currentUser,
            currentPass: "",
            newPass1: "",
            newPass2: ""
        }
    }

    componentDidMount(){
    }

    componentDidUpdate(){
        if(this.state.status !== this.props.status){
            this.setState({
                status: this.props.status,
                newName: this.props.status.currentUser
            })
        }
    }

    changeInput = (e) => {
        var name = e.target.name;
        var value = e.target.value;
        console.log("name", name, " // ", value)
        this.setState({
            [name]: value
        })
    }

    editUserInfo = () => {
        var pattern_spc = /[~!@#$%^&*()_+|<>?:{}]/;
        const { status, newName, currentPass, newPass1, newPass2 } = this.state
        if(newPass1){
            if(newPass1 === newPass2){
                if(newPass1.length > 7){
                    if(pattern_spc.test(newPass1)){
                        this.props.editUserInfo(status.userEmail, newName, currentPass, newPass1)
                        .then((response) => {
                            if(response === newName){
                                alert("회원정보가 수정되었습니다")
                                $('#userInfoModal').modal('hide')
                            } else {
                                alert(response)
                            }
                        })
                    } else {
                        alert("특수문자를 포함해주세요.")
                    }
                } else {
                    alert("최소 8글자 이상 입력해 주세요.")
                }
            } else {
                alert("비밀번호가 서로 다릅니다.")
            }
        } else {
            this.props.editUserInfo(status.userEmail, newName, currentPass, newPass1)
            .then((response) => {
                if(response === newName){
                    alert("회원정보가 수정되었습니다")
                    $('#userInfoModal').modal('hide')
                } else {
                    alert(response)
                }
            })
        }
    }

    render(){
        var { status, newName, currentPass, newPass1, newPass2 } = this.state
        var pattern_spc = /[~!@#$%^&*()_+|<>?:{}]/;
        return(
            <div className="modal fade" id="userInfoModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">회원정보 변경</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span style={{width:"150px"}} className="input-group-text" id="basic-addon1">아이디</span>
                            </div>
                            <input value={status.userEmail} readOnly type="text" className="form-control" placeholder="UserID" />
                        </div>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span style={{width:"150px"}} className="input-group-text" id="basic-addon1">이름</span>
                            </div>
                            <input onChange={this.changeInput} name="newName" value={newName} type="text" className="form-control" placeholder="" />
                        </div>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span style={{width:"150px"}} className="input-group-text" id="basic-addon1">현재 비밀번호</span>
                            </div>
                            <input onChange={this.changeInput} name="currentPass" value={currentPass} type="password" className="form-control" placeholder="" />
                        </div>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span style={{width:"150px"}} className="input-group-text" id="basic-addon1">새 비밀번호</span>
                            </div>
                            <input onChange={this.changeInput} name="newPass1" value={newPass1} type="password" className="form-control" placeholder="" />
                        </div>
                        {
                            (newPass1.length > 0) && (newPass1.length < 8) ?
                            <div>
                                <small style={{marginLeft:"150px"}}>(사용불가) 최소 8글자 이상 입력해 주세요.</small>
                            </div>
                            :
                            (newPass1.length > 7) && (pattern_spc.test(newPass1)) ?
                            ""
                            :
                            (newPass1.length > 7) ? 
                            <div>
                                <small style={{marginLeft:"150px"}}>(사용불가) 특수문자를 포함해주세요.</small>
                            </div> 
                            :""
                        }
                        <div className="input-group mt-3">
                            <div className="input-group-prepend">
                                <span style={{width:"150px"}} className="input-group-text" id="basic-addon1">새 비밀번호 확인</span>
                            </div>
                            <input onChange={this.changeInput} name="newPass2" value={newPass2} type="password" className="form-control" placeholder="" />
                        </div>
                        {
                            newPass2.length > 0 ?
                            newPass1 !== newPass2 ?
                            <div>
                                <small style={{marginLeft:"150px"}}>비밀번호가 서로 다릅니다.</small>
                            </div>
                            :""
                            :""    
                        }
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">취소</button>
                        <button onClick={this.editUserInfo} type="button" className="btn btn-primary">정보수정</button>
                    </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        editUserInfo: (email, newName, currentPass, newPass) => {
            return dispatch(editUserInfo(email, newName, currentPass, newPass));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserEditModal);