import React from "react"
import * as d3 from "d3";
import { connect } from "react-redux"
import { getUserOfTime } from '../../store/actions/chart';

class Heatmap extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userOfTimeArr: this.props.userOfTime,
            conditionDates: this.props.conditionDates,
            conditionMainArr: this.props.conditionMainArr,
            conditionSubArr: this.props.conditionSubArr,
        }
        this.chartRef = React.createRef();
        // this.drawChart = this.drawChart.bind(this);
        // this.getHeatmap1()
    }

    shouldComponentUpdate(nextProps, nextState){
        if (nextProps.conditionDates !== nextState.conditionDates){
            // console.log(nextProps, " /date/ ", nextState )
            this.setState({
                conditionDates: nextProps.conditionDates,
                drawed: true,
            });
            return true
        } else if (nextProps.conditionMainArr !== nextState.conditionMainArr) {
            // console.log(nextProps, " /main/ ", nextState )
            this.setState({
                conditionMainArr: nextProps.conditionMainArr,
                conditionSubArr: nextProps.conditionSubArr,
                drawed: true,
            })
            return true
        } else {return false}
    }

    componentDidUpdate(){
            var svg = document.getElementById("heatmap1_svg")
            if(svg){
                svg.remove()
            }
            this.getHeatmap1()
    }

    drawChart = () => {
        // this.setState({drawed:true})

        var margin = {top: 30, right: 30, bottom: 30, left: 50}
        var width = 450 - margin.left - margin.right
        var height = 450 - margin.top - margin.bottom
        var colors = ["rgb(106, 228, 118)","rgb(75, 199, 87)","rgb(45, 175, 58)","rgb(22, 136, 34)"]
        // colors = ["#f7fcf0","#e0f3db","#ccebc5","#a8ddb5","#7bccc4","#4eb3d3","#2b8cbe","#0868ac","#084081"],
        var gridSize=Math.floor(width/24)
		var legendElementWidth=gridSize*5
        

        // append the svg object to the body of the page
        var svg = d3.select("#my_dataviz")
        .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom + 40)
        .attr("id", "heatmap1_svg")
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        // Labels of row and columns
        var myGroups = ["일", "월", "화", "수", "목", "금", "토"]
        var myVars = ["11오후", "10오후", "9오후", "8오후", "7오후", "6오후", "5오후", "4오후", "3오후", "2오후", "1오후", "12오후", "11오전", "10오전", "9오전", "8오전", "7오전", "6오전", "5오전", "4오전", "3오전", "2오전", "1오전", "12오전",]

        // Build X scales and axis:
        var x = d3.scaleBand()
        .range([ 0, width ])
        .domain(myGroups)
        .padding(0.03);
        svg.append("g")
        .attr("transform", "translate(0," + height + ")")
        .call(d3.axisBottom(x))

        // Build X scales and axis:
        var y = d3.scaleBand()
        .range([ height, 0 ])
        .domain(myVars)
        .padding(0.1);
        svg.append("g")
        .call(d3.axisLeft(y));

        // Build color scale
        // var myColor = d3.scaleLinear()
        // // .range(["white", "#69b3a2"])
        // .domain([1,25,50,74,100])
        // .range(colors)
        var myColor = (num, range) => {
            if(num === 0) {
                return "rgba(0,0,0,0.12)"
            } else if(num < range[0]){
                return colors[0]
            } else if (num < range[1]) {
                return colors[1]
            } else if (num < range[2]) {
                return colors[2]
            } else {
                return colors[3]
            }
        }

        var tooltip = d3.select("#my_dataviz")
        .append("div")
        .style("opacity", 0)
        .attr("class", "tooltip")
        .style("background-color", "white")
        .style("border", "solid")
        .style("border-width", "1px")
        .style("border-radius", "5px")
        .style("padding", "5px")
    
        // Three function that change the tooltip when user hover / move / leave a cell
        var mouseover = function(d) {
            tooltip
            .style("opacity", 1)
            d3.select(this)
            .style("stroke", "black")
            .style("opacity", 0.5)
        }
        var mousemove = function(d) {
            if(d.group === "금" || d.group ==="토"){
                tooltip
                .html(`${d.group} ${d.variable} ${"<br/>"}사용자 ${d.value.toLocaleString()}명`)
                .style("left", (d3.mouse(this)[0] -30) + "px")
                .style("top", (d3.mouse(this)[1]+45) + "px")

            } else {
                tooltip
                .html(`${d.group} ${d.variable} ${"<br/>"}사용자 ${d.value.toLocaleString()}명`)
                .style("left", (d3.mouse(this)[0]+70) + "px")
                .style("top", (d3.mouse(this)[1]+45) + "px")

            }
        }
        var mouseleave = function(d) {
            tooltip
            .style("opacity", 0)
            d3.select(this)
            .style("stroke", "none")
            .style("opacity", 1)
        }


        //Read the data

            var colorScale = d3.scaleQuantile()
				.domain([0, (d3.max(this.state.userOfTimeArr, function(d){return d.value;})/2), d3.max(this.state.userOfTimeArr, function(d){return d.value;})])
				.range(colors);

            svg.selectAll()
                .data(this.state.userOfTimeArr, (d) => d.group+':'+d.variable)
                .enter()
                .append("rect")
                .attr("x", (d) => x(d.group) )
                .attr("y", (d) => y(d.variable))
                .attr("width", x.bandwidth() )
                .attr("height", y.bandwidth() )
                .style("fill", (d) => myColor(d.value, colorScale.quantiles()))
                .on("mouseover", mouseover)
                .on("mousemove", mousemove)
                .on("mouseleave", mouseleave)

                var legend = svg.selectAll(".legend")
                    .data([0].concat(colorScale.quantiles()), function(d) {return d; })
                    .enter().append("g")
                    .attr("class", "legend")
                    
                    legend.append("rect")
                    .attr("x", function(d, i) { return legendElementWidth * i + i*4 + 20; })
                    .attr("y", height + 30)
                    .attr("width", legendElementWidth)
                    .attr("height", gridSize)
                    .style("fill", function(d, i) { return colors[i]; });
        
                  legend.append("text")
                    .attr("class", "mono")
                    .text(function(d) { return  Math.round(d); })
                    .attr("x", function(d, i) { return legendElementWidth * i + i*4 + 20; })
                    .attr("y", height + gridSize + 45);
    }

    getHeatmap1 = () => {
        // console.log(this.props.selectedHost, " /\n /", this.props.conditionMainArr, " /\n /", this.props.conditionSubArr, " /\n /", this.props.conditionDates)
        if(this.props.selectedHost || this.props.conditionDates){
            this.props.getUserOfTime(this.props.selectedHost, ["USER_OF_TIME"], this.props.conditionMainArr, this.props.conditionSubArr, this.props.conditionDates)
            .then(() => {
                this.drawChart()
            })
        }
    }

    render() {
        return (
            <div id="my_dataviz" className="w-100 d-flex justify-content-center" ref={this.chartRef}> 

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userOfTime: state.chart.d3.userOfTime,
        conditionMainArr: state.chart.userData.conditionMainArr,
        conditionSubArr: state.chart.userData.conditionSubArr,
        email: state.authentication.status.email,
        selectedHost: state.authentication.status.selectedHost,
        conditionDates: state.chart.userData.conditionDates
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUserOfTime: (host, type, conditionMainArr, conditionSubArr, conditionDates) => {
            return dispatch(getUserOfTime(host,type, conditionMainArr, conditionSubArr, conditionDates));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Heatmap);