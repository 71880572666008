import React, {Component} from "react"
import { connect } from "react-redux"
import { getCafe24Products, getGodomallProducts, getGodomallProductsAll } from '../../store/actions/authentication';
import Loading from "../../components/Loading"
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
const { SearchBar } = Search;
class PageName extends Component{
    constructor(props){
        super(props)
        // console.log("home constructor")
        this.state ={
            status: props.status
        }
    }


    render(){
        return(
            <div>
                pageNaming
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        status: state.authentication.status,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
      return {
        // getCafe24Products: (cafe24, productsNumber) => {
        //     return dispatch(getCafe24Products(cafe24, productsNumber));
        // },
        // getGodomallProducts: (godomall, productsNumber) => {
        //     return dispatch(getGodomallProducts(godomall, productsNumber));
        // },
        // getGodomallProductsAll: (status) => {
        //     return dispatch(getGodomallProductsAll(status));
        // },
      };
  };
  

export default connect(mapStateToProps, mapDispatchToProps)(PageName);