import React from 'react'
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import {FormControl} from 'react-bootstrap'
import moment from "moment"
import "./DateTimer.css"
import { faChevronLeft, faChevronRight, faCalendarAlt } from "@fortawesome/free-solid-svg-icons"
import { faClock } from "@fortawesome/free-regular-svg-icons"
import { library, icon } from "@fortawesome/fontawesome-svg-core"
import { getFunnel, setDates, setLoading } from '../store/actions/chart';
import { connect } from "react-redux"

class DateTimer extends React.Component {
    constructor(props){
        super(props);
        library.add(faChevronLeft, faChevronRight, faCalendarAlt, faClock)
        let now = new Date();
        let today = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0,0,0,0));
        let start = moment(today).subtract(1, "days");
        let end = moment(today).add(1, "days").subtract(1, "seconds");
        let ranges = {
            "오늘": [moment(today), moment(end)],
            "어제": [moment(today).subtract(1, "days"), moment(end).subtract(1, "days")],
            "지난 주": [moment(today).subtract(1, "weeks").startOf('week'), moment(today).subtract(1, "weeks").endOf('week')],
            "지난 달": [moment(today).subtract(1, "months").startOf('month'), moment(today).subtract(1, "months").endOf('month')],
            "최근 7일": [moment(today).subtract(8, "days"), moment(end).subtract(1, "days")],
            "지난 30일": [moment(today).subtract(31, "days"), moment(end).subtract(1, "days")],
        }
        let local = {
            "format":"YYYY-MM-DD HH:mm",
            "sundayFirst" : false,
            "days" : ['월', '화', '수', '목', '금', '토', '일'],
            "months": ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"],
        }
        let maxDate = moment(end)
        this.state = {
            start : start,
            end : end,
            value: `${start.format("YYYY-MM-DD HH:mm")} - ${end.format("YYYY-MM-DD HH:mm")}`,
            ranges: ranges,
            local: local,
            maxDate: maxDate,
            selectedHost: this.props.selectedHost
        }


        this.applyCallback = this.applyCallback.bind(this);
    }

    componentDidUpdate(){
        //새로고침 되돌리기
        if(this.props.selectedHost !== this.state.selectedHost){
            this.applyCallback(this.state.start, this.state.end)
        }
    }

    componentDidMount(){
        const leftArrowContainer = document.createElement('span');
        const leftArrowContainer2 = document.createElement('span');
        const rightArrowContainer = document.createElement('span');
        const rightArrowContainer2 = document.createElement('span');
        const clockContainer = document.createElement('span')
        const clockContainer2 = document.createElement('span')
        const calendarContainer = document.createElement('span')
        const calendarContainer2 = document.createElement('span')

        calendarContainer.setAttribute("class", "glyphicon font-calender")
        calendarContainer2.setAttribute("class", "glyphicon font-calender")
        clockContainer.setAttribute("class", "font-clock")
        clockContainer2.setAttribute("class", "font-clock")

        leftArrowContainer.innerHTML = icon({ prefix: 'fas', iconName: 'chevron-left' }).html;
        leftArrowContainer2.innerHTML = icon({ prefix: 'fas', iconName: 'chevron-left' }).html;
        rightArrowContainer.innerHTML = icon({ prefix: 'fas', iconName: 'chevron-right' }).html;
        rightArrowContainer2.innerHTML = icon({ prefix: 'fas', iconName: 'chevron-right' }).html;
        clockContainer.innerHTML = icon({ prefix: 'far', iconName: 'clock' }).html;
        clockContainer2.innerHTML = icon({ prefix: 'far', iconName: 'clock' }).html;
        calendarContainer.innerHTML = icon({ prefix: 'fas', iconName: 'calendar-alt'}).html;
        calendarContainer2.innerHTML = icon({ prefix: 'fas', iconName: 'calendar-alt'}).html;

        var leftArrow = document.getElementsByClassName("glyphicon-chevron-left")[0]
        var leftArrow2 = document.getElementsByClassName("glyphicon-chevron-left")[1]
        var rightArrow = document.getElementsByClassName("glyphicon-chevron-right")[0]
        var rightArrow2 = document.getElementsByClassName("glyphicon-chevron-right")[1]
        var calendar = document.getElementsByClassName('calendarAddon')[0]
        var calendar2 = document.getElementsByClassName('calendarAddon')[1]
        var clock = document.getElementsByClassName('timeContainer')[0]
        var clock2 = document.getElementsByClassName('timeContainer')[1]

        leftArrow.appendChild(leftArrowContainer)
        leftArrow2.appendChild(leftArrowContainer2)
        rightArrow.appendChild(rightArrowContainer)
        rightArrow2.appendChild(rightArrowContainer2)
        calendar.insertBefore(calendarContainer, calendar.firstChild)
        calendar2.insertBefore(calendarContainer2, calendar2.firstChild)
        clock.appendChild(clockContainer)
        clock2.appendChild(clockContainer2)

        this.applyCallback(this.state.start, this.state.end)
    }

    checkDataLoading = () => {
        const {userCount, sessionCount, sessionTime, pageView, deviceCount, funnelCount, flowOfUser, realTime, numberOfRun} = this.state
        if(userCount && sessionCount && sessionTime && pageView && deviceCount && funnelCount && flowOfUser && realTime && numberOfRun){
            this.props.setLoading(false)
        }
    }

    applyCallback(startDate, endDate){
        console.log("apply callback@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@")
        if(this.props.selectedHost){
            this.setState({
                    start: startDate,
                    end: endDate,
                    value:`${startDate.format("YYYY-MM-DD HH:mm")} - ${endDate.format("YYYY-MM-DD HH:mm")}`,
                    selectedHost: this.props.selectedHost
                }
            )
            var start = new Date(startDate._d.getTime()+ (3600000*9)).toISOString()
            var end = new Date(endDate._d.getTime()+ (3600000*9)).toISOString()
            var dates = [start, end]
            this.props.setDates(dates)
            this.props.setLoading(true)
            this.setState({
                userCount:false,
                sessionCount:false,
                sessionTime:false,
                pageView:false,
                deviceCount:false,
                funnelCount:false,
                flowOfUser:false,
                realTime:false,
                numberOfRun:false,
            })
            if(this.props.selectFunnel){
                this.props.getFunnel(this.props.selectedHost, [this.props.selectFunnel], this.props.conditionMainArr, this.props.conditionSubArr, dates)
                .then((result) => {
                    if(result){
                        if(result.success){
                            this.setState({
                                userCount:true,
                                sessionCount:true,
                                sessionTime:true,
                                pageView:true,
                                deviceCount:true,
                                funnelCount:true,
                                flowOfUser:true,
                                realTime:true,
                                numberOfRun:true,
                            })
                            this.checkDataLoading()
                        }
                    }
                })
            } else {
                this.props.getFunnel(this.props.selectedHost, ["SESSION_COUNT"], this.props.conditionMainArr, this.props.conditionSubArr, dates)
                .then((result) => {if(result){if(result.success){console.log("2번");this.setState({sessionCount:true}); this.checkDataLoading()}}})
                this.props.getFunnel(this.props.selectedHost, ["USER_COUNT"], this.props.conditionMainArr, this.props.conditionSubArr, dates)
                .then((result) => {if(result){if(result.success){console.log("1번");this.setState({userCount:true}); this.checkDataLoading()}}})
                this.props.getFunnel(this.props.selectedHost, ["SESSION_TIME"], this.props.conditionMainArr, this.props.conditionSubArr, dates)
                .then((result) => {if(result){if(result.success){console.log("4번");this.setState({sessionTime:true}); this.checkDataLoading()}}})
                this.props.getFunnel(this.props.selectedHost, ["PAGE_VIEW"], this.props.conditionMainArr, this.props.conditionSubArr, dates)
                .then((result) => {if(result){if(result.success){console.log("5번");this.setState({pageView:true}); this.checkDataLoading()}}})
                this.props.getFunnel(this.props.selectedHost, ["DEVICE_COUNT"], this.props.conditionMainArr, this.props.conditionSubArr, dates)
                .then((result) => {if(result){if(result.success){console.log("6번");this.setState({deviceCount:true}); this.checkDataLoading()}}})
                this.props.getFunnel(this.props.selectedHost, ["FUNNEL_COUNT",], this.props.conditionMainArr, this.props.conditionSubArr, dates)
                .then((result) => {if(result){if(result.success){console.log("7번");this.setState({funnelCount:true}); this.checkDataLoading()}}})
                this.props.getFunnel(this.props.selectedHost, ["FLOW_OF_USER"], this.props.conditionMainArr, this.props.conditionSubArr, dates)
                .then((result) => {if(result){if(result.success){console.log("8번");this.setState({flowOfUser:true}); this.checkDataLoading()}}})
                this.props.getFunnel(this.props.selectedHost, ["REAL_TIME"], this.props.conditionMainArr, this.props.conditionSubArr, dates)
                .then((result) => {if(result){if(result.success){console.log("9번");this.setState({realTime:true}); this.checkDataLoading()}}})
                this.props.getFunnel(this.props.selectedHost, ["NUMBER_OF_RUN"], this.props.conditionMainArr, this.props.conditionSubArr, dates)
                .then((result) => {if(result){if(result.success){console.log("3번");this.setState({numberOfRun:true}); this.checkDataLoading()}}})
                this.props.getFunnel(this.props.selectedHost, ["PAGE_MOVING"], this.props.conditionMainArr, this.props.conditionSubArr, dates)
                .then((result) => {if(result){if(result.success){console.log("10번");this.setState({numberOfRun:true}); this.checkDataLoading()}}})
            }
        }

    }

    render(){
            return(
                <div>
                    <DateTimeRangeContainer 
                        ranges={this.state.ranges}
                        start={this.state.start}
                        end={this.state.end}
                        local={this.state.local}
                        maxDate={this.state.maxDate}
                        applyCallback={this.applyCallback}
                        // smartMode
                        leftMode
                        noMobileMode
                        // forceMobileMode
                    >    
                        <FormControl
                        id="formControlsTextB"
                        type="text"
                        label="Text"
                        placeholder="Enter text"
                        value={this.state.value}
                        onChange={this.applyCallback}
                        disabled={false}
                        style={{width:"300px", cursor: "pointer"}}
                        /> 
                    </DateTimeRangeContainer>
                </div>
            );
        }
}

const mapStateToProps = (state) => {
    return {
      conditionMainArr: state.chart.userData.conditionMainArr,
      conditionSubArr: state.chart.userData.conditionSubArr,
      selectedHost: state.authentication.status.selectedHost,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
      return {
          getFunnel: (host, type, conditionMainArr, conditionSubArr, dates) => {
              return dispatch(getFunnel(host,type, conditionMainArr, conditionSubArr, dates));
          },
          setDates: (dates) => {
            return dispatch(setDates(dates));
          },
          setLoading: (bool) => {
              return dispatch(setLoading(bool));
          }
      };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(DateTimer);