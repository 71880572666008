const initialState = {
    newABTestName: "",
    newRecommendationName: "",
    abTests: [],
    recommendations: [],
    homeMenu: {
        menus: [
            {
                name: "",
                subMenus: [],
            },
        ],
        mainCategories : ["Revisit or not", "Scroll depth", "Device category"],
        subCategories: {
            "Revisit or not": ["Number of revisit", "Time of revisit"],
            "Scroll depth": ["10-30 %", "31-50 %", "51-70 %"],
            "Device category": ["PC", "Mobile", "Tablet"]
        },
        subIsFinish: ["Scroll depth", "Device category"],
        needCompare: ["Scroll depth", "Number of revisit", "Time of revisit"],
        compareValue: ["is", "at least", "at most"],
        lastCategories: { // is일 때 보여줄 값들,, (이상,이하에서는 직접입력)
            "Number of revisit": ["1-3 Times", "4-6 Times", "7-9 Times"],
            "Time of revisit": ["1-2 Hours", "2-3 Hours", "3-4 Hours"],
        }
    },
    who: {
        menus: [
            {
                name: "",
                subMenus: [],
            },
        ],
        mainCategories : ["Age Group", "Gender", "Membership Lv"],
        subCategories: {
            "Age Group": ["18-25 Years Old", "26-35 Years Old", "36-45 Years Old"],
            "Gender": ["Men", "Women"],
            "Membership Lv": ["Silver", "Gold", "VIP"]
        },
        subIsFinish: ["Age Group", "Gender", "Membership Lv"],
        needCompare: ["Age Group"],
        compareValue: ["is", "at least", "at most"],
        lastCategories: { // is일 때 보여줄 값들,, (이상,이하에서는 직접입력)
            
        }
    },
    what: {
        menus: [
            {
                name: "",
                subMenus: [],
            },
        ],
        mainCategories : ["Recommendation goods"],
        subCategories: {
            "Recommendation goods": ["Type A", "Type B"],
        },
        subIsFinish: ["Recommendation goods", "Coupon"],
        needCompare: [],
        compareValue: ["is", "at least", "at most"],
        lastCategories: { // is일 때 보여줄 값들,, (이상,이하에서는 직접입력)
            
        }
    },
    where: {
        menus: [
            {
                name: "",
                subMenus: [],
            },
        ],
        mainCategories : ["Page Type"],
        subCategories: {
            "Page Type": ["homepage", "category", "product", "cart"],
        },
        subIsFinish: ["Page Type"],
        needCompare: [],
        compareValue: ["is", "at least", "at most"],
        lastCategories: { // is일 때 보여줄 값들,, (이상,이하에서는 직접입력)
            
        }
    },
    when: {
        menus: [
            {
                name: "",
                subMenus: [],
            },
        ],
        mainCategories : ["Time"],
        subCategories: {
            "Time": ["day", "night"],
        },
        subIsFinish: ["Time"],
        needCompare: [],
        compareValue: ["is", "at least", "at most"],
        lastCategories: { // is일 때 보여줄 값들,, (이상,이하에서는 직접입력)
            
        }
    },
    abDivision: {
        menus: [
            {
                name: "",
                subMenus: [],
            },
        ],
        mainCategories: ["할당량 지정", "페이지 뷰 횟수"],
        subCategories: {
            "할당량 지정": ["0%", "10%", "20%", "30%", "40%", "50%", "60%", "70%", "80%", "90%", "100%"],
            "페이지 뷰 횟수": ["3회 초과", "5회 초과", "7회 초과", "9회 초과"],
            // "나이": ["10대", "20대", "30대", "40대"],
            // "성별": ["남자", "여자"],
            // "시점": ["12시~15시", "15시~18시", "18시~21시", "21시~24시", "00시~06시"]
        },
        subIsFinish: ["할당량 지정", "페이지 뷰 횟수"],
        needCompare: [],
        compareValue: ["is", "at least", "at most"],
        lastCategories: {}
    }

};
 
export default function condition(state = initialState, action) {
  switch(action.type) {
    case "AB_SET_SUCCESS":
        return {
            ...state,
            newABTestName: action.testName
        }
    case "AB_REQUEST_SUCCESS":
        return {
            ...state,
            abTests: action.result
        }
    case "REC_SET_SUCCESS":
        return {
            ...state,
            newRecommendationName: action.recommendationName
        }
    case "REC_REQUEST_SUCCESS":
        return {
            ...state,
            recommendations: action.result
        }
    default:
      return state;
  }
}