import React from "react"
import "./ABCreate.css"
import { connect } from "react-redux"
import SelectionBoxs from "../../components/SelectionBoxs"
import { setABCondition } from "../../store/actions/condition"
import { changePage } from "../../store/actions/authentication"
import Navbar from "../../components/Navbar"
import { faPlus, faUserCircle, faTimes, faChevronRight, faChevronLeft, faChevronUp, faChevronDown, faUsers, faCogs, faSpellCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getRecommendation } from "../../store/actions/condition"

class ABCreate extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            abDivision: JSON.parse(JSON.stringify(this.props.abDivision)), //selection menus
            abDivisionName: "",
            allocationCount: 0,
            pageViewCount: 0,
            countA: 50,
            countB: 50,
            setCategories: "A와 동일",
            selectedRec: "",
            selectedRecName: "",
            abTestName: "",
            who: [],
            testA: [],
            testB: [],
            where: [],
            when: [],
            experienceName: "experienceA",
            algoName: "알고리즘 1",
            algoBoxColor: "primary",
            categories : ["신발", "패션소품", "상의", "하의", "쥬얼리", "헤어악세사리", "메이크업", "가발", "신발", "패션소품", "상의", "하의", "쥬얼리", "헤어악세사리", "메이크업", "가발"],
            recommendations: this.props.recommendations,
            selectedHost: this.props.selectedHost,
        }
        this.props.getRecommendation(this.props.email, this.props.selectedHost)

        this.experienceA_header = React.createRef()
        this.experienceB_header = React.createRef()
        this.setting_header = React.createRef()
        this.experienceA_body = React.createRef()
        this.experienceB_body = React.createRef()
        this.setting_body = React.createRef()
        this.algoBox = React.createRef()
    }

    componentDidUpdate(){
        if(this.state.selectedHost !== this.props.selectedHost){
            this.setState({selectedHost: this.props.selectedHost})
            this.props.getRecommendation(this.props.email, this.props.selectedHost)
        }
    }

    componentDidMount() {
        // changeCount 버튼 누를 때 주변 input 하이라이트 방지
        var countBoxs = document.getElementsByClassName("count--box")
        for(var i = 0; i < countBoxs.length; i++){
            countBoxs[i].addEventListener('mousedown', function(e){e.preventDefault();}, false)
        }
    }

    setABCondition = () => {
        const { abTestName, selectedRecName, algoName, categories, abDivision, abDivisionName, countB, pageViewCount} = this.state
        const { email, selectedHost } = this.props
        var abDivisionNumber = 0;
        if(abDivisionName === "할당량 지정"){
            abDivisionNumber = countB;
        } else if (abDivisionName === "페이지 뷰 횟수"){
            abDivisionNumber = pageViewCount;
        }
        var testB = {
            algorithm: algoName,
            categories: categories,
            abDivision: abDivision.menus,
            abDivisionName: abDivisionName,
            abDivisionNumber: abDivisionNumber,
            isPlay: true,
            lastModify: new Date(new Date().getTime() + (3600000*9))
        }
        var tests = [testB]

        this.props.setABCondition(selectedRecName, abTestName, tests, email, selectedHost)
        .then(()=> {
            if(this.props.newABTestName === abTestName){
                alert("Success! Please check new A/B TEST")
                this.setState({abDivision: this.props.abDivision})
                this.props.changePage('abShowing')
                this.props.props.history.push('/dashboard/abShowing')
            }
        })
    }

    getAllCondition = (menus, stateName) => {
        

        // if(menus.menus[0].name === "할당량 지정"){
        //     var countB = parseInt(menus.menus[0].subMenus[0].name.replace("%", ""));
        //     var countA = 100 - countB
        //     var abDivisionName = menus.menus[0].name
        //     this.setState({countA: countA, countB:countB, abDivisionName:abDivisionName})
        // } else if(menus.menus[0].name === "페이지 뷰 횟수"){
        //     var pageViewCount = parseInt(menus.menus[0].subMenus[0].name.replace("회 초과", ""))
        //     var abDivisionName = menus.menus[0].name
        //     this.setState({pageViewCount: pageViewCount, abDivisionName:abDivisionName})
        // }

        this.setState(state=> {
            var obj = {}
            if(menus.menus[0].name === ""){
                obj[stateName] = [];
            } else {
                obj[stateName] = menus;
            }

            if(menus.menus[0].name === "할당량 지정"){
                obj["countB"] = parseInt(menus.menus[0].subMenus[0].name.replace("%", ""));
                obj["countA"] = 100 - parseInt(menus.menus[0].subMenus[0].name.replace("%", ""));
                obj["abDivisionName"] = menus.menus[0].name;
            } else if(menus.menus[0].name === "페이지 뷰 횟수"){
                obj["pageViewCount"] = parseInt(menus.menus[0].subMenus[0].name.replace("회 초과", ""));
                obj["abDivisionName"] = menus.menus[0].name;
            }
            return obj
        })

    }

    handleChange = event => {
        this.setState({
          [event.target.name]: event.target.value
        });
    }

    changeABExperience = (direction) => {
        const { experienceName } = this.state
        if(experienceName === "experienceA"){
            this.experienceA_header.current.classList.remove('now--experience')
            this.experienceA_body.current.classList.add('now--hide')
            this.experienceB_header.current.classList.add('now--experience')
            this.experienceB_body.current.classList.remove('now--hide')
            this.setState({experienceName: "experienceB"})
        } else if (experienceName === "setting"){
            this.setting_header.current.classList.remove('now--experience')
            this.setting_body.current.classList.add('now--hide')
            this.experienceB_header.current.classList.add('now--experience')
            this.experienceB_body.current.classList.remove('now--hide')
            this.setState({experienceName: "experienceB"})
        } else {
            this.experienceB_header.current.classList.remove('now--experience')
            this.experienceB_body.current.classList.add('now--hide')
            if(direction === "prev"){
                this.experienceA_header.current.classList.add('now--experience')
                this.experienceA_body.current.classList.remove('now--hide')
                this.setState({experienceName: "experienceA"})
            }else {
                this.setting_header.current.classList.add('now--experience')
                this.setting_body.current.classList.remove('now--hide')
                this.setState({experienceName: "setting"})
            }
        }
    }

    changeRecAlgorithm = (e) => {
        var algoName = ""
        if(e.target.nodeName === "SPAN"){
            algoName = e.target.parentNode.childNodes[1].data
        } else {
            algoName = e.target.childNodes[1].data
        }

        this.algoBox.current.classList.remove(`btn-${this.state.algoBoxColor}`)
        if(algoName === "알고리즘 1") {
            this.algoBox.current.classList.add("btn-primary")
            this.setState({
                algoBoxColor: "primary",
                algoName: algoName
            })
        } else if(algoName === "알고리즘 2") {
            this.algoBox.current.classList.add("btn-success")
            this.setState({
                algoBoxColor: "success",
                algoName: algoName
            })
        } else if(algoName === "알고리즘 3"){
            this.algoBox.current.classList.add("btn-danger")
            this.setState({
                algoBoxColor: "danger",
                algoName: algoName
            })
        } else if(algoName === "알고리즘 4"){
            this.algoBox.current.classList.add("btn-warning")
            this.setState({
                algoBoxColor: "warning",
                algoName: algoName
            })
        }
    }

    deleteCategories = (idx) => {
        const { categories } = this.state;
        categories.splice(idx, 1)
        this.setState(categories)
    }
    
    selectRec = event => {
        const { selectedRec } = this.state
        var target = event.target
        if(!event.target.classList.contains("btn--box")){
            target = event.target.parentNode
        }
        
        if(selectedRec === ""){
            target.style.backgroundColor= ""
            // target.classList.remove("btn-secondary")
            target.classList.add("btn-primary")
            this.setState({selectedRec: target, selectedRecName: target.firstElementChild.innerText})
        } else {
            if(selectedRec !== target){
                selectedRec.classList.remove("btn-primary")
                // selectedRec.classList.add("btn-secondary")
                selectedRec.style.backgroundColor = "darkgrey"
                // target.classList.remove("btn-secondary")
                target.style.backgroundColor = ""
                target.classList.add("btn-primary")
                this.setState({selectedRec: target, selectedRecName: target.firstElementChild.innerText})
            }
        }
    }

    changeCount = (ab, updown) => {
        const { countA, countB } = this.state;
        var A, B;
        if(this.state.abDivision.menus[0]){
            if(this.state.abDivision.menus[0].name === "할당량 지정"){
                if(ab === "a"){
                    if(updown ==="up"){
                        A = countA + 1
                        B = countB - 1
                    }else{
                        A = countA - 1
                        B = countB + 1
                    }
                }else{
                    if(updown==="up"){
                        A = countA - 1
                        B = countB + 1
                    }else{
                        A = countA + 1
                        B = countB - 1
                    }
                }
                if(A >= 0 && A <= 100){
                    this.setState({
                        countA: A,
                        countB: B
                    })
                }
            } else {
                if(updown === "up"){
                    this.setState({pageViewCount: this.state.pageViewCount + 1})
                }else {
                    this.setState({pageViewCount: this.state.pageViewCount - 1})
                }
            }
        }
        
    }

    inputCount = event => {
        var result = parseInt(event.target.value.replace("%", ""))
        if(isNaN(result) === false){
            if(event.target.name === "countA"){
                if(result > 100){
                    this.setState({
                        [event.target.name]: 100,
                        countB: 0
                    });
                }else if( result < 0){
                    this.setState({
                        [event.target.name]: 0,
                        countB: 100
                    });
                } else {
                    this.setState({
                        [event.target.name]: result,
                        countB: 100-result
                    });
                }
            } else {
                if(result > 100){
                    this.setState({
                        [event.target.name]: 100,
                        countA: 0
                    });
                }else if( result < 0){
                    this.setState({
                        [event.target.name]: 0,
                        countA: 100
                    });
                } else {
                    this.setState({
                        [event.target.name]: result,
                        countA: 100-result
                    });
                }
            }
        } else {
            this.setState({
                countA: 50,
                countB: 50
            })
        }
    }

    checkNext = (num) => {
        if(num === 1){
            if(this.state.abTestName === ""){
                alert("A/B테스트 이름을 입력해주세요")
            } else if (this.state.selectedRec === ""){
                alert("추천 상품 한 가지를 선택해주세요")
            } else {
                this.changeABExperience()
            }
        } else if (num === 2) {
            if(this.state.abDivision.menus[0].name === ""){
                alert("A/B 구분 기준을 선택해주세요")
            } else {
                this.changeABExperience()
            }
        }
    }

    render(){
        const { recommendations } = this.props
        return (
            <>
            <div className="ab--create--progress">
                <Navbar type="2" title ="A/B Test" title2 = "Create" />
            </div>
            <div className="ab--create--title">
                <h4 style={{margin:"0"}}>New A/B Test Widget</h4>
            </div>
            <div className="ab--create--con">
                <div className="ab--con--line">
                    <div className="ab--line"></div>
                    <div className="ab--circle"><FontAwesomeIcon style={{color:"darkgray"}} icon={faPlus} size="sm"/></div>
                    <div className="ab--line2"></div>
                    <div className="ab--circle2">
                        <FontAwesomeIcon className="ab--font" icon={faUserCircle} size="2x"/>
                        <div className="ab--line3"></div>
                    </div>
                </div>
                <div className="ab--container" >
                    <div className="ab--header">
                        <h5>New A/B Test Experience</h5>
                        <div className="d-flex pt-4">
                            <div ref={this.experienceA_header} className="ab--header--div now--experience">1</div>
                            <div className="font-weight-bold"> Experience A</div>
                            <div> ―――― </div>
                            <div ref={this.experienceB_header} className="ab--header--div ml-2">2</div>
                            <div className="font-weight-bold"> Experience B</div>
                            <div> ―――― </div>
                            <div ref={this.setting_header} className="ab--header--div ml-2">3</div>
                            <div className="font-weight-bold"> Setting</div>
                        </div>
                    </div>
                    <div className="ab--body">

                        {/* experienceA */}
                        <div ref={this.experienceA_body}>
                            <div className="mb-4">
                                <h6 className="text-dark">* A/B Test Name</h6>
                                <input
                                    style={{border:0, borderBottom:"1px solid gray", width:"30%"}}
                                    name='abTestName'
                                    placeholder='Enter Test Name'
                                    value={this.state.abTestName}
                                    onChange={this.handleChange}  
                                    autoComplete="off"  
                                />
                            </div>
                            <div className="mb-3" style={{width:"80%"}}>
                                <h5 className="text-dark font-weight-bold">* 현재 작동중인 추천상품 (A/B테스트 대상 상품을 선택해주세요)</h5>
                                {
                                    recommendations.map((rec, index) => {
                                        if(rec.done === "On"){
                                            return (
                                                <div key={index} onClick={(e) => this.selectRec(e)} className="btn--box btn mr-3 mb-3" style={{padding:"0 10px", backgroundColor:"darkgrey"}}>
                                                    <div style={{textAlign:"left", color:"white", borderBottom:"1px solid white", height:"inherit", padding:"7px 0", margin:"0"}} className="h5">{rec.recommendationName}</div>
                                                    <div style={{textAlign:"right", color:"white", height:"inherit", padding:"7px 0", margin:"0"}} className="h6">{rec.algorithm}</div>
                                                </div>
                                            )
                                        } else {return ""}
                                    })
                                }
                            </div>
                        </div>

                        {/* experienceB */}
                        <div ref={this.experienceB_body} className="now--hide">
                            {/* <div className="mb-3">
                                <h6 className="text-dark">* Who ?</h6>
                                <SelectionBoxs menus={this.props.who} onSubmit={(menus)=>this.getAllCondition(menus, "who")} />
                            </div> */}
                            {/* <div className="mb-3">
                                <h6 className="text-dark">* When ?</h6>
                                <SelectionBoxs menus={this.props.when} onSubmit={(menus)=>this.getAllCondition(menus, "when")}/>
                            </div> */}
                            <div className="mb-3">
                                <h6 className="text-dark">* A/B 구분 기준</h6>
                                <SelectionBoxs notAndOr={true} menus={this.state.abDivision} onSubmit={(menus)=>this.getAllCondition(menus, "abDivision")} />
                            </div>
                            <div className="mb-3">
                                <h6 className="font-weight-bold">* 알고리즘</h6>
                                <div ref={this.algoBox} className="btn--box btn btn-primary">
                                    <div style={{textAlign:"left", color:"white"}}>All widget slots</div>
                                    <button type="button" className="btn--algo d-flex btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <div>
                                            {this.state.algoName}
                                        </div>
                                    </button>
                                    <div className="dropdown-menu">
                                        <a onClick={this.changeRecAlgorithm} className="dropdown-item" href="#/dashboard/abSetting"><span className="h5 text-primary">■&nbsp;</span>알고리즘 1</a>
                                        <a onClick={this.changeRecAlgorithm} className="dropdown-item" href="#/dashboard/abSetting"><span className="h5 text-success">■&nbsp;</span>알고리즘 2</a>
                                        <a onClick={this.changeRecAlgorithm} className="dropdown-item" href="#/dashboard/abSetting"><span className="h5 text-danger">■&nbsp;</span>알고리즘 3</a>
                                        <a onClick={this.changeRecAlgorithm} className="dropdown-item" href="#/dashboard/abSetting"><span className="h5 text-warning">■&nbsp;</span>알고리즘 4</a>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <h6 className="font-weight-bold">* 상품군</h6>
                                <ul className="nav side--navbar--nav d-flex">
                                    <li className="d-flex flex-column">
                                        <div className="container--div d-flex">
                                            <div className="main--div h-100 d-flex flex-column">
                                                <div className="nav-item dropdown drop--div">
                                                    <input onChange={this.handleChange} value={this.state.setCategories} autoComplete="off" placeholder="Select Condition"  id="dropmenu1" className="drop--input"  data-toggle="dropdown" aria-haspopup="true"/>
                                                    <button className="drop--toggle bg-white dropdown-toggle dropdown-toggle-split" id="dropmenu1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"/>
                                                    <div className="dropdown-menu" aria-labelledby="dropmenu1" draggable="false">
                                                        <a href="#/dashboard/abSetting" onClick={(e)=> this.setState({setCategories: e.target.innerText})} className="dropdown-item" >A와 동일</a>
                                                        <a href="#/dashboard/abSetting" onClick={(e)=> this.setState({setCategories: e.target.innerText})} className="dropdown-item" >재설정</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                {
                                    this.state.setCategories === "재설정" ? 
                                    <div className="categoris--menu mt-1">
                                        {
                                            this.state.categories.map(
                                                (categorie, idx) => 
                                                <div key={idx} className="btn btn-secondary categoris--item">
                                                    {categorie} <FontAwesomeIcon onClick={() => this.deleteCategories(idx)} icon={faTimes}/>
                                                </div>
                                            )
                                        }
                                    </div>
                                    : ""
                                }
                            </div>
                        </div>

                        {/* experience - setting */}
                        <div ref={this.setting_body} className="now--hide">
                            <div className="mb-3">
                                <div className="d-flex justify-content-between" style={{width:"780px"}}>
                                    <h5 className="text-dark mb-4 font-weight-bold">* 고객</h5>
                                    <h5 className="text-dark mb-4 font-weight-bold pl-5">* 알고리즘</h5>
                                    <h5 className="text-dark mb-4 font-weight-bold pr-5">{this.state.abDivision.menus[0] ? "* " + this.state.abDivision.menus[0].name : "no selected"}</h5>
                                </div>
                                <div className="d-flex">
                                    <div className="d-flex flex-column">
                                        <div className="setting--box btn btn-light d-flex mb-5">
                                            <div style={{width:"30%", borderRight:"2px solid rgba(122,122,122,0.4)"}} className="d-flex flex-column align-items-center justify-content-center">
                                                <FontAwesomeIcon icon={faUsers} size="2x" style={{color:"gray"}}/>
                                                <div className="font-weight-bold">A</div>
                                            </div>
                                            <div style={{width:"70%"}} className="d-flex flex-column justify-content-center align-items-center">
                                                <div className="d-flex flex-column">
                                                    <div className="font-weight-bold">추천상품 이름?</div>
                                                    <span>{this.state.selectedRecName}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="setting--box btn btn-light d-flex">
                                            <div style={{width:"30%", borderRight:"2px solid rgba(122,122,122,0.4)"}} className="d-flex flex-column align-items-center justify-content-center">
                                                <FontAwesomeIcon icon={faUsers} size="2x" style={{color:"gray"}}/>
                                                <div className="font-weight-bold">B</div>
                                            </div>
                                            <div style={{width:"70%"}} className="d-flex flex-column justify-content-center align-items-center">
                                                <div className="d-flex flex-column">
                                                    <div className="font-weight-bold">A/B 구분 기준?</div>
                            <span>{this.state.abDivisionName === "할당량 지정" ? this.state.abDivisionName + ": " + this.state.countB + "%" : this.state.abDivisionName + ": ＞" + this.state.pageViewCount}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column justify-content-center">
                                        <div className="d-flex align-items-center h-25 card--line" style={{marginBottom:"108%"}}>―――――</div>
                                        <div className="d-flex align-items-center h-25 card--line">―――――</div>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <div className="setting--box btn btn-light d-flex mb-5">
                                            <div style={{width:"30%", borderRight:"2px solid rgba(122,122,122,0.4)"}} className="d-flex flex-column align-items-center justify-content-center">
                                                <FontAwesomeIcon icon={faCogs} size="2x" style={{color:"gray"}}/>
                                                <div className="font-weight-bold">A</div>
                                            </div>
                                            <div style={{width:"70%"}} className="d-flex flex-column justify-content-center align-items-center">
                                                <div className="font-weight-bold">알고리즘?</div>
                                                <div>알고리즘1</div>
                                            </div>
                                        </div>
                                        <div className="setting--box btn btn-light d-flex">
                                            <div style={{width:"30%", borderRight:"2px solid rgba(122,122,122,0.4)"}} className="d-flex flex-column align-items-center justify-content-center">
                                                <FontAwesomeIcon icon={faCogs} size="2x" style={{color:"gray"}}/>
                                                <div className="font-weight-bold">B</div>
                                            </div>
                                            <div style={{width:"70%"}} className="d-flex flex-column justify-content-center align-items-center">
                                                <div className="font-weight-bold">알고리즘?</div>
                                                <div>{this.state.algoName}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column justify-content-center">
                                        <div className="d-flex align-items-center h-25 card--line" style={{marginBottom:"108%"}}>―――――</div>
                                        <div className="d-flex align-items-center h-25 card--line">―――――</div>
                                    </div>
                                    <div className="d-flex flex-column justify-content-between">
                                        <div className="d-flex align-items-center count--container">
                                            <div className="count--box--con d-flex">
                                                <div className="d-flex flex-column w-50">
                                                    <div onClick={()=>this.changeCount("a", "up")} className="count--box h-50 d-flex align-items-center justify-content-center" style={{borderBottom: "1px solid rgba(122,122,122,.6)"}}>
                                                        <FontAwesomeIcon icon={faChevronUp} style={{color:"gray"}}/>
                                                    </div>
                                                    <div onClick={()=>this.changeCount("a", "down")} className="count--box h-50 d-flex align-items-center justify-content-center">
                                                        <FontAwesomeIcon icon={faChevronDown} style={{color:"gray"}}/>
                                                    </div>
                                                </div>
                                                <div className="w-50 d-flex align-items-center justify-content-center" style={{borderLeft: "1px solid rgba(122,122,122,.6)"}}>
                                                    <input onChange={this.inputCount} name="countA" className="count--input" value={this.state.abDivision.menus[0] ? this.state.abDivision.menus[0].name === "할당량 지정" ?  this.state.countA + "%" : "≤" + this.state.pageViewCount : "]"} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center count--container">
                                            <div className="count--box--con d-flex">
                                                <div className="d-flex flex-column w-50">
                                                    <div onClick={()=>this.changeCount("b", "up")} className="count--box h-50 d-flex align-items-center justify-content-center" style={{borderBottom: "1px solid rgba(122,122,122,.6)"}}>
                                                        <FontAwesomeIcon icon={faChevronUp} style={{color:"gray"}}/>
                                                    </div>
                                                    <div onClick={()=>this.changeCount("b", "down")} className="count--box h-50 d-flex align-items-center justify-content-center">
                                                        <FontAwesomeIcon icon={faChevronDown} style={{color:"gray"}}/>
                                                    </div>
                                                </div>
                                                <div className="w-50 d-flex align-items-center justify-content-center" style={{borderLeft: "1px solid rgba(122,122,122,.6)"}}>
                                                    <input onChange={this.inputCount} name="countB" className="count--input" value={this.state.abDivision.menus[0] ? this.state.abDivision.menus[0].name === "할당량 지정" ? this.state.countB + "%" : "＞" + this.state.pageViewCount : ""} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center" style={{width:"3%"}}>
                                        <div className="d-flex align-items-center w-100" style={{height:"60%", marginTop:"5px", border:"1px solid rgba(122,122,122,.6)", borderLeft:"0"}}>

                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center" style={{width:"3%"}}>
                                        <div className="d-flex align-items-center w-100" style={{marginTop:"1px", borderBottom:"1px solid rgba(122,122,122,.6)"}} >

                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center w-25">
                                        <div className="setting--box btn btn-light d-flex w-100">
                                        <div style={{width:"30%", borderRight:"2px solid rgba(122,122,122,0.4)"}} className="d-flex flex-column align-items-center justify-content-center">
                                            <FontAwesomeIcon icon={faSpellCheck} size="2x" style={{color:"gray"}}/>
                                        </div>
                                        <div style={{width:"70%"}} className="d-flex flex-column justify-content-center align-items-center">
                                            <div className="font-weight-bold">A/B TEST</div>
                                            <div>{this.state.abTestName}</div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ab--footer">
                        {
                            this.state.experienceName === "experienceA" ? 
                            <>
                                <button onClick={()=> this.checkNext(1)} type="button" className="btn btn-primary float-right mr-2">
                                    다음 <FontAwesomeIcon style={{marginBottom:"2px"}} size="xs" icon={faChevronRight}/>
                                </button>
                                <button onClick={()=> {this.props.changePage("abShowing"); this.props.props.history.push('/dashboard/abShowing')}} type="button" className="btn float-right mr-2 ml-2">
                                    취소
                                </button>
                            </>
                            : this.state.experienceName === "experienceB" ? 
                            <>    
                                <button onClick={()=>this.changeABExperience("prev")} type="button" className="btn btn-light float-left ml-2">
                                    <FontAwesomeIcon style={{marginBottom:"2px"}} size="xs" icon={faChevronLeft}/> 이전
                                </button>
                                <button onClick={()=> this.checkNext(2)} type="button" className="btn btn-primary float-right mr-2">
                                    다음 <FontAwesomeIcon style={{marginBottom:"2px"}} size="xs" icon={faChevronRight}/>
                                </button>
                                <button onClick={()=> {this.props.changePage("abShowing"); this.props.props.history.push('/dashboard/abShowing')}} type="button" className="btn float-right mr-2 ml-2">
                                    취소
                                </button>
                            </>
                            :
                            <>
                                <button onClick={this.changeABExperience} type="button" className="btn btn-light float-left ml-2">
                                    <FontAwesomeIcon style={{marginBottom:"2px"}} size="xs" icon={faChevronLeft}/> 이전
                                </button>
                                <button onClick={this.setABCondition} type="button" className="btn btn-primary float-right mr-2">
                                    저장 및 실행
                                </button>
                                <button onClick={()=> {this.props.changePage("abShowing"); this.props.props.history.push('/dashboard/abShowing')}} type="button" className="btn float-right mr-2 ml-2">
                                    취소
                                </button>
                            </>
                        }
                    </div>
                </div>
            </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        email: state.authentication.status.userEmail,
        selectedHost: state.authentication.status.selectedHost,
        newABTestName: state.condition.newABTestName,
        abDivision: state.condition.abDivision,
        recommendations: state.condition.recommendations,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changePage: (pageName) => {
            return dispatch(changePage(pageName))
        },
        setABCondition: (recommendationName, abTestName, tests, email, host) => {
            return dispatch(setABCondition(recommendationName, abTestName, tests, email, host))
        },
        getRecommendation: (email, host) => {
            return dispatch(getRecommendation(email, host))
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ABCreate);