import React from "react"
import * as d3 from "d3";
import { connect } from "react-redux"
import { getUserOfTime } from '../../store/actions/chart';

class Heatmap2 extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            continuousUserArr: this.props.continuousUser,
            myGrouops: this.props.myGroups,
            myVars: this.props.myVars,
            selectedHost: this.props.selectedHost,
        }
        // console.log("arrtest@@", this.state.coninuousUserArr)
        this.chartRef = React.createRef();
        // this.drawChart = this.drawChart.bind(this);
        this.getHeatmap2()
    }

    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.selectedHost !== nextState.selectedHost){
            this.setState({
                selectedHost: nextProps.selectedHost,
            })
            return true
        } else if(nextState.drawed === false){
            return true
        } else {return false}
    }

    componentDidUpdate(){
        // if(this.state.drawed){
            var svg = document.getElementById("heatmap2_svg")
            if(svg){
                svg.remove()
            }
            this.getHeatmap2()
            // this.setState({drawed:false})
        // }
    }

    drawChart = () => {
        this.setState({drawed:true})

        var margin = {top: 30, right: 30, bottom: 30, left: 120},
        width = 600 - margin.left - margin.right,
        height = 380 - margin.top - margin.bottom,
        colors = ["rgb(106, 228, 118)","rgb(75, 199, 87)","rgb(45, 175, 58)","rgb(22, 136, 34)"];
        

        // append the svg object to the body of the page
        var svg = d3.select("#continuous_user")
        .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom + 40)
        .attr("id", "heatmap2_svg")
        .append("g")
        .attr("transform", "translate(" + (margin.left+20) + "," + (margin.top + 20) + ")")
        .attr("class", "first_g");

        // Build X scales and axis:
        var x = d3.scaleBand()
        .range([ 0, width ])
        .domain(this.props.myGroups)
        .padding(0.07);
        svg.append("g")
        .attr("transform", "translate(0," + (height-345) + ")")
        .call(d3.axisBottom(x))

        // console.log("mybars", this.props.myVars)
        // Build X scales and axis:
        var y = d3.scaleBand()
        .range([ height, 0 ])
        .domain(this.props.myVars)
        .padding(0.07);
        svg.append("g")
        .attr("width", 80)
        .call(d3.axisLeft(y));

        // Build color scale
        // var myColor = d3.scaleLinear()
        // // .range(["white", "#69b3a2"])
        // .domain([1,25,50,74,100])
        // .range(colors)
        var myColor = (num, range) => {
            if(num === 0) {
                return "rgba(0,0,0,0.12)"
            } else if(num < range[0]){
                return colors[0]
            } else if (num < range[1]) {
                return colors[1]
            } else if (num < range[2]) {
                return colors[2]
            } else {
                return colors[3]
            }
        }

        var tooltip2 = d3.select("#continuous_user")
        .append("div")
        .style("opacity", 0)
        .attr("class", "tooltip")
        .style("background-color", "white")
        .style("border", "solid")
        .style("border-width", "1px")
        .style("border-radius", "5px")
        .style("padding", "5px")
    
        // Three function that change the tooltip when user hover / move / leave a cell
        var mouseover = function(d) {
            tooltip2
            .style("opacity", 1)
            d3.select(this)
            .style("stroke", "black")
            .style("opacity", 0.5)
        }
        var mousemove = function(d) {
            if(d.group === "5번째 일" || d.group ==="6번째 일"){
                tooltip2
                .html(`<small>${d.variable}*${d.group}</small><br/>사용자 ${d.count ? d.count.toLocaleString(): 0}명 <br/>사용자 유지율 ${d.value}%`)
                .style("left", (d3.mouse(this)[0]+35) + "px")
                .style("top", (d3.mouse(this)[1]+65) + "px")

            } else {
                tooltip2
                .html(`<small>"${d.variable}*${d.group}"</small><br/>사용자 ${d.count ? d.count.toLocaleString(): 0}명 <br/>사용자 유지율 ${d.value}%`)
                .style("left", (d3.mouse(this)[0]+ 190) + "px")
                .style("top", (d3.mouse(this)[1]+65) + "px")

            }
        }
        var mouseleave = function(d) {
            tooltip2
            .style("opacity", 0)
            d3.select(this)
            .style("stroke", "none")
            .style("opacity", 1)
        }

        //Read the data

            var colorScale = d3.scaleQuantile()
				.domain([0, (d3.max(this.state.continuousUserArr, function(d){return d.value;})/2), d3.max(this.state.continuousUserArr, function(d){return d.value;})])
				.range(colors);

            svg.selectAll()
                .data(this.state.continuousUserArr, (d) => d.group+':'+d.variable)
                .enter()
                .append("rect")
                .attr("x", (d) =>  x(d.group) )
                .attr("y", (d) => y(d.variable))
                .attr("width", x.bandwidth() )
                .attr("height", y.bandwidth() )
                .attr("display", (d, i) => {
                    if((i%8) < Math.floor(i/8) || d.variable ==="모든 사용자"){
                        return "none"
                    } else {return "block"}
                })
                .style("fill", (d) => myColor(d.value, colorScale.quantiles()))
                .on("mouseover", mouseover)
                .on("mousemove", mousemove)
                .on("mouseleave", mouseleave)

            svg.selectAll()
                .data(this.state.continuousUserArr, (d) => d.group+':'+d.variable)
                .enter()
                .append("text")
                .attr("class", "mono")
                .text((d) => d.value + "%")
                .attr("x", (d) =>  x(d.group) + 17 )
                .attr("y", (d) => y(d.variable) + 25)
                .attr("display", (d) => {if(d.variable !== "모든 사용자"){return "none"}})

                svg.selectAll('.tick line')
                .attr("display", "none")
                svg.selectAll('path')
                .attr("display", "none")
                svg.selectAll('text')
                .attr("font-size", 13)
                .attr("font-weight", 500)
                
                svg.select("g")
                .insert('line', "g")
                .style("stroke", "darkgray")
                .style("stroke-width", 1)
                .attr("x1", -110)
                .attr("y1", -5)
                .attr("x2", 450)
                .attr("y2", -5)
                svg.select("g")
                .insert('line', "g")
                .style("stroke", "darkgray")
                .style("stroke-width", 1)
                .attr("x1", -110)
                .attr("y1", 30)
                .attr("x2", 450)
                .attr("y2", 30)
                svg.select("g")
                .insert('line', "g")
                .style("stroke", "darkgray")
                .style("stroke-width", 1)
                .attr("x1", -110)
                .attr("y1", 65.5)
                .attr("x2", 450)
                .attr("y2", 65.5)

                for(var idx = 0; idx < 7; idx++){
                    svg.select("g")
                    .insert('line', "g")
                    .style("stroke", "darkgray")
                    .style("stroke-width", 0.5)
                    .attr("x1", -110)
                    .attr("y1", 105.8 + idx*39.6)
                    .attr("x2", 450)
                    .attr("y2", 105.8 + idx*39.6)

                }
    }

    getHeatmap2 = () => {
        if(this.props.selectedHost){
            this.props.getUserOfTime(this.props.selectedHost, ["CONTINUOUS_USER"], this.props.conditionMainArr, this.props.conditionSubArr)
            .then(() => this.drawChart())
        }
    }

    render() {
        return (
            <div id="continuous_user" className="w-100 d-flex justify-content-center" ref={this.chartRef}> 

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        continuousUser: state.chart.d3.continuousUser,
        myGroups: state.chart.d3.myGroups,
        myVars: state.chart.d3.myVars,
        conditionMainArr: state.chart.userData.conditionMainArr,
        conditionSubArr: state.chart.userData.conditionSubArr,
        email: state.authentication.status.email,
        selectedHost: state.authentication.status.selectedHost,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUserOfTime: (host, type, conditionMainArr, conditionSubArr) => {
            return dispatch(getUserOfTime(host,type, conditionMainArr, conditionSubArr));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Heatmap2);