import React from "react"
import { connect } from 'react-redux';
import { loginRequest } from '../store/actions/authentication';
import { Link } from "react-router-dom"
import "./Login.css"
import { changePage } from "../store/actions/authentication"


class Login extends React.Component {
    state = {
        useremail: "",
        password: ""
    }

    componentDidMount(){
        localStorage.setItem("dashboard_page", "")
    }

    handleChange = event => {
        this.setState({
          [event.target.name]: event.target.value
        });
    }

    handleSubmit = event => {
        event.preventDefault()
        this.props.loginRequest(this.state.useremail, this.state.password)
        .then(
            () => {
                if(this.props.status === "SUCCESS"){
                    alert('Welcome, ' + this.props.currentUser + '!');
                    this.props.history.push('/dashboard');
                    this.props.changePage("home")
                } else {
                    let errorMessage = [
                        "PASSWORD IS NOT STRING",
                        "THERE IS NO USER",
                        "PASSWORD IS NOT CORRECT"
                    ]
                    alert(errorMessage[this.props.errorCode - 1]);
                }
            }
        )
    }

    render(){
        return (
            <div className="Login OutLine">
                <form onSubmit={this.handleSubmit}>
                    <div className="top">
                        <h1>Login</h1>
                    </div>
                    <div className="mid">
                        <div className="mid--email">
                            <label>User email</label>
                            <input
                                type="email"
                                name='useremail'
                                placeholder='Enter email'
                                value={this.state.useremail}
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className="mid--pwd">
                            <label>Password</label>
                            <input
                                type='password'
                                name='password'
                                placeholder='Enter password'
                                value={this.state.password}
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>
                    <div className="bot">
                        <input value="Submit" type='submit'/>
                        {/* <div>
                        <Facebook props={this.props}/>
                        <Google props={this.props}/>
                        </div> */}
                        {/* <p>New Here? <Link to="/register">Create an account</Link></p> */}
                    </div>
                </form>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        status: state.authentication.login.status,
        currentUser: state.authentication.status.currentUser,
        errorCode: state.authentication.login.error
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        loginRequest: (email, pw) => {
            return dispatch(loginRequest(email,pw));
        },
        changePage: (pageName) => {
            return dispatch(changePage(pageName))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);