import React, {Component} from "react"
// import "./Summary.css"
import { connect } from "react-redux"
import DateTimer_rec from "../../../components/DateTimer_rec"
import Loading from "../../../components/Loading"
import { getApiCall } from '../../../store/actions/recommendation'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import moment from "moment"
const { SearchBar } = Search;
class ApiCall extends Component{
    constructor(props){
        super(props)
        let now = new Date();
        let today = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0,0,0,0));
        let start = moment(today).subtract(7, "days");
        let end = moment(today).add(1, "days").subtract(1, "seconds");
        this.state ={
            loading: false,
            dates: [start, end],
            status: this.props.status,
            apiCall: this.props.apiCall
        }
    }

    componentDidMount(){
    }

    componentDidUpdate(){
        if(this.state.status !== this.props.status){
            this.setState({status: this.props.status})
            this.setDates(this.state.dates)
        }
        if(this.state.apiCall !== this.props.apiCall) {
            this.setState({apiCall: this.props.apiCall})
        }
    }

    setDates = (dates) => {
        this.setState({dates})
        if(this.state.status.isLoggedIn){
            this.props.getApiCall(dates, this.props.status)
        }
    }


    render(){
        const columns = [{
            dataField: 'rating',
            text: '순위',
            headerAlign: 'center',
            align: 'center',
            formatter: (cell, row) => {
                return (
                    <div>
                        <span className="h5">{cell}</span>
                    </div>
                )
            }
          }, {
            dataField: 'photoUrl',
            text: '이미지',
            headerAlign: 'center',
            align: 'center',
            formatter: (cell, row) => {
                return (
                    <img src={cell} style={{width:"80px"}}></img>
                )
            }
          }, {
            dataField: 'goodsName',
            text: '상품정보',
            headerAlign: 'center',
            align: 'center',
            headerClasses: 'w-50',
            formatter: (cell, row) => {
                return (
                    <div className="w-100">
                        <div className="text-center h5">{cell}</div>
                        <div className="text-center text-muted">{row.price + "원"}</div>
                    </div>
                )
            }
          }, {
            dataField: 'apiCall',
            text: 'API콜수',
            headerAlign: 'center',
            align: 'center',
            headerClasses: 'w-25',
            formatter: (cell, row) => {
                return (
                    <span className="h6 font-weight-bold">{cell.toLocaleString()}</span>
                )
            }
          }
        ];
        return(
            <div className="content pl-2 pr-2">
                <Loading loading={this.state.loading}/>
                <div className="d-flex row w-100 m-0 mb-2 mt-2 pl-2 pr-2">
                    <h5 className="content--header col bg-light p-2 ml-2"></h5>
                    <DateTimer_rec setDates={(dates) => this.setDates(dates)}/>
                </div>
                <div className="row w-100 m-0">
                    <div className="w-100 pl-2 pr-2">
                    <div className={"card m-0"}>
                            <div className={"card-body  table-responsive p-0"}>
                                <div className={" p-3"}>
                                    {
                                        this.state.apiCall.length > 0 ?
                                        <ToolkitProvider
                                        keyField="name"
                                        data={ this.state.apiCall }
                                        columns={ columns }
                                        bootstrap4
                                        search
                                        >
                                        {
                                            props => (
                                            <div>
                                                <SearchBar { ...props.searchProps } />
                                                <hr />
                                                <BootstrapTable
                                                bordered={ false }
                                                hover
                                                noDataIndication="Table is Empty"
                                                pagination={ paginationFactory() }
                                                { ...props.baseProps }
                                                />
                                            </div>
                                            )
                                        }
                                        </ToolkitProvider>
                                        :""
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        status: state.authentication.status,
        apiCall: state.recommendation.table.apiCall
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getApiCall: (dates, status) => {
            return dispatch(getApiCall(dates, status));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApiCall);