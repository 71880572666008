import React, { Component } from 'react';
import { connect } from 'react-redux';
import { registerRequest } from '../store/actions/authentication';
import { Link } from 'react-router-dom'
import './Register.css'
 
class Register extends Component {
  state = {
    platform_type: 'local',
    username: "",
    password: "",
    useremail: "",
    url: "",
  }
    
  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }
    
  handleSubmit = event => {
    event.preventDefault()
    this.props.registerRequest(this.state.platform_type, this.state.username, this.state.password, this.state.useremail, this.state.url)
    .then(
      () => {
        if(this.props.status === "SUCCESS"){
          alert("Success! Please log In.");
          this.props.history.push('/login');
        } else {
          let errorMessage = [
            'INVALID USERNAME',
            'PASSWORD IS TOO SHORT',
            'USERNAME ALREADY EXISTS'
          ];
          alert(errorMessage[this.props.errorCode - 1])
        }
      }
    )
  }
    
  render() {
    return (
      <div className="Register OutLine">
        <form onSubmit={this.handleSubmit}>
            <div className="top">
                <h1>Sign Up</h1>
            </div>
            <div className="mid">
                <div className="mid--info">
                    <label>User name 
                      {/* <span style={{color:"red"}}> @{}
                      </span> */}
                    </label>
                    <input
                      name='username'
                      placeholder='Enter name'
                      value={this.state.username}
                      onChange={this.handleChange}
                    />
                </div>
                <div className="mid--info">
                    <label>User email</label>
                    <input
                      type="email"
                      name='useremail'
                      placeholder='Enter email'
                      value={this.state.useremail}
                      onChange={this.handleChange}
                    />
                </div>
                <div className="mid--info">
                    <label>Password</label>
                    <input
                        type='password'
                        name='password'
                        placeholder='Enter password'
                        value={this.state.password}
                        onChange={this.handleChange}
                    />
                </div>
                <div className="mid--info">
                    <label>Host URL</label>
                    <input
                        name='url'
                        placeholder='allbigdat.com'
                        value={this.state.url}
                        onChange={this.handleChange}
                    />
                </div>
            </div>
            <div className="bot">
                <input value="Submit" type='submit'/>
                <p>Already registerd <Link to="/login">sign in?</Link></p>
            </div>
        </form>
      </div>
    )
  }
}
 
const mapStateToProps = (state) => {
  return {
      status: state.authentication.register.status,
      errorCode: state.authentication.register.error
  };
};
const mapDispatchToProps = (dispatch) => {
    return {
        registerRequest: (platform_type, name, pw, email, url) => {
            return dispatch(registerRequest(platform_type, name, pw, email, url));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);