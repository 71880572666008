import React, {Component} from "react"
import "./Home.css"
import { faArrowUp, faSquare, faUsers, faUserCog, faUserSlash, faUserClock } from "@fortawesome/free-solid-svg-icons"
import Chart from "chart.js"
import { connect } from "react-redux"
import { getFunnel, storeInterval } from '../../store/actions/chart';
import { getCafe24Products, getGodomallProducts } from '../../store/actions/authentication';
// import Date1 from "../../components/Dates"
// import Date2 from "../../components/Dates2"
import DateTimer from "../../components/DateTimer"
import Minibox from "../../components/chart/MiniBox"
import Card from "../../components/chart/Card"
import Loading from "../../components/Loading"

class Home extends Component{
    constructor(props){
        super(props)
        // console.log("home constructor")
        this.state ={
            config1: this.props.stackedBar,
            // config2: this.props.lineChartData,
            config3: this.props.realTimeLine,
            config4: this.props.deviceDoughnut,
            config5: this.props.flowOfUser,
            realTimeUserPerPage: this.props.userData.realTimeUserPerPage,
            //fake UPP & PAGEVIEW는 한글형식으로 변환이 되지 않은 URL이다.
            fakeRealtimeUPP: this.props.userData.realTimeUserPerPage,
            pageView: this.props.userData.pageView,
            fakePageView: this.props.userData.pageView,
            sessionCount: 0,
            numberOfRun: 0,
            rateOfRun: 0,
        }
    }

    componentDidMount(){
        // console.log("home mount")
        var ctx1 = document.getElementById("chart-1").getContext("2d");
        var chart_1 = new Chart(ctx1, this.state.config1)
        var ctx2 = document.getElementById("chart-2").getContext("2d");
        var chart_2 = new Chart(ctx2, this.state.config3)
        var ctx4 = document.getElementById("chart-4");
        var chart_4 = new Chart(ctx4, this.state.config4)
        var ctx5 = document.getElementById("chart-5").getContext("2d");
        var chart_5 = new Chart(ctx5, this.state.config5)
        this.setState({chart_1, chart_2, chart_4, chart_5})
        var intervalId = setInterval(this.realChange, 10000);
        this.props.storeInterval(intervalId)
    }

    componentDidUpdate(){
        // realtime URL이름과 상품 번호, 카테고리 번호, 게시판 번호를 이름으로 바꾸기
        const { platformType } = this.props
        if(this.props.userData.realTimeUserPerPage !== this.state.fakeRealtimeUPP){
            if(platformType){
                if(platformType === "cafe24"){
                    console.log("original page", this.props.userData.realTimeUserPerPage)
                    if(this.props.userData.realTimeUserPerPage){
                        this.changeCafe24ProductsNumberToName(this.props.userData.realTimeUserPerPage, "real")
                    }
                } else if (platformType === "godomall"){
                    console.log("original page", this.props.userData.realTimeUserPerPage)
                    if(this.props.userData.realTimeUserPerPage){
                        this.changeGodomallGoodsNumberToName(this.props.userData.realTimeUserPerPage, "real")
                    }
                } else {
                    if(this.props.local.pathInclude[0]){
                        console.log("original page", this.props.userData.realTimeUserPerPage)
                        if(this.props.userData.realTimeUserPerPage){
                            this.changeLocalGoodsNumberToName(this.props.userData.realTimeUserPerPage, "real")
                        }
                    } else {
                        this.setState({
                            fakeRealtimeUPP: this.props.userData.realTimeUserPerPage,
                            realTimeUserPerPage: this.props.userData.realTimeUserPerPage,
                        })
                    }
                }
            }
        } 

        // pageView URL이름과 상품 번호, 카테고리 번호, 게시판 번호를 이름으로 바꾸기
        if(this.props.userData.pageView !== this.state.fakePageView){
            if(platformType){
                if(platformType === "cafe24"){
                    this.changeCafe24ProductsNumberToName(this.props.userData.pageView, "pageView")
                } else if (platformType === "godomall"){
                    this.changeGodomallGoodsNumberToName(this.props.userData.pageView, "pageView")
                } else {
                    if(this.props.local.pathInclude[0]){
                        this.changeLocalGoodsNumberToName(this.props.userData.pageView, "pageView")
                    } else {
                        this.setState({
                            fakePageView: this.props.userData.pageView,
                            pageView: this.props.userData.pageView,
                        })
                    }
                }
            }
        }

        if(this.props.deviceDoughnut){
            var total = 0
            for(var i = 0; i < this.props.deviceDoughnut.data.datasets[0].data.length; i++){
                total += this.props.deviceDoughnut.data.datasets[0].data[i]
            }
            this.props.deviceDoughnut.options.elements.center.text = `   Total   \n${total.toLocaleString()}`
        }

        // 이탈률 계산하기 (세션수와 이탈횟수가 둘 다 들어왔을 때)
        if(this.props.userData.numberOfRun !== this.state.numberOfRun){
            if(this.props.userData.sessionCount !== this.state.sessionCount){
                var rateOfRun = Math.round(this.props.userData.numberOfRun / this.props.userData.sessionCount * 10000) / 100
                this.setState({
                    sessionCount: this.props.userData.sessionCount.toLocaleString(),
                    numberOfRun: this.props.userData.numberOfRun,
                    rateOfRun: rateOfRun
                })
            }
        }
        
        if(this.state.chart_1){
            this.state.chart_1.update()
            this.state.chart_2.update()
            this.state.chart_4.update()
            this.state.chart_5.update()
        }
    }

    changeCafe24ProductsNumberToName = (UPP, type) => {
        var productsNumber = "product_no="
        var userPerPage = JSON.parse(JSON.stringify(UPP));
        var newUPP = []
        for(var i = 0; i < userPerPage.length; i++){
            newUPP[i] = userPerPage[i].name.split("?")
            if(newUPP[i][1]){
                var param = new URLSearchParams(newUPP[i][1])
                if(param.get("product_no")){
                    productsNumber += (param.get("product_no") + ",")
                    newUPP[i][2] = parseInt(param.get("product_no"))
                }
                if(param.get("cate_no")){
                    newUPP[i][3] = parseInt(param.get("cate_no"))
                }
                if(param.get("keyword")){ //검색 키워드
                    newUPP[i][4] = ("/" + param.get("keyword"))
                }
                if(param.get("board_no")){
                    newUPP[i][5] = parseInt(param.get("board_no"))
                }
            }
        }
        console.log("newUPP", newUPP)
        // location.search 에서 product_no가 있을 경우!
        if(productsNumber.length > 11){
            var products = []
            this.props.getCafe24Products(this.props.cafe24, productsNumber)
            .then((response)=> {
                products = response.products
                if(products){
                    for(var j = 0; j < newUPP.length; j++){
                        //pathname 바꾸기
                        if(this.props.cafe24.urlName[newUPP[j][0]] != null){
                            newUPP[j][0] = this.props.cafe24.urlName[newUPP[j][0]]
                        } else {
                            const {pathInclude} = this.props.cafe24
                            for(var k = 0; k < pathInclude.length; k++){
                                if(newUPP[j][0].indexOf(pathInclude[k].url) > -1){
                                    newUPP[j][0] = newUPP[j][0].replace(pathInclude[k].url, pathInclude[k].name)
                                    break;
                                }
                            }
                        }
                        //search 바꾸기
                        if(newUPP[j][2]){ //search에서 product_no를 이름으로바꾸기
                            for(var z = 0; z < products.length; z++){
                                if(newUPP[j][2] === products[z].product_no){
                                    newUPP[j][0] += ("/"+products[z].product_name);
                                    break;
                                }
                            }
                        } else if (newUPP[j][4]){// keyword 붙이기
                            newUPP[j][0] += newUPP[j][4]
                        }

                        if(newUPP[j][3]){ //search에서 cate_no를 이름으로 바꾸기
                            for(z = 0; z < this.props.cafe24.categories.length; z++){
                                if(newUPP[j][3] === this.props.cafe24.categories[z].category_no){
                                    newUPP[j][0] += ("/"+this.props.cafe24.categories[z].category_name);
                                    break;
                                }
                            }
                        } else if (newUPP[j][5]){ //search에서 board_no를 이름으로 바꾸기
                            for(z = 0; z < this.props.cafe24.boards.length; z++){
                                if(newUPP[j][5] === this.props.cafe24.boards[z].board_no){
                                    newUPP[j][0] += ("/"+this.props.cafe24.boards[z].board_name);
                                    break;
                                }
                            }
                        }
                        userPerPage[j].name = newUPP[j][0]
                    }

                    // no -> name 변경 후 재정렬
                    userPerPage.sort((a,b) => a.name > b.name ? -1 : 1)
                    for(i = userPerPage.length-2; i > -1; i--){
                        if(userPerPage[i].name === userPerPage[i+1].name){
                            userPerPage[i].count = parseInt(userPerPage[i].count) + parseInt(userPerPage[i+1].count)
                            userPerPage.splice(i+1, 1)
                        }
                    }
                    userPerPage.sort((a,b) => parseInt(b.count) - parseInt(a.count)) 
                }
                if(type === "real"){
                    this.setState({
                        realTimeUserPerPage: userPerPage,
                        fakeRealtimeUPP: UPP
                    })
                } else if (type ==="pageView"){
                    userPerPage = userPerPage.slice(0, 10)
                    userPerPage.forEach(page => {
                        page.count = page.count.toLocaleString();
                    })
                    this.setState({
                        pageView: userPerPage,
                        fakePageView: UPP
                    })
                }
            })
        } else {
            if(type === "real"){
                this.setState({fakeRealtimeUPP: UPP})
            } else if (type === "pageView"){
                this.setState({fakePageView: UPP})
            }
        }
    }

    changeGodomallGoodsNumberToName = (UPP, type) => {
        var userPerPage = JSON.parse(JSON.stringify(UPP));
        var newUPP = []
        var productsNumber = []
        for(var i = 0; i < userPerPage.length; i++){
            newUPP[i] = userPerPage[i].name.split("?")
            if(newUPP[i][1]){
                var param = new URLSearchParams(newUPP[i][1])
                if(param.get("goodsNo")){
                    productsNumber.push(param.get("goodsNo"))
                    newUPP[i][2] = param.get("goodsNo")
                }
                if(param.get("cateCd")){
                    newUPP[i][3] = param.get("cateCd")
                }
                if(param.get("keyword")){ //검색 키워드
                    newUPP[i][4] = ("/" + param.get("keyword"))
                }
                if(param.get("bdId")){
                    newUPP[i][5] = param.get("bdId")
                }
            }
        }
        console.log("productsNumber", productsNumber)
        this.props.getGodomallProducts(this.props.godomall, productsNumber)
        .then((response)=> {
            console.log("godo product@@", response)
            for(var j = 0; j < newUPP.length; j++){
                //pathname 바꾸기
                if(this.props.godomall.urlName[newUPP[j][0]] != null){
                    newUPP[j][0] = this.props.godomall.urlName[newUPP[j][0]]
                }
                //search 바꾸기
                if(newUPP[j][2]){ //search에서 product_no를 이름으로바꾸기
                    for(var z = 0; z < response.length; z++){
                        if(newUPP[j][2] === response[z].goodsNo[0]){
                            newUPP[j][0] += ("/"+response[z].goodsNm[0]);
                            break;
                        }
                    }
                } else if (newUPP[j][4]){// keyword 붙이기
                    newUPP[j][0] += newUPP[j][4]
                }

                if(newUPP[j][3]){ //search에서 cate_no를 이름으로 바꾸기
                    for(z = 0; z < this.props.godomall.categories.length; z++){
                        if(newUPP[j][3] === this.props.godomall.categories[z].cateCd[0]){
                            newUPP[j][0] += ("/"+this.props.godomall.categories[z].cateNm[0]);
                            break;
                        }
                    }
                } else if (newUPP[j][5]){ //search에서 board_no를 이름으로 바꾸기
                    for(z = 0; z < this.props.godomall.boards.length; z++){
                        if(newUPP[j][5] === this.props.godomall.boards[z].bdId[0]){
                            newUPP[j][0] += ("/"+this.props.godomall.boards[z].bdNm[0]);
                            break;
                        }
                    }
                }
                userPerPage[j].name = newUPP[j][0]
            }

            // no -> name 변경 후 재정렬
            userPerPage.sort((a,b) => a.name > b.name ? -1 : 1)
            for(i = userPerPage.length-2; i > -1; i--){
                if(userPerPage[i].name === userPerPage[i+1].name){
                    userPerPage[i].count = parseInt(userPerPage[i].count) + parseInt(userPerPage[i+1].count)
                    userPerPage.splice(i+1, 1)
                }
            }
            userPerPage.sort((a,b) => parseInt(b.count) - parseInt(a.count))

            if(type === "real"){
                this.setState({
                    realTimeUserPerPage: userPerPage,
                    fakeRealtimeUPP: UPP
                })
            } else if (type ==="pageView"){
                userPerPage = userPerPage.slice(0, 10)
                userPerPage.forEach(page => {
                    page.count = page.count.toLocaleString();
                })
                this.setState({
                    pageView: userPerPage,
                    fakePageView: UPP
                })
            }
        })

    }

    changeLocalGoodsNumberToName = (UPP, type) => {
        var userPerPage = JSON.parse(JSON.stringify(UPP));
        var newUPP = []
        const { pathInclude, searchParam, urlName } = this.props.local
        for(var i = 0; i < userPerPage.length; i++){
            newUPP[i] = userPerPage[i].name.split("?")

            //pathName 변경
            if(urlName[newUPP[i][0]] != null){
                newUPP[i][0] = urlName[newUPP[i][0]];
            } else {
                for(var z = 0; z < pathInclude.length; z++){
                    if(newUPP[i][0].indexOf(pathInclude[z].url) > -1){
                        newUPP[i][0] = pathInclude[z].name
                    }
                }
            }

            //search 변경
            if(newUPP[i][1]){
                var param = new URLSearchParams(newUPP[i][1])
                for(var j = 0; j < searchParam.length; j++){
                    if(param.get(searchParam[j].param)){
                        newUPP[i][0] += "/" + searchParam[j].name + "-" + param.get(searchParam[j].param)
                    }
                }
            }

            userPerPage[i].name = newUPP[i][0]
        }

        // no -> name 변경 후 재정렬
        userPerPage.sort((a,b) => a.name > b.name ? -1 : 1)
        for(i = userPerPage.length-2; i > -1; i--){
            if(userPerPage[i].name === userPerPage[i+1].name){
                userPerPage[i].count = parseInt(userPerPage[i].count) + parseInt(userPerPage[i+1].count)
                userPerPage.splice(i+1, 1)
            }
        }
        userPerPage.sort((a,b) => parseInt(b.count) - parseInt(a.count)) 

        if(type === "real"){
            this.setState({
                realTimeUserPerPage: userPerPage,
                fakeRealtimeUPP: UPP
            })
        } else if (type ==="pageView"){
            userPerPage = userPerPage.slice(0, 10)
            userPerPage.forEach(page => {
                page.count = page.count.toLocaleString();
            })
            this.setState({
                pageView: userPerPage,
                fakePageView: UPP
            })
        }
    }

    realChange = () =>{
        if(this.props.selectedHost){
            this.props.getFunnel(this.props.selectedHost, ["REAL_TIME"], this.props.userData.conditionMainArr, this.props.userData.conditionSubArr)
        }
    }

    render(){
        return(
            <div className="content pl-2 pr-2">
                <Loading loading={this.props.loading}/>
                <div className="d-flex row w-100 m-0 mb-2 mt-2 pl-2 pr-2">
                    <h5 className="content--header col bg-light p-2 ml-2">User Segment Data</h5>
                    {/* <Date1 /> */}
                    {/* <Date2 /> */}
                    <DateTimer />
                </div>
                <div className="row w-100 m-0">
                    <Minibox color={"bg-warning"} icon={faUsers} name={"사용자"} subname={" 명"} data={this.props.userCount} />
                    <Minibox color={"bg-info"} icon={faUserCog} name={"세션수"} subname={" 번"} data={this.state.sessionCount} />
                    <Minibox color={"bg-danger"} icon={faUserSlash} name={"이탈률"} subname={" %"} data={this.state.rateOfRun} />
                    <Minibox color={"bg-success"} icon={faUserClock} name={"세션시간"} subname={""} data={this.props.userData.sessionTime} />
                </div>
                <div className="row w-100 m-0">
                    <div className="col-lg-7 pl-2 pr-2">
                        <Card title="실시간 활성 사용자" useCompare={this.props.userData.realTimeUser + " 명"} canvasId="chart-2" useFooter={this.state.realTimeUserPerPage} icon1={faArrowUp} />
                        <Card title="사용자 유입 경로"  canvasId="chart-1" icon1={faArrowUp} icon2={faSquare} />
                        <Card title="사용자 유지율" canvasId="heatmap2" icon2={faSquare} />
                        <Card title="활성 사용자 추세" canvasId="chart-5" />
                    </div>
                    <div className="col-lg-5 pl-2 pr-2">
                        <Card title="사용자 방문 페이지 파악" useDetail="pageView" useTable={this.state.pageView} />
                        <Card title="사용자 기기 정보" canvasId="chart-4" icon1={faArrowUp} icon2={faSquare} />
                        <Card title="시간별 사용자 수" canvasId="heatmap1" icon2={faSquare} />
                        {/* <Card title="사용자 이동 경로" useCompare2={true} canvasId="sankey" icon2={faSquare} /> */}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        lineChartData: state.chart.line,
        stackedBar: state.chart.bar,
        realTimeLine: state.chart.realTimeLine,
        deviceDoughnut: state.chart.deviceDoughnut,
        flowOfUser: state.chart.flowOfUser,
        userData: state.chart.userData,
        userCount: state.chart.userData.userCount,
        loading: state.chart.loading,
        selectedHost: state.authentication.status.selectedHost,
        platformType: state.authentication.status.platformType,
        cafe24: state.authentication.cafe24,
        godomall: state.authentication.godomall,
        local: state.authentication.local
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getFunnel: (host, type, conditionMainArr, conditionSubArr) => {
            return dispatch(getFunnel(host,type, conditionMainArr, conditionSubArr));
        },
        storeInterval: (intervalId) => {
            return dispatch(storeInterval(intervalId));
        },
        getCafe24Products: (cafe24, productsNumber) => {
            return dispatch(getCafe24Products(cafe24, productsNumber));
        },
        getGodomallProducts: (godomall, productsNumber) => {
            return dispatch(getGodomallProducts(godomall, productsNumber));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);