import axios from "../../api"

export function getRecResults(dates, status){
    return (dispatch) => {
        return axios.post('/recommendation/getRecResults', { dates, status })
        .then((response) => {
            if(response.data.success){
                dispatch(successRecResults(response.data.results));
                return response.data.results
            } else {
                return response.data.results
            }
        })
    };
}

export function successRecResults(results){
    return {
        type: "GET_REC_RESULTS",
        results
    }
}

export function getPurchaseChangeRate(dates, status){
    return (dispatch) => {
        return axios.post('/recommendation/getPurchaseChangeRate', { dates, status })
        .then((response) => {
            if(response.data.success){
                dispatch(successgetPurchaseChangeRate(response.data.results));
                return response.data.results
            }
        })
    };
}

export function successgetPurchaseChangeRate(results){
    return {
        type: "GET_PURCHASE_CHANGE_RATE",
        results
    }
}

export function getTotalPrice(dates, status){
    return (dispatch) => {
        return axios.post('/recommendation/getTotalPrice', { dates, status })
        .then((response) => {
            if(response.data.success){
                dispatch(successgetTotalPrice(response.data.results));
                return response.data.results
            }
        })
    };
}

export function successgetTotalPrice(results){
    return {
        type: "GET_TOTAL_PRICE",
        results
    }
}

export function getClickRate(dates, status){
    return (dispatch) => {
        return axios.post('/recommendation/getClickRate', { dates, status })
        .then((response) => {
            if(response.data.success){
                dispatch(successgetClickRate(response.data.results));
                return response.data.results
            }
        })
    };
}

export function successgetClickRate(results){
    return {
        type: "GET_CLICK_RATE",
        results
    }
}

export function getClickCount(dates, status){
    return (dispatch) => {
        return axios.post('/recommendation/getClickCount', { dates, status })
        .then((response) => {
            if(response.data.success){
                dispatch(successgetClickCount(response.data.results));
                return response.data.results
            }
        })
    };
}

export function successgetClickCount(results){
    return {
        type: "GET_CLICK_COUNT",
        results
    }
}

export function getApiCall(dates, status){
    return (dispatch) => {
        return axios.post('/recommendation/getApiCall', { dates, status })
        .then((response) => {
            if(response.data.success){
                dispatch(successgetApiCall(response.data.results));
                return response.data.results
            }
        })
    };
}

export function successgetApiCall(results){
    return {
        type: "GET_API_CALL",
        results
    }
}

export function getRecResults2(dates, status){
    return (dispatch) => {
        return axios.post('/recommendation/getRecResults2', { dates, status })
        .then((response) => {
            if(response.data.success){
                dispatch(successRecResults2(response.data.results));
                return response.data.results
            } else {
                return response.data.results
            }
        })
    };
}

export function successRecResults2(results){
    return {
        type: "GET_REC2_RESULTS",
        results
    }
}


export function getGapClickToOrder(dates, status){
    return (dispatch) => {
        return axios.post('/recommendation/getGapClickToOrder', { dates, status })
        .then((response) => {
            if(response.data.success){
                return response.data.results
            } else {
                return response.data.results
            }
        })
    };
}