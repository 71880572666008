import axios from "../../api"

function replaceName(target){
    if(target.indexOf("Years Old") > -1){
        target = target.replace("Years Old", "");
    }

    if(target.indexOf("-") > -1){
        target = target.split("-")
        target = [parseInt(target[0]), parseInt(target[1])]
        return target
    } else {
        return parseInt(target)
    }
}

function combinationName(compareName, keyName){
    var result = ""
    var key = replaceName(keyName)
    if(compareName === "is"){
        result = { "$gte" : key[0], "$lte": key[1] }
    } else if (compareName === "at least") {
        result = { "$gte" : key }
        
        //revisit 예외처리
        if(keyName === "0 Hours" || keyName === "0Hours"){
            result = {"$gte": 3600000}
        }
    } else if (compareName === "at most"){
        result = { "$lte" : key }

        //revisit 예외처리
        if(keyName.indexOf("Hours") > -1 ) {
            result = { "$gte": 3600000, "$lte": key }
        }
    }
    return result
}

function checkWho (who){
    var whoTransform =  {
        "Age Group": "age",
        "Gender": "gender",
        "Membership Lv": "membership"
    }
    var whoArr = []
    for(var i = 0; i < who.length; i ++){
        var whoQuery = []
        var whoFieldName
        // 서브메뉴 1개 이상인지 체크 (or 인지 확인해야하므로) 그 후에 추가하면 됨.
        if(who[i].subMenus.length > 1){
            for(var j = 0; j < who[i].subMenus.length; j++){
                if(who[i].name ==="Age Group"){
                    whoQuery.push(combinationName(who[i].subMenus[j].compareName, who[i].subMenus[j].name))
                } else if (who[i].name === "Gender"){
                    whoQuery.push(who[i].subMenus[j].name)
                }
            }
        } else {
            if(who[i].name ==="Age Group"){
                whoQuery = combinationName(who[i].subMenus[0].compareName, who[i].subMenus[0].name)
            } else if (who[i].name === "Gender"){
                whoQuery = who[i].subMenus[0].name
            }
        }

        whoArr.push({"$match": {}})
        whoFieldName = whoTransform[who[i].name]
        if(who[i].subMenus.length > 1){
            whoArr[whoArr.length - 1]["$match"] = { "$or": []}
            for(j = 0; j < who[i].subMenus.length; j++){
                whoArr[whoArr.length - 1]["$match"]["$or"][j] = {};
                whoArr[whoArr.length - 1]["$match"]["$or"][j][whoFieldName] = whoQuery[j];
            }
        } else {
            whoArr[whoArr.length - 1]["$match"][whoFieldName] = whoQuery
        }
    }
    if(whoArr.length > 0){
        whoArr.push({ "$group": { "_id": "$userId" }})
    }
    return whoArr
}

function checkWhere(where){
    var newWhere = {
        pageType: []
    }
    for(var i = 0; i < where.length; i++){
        if(where[i].subMenus.length > 1){
            if(where[i].name === "Page Type"){
                for(var j = 0; j < where[i].subMenus.length; j++){
                    newWhere["pageType"].push(where[i].subMenus[j].name)
                }
            }
        } else {
            if(where[i].name === "Page Type"){
                newWhere["pageType"].push(where[i].subMenus[0].name)
            }
        }
    }
    return newWhere
}

export function setABCondition(recommendationName, abTestName, tests, email, host) {
    return (dispatch) => {
        return axios.post('/abTest/setABTest', {recommendationName, abTestName, tests, email, host})
        .then((response) => {
            if(response.data.success){
                dispatch(abSetSuccess(response.data.testName))
            }
        })
    }
}

export function abSetSuccess(testName) {
    return{
        type: "AB_SET_SUCCESS",
        testName: testName
    }
}

export function getABTest(email, host){
    return (dispatch) => {
        return axios.post('/abTest/getABTest', {email, host})
        .then((response) => {
            if(response.data.success){
                dispatch(abRequestSuccess(response.data))
            }
        })
    }
}

export function deleteABTest(email, host, deleteABName) {
    return (dispatch) => {
        return axios.post('/abTest/deleteABTest', {email, host, deleteABName})
        .then(response => {
            if(response.data.success){
                dispatch(abRequestSuccess(response.data))
            }
        })
    }
}

export function updateABTest(email, host, updateABName, updateSet) {
    return (dispatch) => {
        return axios.post('/abTest/updateABTest', {email, host, updateABName, updateSet})
        .then(response => {
            if(response.data.success){
                dispatch(abRequestSuccess(response.data))
            }
        })
    }
}

export function addSubTest(email, host, updateABName, updateSet) {
    return (dispatch) => {
        return axios.post('/abTest/addSubTest', {email, host, updateABName, updateSet})
        .then(response => {
            if(response.data.success){
                dispatch(abRequestSuccess(response.data))
            }
        })
    }
}

export function abRequestSuccess(response){
    return {
        type: "AB_REQUEST_SUCCESS",
        result: response.abTests
    }
}

export function setRecommendation(recommendationName, who, where, when, algorithm, categories, email, host){
    var whoArr = checkWho(who);
    var newWhere = checkWhere(where);
    return (dispatch) => {
        return axios.post('/recommendation/setRecommendation', {recommendationName, whoArr, newWhere, when, algorithm, categories, email, host})
        .then((response) => {
            if(response.data.success){
                dispatch(recSetSuccess(response.data.recommendationName))
            }
        })
    }
}

export function recSetSuccess(recommendationName) {
    return{
        type: "REC_SET_SUCCESS",
        recommendationName: recommendationName
    }
}

export function getRecommendation(email, host){
    return (dispatch) => {
        return axios.post('/recommendation/getRecommendation', {email, host})
        .then((response) => {
            if(response.data.success){
                dispatch(recRequestSuccess(response.data))
            }
        })
    }
}

export function deleteRecommendation(email, host, deleteRecName) {
    return (dispatch) => {
        return axios.post('/recommendation/deleteRecommendation', {email, host, deleteRecName})
        .then(response => {
            if(response.data.success){
                dispatch(recRequestSuccess(response.data))
            }
        })
    }
}

export function updateRecommendation(email, host, updateRecName, updateSet) {
    return (dispatch) => {
        return axios.post('/recommendation/updateRecommendation', {email, host, updateRecName, updateSet})
        .then(response => {
            if(response.data.success){
                dispatch(recRequestSuccess(response.data))
            }
        })
    }
}

export function recRequestSuccess(response){
    return {
        type: "REC_REQUEST_SUCCESS",
        result: response.recommendations
    }
}