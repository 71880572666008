import React from "react"
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { connect } from "react-redux"
import { requestCondition, setLoading } from '../store/actions/chart';
// import $ from "jquery"
import "./SelectionBoxs.css"

class SelectionBoxs extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            // pageName: this.props.pageName,
            menus: props.menus.menus,
            mainCategories: props.menus.mainCategories,
            subCategories: props.menus.subCategories,
            lastCategories: props.menus.lastCategories,
            subIsFinish: props.menus.subIsFinish,
            needCompare: props.menus.needCompare,
            compareValue: props.menus.compareValue,
            reset: props.reset,
        }
    }

    componentDidUpdate(){

        //selectionBox는 첫 로딩에서만 menus가 지정되므로, 도중에 초기화시키기 위한 방법으로 reset을 props으로 사용
        if(this.state.reset !== null){
            if(this.state.reset !== this.props.reset){
                this.successReset()
            }
        }
    }

    // changeMenu = () => {
    //     if(this.props.pageName === "home") {
    //         this.setState({
    //             pageName: this.props.pageName,
    //             menus: this.props.homeMenu.menus,
    //             mainCategories: this.props.homeMenu.mainCategories,
    //             subCategories: this.props.homeMenu.subCategories,
    //             lastCategories: this.props.homeMenu.lastCategories,
    //             subIsFinish: this.props.homeMenu.subIsFinish,
    //             needCompare: this.props.homeMenu.needCompare,
    //             compareValue: this.props.homeMenu.compareValue
    //         })
    //     } else if(this.props.pageName === "abSetting") {
    //         this.setState({
    //             pageName: this.props.pageName,
    //             menus: this.props.abMenu.menus,
    //             mainCategories: this.props.abMenu.mainCategories,
    //             subCategories: this.props.abMenu.subCategories,
    //             lastCategories: this.props.abMenu.lastCategories,
    //             subIsFinish: this.props.abMenu.subIsFinish,
    //             needCompare: this.props.abMenu.needCompare,
    //             compareValue: this.props.abMenu.compareValue
    //         })
    //     }  
    // }

    successReset = () => {
        this.setState({
            menus: this.props.menus.menus,
            subCategories: this.props.menus.subCategories
        })
        this.props.successReset()
    }

    checkDataLoading = () => {
        const {userCount, sessionCount, sessionTime, pageView, deviceCount, funnelCount, flowOfUser, realTime, numberOfRun} = this.state
        if(userCount && sessionCount && sessionTime && pageView && deviceCount && funnelCount && flowOfUser && realTime && numberOfRun){
            this.props.setLoading(false)
        }
    }

    requestCondition = (menu) => {
        if(this.props.requestFunc){
            this.props.setLoading(true)
            this.setState({
                userCount:false,
                sessionCount:false,
                sessionTime:false,
                pageView:false,
                deviceCount:false,
                funnelCount:false,
                flowOfUser:false,
                realTime:false,
                numberOfRun:false,
            })
            this.props.requestCondition(this.props.selectedHost, ["USER_COUNT"], menu, this.props.conditionDates)
            .then(() => {this.setState({userCount:true}); this.checkDataLoading()})
            this.props.requestCondition(this.props.selectedHost, ["SESSION_COUNT"], menu, this.props.conditionDates)
            .then(() => {this.setState({sessionCount:true}); this.checkDataLoading()})
            this.props.requestCondition(this.props.selectedHost, ["NUMBER_OF_RUN"], menu, this.props.conditionDates)
            .then(() => {this.setState({numberOfRun:true}); this.checkDataLoading()})
            this.props.requestCondition(this.props.selectedHost, ["SESSION_TIME"], menu, this.props.conditionDates)
            .then(() => {this.setState({sessionTime:true}); this.checkDataLoading()})
            this.props.requestCondition(this.props.selectedHost, ["PAGE_VIEW",], menu, this.props.conditionDates)
            .then(() => {this.setState({pageView:true}); this.checkDataLoading()})
            this.props.requestCondition(this.props.selectedHost, ["DEVICE_COUNT",], menu, this.props.conditionDates)
            .then(() => {this.setState({deviceCount:true}); this.checkDataLoading()})
            this.props.requestCondition(this.props.selectedHost, ["FUNNEL_COUNT",], menu, this.props.conditionDates)
            .then(() => {this.setState({funnelCount:true}); this.checkDataLoading()})
            this.props.requestCondition(this.props.selectedHost, ["FLOW_OF_USER",], menu, this.props.conditionDates)
            .then(() => {this.setState({flowOfUser:true}); this.checkDataLoading()})
            this.props.requestCondition(this.props.selectedHost, ["REAL_TIME"], menu, this.props.conditionDates)
            .then(() => {this.setState({realTime:true}); this.checkDataLoading()})
        } else {
            this.props.menus.menus = menu
            this.props.onSubmit(this.props.menus)
        }

        //프로그레스바 애니메이션
        // var myVar = setInterval(function(){myTimer()},0.5);
        // var count = 0;
        // function myTimer() {
        //     if(count < 100){
        //         $('.progress').css('width', count + "%");
        //         count += 4;
        //     } else if(count > 99){  
        //         count = 0;
        //         $('.progress').css('width', count + "%")
        //         clearInterval(myVar)
        //     }
        // }
    }

    dropBtn = (e) => {
        if(e.target.nextSibling.classList.contains("show")){
            e.target.nextSibling.classList.remove("show")
        } else {
            e.target.nextSibling.classList.add("show")
        }
    }

    selectedMainDrop = (e, mainIdx, subIdx) => {
        const {subCategories, lastCategories, menus, needCompare, subIsFinish} = this.state
        const innerText = e.target.innerText
        var newMenus = menus.slice();
        var newSubMenus = menus[mainIdx].subMenus.slice()

        // 드랍다운 클릭으로 들어옴
        if(subCategories[innerText]){
            if (menus[mainIdx].subMenus[0] && menus[mainIdx].subMenus[0].parentName === innerText) {
                //이전과 같은 메인메뉴를 클릭했을 때 무반응
            } else {
                //서브메뉴가 마지막일 때
                if(subIsFinish.indexOf(innerText) > -1){
                    newSubMenus[0] = {
                        parentName: innerText,
                        name: subCategories[innerText][0],
                    }
                    if(needCompare.indexOf(innerText) > -1){
                        newSubMenus[0].compareName = "is"
                    }
                    newMenus[mainIdx] = {
                        name: innerText,
                        subMenus: newSubMenus
                    }
                    this.setState({menus: newMenus})
                    this.requestCondition(newMenus)
                } else { // 라스트메뉴가 있을 때
                    newSubMenus[0] = {
                        parentName: innerText,
                        name: "",
                    }
                    newMenus[mainIdx] = {
                        name: innerText,
                        subMenus: newSubMenus
                    }
                    this.setState({menus: newMenus})
                }
            }
        }
        // +or클릭해서 들어옴
        if(subIdx > 0){

            // 서브에 비교구문이 있을 때 (비교구문이 있으면 마지막 메뉴라고 본다.)
            if(newSubMenus[0].compareName){
                newSubMenus[subIdx] = {
                    parentName: newSubMenus[0].parentName,
                    name: subCategories[menus[mainIdx].name][0],
                    compareName: "is"
                }
                newMenus[mainIdx] = {
                    ...newMenus[mainIdx],
                    subMenus: newSubMenus
                }
                this.setState({menus: newMenus})
                this.requestCondition(newMenus)
            }else {
                //서브에 비교구문이 없고, 라스트 메뉴가 있을 때
                if(lastCategories[subCategories[newSubMenus[0].parentName][0]]){
                    newSubMenus[subIdx] = {
                        parentName: newSubMenus[0].parentName,
                        name: "",
                    }
                    newMenus[mainIdx] = {
                        ...newMenus[mainIdx],
                        subMenus: newSubMenus
                    }
                    this.setState({menus: newMenus})

                } else {// 서브에 비교구문이 없고, 서브가 마지막 메뉴일 때
                    newSubMenus[subIdx] = {
                        parentName: newSubMenus[0].parentName,
                        name: subCategories[newSubMenus[0].parentName][0],
                    }
                    newMenus[mainIdx] = {
                        ...newMenus[mainIdx],
                        subMenus: newSubMenus
                    }
                    this.setState({menus: newMenus})

                    //db즉시반영
                    this.requestCondition(newMenus)
                }
            }
        }
    }

    selectedSubDrop = (e, mainIdx, subIdx) => {
        const {lastCategories, subCategories, menus, needCompare} = this.state;
        const innerText = e.target.innerText

        // 드랍다운 메뉴 드래그할 때 글자 겹침 방지용
        if(subCategories[menus[mainIdx].name].indexOf(innerText) > -1){
            var newSubMenus = menus[mainIdx].subMenus.slice()
            var newMenus = menus.slice();
            // 카테고리 하나 더 있을 때 lastMenu에 부모메뉴이름 적어주기
            if(lastCategories[innerText]){
                newSubMenus[subIdx] = {
                    ...newSubMenus[subIdx],
                    name: innerText,
                    lastMenu: {
                        parentName: innerText,
                        name: ""
                    }
                }
                if(needCompare.indexOf(innerText) > -1){
                    newSubMenus[subIdx].lastMenu.compareName = "is"
                }
                newMenus[mainIdx] = {
                    ...newMenus[mainIdx],
                    subMenus: newSubMenus
                }
                this.setState({menus: newMenus})

            } else {
                newSubMenus[subIdx] = {
                    ...newSubMenus[subIdx],
                    name: innerText,
                }
                newMenus[mainIdx] = {
                    ...newMenus[mainIdx],
                    subMenus: newSubMenus
                }
                this.setState({menus: newMenus})

                //db즉시반영
                this.requestCondition(newMenus)
            }

            if(e.target.parentNode.classList.contains("show")){
                e.target.parentNode.classList.remove("show")
            }
        }
    }

    selectedLastDrop = (e, mainIdx, subIdx) => {
        const {menus, lastCategories} = this.state;
        const innerText = e.target.innerText;
        const name = menus[mainIdx].subMenus[subIdx].name
        const index = lastCategories[menus[mainIdx].subMenus[subIdx].name].indexOf(innerText)
        
        if(lastCategories[name][index] === innerText){
            var newSubMenus = menus[mainIdx].subMenus.slice()
            newSubMenus[subIdx] = {
                ...newSubMenus[subIdx],
                lastMenu: {
                    ...newSubMenus[subIdx].lastMenu,
                    name: e.target.innerText
                }
            }
            
            var newMenus = menus.slice();
            newMenus[mainIdx] = {
                ...newMenus[mainIdx],
                subMenus: newSubMenus
            }
            this.setState({menus: newMenus})
            //db즉시반영
            this.requestCondition(newMenus)

            if(e.target.parentNode.classList.contains("show")){
                e.target.parentNode.classList.remove("show")
            }
        }

    }

    selectedCompareDrop = (e, menu) => {
        const {menus, compareValue, subCategories} = this.state;
        const innerText = e.target.innerText

        //글자 겹침 방지용 if
        if(compareValue.indexOf(innerText) > -1){
            menu.compareName = innerText

            if(innerText !== "is"){
                if(menu.parentName === "Time of revisit"){
                    menu.name = "0 Hours"
                } else if (menu.parentName === "Scroll depth"){
                    menu.name = "0 %"
                } else if (menu.parentName === "Number of revisit"){
                    menu.name = "0 Times"
                } else {
                    menu.name = ""
                }
            } else {
                if(menu.name === ""){
                    if(subCategories[menu.parentName]){
                        menu.name = subCategories[menu.parentName][0]
                    }
                }
            }
            

            this.setState(menus)

            if(menu.name !== ""){
                //db즉시반영
                this.requestCondition(menus)
            }
        }

    }
    
    deleteMenu = (e, mainIdx, subIdx) => {
        const { menus } = this.state

        var newMenus = menus.slice()
        if(newMenus[mainIdx].subMenus.length === 1){
            if(newMenus.length === 1) {
                newMenus[mainIdx].subMenus.splice(subIdx, 1)
                newMenus[mainIdx].name = "";
                var bigAnd = document.getElementsByClassName("big-and")[0];
                bigAnd.classList.remove("menu2show")
            }else {
                newMenus[mainIdx].subMenus.splice(subIdx, 1)
                newMenus.splice(mainIdx, 1)
            }
        } else {
            newMenus[mainIdx].subMenus.splice(subIdx, 1)
        }
        //db즉시반영
        this.requestCondition(newMenus)
        this.setState({menus: newMenus})
    }
    
    // or 기능 유무와 위치 정하기위해 사용
    checkOr = (mainIdx, subIdx) =>{
        const {menus} = this.state;
        console.log("mewnus@@@", menus)
        if(menus[mainIdx].subMenus[0].parentName === menus[mainIdx].subMenus[menus[mainIdx].subMenus.length-1].parentName){
            if(menus[mainIdx].subMenus.length === 1){
                return false
            }else {
                if(menus[mainIdx].subMenus.length - 1 === subIdx){
                    return false
                }else{
                    return true
                }
            }
        } else {
            return false
        }
    }

    enterkey = (e) => {
        if(e.keyCode === 13){
            this.requestCondition(this.state.menus)
        }
    }

    handleInputChange = (e, mainIdx, subIdx, lastIdx) => {
        // change input value
        const target = e.target;
        const value = target.value;
        var newSubMenus = this.state.menus[mainIdx].subMenus.slice()
        var newMenus = this.state.menus.slice();

        if(lastIdx !== undefined){
            newSubMenus[subIdx].lastMenu.name = value;
            newMenus[mainIdx].subMenus = newSubMenus;
            this.setState({
                mewnus: newMenus
            })
        } else if (subIdx !== undefined) {
            newSubMenus[subIdx].name = value;
            newMenus[mainIdx].subMenus = newSubMenus;
            this.setState({
                mewnus: newMenus
            })
        }else {
            newMenus[mainIdx].name = value;
            this.setState({
                menus : newMenus
            })

        }

        // filter dropdown
        if(e.target.nextSibling){
            var txtValue, i;
            var filter = e.target.value.toUpperCase();
            var div = e.target.nextSibling.nextSibling;
            var a = div.getElementsByTagName("a");
            for (i = 0; i < a.length; i++) {
                txtValue = a[i].textContent || a[i].innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    a[i].style.display = "";
                } else {
                    a[i].style.display = "none";
                }
            }

        }
    }

    renderCompareMenu = (mainIdx, index, menu) => {
        const {compareValue, subIsFinish} = this.state
        return (
            <div id="dropmenu-2" className="d-flex flex-column" style={{height:"50px"}}>
                <div className="nav-item dropdown drop--div" style={{width:"auto"}}>
                    <input style={{width:"80px"}} autoComplete="off" onChange={(e) => this.handleInputChange(e, mainIdx, index)} value={menu.compareName} placeholder="Select Attribute"   className="drop--input"  data-toggle="dropdown" aria-haspopup="true"/>
                    <button className="drop--toggle bg-white dropdown-toggle dropdown-toggle-split" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"/>
                    <div onClick={(e)=>this.selectedCompareDrop(e, menu)} className="dropdown-menu" aria-labelledby="menu-2">
                        {compareValue.map((name, idx)=> <a href={`#/dashboard/${localStorage.getItem("dashboard_page")}`} key={idx} className="dropdown-item" >{name}</a>)}
                    </div>
                </div>
                <div style={{position:"relative"}}>
                    {
                    subIsFinish.indexOf(menu.parentName) > -1 ? 
                    <span
                    onClick={(e)=> this.checkOr(mainIdx, index) ? "" : this.selectedMainDrop(e, mainIdx, index+1)} 
                    className={this.checkOr(mainIdx, index) ? "move--or btn btn-sm" : "btn btn-sm"}>
                        <small>+</small>
                        or
                    </span> : ""
                    }
                </div>
            </div>
        )
    }

    renderLastDropMenu = (subMenu, mainIdx, subIdx) =>{
        const {lastCategories, menus} = this.state

        if(lastCategories[subMenu.name]) {
            return (
                <div key={subIdx}>
                    {
                        subMenu.lastMenu.compareName ? (subMenu.lastMenu.compareName === "is" ? 
                        <div className="nav-item dropdown drop--div">
                            <input autoComplete="off" onChange={(e) => this.handleInputChange(e, mainIdx, subIdx, 1)} value={menus[mainIdx].subMenus[subIdx].lastMenu.name} placeholder="" className="drop--input"  data-toggle="dropdown" aria-haspopup="true"/>
                            <button onClick={this.dropBtn} className="drop--toggle bg-white dropdown-toggle dropdown-toggle-split" type="button"/>
                            <div onClick={(e)=>this.selectedLastDrop(e, mainIdx, subIdx)} className="dropdown-menu" aria-labelledby="menu-2">
                                {lastCategories[`${subMenu.name}`] ? lastCategories[`${subMenu.name}`].map((name, idx)=> <a key={idx} href={`#/dashboard/${localStorage.getItem("dashboard_page")}`} className="dropdown-item" >{name}</a>) : null}
                            </div>
                        </div> : 
                        <div className="nav-item dropdown drop--div">
                            <input onKeyUp={this.enterkey} autoComplete="off" onChange={(e) => this.handleInputChange(e, mainIdx, subIdx, 1)} value={menus[mainIdx].subMenus[subIdx].lastMenu.name} placeholder="직접입력" className="drop--input" />
                        </div> ) : 
                        <div className="nav-item dropdown drop--div">
                            <input autoComplete="off" onChange={(e) => this.handleInputChange(e, mainIdx, subIdx, 1)} value={menus[mainIdx].subMenus[subIdx].lastMenu.name} placeholder="" className="drop--input"  data-toggle="dropdown" aria-haspopup="true"/>
                            <button className="drop--toggle bg-white dropdown-toggle dropdown-toggle-split" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"/>
                            <div onClick={(e)=>this.selectedLastDrop(e, mainIdx, subIdx)} className="dropdown-menu" aria-labelledby="menu-2">
                                {lastCategories[`${subMenu.name}`] ? lastCategories[`${subMenu.name}`].map((name, idx)=> <a key={idx} href={`#/dashboard/${localStorage.getItem("dashboard_page")}`} className="dropdown-item" >{name}</a>) : null}
                            </div>
                        </div>
                    }
                    <div style={{position:"relative", display:'none'}}>
                        <span className="btn btn-sm"><span>+</span>or</span>
                    </div>
                </div>
            )
        }
    }

    renderSubMenu = (subMenu, index, mainIdx) => {
        const {menus, subCategories} = this.state

        if(subMenu.parentName !== menus[mainIdx].subMenus[0].parentName){
            menus[mainIdx].subMenus.splice(index, 1)
        } else {
            return(
                <div key={index} className="d-flex">
                    {subMenu.compareName ? this.renderCompareMenu(mainIdx, index, subMenu) : ""}
                    <div id="dropmenu-2" className="d-flex flex-column" style={{height:"50px"}}>
                        { // 삼항연산자 중첩사용
                            subMenu.compareName ? (subMenu.compareName === "is" ?
                            <div className="nav-item dropdown drop--div">
                                <input id="menu-2" autoComplete="off" onChange={(e) => this.handleInputChange(e, mainIdx, index)} value={menus[mainIdx].subMenus[index].name} placeholder="Select Attribute" className="drop--input"  data-toggle="dropdown" aria-haspopup="true"/>
                                <button onClick={this.dropBtn} className="drop--toggle bg-white dropdown-toggle dropdown-toggle-split" type="button" />
                                <div onClick={(e)=>this.selectedSubDrop(e, mainIdx, index)} className="dropdown-menu" aria-labelledby="menu-2">
                                    {subCategories[`${subMenu.parentName}`].map((name, idx)=> <a key={idx} href={`#/dashboard/${localStorage.getItem("dashboard_page")}`} className="dropdown-item" >{name}</a>)}
                                </div>
                            </div> : 
                            <div className="nav-item dropdown drop--div">
                                <input onKeyUp={this.enterkey} autoComplete="off" onChange={(e) => this.handleInputChange(e, mainIdx, index)} value={menus[mainIdx].subMenus[index].name} placeholder="직접 입력" className="drop--input" />
                            </div>) :
                            <div className="nav-item dropdown drop--div">
                                <input autoComplete="off" onChange={(e) => this.handleInputChange(e, mainIdx, index)} value={menus[mainIdx].subMenus[index].name} placeholder="Select Attribute" className="drop--input"  data-toggle="dropdown" aria-haspopup="true" />
                                <button className="drop--toggle bg-white dropdown-toggle dropdown-toggle-split" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"/>
                                <div onClick={(e)=>this.selectedSubDrop(e, mainIdx, index)} className="dropdown-menu" aria-labelledby="menu-2">
                                    {subCategories[`${subMenu.parentName}`].map((name, idx)=> <a key={idx} href={`#/dashboard/${localStorage.getItem("dashboard_page")}`} className="dropdown-item" >{name}</a>)}
                                </div>
                            </div>
                        }
                        <div style={{position:"relative"}}>
                            {
                            this.props.notAndOr ? "" 
                            : subMenu.compareName ? "" 
                            : (<span 
                                onClick={(e)=> this.checkOr(mainIdx, index) ? "" : this.selectedMainDrop(e, mainIdx, index+1)} 
                                className={this.checkOr(mainIdx, index) ? "move--or btn btn-sm" : "btn btn-sm"}>
                                    <small>+</small>
                                    or
                                </span>) }
                        </div>
                    </div>
                    {subMenu.lastMenu ? (subMenu.lastMenu.compareName ? this.renderCompareMenu(mainIdx, index, subMenu.lastMenu) : "") : ""}
                    {menus[mainIdx].subMenus[index].lastMenu ? this.renderLastDropMenu(subMenu, mainIdx, index) : null}
                    <div>
                        <FontAwesomeIcon style={{cursor: "pointer"}} onClick={(e) => this.deleteMenu(e, mainIdx, index)} icon={faTimes} size="sm"/>
                    </div>
                </div>
            )
        }
    }

    
    renderMainMenu = (mainMenu, index) => {
        const {menus, mainCategories} = this.state
        return (
            <div key={index} className="container--div d-flex">
                <div className="main--div h-100 d-flex flex-column">
                    <div data-toggle="dropdown" className="nav-item dropdown drop--div">
                        <input autoComplete="off" onChange={(e)=> this.handleInputChange(e, index)} value={menus[index].name} placeholder="Select Condition"  className="drop--input"  data-toggle="dropdown" aria-haspopup="true"/>
                        <button className="drop--toggle bg-white dropdown-toggle dropdown-toggle-split" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"/>
                        <div onClick={(e)=>this.selectedMainDrop(e, index, 0)} className="dropdown-menu" aria-labelledby="dropmenu1" draggable="false">
                            {mainCategories.map((main, index) => <a key={index} href={`#/dashboard/${localStorage.getItem("dashboard_page")}`} className="dropdown-item" >{main}</a>)}
                        </div>
                    </div>
                    <div className={"and-box text-center flex-column align-items-center justify-content-center" + (menus.length - 1 > index ? " d-flex" : "")}>
                        <span className="h-75 m-0 pr-25" style={{width:"4px", borderRight:"1px solid gray"}}> </span>
                        <span className="btn-sm">and</span>
                        <span className="h-75 m-0 pr-25" style={{width:"4px", borderRight:"1px solid gray"}}> </span>
                    </div>
                </div>
                <div>
                    {menus[index].subMenus.map((subMenu, idx) => this.renderSubMenu(subMenu, idx, index)) }
                </div>
            </div>
        )
    }

    addMainDrop = () => {
        const {menus} = this.state
        menus[menus.length] = {
            name: "",
            subMenus: [],
        }
        this.setState(menus)
    }
    
    render(){
        // console.log("selectionBox render")
        const {menus} = this.state
        return (
            <ul className="nav side--navbar--nav d-flex">
                <li className="d-flex flex-column">
                    {menus.map(this.renderMainMenu)}
                    <div className={"big-and" + (menus[menus.length-1].subMenus[0] ? " menu2show" : "")}>
                        {
                            this.props.notAndOr ? "" :
                            <span onClick={this.addMainDrop} className="btn btn-sm" style={{marginRight:"98px"}}>
                                +and
                            </span>
                        }
                    </div>
                </li>
            </ul>
        )

    }
}


const mapStateToProps = (state) => {
    return {
        // pageName: state.authentication.page.pageName,
        conditionDates: state.chart.userData.conditionDates,
        // homeMenu: state.condition.homeMenu,
        // abMenu: state.condition.abMenu,
        selectedHost: state.authentication.status.selectedHost,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        requestCondition: (host, type, menus, conditionDates) => {
            return dispatch(requestCondition(host,type,menus, conditionDates));
        },
        setLoading: (bool) => {
            return dispatch(setLoading(bool));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectionBoxs);