import React from "react"
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./Navbar.css"

class Navbar extends React.Component{
    componentDidMount(){
        var mediaNavController = document.getElementsByClassName('media--nav--controller')[0]
        mediaNavController.addEventListener('click', this.mediaNavControll)

    }

    mediaNavControll = () => {
        var mainSidebar = document.getElementsByClassName('main-sidebar')[0]
        if(mainSidebar.classList.contains("hide-sidebar")){
            mainSidebar.classList.add("show-sidebar")
            mainSidebar.classList.remove("hide-sidebar")
        } else {
            mainSidebar.classList.add("hide-sidebar")
            mainSidebar.classList.remove("show-sidebar")
        }
    }

    render(){
        return(
            <div className="d-flex justify-content-between">
                {
                    this.props.type === "1" ? <h2>{this.props.title}</h2> 
                    : <p>{this.props.title} > <span className="font-weight-bold">{this.props.title2}</span></p>
                }
                <div className="media--nav--controller">
                    <FontAwesomeIcon icon={faBars} size={"2x"} />
                </div>
            </div>
            
        )
    }
}

export default Navbar;