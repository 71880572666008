import authentication from './authentication';
import chart from './chart';
import condition from './condition';
import recommendation from './recommendation';
import custom from './custom';


import { combineReducers } from 'redux';

export default combineReducers({
    authentication,
    chart,
    condition,
    recommendation,
    custom
})